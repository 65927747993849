import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    return {
        Container: styled.div({
            padding: size.isMobile ? '0em' : '1.5em',
            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile ? '1em' : '2em',
        }),
        size,
    };
};
