import React, { HTMLAttributes, memo, useEffect, useRef, useState } from 'react';
import style from './style';
import { CInputGroup, CInputGroupText, CFormInput, CFormTextarea } from '@coreui/react';
import useTheme from '@js/hooks/useTheme';
import useAppDispatch from '@js/hooks/useAppDispatch';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { userSimbolCounter } from '@maket/js/constants/values';

interface Tprops extends HTMLAttributes<HTMLDivElement> {
    placeholder: string;
    iconName: string;
    setExtState?: ActionCreatorWithPayload<string>;
    setExtStateOuter?: (v: string) => void;
    marginBottomNone?: boolean;
    disabled?: boolean;
    value?: string;
    textareaRows?: number;
    textarea?: boolean;
    stopInput?: boolean;
    stopInputMaxSize3000?: boolean;
    regExp?: RegExp;
    typeInput?: string;
    inputStyle?: HTMLAttributes<HTMLInputElement>;
    defaultValue?: string;
    autocomplete?: string;
}

export default memo(
    ({
        placeholder,
        iconName,
        setExtState,
        setExtStateOuter,
        marginBottomNone,
        disabled,
        value,
        textareaRows,
        textarea,
        stopInput,
        stopInputMaxSize3000,
        regExp,
        typeInput,
        inputStyle,
        defaultValue,
        autocomplete,
        ...props
    }: Tprops) => {
        const { Icon, size } = style();
        const { blackIcon, liteGreen, notificationRed } = useTheme();
        const [state, setState] = useState(value || '');
        const dispatch = useAppDispatch();

        const textAreaRef = useRef(null);

        const [textAreaRowsCounter, settextAreaRowsCounter] = useState(textareaRows || 1);
        const [regExpErr, setRegExpErr] = useState<boolean | null>(null);

        useEffect(() => {
            if (value) {
                setState(value);
            }
        }, [value]);
        useEffect(() => {
            if (setExtState) {
                dispatch(setExtState(state));
            }
            if (setExtStateOuter) {
                setExtStateOuter(state);
            }
            if (textAreaRef.current) {
                if (textAreaRowsCounter < 8) {
                    // @ts-ignore: Unreachable code error
                    textAreaRef.current.innerHTML = state;
                    // @ts-ignore: Unreachable code error
                    const rowCounter = (textAreaRef.current.scrollHeight - 2) / 16;
                    settextAreaRowsCounter(rowCounter);
                }
            }
            if (regExp) {
                const checkRegExp = state.match(regExp);
                setRegExpErr(!!checkRegExp);
            } else {
                setRegExpErr(null);
            }
        }, [state]);

        return (
            <CInputGroup
                data-inputgroupe="true"
                {...props}
                className={marginBottomNone ? '' : 'mb-3'}
            >
                {iconName != 'none' && (
                    <CInputGroupText
                        style={{
                            padding: size.isMobile ? '0.3em' : '0.5em',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',

                            transition: '0.3s',
                            backgroundColor:
                                regExpErr == null ? '' : regExpErr ? liteGreen : notificationRed,
                        }}
                        id="basic-addon1"
                    >
                        <Icon className={`icon-${iconName}`}></Icon>
                    </CInputGroupText>
                )}
                {textarea ? (
                    <div style={{ position: 'relative', flex: ' 1 1' }}>
                        <CFormTextarea
                            data-textarea="true"
                            onChange={e => {
                                const check = state.length > e.target.value.length;
                                if (
                                    (stopInputMaxSize3000
                                        ? userSimbolCounter > 1
                                            ? true
                                            : false
                                        : true && !stopInput) ||
                                    check
                                ) {
                                    setState(e.target.value), e.target.focus();
                                }
                            }}
                            placeholder={placeholder}
                            style={{
                                color: blackIcon,
                                height: '100%',
                                resize: 'none',
                                width: '100%',
                                minHeight: '4em',
                                maxHeight: '11em',
                            }}
                            value={state}
                            disabled={disabled}
                            rows={textAreaRowsCounter}
                            className="textareaInput scrollbar-unvisable"
                        ></CFormTextarea>
                        <div
                            ref={textAreaRef}
                            style={{
                                fontSize: '1rem',
                                position: 'absolute',
                                width: '100%',
                                maxWidth: '100%',
                                wordWrap: 'break-word',
                                opacity: 0,
                                pointerEvents: 'none',
                                lineHeight: '1rem',
                                padding: '0 .825rem',
                            }}
                        ></div>
                    </div>
                ) : (
                    <CFormInput
                        defaultValue={defaultValue}
                        onChange={e => {
                            const check = state.length > e.target.value.length;
                            if (
                                (stopInputMaxSize3000
                                    ? userSimbolCounter > 1
                                        ? true
                                        : false
                                    : true && !stopInput) ||
                                check
                            ) {
                                setState(e.target.value), e.target.focus();
                            }
                        }}
                        placeholder={placeholder}
                        style={{
                            color: blackIcon,
                            ...inputStyle?.style,
                        }}
                        value={state}
                        disabled={disabled}
                        type={typeInput}
                        aria-autocomplete={autocomplete}
                    />
                )}
            </CInputGroup>
        );
    },
);
