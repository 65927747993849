import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';
import { motion } from 'framer-motion';

export default (lengthEl?: number, type?: 'page', reverce?: boolean) => {
    const size = useWindowSizeCheck();
    const {
        mainBlack,
        whiteText,
        grayShadowColor,
        hoverLiteGreen,
        altTextBlackColor,
        grayTextSelect,
        navBlackBack,
        navHoverGray,
        mainNavBackground,
    } = useTheme();
    return {
        Container: styled(motion.div)({
            position: 'relative',
            fontFamily: 'Inter, sans-serif',
            padding: size.isMobile || size.isTab ? '1em' : '0.5em',
            display: 'flex',
            alignItems: 'center',
            transition: '0.3s',
            cursor: 'pointer',

            color: type == 'page' ? mainBlack : size.isMobile || size.isTab ? whiteText : mainBlack,
            backgroundColor:
                type == 'page'
                    ? whiteText
                    : size.isMobile || size.isTab
                    ? mainNavBackground
                    : whiteText,
            boxShadow: `0 0 0 2px ${whiteText}`,
            borderRadius: 8,

            justifyContent: size.isMobile || size.isTab ? 'center' : '',
        }),
        MainContainer: styled.div({
            display: 'flex',
            gap: '0.625em',
            alignItems: 'center',
            justifyContent: type == 'page' ? 'space-between' : 'center',
            minWidth: '6em',
            width: '100%',
        }),
        MainText: styled.div({
            fontSize: '0.75em',
        }),
        Arrow: styled(motion.img)({
            transition: '0.3s',
            width: 6,
            filter: type == 'page' ? '' : size.isMobile || size.isTab ? 'invert(100%)' : '',
        }),
        SelectList: styled(motion.div)({
            position: 'absolute',
            overflow: 'hidden',
            boxShadow:
                size.isMobile || size.isTab || type == 'page'
                    ? `0 0 0 2px ${whiteText}`
                    : `0 0 10px ${grayShadowColor}`,
            outline: type == 'page' ? `1px solid ${grayShadowColor}` : '',

            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            columnGap: '1.5em',

            opacity: 0,
            pointerEvents: 'none',
            zIndex: 10,
            width: size.isTab || size.isMobile ? '97%' : '',

            transition: '0.1s',
            backgroundColor:
                type == 'page' ? whiteText : size.isMobile || size.isTab ? navBlackBack : whiteText,
            right: size.isMobile || size.isTab || type == 'page' ? '0' : '-25%',
            bottom: size.isMobile || size.isTab || type == 'page' ? '0' : '-10px',
            height:
                size.isMobile || size.isTab || type == 'page'
                    ? 'fit-content'
                    : `calc(3.375em * ${lengthEl ? (lengthEl < 7 ? lengthEl : 7) : 7})`,
            borderRadius:
                size.isMobile || size.isTab || type == 'page'
                    ? reverce
                        ? '8px 8px 0 0'
                        : '0 0 8px 8px'
                    : 8,
        }),
        SelectElement: styled.div({
            display: 'flex',
            justifyContent: 'space-between',
            transition: '0.3s',
            borderRadius: 4,

            ':hover': {
                backgroundColor:
                    type == 'page'
                        ? hoverLiteGreen
                        : size.isMobile || size.isTab
                        ? navHoverGray
                        : hoverLiteGreen,
            },

            width:
                size.isMobile || size.isTab || type == 'page'
                    ? '100%'
                    : 'calc((1.1875em * 2 + 1em)*4)',
            color:
                type == 'page'
                    ? grayTextSelect
                    : size.isMobile || size.isTab
                    ? whiteText
                    : grayTextSelect,
            fontSize: type == 'page' ? '0.8em' : '',
            padding:
                type == 'page' ? '0.35em 0.75em' : size.isMobile || size.isTab ? '0.5em' : '1.1em',
        }),
        SelectElementCheckMark: styled(motion.div)({
            opacity: 0,
            transition: '0.3s',
            fontSize: '1em',
            color: altTextBlackColor,

            filter: size.isMobile || size.isTab ? 'invert(100%)' : '',
        }),
        size,
    };
};
