import { userSimbolCounter } from '@maket/js/constants/values';
import { createSlice } from '@reduxjs/toolkit';

const initialState: {
    state: boolean;
    reasonInputState: string;
    responseInputState: string;
} = {
    state: true,
    reasonInputState: '',
    responseInputState: '',
};

const muteAddItemSwithState = createSlice({
    name: 'muteAddItemSwithState',
    initialState,
    reducers: {
        setSwithState: (state, { payload }: { payload: boolean }) => {
            state.state = payload;
        },
        setInputReasonState: (state, { payload }: { payload: string }) => {
            state.reasonInputState = payload;
        },
        setInputResponseState: (state, { payload }: { payload: string }) => {
            state.responseInputState = payload;
        },
    },
});

export const { setSwithState, setInputReasonState, setInputResponseState } =
    muteAddItemSwithState.actions;

export default muteAddItemSwithState;
