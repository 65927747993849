import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    return {
        InputBox: styled.div({
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '-1em',
        }),
        InputItem: styled.div({}),
        size,
    };
};
