import React, { HTMLAttributes } from 'react';
import style from './style';
import useWindowSizeCheck from '@js/hooks/useWindowSizeCheck';

interface Tprops extends HTMLAttributes<HTMLDivElement> {
    fullVersion?: boolean;
}

export default ({ fullVersion, ...props }: Tprops) => {
    const { LogoBox, Logo, LogoText, LogoTexSignature } = style();
    const { isMobile } = useWindowSizeCheck();
    return (
        <LogoBox {...props}>
            <Logo src="/img/Logo.svg" />
            {!isMobile || fullVersion ? (
                <LogoText>
                    MazeKeeper
                    <LogoTexSignature>βeta</LogoTexSignature>
                </LogoText>
            ) : (
                <></>
            )}
        </LogoBox>
    );
};
