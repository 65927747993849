import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { darkGray, whiteText } = useTheme();
    return {
        Container: styled.div({
            padding: size.isDesktop
                ? '3.75em 5.31em'
                : size.isMobile
                ? '1.5em 0.5em'
                : '1.75em 2.31em',
            fontFamily: 'Inter, sans-serif',

            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile || size.isTab ? '1.5em' : '3.125em',

            fontSize: size.isMicroMobile ? '0.7em' : '',
        }),
        Top: styled.div({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '1.56em',
        }),
        Bottom: styled.div({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: size.isMobile || size.isTab ? 'column' : 'row',
            gap: '0.5em',
        }),
        TitleBox: styled.div({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: size.isLaptop || size.isTab ? 'start' : 'center',

            flexDirection: size.isMobile || size.isLaptop || size.isTab ? 'column' : 'row',
            gap: size.isMobile || size.isLaptop || size.isTab ? '2em' : '0',
        }),
        Title: styled.div({
            fontSize: size.isMobile ? '2.75em' : '2.17em',
            fontWeight: 700,
            width: size.isMobile ? '100%' : '',
        }),
        CountBox: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: '1.125em',
        }),
        Counter: styled.div({
            fontSize: size.isMobile ? '0.875em' : '1.5em',
            fontWeight: 400,
            width: size.isMobile ? '100%' : '',
            span: {
                color: darkGray,
            },
        }),

        size,
    };
};
