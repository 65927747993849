import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';
import { motion } from 'framer-motion';

export default () => {
    const size = useWindowSizeCheck();
    const { whiteText, mainBlack } = useTheme();
    return {
        Container: styled(motion.div)({
            height: '33vh',
            width: '100vw',
            position: 'fixed',
            backgroundColor: whiteText,
            zIndex: 10,
            bottom: 0,
            left: 0,

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            fontFamily: 'Inter, sans-serif',

            padding: '3em 1em',
        }),
        Text: styled.div({
            lineHeight: '1.5em',
            maxWidth: size.isMobile ? '90%' : '80%',
            textAlign: 'center',
            overflow: 'auto',
            maxHeight: '100%',
            a: { textDecoration: 'underline', color: mainBlack },
        }),
        Icon: styled.img({
            position: 'absolute',
            top: '1em',
            right: '1em',
            cursor: 'pointer',
        }),
        size,
    };
};
