import React, { useEffect, useLayoutEffect, useState } from 'react';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import style from './style';
import Switch from '@maket/js/components/microComponets/switch';
import { Tprofile } from '@maket/js/types/state/auth';
import { TactiveLanguage } from '@maket/js/types/state/language';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import {
    setActiveProfile,
    setModerProfile,
    setProtectionProfile,
    setDeleteForwardedStoryProfile,
    setDeleteAnonymousMessagesProfile,
} from '@maket/js/state/settingProfile/settingPassValueState';
import { useParams } from 'react-router';

type Ttype =
    | 'active'
    | 'moder'
    | 'protection'
    | 'delete-forwarded-story'
    | 'delete-anonymous-messages';

let lastWidth = Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.body.clientWidth,
    document.documentElement.clientWidth,
);

export default ({
    settingProfile,
    type,
}: {
    settingProfile: Tprofile | undefined;
    type: Ttype;
}) => {
    const dispatch = useAppDispatch();
    const { Container, Item, ItemTitle } = style();
    const { AuthSettingContainer, AuthSettingMain, AuthSettingTitle, AuthSettingText, size } =
        style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const { active, moder, protection, delete_anonymous_messages, delete_forwarded_story } =
        useAppSelector(state => state.settingProfileValueState);

    const [selectValue, setSelectValue] = useState(() => {
        switch (type) {
            case 'active':
                return active;
            case 'moder':
                return moder;
            case 'protection':
                return protection;
            case 'delete-forwarded-story':
                return delete_forwarded_story;
            case 'delete-anonymous-messages':
                return delete_anonymous_messages;
            default:
                return false;
        }
    });

    useEffect(() => {
        let scrollWidth = Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.body.clientWidth,
            document.documentElement.clientWidth,
        );
        if (settingProfile && lastWidth == scrollWidth) {
            switch (type) {
                case 'active':
                    dispatch(setActiveProfile(settingProfile.status === 'active'));
                case 'moder':
                    dispatch(setModerProfile(!!settingProfile.moderation));
                case 'protection':
                    dispatch(setProtectionProfile(!!settingProfile.active_protection));
                case 'delete-forwarded-story':
                    dispatch(
                        setDeleteForwardedStoryProfile(!!settingProfile.delete_forwarded_story),
                    );
                case 'delete-anonymous-messages':
                    dispatch(
                        setDeleteAnonymousMessagesProfile(
                            !!settingProfile.delete_anonymous_messages,
                        ),
                    );
            }
        }
    }, [settingProfile]);

    useEffect(() => {
        setSelectValue(() => {
            switch (type) {
                case 'active':
                    return active;
                case 'moder':
                    return moder;
                case 'protection':
                    return protection;
                case 'delete-forwarded-story':
                    return delete_forwarded_story;
                case 'delete-anonymous-messages':
                    return delete_anonymous_messages;
                default:
                    return false;
            }
        });
    }, [active, moder, protection, delete_anonymous_messages, delete_forwarded_story]);

    return (
        <Container>
            <Item>
                <AuthSettingContainer>
                    <Switch
                        initState={selectValue}
                        setState={v => {
                            setSelectValue(v);

                            if (type == 'active') {
                                dispatch(setActiveProfile(v));
                            }
                            if (type == 'moder') {
                                dispatch(setModerProfile(v));
                            }
                            if (type == 'protection') {
                                dispatch(setProtectionProfile(v));
                            }
                            if (type == 'delete-forwarded-story') {
                                dispatch(setDeleteForwardedStoryProfile(v));
                            }
                            if (type == 'delete-anonymous-messages') {
                                dispatch(setDeleteAnonymousMessagesProfile(v));
                            }
                        }}
                        closed={
                            type == 'protection'
                                ? !settingProfile?.settings.active_protection
                                : false
                        }
                    />
                    <AuthSettingMain>
                        <AuthSettingTitle>
                            {createText(type, activeLanguage, 'title')}
                        </AuthSettingTitle>
                        <AuthSettingText>
                            {createText(type, activeLanguage, 'text', selectValue)}
                        </AuthSettingText>
                    </AuthSettingMain>
                </AuthSettingContainer>
            </Item>
        </Container>
    );
};

function createText(
    type: Ttype,
    activeLanguage: TactiveLanguage,
    pos: 'title' | 'text',
    status?: boolean,
) {
    if (pos == 'title') {
        if (type == 'active') {
            return activeLanguage['ProfileSettings.Active.Title'];
        }
        if (type == 'moder') {
            return activeLanguage['ProfileSettings.Moderation.Title'];
        }
        if (type == 'protection') {
            return activeLanguage['ProfileSettings.ActiveProtection.Title'];
        }
        if (type == 'delete-forwarded-story') {
            return activeLanguage['ProfileSettings.DeleteForwardedStory.Title'];
        }
        if (type == 'delete-anonymous-messages') {
            return activeLanguage['ProfileSettings.DeleteAnonymousMessages.Title'];
        }
    }
    if (pos == 'text') {
        if (type == 'active') {
            return `${activeLanguage['Status.']}: ${
                status ? activeLanguage['Active.'] : activeLanguage['NotActive.']
            }`;
        }
        if (type == 'moder') {
            return `${activeLanguage['Status.']}: ${
                status
                    ? activeLanguage['ProfileSettings.Moderation.Active']
                    : activeLanguage['ProfileSettings.Moderation.NotActive']
            }`;
        }
        if (type == 'protection') {
            return activeLanguage['ProfileSettings.ActiveProtection.Text'];
        }
        if (type == 'delete-forwarded-story') {
            return `${activeLanguage['Status.']}: ${
                status
                    ? activeLanguage['ProfileSettings.DeleteForwardedStory.Active']
                    : activeLanguage['ProfileSettings.DeleteForwardedStory.NotActive']
            }`;
        }
        if (type == 'delete-anonymous-messages') {
            return `${activeLanguage['Status.']}: ${
                status
                    ? activeLanguage['ProfileSettings.DeleteAnonymousMessages.Active']
                    : activeLanguage['ProfileSettings.DeleteAnonymousMessages.NotActive']
            }`;
        }
    }
}
