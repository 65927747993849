import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { TserviseNames } from '@maket/js/types/state/serviseList';
import axios from 'axios';

type Tprops = {
    token: string;
    code: string;
    tg_id: string;
    tg_str_id: string;
    service_type: TserviseNames;
};

export default async ({ token, code, service_type, tg_id, tg_str_id }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post(
            apiUrl + `/service/${service_type}/bind_by_code`,
            {
                service_type,
                code,
                service_user_id: +tg_id,
                service_user_str_id: tg_str_id,
            },
            {
                headers: { Authorization: `Bearer ${token}` },
            },
        );
        setVisableLoader('notVisable');
        document.cookie = `tgAccessState=${undefined}; path=/;`;
        return req.data?.result;
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
