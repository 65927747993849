import { createSlice } from '@reduxjs/toolkit';

const initialState: { mail: string } = { mail: '' };

const fogotPassState = createSlice({
    name: 'fogotPassState',
    initialState,
    reducers: {
        setMailInputValue: (state, { payload }: { payload: string }) => {
            state.mail = payload;
        },
    },
});

export const { setMailInputValue } = fogotPassState.actions;

export default fogotPassState;
