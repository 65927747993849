import React from 'react';
import style from './style';
import LoginElement from '@js/components/microComponets/loginElement';

import FacebookLogin from '@greatsumini/react-facebook-login';

//login hook
import googleLogin from '@api/auth/google/login';
import facebookLogin from '@api/auth/facebook/login';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { fbAppId } from '@maket/js/constants/values';

type Tprops = {};

export default ({}: Tprops) => {
    const dispatch = useAppDispatch();
    const { Container } = style();

    const lang = useAppSelector(state => state.languageState.language);
    const loginGoogleHook = googleLogin(lang, dispatch);

    return (
        <Container>
            <LoginElement type="google" onClick={() => loginGoogleHook()} />
            {/* <FacebookLogin
                appId={fbAppId}
                onSuccess={res => facebookLogin(res, dispatch, lang)}
                onFail={error => {
                    {
                    }
                }}
                render={({ onClick, logout }) => <LoginElement type="facebook" onClick={onClick} />}
            /> */}
            {/* <LoginElement type="apple" /> */}
        </Container>
    );
};
