import React from 'react';
import style from './style';
import { setActivePopap } from '@js/state/openPopap/openPopapState';
import LanguageTextMain from './languageTextMain';
import useAppDispatch from '@js/hooks/useAppDispatch';
import useAppSelector from '@js/hooks/useAppSelector';

type Tprops = { openFn?: (v: string) => void; openCheck?: string; type?: 'page' };

export default ({ openCheck, openFn, type }: Tprops) => {
    const { MainContainer, Arrow } = style(undefined, type);

    const dispatch = useAppDispatch();
    const { activePopapName } = useAppSelector(state => state.openPopapState);

    return (
        <MainContainer
            onClick={p => {
                if (openFn != undefined) {
                    openFn('languagePopap');
                } else {
                    dispatch(
                        setActivePopap(type == 'page' ? 'languagePopapPage' : 'languagePopap'),
                    );
                }
            }}
        >
            <LanguageTextMain type={type} />
            <Arrow
                src="/img/microIcon/arrow.svg"
                animate={
                    activePopapName == 'languagePopap'
                        ? { transform: 'rotate(180deg)' }
                        : { transform: 'rotate(0deg)' }
                }
            />
        </MainContainer>
    );
};
