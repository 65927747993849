import React from 'react';
import style from './style';
import { comparison, tNameSetting } from '../box';
import useTheme from '@js/hooks/useTheme';

type Tprops = {
    type: tNameSetting;
    activeName: tNameSetting;
    disabled?: boolean;
};

export default ({ type, activeName, disabled }: Tprops) => {
    const { MobileItem, MobileItemIcon, MobileItemName } = style();
    const { textLowGray, mainBlack } = useTheme();
    return (
        <MobileItem style={disabled ? { opacity: 0, pointerEvents: 'none' } : {}}>
            <MobileItemIcon
                animate={{
                    color: type == activeName ? comparison[type].iconColor : textLowGray,
                }}
                className={`icon-${comparison[type].iconName}`}
            ></MobileItemIcon>
            <MobileItemName
                animate={{
                    color: type == activeName ? mainBlack : textLowGray,
                }}
            >
                {comparison[type].title}
            </MobileItemName>
        </MobileItem>
    );
};
