import React, { useState } from 'react';
import style from './style';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { AnimatePresence } from 'framer-motion';

type Tprops = {
    title: string;
    text: string;
    active: boolean;
};

export default ({ text, title, active }: Tprops) => {
    const {
        BottomItem,
        BottomItemTitle,
        BottomItemText,
        BottomItemTitleBotTag,
        Icon,
        Underline,
        CopyAllert,
    } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const [copyState, setCopyState] = useState(false);

    const splitText = activeLanguage[text]?.split('@MazeKeeper_bot') || [];
    const splitText2 = splitText[0]?.split('<br/>');
    return (
        <BottomItem className={`${active ? 'active' : ''}`}>
            <BottomItemTitle>{activeLanguage[title]}</BottomItemTitle>
            <BottomItemText>
                {splitText2 ? (
                    splitText2[1] ? (
                        <>
                            {splitText2[0]}
                            <br />
                            {splitText2[1]}
                        </>
                    ) : (
                        splitText2[0]
                    )
                ) : (
                    <></>
                )}
                {splitText[1] ? (
                    <BottomItemTitleBotTag
                        className="BottomItemTitleBotTag"
                        onClick={() => {
                            navigator.clipboard.writeText('@MazeKeeper_bot');
                            setCopyState(true);
                            setTimeout(() => {
                                setCopyState(false);
                            }, 1500);
                        }}
                    >
                        @MazeKeeper_bot
                        <Underline className="Underline" />
                        <Icon className="icon-copy" />
                    </BottomItemTitleBotTag>
                ) : (
                    <></>
                )}
                {splitText[1]}
            </BottomItemText>

            <AnimatePresence>
                {copyState && (
                    <CopyAllert
                        animate={{ transform: 'translate(-50%, -50%)' }}
                        exit={{ transform: 'translate(-50%, 0%)' }}
                        initial={{ transform: 'translate(-50%, 0%)' }}
                    >
                        <div
                            className="icon-checkMark"
                            style={{ transform: 'translate(0,10%)' }}
                        ></div>
                        {activeLanguage['ChatList.Copy.Link']}
                    </CopyAllert>
                )}
            </AnimatePresence>
        </BottomItem>
    );
};
