import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { whiteText } = useTheme();
    return {
        Container: styled.div({
            padding: size.isDesktop
                ? '3.75em 5.31em'
                : size.isMobile
                ? '1.5em 0.5em'
                : '1.75em 2.31em',

            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile || size.isTab ? '1.5em' : '3.125em',

            fontFamily: 'Inter, sans-serif',

            fontSize: size.isMicroMobile ? '0.7em' : '',
        }),
        Item: styled.div({
            padding: size.isMobile ? '0.71em' : '2.21em',
            backgroundColor: whiteText,
        }),
        size,
    };
};
