import React from 'react';
import style from './style';
import CardColumn from '@js/components/microComponets/cardColumn';
import useWindowSizeCheck from '@js/hooks/useWindowSizeCheck';
import useAppSelector from '@js/hooks/useAppSelector';

const cardConfig = [
    {
        title: 'BlockOneTitle',
        text: 'BlockOneText',
    },
    {
        title: 'BlockTwoTitle',
        text: 'BlockTwoText',
    },
    {
        title: 'BlockThreeTitle',
        text: 'BlockThreeText',
    },
    {
        title: 'BlockFourTitle',
        text: 'BlockFourText',
    },
    {
        title: 'BlockFiveTitle',
        text: 'BlockFiveText',
    },
];

export default () => {
    const { Container, Title, ListCard } = style();
    const { isMobile, isTab } = useWindowSizeCheck();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    return (
        <Container>
            <Title>
                {activeLanguage['Main.Feature.Title']?.split('\\').map((el, i) => (
                    <div key={i + 'previewText'}>
                        {el}
                        <br />
                    </div>
                ))}
            </Title>
            <ListCard>
                {cardConfig.map((el, i) => (
                    <CardColumn
                        key={el.title + i}
                        title={activeLanguage['Main.Feature.' + el.title]}
                        text={activeLanguage['Main.Feature.' + el.text]}
                        typeCard={'arrow'}
                        typeColor="white"
                        style={{ width: isMobile || isTab ? '100%' : '' }}
                    />
                ))}
            </ListCard>
        </Container>
    );
};
