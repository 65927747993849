import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';
import { NavLink } from 'react-router-dom';

export default () => {
    const size = useWindowSizeCheck();
    const {
        whiteText,
        darkGray,
        buttonBlue,
        disconnectRed,
        blackIcon,
        liteGreenProfilePopap,
        grayTextSelect,
    } = useTheme();
    return {
        Container: styled.div({
            backgroundColor: whiteText,
            color: darkGray,
            padding: '0.71em',
            textAlign: 'center',
        }),
        ChatListMain: styled.div({}),

        OneCell: styled.div({
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '1em',
            padding: '0.25em 0',
            height: '100%',

            justifyContent: 'space-between',

            '& .info': { opacity: 0, transition: '0.3s' },
            '& .infoPopap': { opacity: 0, transition: '0.3s', transform: 'translate(-50%, -150%)' },
            ':hover': {
                '& .info': { opacity: 1 },
                '& .infoPopap': { opacity: 1, transform: 'translate(-50%, -100%)' },
            },
            '&.open': {
                '& .info': { opacity: 1 },
                '& .infoPopap': { opacity: 1, transform: 'translate(-50%, -100%)' },
            },
        }),
        OneCellMain: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: '1em',
        }),
        OneCellPopap: styled.div({
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translate(-50%, -100%)',
            width: '100%',
            padding: '0.5em 1em',
            borderRadius: '0.5rem',
            backgroundColor: liteGreenProfilePopap,
            fontWeight: 500,
            textAlign: 'center',
            pointerEvents: 'none',
        }),
        TwoCell: styled.div({
            padding: '0.25em 0',
            display: 'flex',
            alignItems: 'center',

            gap: '1em',
            height: '100%',

            justifyContent: size.isMobile || size.isTab ? 'space-between' : 'start',
        }),
        TwoCellIcon: styled.img({
            width: '1.5em',
            height: '1.5em',
        }),
        ThreeCell: styled.div({
            padding: '0.25em 0',
            height: '100%',
            display: 'flex',
            alignItems: 'center',

            '&.delete': { color: disconnectRed },
            '&.connect': { color: buttonBlue },
            '&.notAdmin': {},

            justifyContent: size.isMobile || size.isTab ? 'space-between' : 'start',
        }),
        FourCell: styled.div({
            padding: '0.25em 0',
            height: '100%',
            display: 'flex',
            alignItems: 'center',

            '&.blue': { color: buttonBlue },

            justifyContent: size.isMobile || size.isTab ? 'space-between' : 'start',
        }),
        FiveCell: styled.div({
            padding: size.isMobile || size.isTab || size.isTab ? 0 : '0.25em',
            height: '100%',
            display: 'flex',
            alignItems: 'center',

            gap: '1em',
            justifyContent: size.isMobile || size.isTab ? 'space-between' : 'start',
        }),
        FiveCellIcon: styled(NavLink)({ fontSize: '1.5em', '&.gray': { color: grayTextSelect } }),
        MobileTable: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
            marginBottom: '2em',
        }),
        MobileTableItem: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5em',
            backgroundColor: whiteText,
            borderRadius: 4,
            padding: '1em',
        }),
        MobileTableItemTitle: styled.div({
            textAlign: 'start',
            color: blackIcon,
            minWidth: '6.5em',
        }),
        size,
    };
};
