import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { whiteText, buttonBlue } = useTheme();
    return {
        Wrapper: styled.div({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
        }),
        Container: styled.div({
            fontFamily: 'Inter, sans-serif',
            width: size.isDesktop ? '35vw' : size.isMobile || size.isTab ? '90vw' : '50vw',

            backgroundColor: whiteText,
            padding: '3.75em',

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '2em',

            fontSize: size.isMicroMobile ? '0.5em' : size.isMobile ? '0.7em' : '',
        }),
        Icon: styled.div({
            color: buttonBlue,
            fontSize: '8em',
        }),
        Title: styled.div({
            fontSize: '2.75em',
            fontWeight: 700,
            textAlign: 'center',
        }),
        Text: styled.div({
            textAlign: 'center',
            fontSize: '1.125em',
        }),
        ButtonBox: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: 10,
        }),
        size,
    };
};
