import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { setAgreeTermsAccount, setSubNewslatterAccount } from '@maket/js/state/auth/authState';
import { TappDispatch } from '@maket/js/state/store';
import axios from 'axios';

type Tprops = {
    token: string;
    dispatch: TappDispatch;
    enable: boolean;
};

export default async ({ token, dispatch, enable }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post<{ status: string }>(
            apiUrl + '/account/subscribe_to_newsletter',
            { enable },
            {
                headers: { Authorization: `Bearer ${token}` },
            },
        );
        setVisableLoader('notVisable');
        dispatch(setSubNewslatterAccount(enable));
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
