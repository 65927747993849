import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import axios from 'axios';

type Tprops = {
    token: string;
    email: string;
};

export default async ({ token, email }: Tprops) => {
    setVisableLoader('visable');
    const req = await axios.post(
        apiUrl + '/account/change_email',
        { email },
        {
            headers: { Authorization: `Bearer ${token}` },
        },
    );
    setVisableLoader('notVisable');
    return req;
};
