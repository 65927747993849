import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { inputBorderColor, whiteText } = useTheme();
    return {
        Container: styled.div({
            position: 'relative',
            width: '100%',
            height: '1em',

            display: 'flex',
            alignItems: 'center',
        }),
        Line: styled.div({
            width: '100%',
            height: 1,
            backgroundColor: inputBorderColor,
        }),
        Content: styled.div({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: ' translate(-50%, -50%)',
            backgroundColor: whiteText,
            padding: '0 0.625em ',
            textAlign: 'center',
        }),
        size,
    };
};
