import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import axios from 'axios';

type Tprops = {
    code: string;
};

export default async ({ code }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post(apiUrl + '/account/reset_password/confirm', {
            code,
        });
        setVisableLoader('notVisable');
        return req.data?.result;
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
