import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

export default () => {
    const size = useWindowSizeCheck();
    const { buttonBlue, grayShadowColor, whiteText, blackIcon } = useTheme();
    return {
        Main: styled.div({
            display: 'flex',
            flexDirection: 'column',
            marginBottom: size.isMobile || size.isTab ? '3em' : '',
        }),
        OneCell: styled(NavLink)({
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '1em',
            padding: '0.25em',
            height: '100%',
            justifyContent: 'space-between',
            maxWidth: size.isMobile || size.isTab ? '100%' : '30vw',
            div: {
                '&.blue': { color: buttonBlue },
                '&.pointer': {
                    opacity: size.isMobile || size.isTab ? 1 : 0,
                    transition: '0.3s',
                    borderRadius: 5,

                    width: '1.5em',
                    height: '1.5em',
                    padding: '0.25em',

                    '&:hover': {
                        boxShadow: `0 0px 5px ${grayShadowColor}`,
                    },
                },
                '&.visable': { opacity: 1 },
                '&.title': {
                    color: buttonBlue,

                    whiteSpace: 'nowrap',
                    width: size.isMobile || size.isTab ? '100%' : '',
                    maxWidth: size.isMobile || size.isTab ? '40vw' : '30vw',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
            },
            ':hover': {
                div: {
                    '&.pointer': {
                        opacity: 1,
                    },
                },
            },
        }),
        OneCellMain: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: '1em',
            flex: size.isMobile || size.isTab ? '0 10%' : '0 0 75%',
        }),
        TwoCell: styled.div({
            padding: '0.25em',
            display: 'flex',
            alignItems: 'center',

            gap: '1em',
            height: '100%',
        }),
        OneCellText: styled.div({ color: blackIcon, transition: '0.3s' }),
        OneCellIcon: styled.div({
            fontSize: '1.5em',
            transform: 'translate(0,7.5%)',
            color: blackIcon,
        }),
        ThreeCell: styled.div({
            padding: '0.25em',
            height: '100%',
            display: 'flex',
            justifyContent: size.isMobile || size.isTab ? 'space-between' : 'center',
            alignItems: 'center',

            '&.connect': { color: buttonBlue },
            '&.notAdmin': {},
            gap: size.isMobile || size.isTab ? '0.5em' : 0,
        }),
        FourCell: styled.div({
            padding: '0.25em',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: size.isMobile || size.isTab ? 'space-between' : 'center',

            '&.blue': { color: buttonBlue },
            gap: size.isMobile || size.isTab ? '0.5em' : 0,
        }),
        FiveCell: styled.div({
            padding: '0.25em',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: size.isMobile || size.isTab ? 'space-between' : 'center',

            gap: size.isMobile || size.isTab ? '0.5em' : '1em',
        }),
        ButtonBox: styled.div({ display: 'flex', flexDirection: 'column' }),
        AddInputBox: styled(motion.div)({
            display: 'flex',
            gap: '1em',
        }),
        MobileTable: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
            marginBottom: '2em',
        }),
        MobileTableItem: styled(NavLink)({
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5em',
            backgroundColor: whiteText,
            borderRadius: 4,
            padding: '1em',
            color: blackIcon,
        }),
        MobileTableItemTitle: styled.div({
            textAlign: 'start',
            color: blackIcon,
        }),

        Bottom: styled.div({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: size.isMobile || size.isTab || size.isLaptop ? 'column' : 'row',
            gap: '0.5em',

            marginTop: '3.125em',
        }),
        size,
    };
};
