import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { main__previewBackground } = useTheme();
    return {
        Container: styled.div({
            position: 'relative',
            backgroundColor: main__previewBackground,
            padding: size.isMicroMobile
                ? '2em 1em'
                : size.isMobile || size.isTab
                ? '3em 1em'
                : '4em 5em',
            fontFamily: 'Inter, sans-serif',

            display: 'flex',
            justifyContent: 'space-between',

            flexDirection: size.isMobile || size.isTab ? 'column' : 'row',
            gap: size.isMicroMobile ? '2em' : size.isMobile || size.isTab ? '3em' : '',
            fontSize: size.isDesktop ? '1em' : size.isLaptop ? '0.8em' : '',
        }),
        Background: styled.img({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
            display: size.isMobile || size.isTab ? 'none' : 'block',
        }),
        BackgroundMask: styled.div({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
        }),
        size,
    };
};
