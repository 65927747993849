import React from 'react';
import style from './style';
import CardOneIcon from '@js/components/microComponets/cardOneIcon';
import useAppSelector from '@js/hooks/useAppSelector';

const cardContent = [
    {
        title: 'CardOneTitle',
        text: 'CardOneText',
        iconName: 'magic',
    },
    {
        title: 'CardTwoTitle',
        text: 'CardTwoText',
        iconName: 'switch',
    },
    {
        title: 'CardThreeTitle',
        text: 'CardThreeText',
        iconName: 'handPalm',
    },
    {
        title: 'CardFourTitle',
        text: 'CardFourText',
        iconName: 'incognito',
    },
];

export default () => {
    const { Bottom, BottomTop, BottomTopTitle, BottomTopText, BottomBottom } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <Bottom id="mainWhyMazeKeeper">
            <BottomTop>
                <BottomTopTitle>{activeLanguage['Main.PreviewHowTitle']}</BottomTopTitle>
                <BottomTopText>{activeLanguage['Main.PreviewHowText']}</BottomTopText>
            </BottomTop>
            <BottomBottom>
                {cardContent.map((el, i) => (
                    <CardOneIcon
                        key={`${el} + ${i}`}
                        title={activeLanguage['Main.Preview.' + el.title]}
                        text={activeLanguage['Main.Preview.' + el.text]}
                        iconName={el.iconName}
                    />
                ))}
            </BottomBottom>
        </Bottom>
    );
};
