import { createSlice } from '@reduxjs/toolkit';

const initialState: {
    index: number;
} = {
    index: 2,
};

const activeSlide = createSlice({
    name: 'activeSlide',
    initialState,
    reducers: {
        setActiveSlide: (state, { payload }: { payload: number }) => {
            state.index = payload;
        },
    },
});

export const { setActiveSlide } = activeSlide.actions;

export default activeSlide;
