import React from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';
import CardOneIcon from '@js/components/microComponets/cardOneIcon';

const cardList = [
    { title: 'Main.Support.CardOneTitle', text: 'Main.Support.CardOneText', iconName: 'teatcher' },
    { title: 'Main.Support.CardTwoTitle', text: 'Main.Support.CardTwoText', iconName: 'defend' },
    {
        title: 'Main.Support.CardThreeTitle',
        text: 'Main.Support.CardThreeText',
        iconName: 'allTime',
    },
];

export default () => {
    const { Container, Title, Text, Background, BackgroundMask, CardList, size } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <Container>
            <Title>{activeLanguage['Main.Support.Title']}</Title>
            <Text>{activeLanguage['Main.Support.Text']}</Text>
            <CardList>
                {cardList.map(el => (
                    <CardOneIcon
                        key={el.title}
                        text={activeLanguage[el.text]}
                        title={activeLanguage[el.title]}
                        iconName={el.iconName}
                        style={{ fontSize: size.isTab ? '1.5em' : '', flex: '0 0 27%' }}
                    />
                ))}
            </CardList>
            <BackgroundMask />
            <Background src="/img/background/home__support.svg"></Background>
        </Container>
    );
};
