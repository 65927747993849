import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';

export default () => {
    const { isMobile, isLaptop, isTab } = useWindowSizeCheck();
    const { whiteText } = useTheme();
    return {
        Container: styled.div({
            display: 'flex',
            justifyContent: 'space-between',
            gap: isMobile ? '0' : '2.5em',
            padding: isMobile ? '0 8px' : '0 16px',
            position: 'fixed',
            width: '100%',
            top: 0,
            backgroundColor: whiteText,
            zIndex: 100,
            fontFamily: 'Inter, sans-serif',

            maxHeight: '3.4em',
        }),
        Item: styled.div(
            isMobile
                ? {
                      display: 'flex',
                      alignItems: 'center',
                      flex: '0 0 15%',
                      gap: 15,
                      padding: '7px 0',
                      ':nth-of-type(2n)': { justifyContent: 'end', flex: '1 1' },
                  }
                : {
                      display: 'flex',
                      alignItems: 'center',
                      ':nth-of-type(2n+1)': {
                          flex: isLaptop ? '0 0 20%' : isTab ? '0 0 30%' : '0 0 20%',
                          gap: 15,
                          padding: '7px 0',
                      },
                      ':nth-of-type(2n)': {
                          flex: '1 1',
                          display: 'flex',
                          gap: isLaptop ? '1em' : '2em',
                          justifyContent: isLaptop ? 'space-between' : '',
                      },
                      ':nth-of-type(3n)': { justifyContent: 'end' },
                      ':nth-of-type(1)': {
                          flex: '0 0 calc(16rem - 1.5em)',
                          justifyContent: 'space-between',
                      },
                  },
        ),
    };
};
