import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';
import { motion } from 'framer-motion';

export default () => {
    const { isMicroMobile } = useWindowSizeCheck();
    const { darkGray, whiteText, grayShadowColor, hoverLiteGreen, grayTextSelect, mainBlack } =
        useTheme();
    return {
        MobileContainer: styled.div({
            position: 'relative',
            border: `1px solid ${darkGray}`,
            borderRadius: 24,
            padding: '4px 8px',
            height: 'fit-content',

            pointerEvents: 'none', // убрать когда будут доступны версии кроме telegram
        }),
        MobileButtonContainer: styled(motion.div)({
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
        }),
        MobileItem: styled.div({
            display: 'flex',
            gap: 4,
            alignItems: 'center',
            fontWeight: 600,
        }),
        MobileItemIcon: styled(motion.div)({
            width: '1em',
            height: '1em',
            color: '#E4E4E7',
            fontSize: '1.5em',
            transition: '0.3s',
            transform: 'translate(0, 0%)',
        }),
        MobileItemName: styled(motion.div)({
            fontSize: '1.0625em',
        }),
        MobilePopap: styled(motion.div)({
            position: 'absolute',
            backgroundColor: whiteText,
            borderRadius: 8,
            boxShadow: `0 0 10px ${grayShadowColor}`,
            padding: 12,
            bottom: '-40%',
            left: isMicroMobile ? '-12%' : '5%',
            transform: 'translate(0, 100%)',

            opacity: 0,
            pointerEvents: 'none',
        }),
        PopapButton: styled.div({
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            gap: '1em',
            padding: 15,
            borderRadius: 4,
            transition: '0.3s',
            width: 'calc((30px + 1.5em)*4.2)',
            color: grayTextSelect,

            ':hover': {
                backgroundColor: hoverLiteGreen,
            },
        }),
        CheckMarkMobile: styled(motion.div)({
            position: 'absolute',
            top: '50%',
            right: 15,
            fontSize: '1',
            transform: 'translate(0,-50%)',
            color: mainBlack,

            opacity: 0,
        }),
        MobileButtonVrap1: styled.div({
            position: 'absolute',
            top: 0,
            left: 6,
            pointerEvents: 'none',
            overflow: 'hidden',
            width: '100%',
            height: '100%',
        }),
        MobileButtonVrap2: styled.div({
            position: 'relative',
        }),
    };
};
