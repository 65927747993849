import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { logoLiteBlue, mainBlack } = useTheme();
    return {
        LogoBox: styled.div({
            display: 'flex',
            fontWeight: 600,
            gap: '0.25em',
            alignItems: 'center',
            fontFamily: 'Inter, serif',
            color: mainBlack,
        }),
        Logo: styled.img({
            width: '1.75em',
            aspectRatio: 1,
        }),
        LogoText: styled.div({
            fontSize: '1.125em',
            position: 'relative',
        }),
        LogoTexSignature: styled.div({
            position: 'absolute',
            top: '90%',
            right: 0,
            fontSize: '0.625em',
            color: logoLiteBlue,
        }),
        size,
    };
};
