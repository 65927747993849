import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { setStateSidebar } from '@maket/js/state/sidebarSicky/sidebarSickyState';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default () => {
    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setStateSidebar(true));
    }, [location.pathname]);
    return <></>;
};
