import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { setServiceList } from '@maket/js/state/auth/authState';
import { TappDispatch } from '@maket/js/state/store';
import { TserviceListItem } from '@maket/js/types/state/auth';
import axios from 'axios';

type Tprops = {
    token: string;
    dispatch: TappDispatch;
};

export default async ({ token, dispatch }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post<Array<TserviceListItem>>(
            apiUrl + `/service/connection/list`,
            {},
            {
                headers: { Authorization: `Bearer ${token}` },
            },
        );
        setVisableLoader('notVisable');

        dispatch(setServiceList(req.data));
        return req.data;
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
