import { unlockReloadCheck } from '@maket/js/components/mainComponents/tokenCheck';
import { logout } from '@maket/js/state/auth/authState';
import { setActivePopap } from '@maket/js/state/openPopap/openPopapState';
import { TappDispatch } from '@maket/js/state/store';
import { deleteToken } from '@maket/js/state/tokenState';
import { NavigateFunction } from 'react-router-dom';

export default (dispatch: TappDispatch, navigate: NavigateFunction, notRedirect?: boolean) => {
    document.cookie = `tgAccessState=${undefined}; path=/;`;
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(setActivePopap(''));
    unlockReloadCheck();
    if (!notRedirect) {
        navigate('app/auth/auth');
    }
    document.cookie = `tgAccessState=${undefined}; path=/;`;
};
