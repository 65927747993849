import React, { HTMLAttributes } from 'react';
import style from './style';

interface Tprops extends HTMLAttributes<HTMLDivElement> {
    content: any;
    title?: string;
    titleSize?: string;
}

export default ({ content, title, titleSize, ...props }: Tprops) => {
    const { Container, Title, Content } = style(titleSize);
    if (title) {
        return (
            <Container {...props}>
                <Title>{title}</Title>
                <Content>{content}</Content>
            </Container>
        );
    } else {
        return (
            <Container {...props}>
                <Content>{content}</Content>
            </Container>
        );
    }
};
