import React, { useCallback, useEffect, useRef, useState } from 'react';
import style from './style';
import Button from '@maket/js/components/microComponets/button';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import Input from '@maket/js/components/microComponets/input';
import useTheme from '@maket/js/hooks/useTheme';
import createProfile from '@maket/js/api/requiresToken/profile/createProfile';
import getProfile from '@maket/js/api/requiresToken/payments/getProfile';
import ButtonAllert, { TalertState } from '@maket/js/components/microComponets/buttonAllert';

let globCheck = false;
export default () => {
    const { size } = style();
    const dispatch = useAppDispatch();

    const InputBoxRef = useRef<HTMLDivElement>(null);
    const [create, setCreate] = useState(false);

    const token = useAppSelector(state => state.tokenState.token);
    const language = useAppSelector(state => state.languageState.language);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const bufferRef = useRef<TalertState>(null);

    const { ButtonBox, AddInputBox } = style();
    const { blackIcon } = useTheme();

    const [allertActive, setAllertActive] = useState<TalertState>({
        typeAllert: '',
        textAllert: '',
    });

    useEffect(() => {
        if (globCheck) {
            setAllertActive({
                textAllert: 'ProfileList.AllertLimitProfiles',
                typeAllert: 'danger',
            });
            globCheck = false;
        }
    }, [bufferRef.current]);

    return (
        <ButtonBox>
            {create && (
                <AddInputBox
                    ref={InputBoxRef}
                    animate={{ transform: 'translate(0, 0%)' }}
                    initial={{
                        transform: 'translate(0,-50%)',
                    }}
                    exit={{ transform: 'translate(0,-50%)' }}
                >
                    <Input
                        style={{ height: '3em' }}
                        placeholder={activeLanguage['ProfileList.Input.Create.Placeholder']}
                        iconName="pencilSimple"
                    />
                    <Button
                        onClick={() => {
                            const name = InputBoxRef.current?.querySelector('input')?.value;
                            if (name) {
                                if (name.length > 254) {
                                    setAllertActive({
                                        textAllert: 'ProfileList.Alert.NameLengthError',
                                        typeAllert: 'danger',
                                    });
                                } else {
                                    createProfile({
                                        token,
                                        name,
                                        language: language.toLowerCase(),
                                    }).then(res => {
                                        if (res && res.data && res.data.errors) {
                                            if (res.data.errors.includes('profile_limit_reached')) {
                                                globCheck = true;
                                            }
                                        }
                                        setCreate(false);
                                        getProfile({ dispatch, token }).then(d => {});
                                    });
                                }
                            }
                        }}
                        borderColor={blackIcon}
                        style={{ minWidth: '10em' }}
                        typeButt="black"
                    >
                        {activeLanguage['Add.']}
                    </Button>
                </AddInputBox>
            )}
            <ButtonAllert
                stateAllertOuter={allertActive}
                setAllertFn={setAllertActive}
                buttonContent={`+ ${activeLanguage['Add.']}`}
                onClick={() => {
                    setCreate(p => !p);
                }}
                typeButt="black"
                style={{
                    minWidth: 'fit-content',
                    width: size.isMobile || size.isTab ? '100%' : '20vw',
                }}
                styleAlert={{
                    // @ts-ignore: Unreachable code error
                    width: `calc(${
                        size.isMobile || size.isTab ? '95vw' : size.isLaptop ? '40vw' : '75vw'
                    } - ${size.isMobile ? '0' : size.isTab ? '3.5em' : '20vw'})`,
                    left: `calc(
						(${size.isMobile || size.isTab ? '95vw' : size.isLaptop ? '40vw' : '75vw'} 
						+ ${size.isMobile || size.isTab ? '0' : '21vw'}
					)/2)`,
                    bottom: size.isMobile || size.isTab ? '' : 'calc(130%)',
                }}
            ></ButtonAllert>
        </ButtonBox>
    );
};
