import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { buttonBlue } = useTheme();
    return {
        Top: styled.div({
            position: 'relative',
            padding: size.isMobile ? '6em 2em 0' : '6em 4em 0',

            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile ? '4em' : '2em',
            justifyContent: 'center',
        }),
        Background: styled.img({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
        }),
        BackgroundMask: styled.div({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
        }),
        Logo: styled.img({
            width: '18.75em',
            height: '18.75em',
            margin: '0 auto',
            zIndex: 1,
        }),
        MainText: styled.div({
            width: size.isMobile ? '100%' : '66%',
            fontSize: size.isMicroMobile ? '3.3em' : '3.75em',
            lineHeight: '1em',
            fontWeight: 800,
            textAlign: 'center',
            margin: '0 auto',
            zIndex: 1,
            span: {
                color: buttonBlue,
            },
        }),
        MidleText: styled.div({
            fontSize: size.isMobile ? '1.875em' : size.isTab ? '1.667em' : '1.25em',
            lineHeight: '1.25em',
            textAlign: 'center',
            margin: '0 auto',
            zIndex: 1,
        }),
        ButtonBox: styled.div({
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            gap: '1em',
            margin: size.isMobile ? '' : '0 auto',

            flexDirection: size.isMobile ? 'column' : 'row',
        }),
        size,
    };
};
