import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { TappDispatch } from '@maket/js/state/store';
import { Tchat } from '@maket/js/types/state/auth';
import axios from 'axios';

type Tprops = {
    token: string;
    dispatch: TappDispatch;
    chat_id: number;
    active: boolean;
};

export default async ({ token, dispatch, chat_id, active }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post<Array<Tchat>>(
            apiUrl + '/chat/set_active',
            { chat_id, active },
            {
                headers: { Authorization: `Bearer ${token}` },
            },
        );
        setVisableLoader('notVisable');
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
