import React from 'react';
import style from './style';
import Button from '@js/components/microComponets/button';
import useAppSelector from '@js/hooks/useAppSelector';
import ContancButton from './contancButton';
import useWindowSizeCheck from '@js/hooks/useWindowSizeCheck';
import useAppDispatch from '@js/hooks/useAppDispatch';
import { setActivePopap } from '@js/state/openPopap/openPopapState';
import { Link, NavLink } from 'react-router-dom';
import { setPopapStateEnterprice } from '@maket/js/state/tariffSwith';

const contactsList = [
    { iconName: 'telegram' },
    { iconName: 'contactInstagram' },
    { iconName: 'contactTwitter' },
    { iconName: 'contactFacebook' },
];

export default () => {
    const {
        Container,
        Title,
        Contacts,
        LogoBox,
        Buttons,
        Main,
        Footer,
        GrayLink,
        FooterItem,
        Logo,
        LogoText,
        LogoTexSignature,
    } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const language = useAppSelector(state => state.languageState.language);
    const languageList = useAppSelector(state => state.languageState.languageList);

    const dispatch = useAppDispatch();
    const { isMobile, isTab } = useWindowSizeCheck();

    const CopyrightSplit = activeLanguage['Foofter.Copyright']?.split('#') || [];
    return (
        <Container data-popap="true">
            <Main>
                <Title>{activeLanguage['Main.Question.Title']}</Title>
                <Contacts>
                    {contactsList.map(el => (
                        <Link
                            key={el.iconName}
                            to={'https://web.telegram.org/k/#@MazeKeeper_support_bot'}
                        >
                            <ContancButton iconName={el.iconName} />
                        </Link>
                    ))}
                </Contacts>
                <LogoBox>
                    <Logo src="/img/Logo.svg" />
                    <LogoText>
                        MazeKeeper
                        <LogoTexSignature>βeta</LogoTexSignature>
                    </LogoText>
                </LogoBox>
                <Buttons>
                    <GrayLink onClick={() => scrollElement('mainWhyMazeKeeper', true)}>
                        {activeLanguage['Main.Question.TextOne']}
                    </GrayLink>
                    <GrayLink onClick={() => scrollElement('mainPlans')}>
                        {activeLanguage['Main.Question.TextTwo']}
                    </GrayLink>
                    <GrayLink onClick={() => scrollElement('mainHowItWork')}>
                        {activeLanguage['Main.Question.TextThree']}
                    </GrayLink>
                    <GrayLink
                        onClick={() => {
                            dispatch(setPopapStateEnterprice('question'));
                        }}
                    >
                        {activeLanguage['Main.Question.TextFour']}
                    </GrayLink>
                </Buttons>
                <NavLink
                    onClick={() => {
                        localStorage.setItem(
                            'MZPreRegTariff',
                            JSON.stringify({ tariffKey: 'free', timestamp: +new Date() }),
                        );
                    }}
                    to={'/app/auth/auth'}
                >
                    <Button typeButt="blue">{activeLanguage['Main.Button.Trial']}</Button>
                </NavLink>
            </Main>
            <Footer>
                <FooterItem>
                    <GrayLink>
                        {CopyrightSplit[0]}
                        {new Date().getFullYear()}
                        {CopyrightSplit[1]}
                    </GrayLink>
                </FooterItem>
                <FooterItem className="mbColumn">
                    <GrayLink href={'/app/cookiePolicy'}>
                        {activeLanguage['Foofter.Cookie']}
                    </GrayLink>
                    <GrayLink href={'/app/privacyPolicy'}>
                        {activeLanguage['Foofter.Privacy']}
                    </GrayLink>
                    <GrayLink href={'/app/refundPolicy'}>
                        {activeLanguage['Foofter.Refund']}
                    </GrayLink>
                    <GrayLink href="/app/termsOfUse">{activeLanguage['Foofter.Terms']}</GrayLink>
                    <GrayLink
                        onClick={() => {
                            if (isMobile || isTab) {
                                dispatch(setActivePopap('navigationPopapAndLanguage'));
                            } else {
                                dispatch(setActivePopap('languagePopap'));
                            }
                        }}
                    >
                        {languageList[language]}
                    </GrayLink>
                </FooterItem>
            </Footer>
        </Container>
    );
};

function scrollElement(id: string, center?: boolean) {
    const wrap = document.getElementById('wrap');
    const el = document.getElementById(id);
    if (el) {
        // @ts-ignore: Unreachable code error
        let elementPos = el.offsetTop - 32;
        if (center) {
            elementPos -= el.scrollHeight;
        }
        wrap?.scrollTo({ top: elementPos, behavior: 'smooth' });
    }
}
