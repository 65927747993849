import React, { useEffect } from 'react';
import style from './style';
import Input from '@maket/js/components/microComponets/input';
import { setInputMailSettingsValue } from '@maket/js/state/settingPass/settingPassValueState';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';

export default () => {
    const { MailContainer, MailTitle } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    return (
        <MailContainer>
            <MailTitle>{activeLanguage['Mail.']}</MailTitle>
            <IsolateInput />
        </MailContainer>
    );
};
let localMail = '';
function IsolateInput() {
    const dispatch = useAppDispatch();
    const accountState = useAppSelector(state => state.authState.account);
    const { mail } = useAppSelector(state => state.settingPassValueState);

    useEffect(() => {
        if (accountState && (mail == 'none' || localMail != accountState?.email)) {
            localMail = accountState.email;
            dispatch(setInputMailSettingsValue(accountState.email));
        }
    }, [accountState, mail]);
    return (
        <Input
            value={mail}
            iconName="envelopeOpen"
            placeholder="email"
            setExtState={setInputMailSettingsValue}
        />
    );
}
