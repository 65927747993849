import React, { HTMLAttributes } from 'react';
import style from './style';
import { AnimatePresence } from 'framer-motion';
import Alert from '@js/components/microComponets/alert';
import { TalertState } from '.';
import useAppSelector from '@maket/js/hooks/useAppSelector';

type Tprops = {
    stateAlert: TalertState;
    styleAlert?: HTMLAttributes<HTMLDivElement>;
    duration?: number;
};

export default ({ stateAlert, styleAlert, duration }: Tprops) => {
    const { AlertBox } = style();

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <AnimatePresence>
            {stateAlert.typeAllert && (
                <AlertBox
                    style={styleAlert}
                    initial={{ transform: 'translate(-50%, 70%)', opacity: 0 }}
                    animate={{ transform: 'translate(-50%, 120%)', opacity: 1 }}
                    exit={{ transform: 'translate(-50%, 70%)', opacity: 0 }}
                    transition={{ duration: duration != undefined ? duration : 0.3 }}
                >
                    <Alert style={{ width: '100%' }} type={stateAlert.typeAllert}>
                        {activeLanguage[stateAlert.textAllert] || 'error'}
                    </Alert>
                </AlertBox>
            )}
        </AnimatePresence>
    );
};
