import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import { motion } from 'framer-motion';

export default () => {
    const size = useWindowSizeCheck();
    return {
        Burger: styled(motion.img)({
            width: 20,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }),
        BurgerContainer: styled.div({
            position: 'relative',
            width: 20,
            height: 20,
            overflow: 'hidden',
        }),
        size,
    };
};
