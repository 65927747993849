import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { grayTextSelect } = useTheme();
    return {
        Container: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: size.isLaptop ? '1em' : '2em',
            width: '100%',
            height: size.isMobile || size.isTab ? '' : '10rem',

            flexDirection: size.isMobile || size.isTab ? 'column' : 'row',

            '& .card-body div': {
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5em',
            },

            '& .chartjs-tooltip': {
                transition: '0.3s',
                backgroundColor: grayTextSelect,
                zIndex: '10',
                padding: '1em',
                overflow: 'hidden',
                borderRadius: 10,

                '& table': { display: 'flex', flexDirection: 'column', gap: '0.5em' },
            },
        }),
        size,
    };
};
