import React from 'react';
import style from './style';
import Left from './left';
import Right from './right';

export default () => {
    const { Container, Background, BackgroundMask } = style();
    return (
        <Container id="mainHowItWork">
            <Left></Left>
            <Right></Right>
            <BackgroundMask></BackgroundMask>
            <Background src="/img/background/home__howWork.svg"></Background>
        </Container>
    );
};
