import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import axios from 'axios';

type Tprops = {
    mail: string;
    pass: string;
};

export default async ({ mail, pass }: Tprops) => {
    setVisableLoader('visable');
    const req = await axios
        .post(apiUrl + '/auth/email', {
            email: mail,
            password: pass,
        })
        .catch(err => {});
    setVisableLoader('notVisable');
    return req;
};
