import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { altTextBlackColor, whiteText } = useTheme();
    return {
        AuthSettingContainer: styled.div({
            display: 'flex',
            gap: '1em',
            alignItems: 'center',
            color: altTextBlackColor,
        }),
        AuthSettingMain: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5em',
            width: '100%',
        }),
        AuthSettingTitle: styled.div({
            textAlign: 'start',
            fontWeight: 700,
        }),
        AuthSettingText: styled.div({ textAlign: 'start' }),
        Icon: styled.img({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: ' translate(-50%, -50%)',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        }),
        IconBox: styled.div({
            position: 'relative',
            backgroundColor: whiteText,
            borderRadius: '50%',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            width: '2.5em',
            height: '2.5em',

            overflow: 'hidden',
        }),
        size,
    };
};
