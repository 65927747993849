import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';
import { useEffect } from 'react';

export default () => {
    const size = useWindowSizeCheck();
    const { authBackground, whiteText } = useTheme();

    useEffect(() => {
        document.body.setAttribute('style', `background-color: ${authBackground}`);
    });

    return {
        Container: styled.div({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '2.5em',
            fontFamily: 'Inter, sans-serif',

            padding: size.isMobile || size.isTab ? '2em 0' : size.isLaptop ? '4em 0' : '6.125em 0',
        }),
        Main: styled.div({
            padding: '3.75em',
            backgroundColor: whiteText,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: size.isMobile || size.isTab ? '1em' : '2em',
            width: size.isMobile || size.isTab ? '90%' : '75%',

            fontSize: size.isMobile || size.isTab ? '0.9em' : '1.125em',
            lineHeight: '1.2em',
        }),
        Title: styled.div({
            width: '100%',
            fontSize: '2.44em',
            fontWeight: 600,
            lineHeight: '1em',
            wordWrap: 'break-word',
        }),
        Text: styled.div({ width: '100%' }),
        List: styled.div({}),
        ListItem: styled.div({ display: 'flex', gap: '0.5em' }),
        ListItemNumber: styled.div({ textAlign: 'left', width: '1em', minWidth: '1em' }),
        ListItemText: styled.div({ textAlign: 'left' }),
        size,
    };
};
