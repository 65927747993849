import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { Tuser } from '@maket/js/types/state/auth';
import { TExeptionState } from '@maket/js/types/state/banAndMute';
import axios from 'axios';

type Tprops = {
    token: string;
    data: TExeptionState;
    rulesProfile: null | string;
};

export default async ({ token, data, rulesProfile }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post<Tuser>(apiUrl + `/profile/${rulesProfile}/rules/unban`, data, {
            headers: { Authorization: `Bearer ${token}` },
        });
        setVisableLoader('notVisable');
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
