import { TauthInputValueState } from '@js/types/state/authInputsValue';
import { createSlice } from '@reduxjs/toolkit';

const initialState: TauthInputValueState = {
    mail: '',
    pass: '',
    lastName: '',
    firstName: '',
};

const authInputsValueState = createSlice({
    name: 'authInputsValueState',
    initialState,
    reducers: {
        setInputMailValue: (state, { payload }: { payload: string }) => {
            state.mail = payload;
        },
        setInputPassValue: (state, { payload }: { payload: string }) => {
            state.pass = payload;
        },
        researchValue: (state, { payload }: { payload: string }) => {
            const pass = state.pass;
            state.pass = payload;
        },
        setInputFirstNameValue: (state, { payload }: { payload: string }) => {
            state.firstName = payload;
        },
        setInputLastNameValue: (state, { payload }: { payload: string }) => {
            state.lastName = payload;
        },
    },
});

export const {
    setInputFirstNameValue,
    setInputLastNameValue,
    setInputMailValue,
    setInputPassValue,
    researchValue,
} = authInputsValueState.actions;

export default authInputsValueState;
