import React, { useEffect } from 'react';
import style from './style';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import {
    maxSimbolRules,
    setUserSimbolCounter,
    userSimbolCounter,
} from '@maket/js/constants/values';
import { setCounter } from '@maket/js/state/userCounterSimbolRules/userCounterSimbolRulesState';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';

export default () => {
    const dispatch = useAppDispatch();

    const { Counter } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const { banState, muteState, exeptionState } = useAppSelector(
        state => state.banAndMuteRulesState,
    );
    const { reasonInputState, responseInputState } = useAppSelector(
        state => state.muteAddItemSwithState,
    );
    const inputValueBan = useAppSelector(state => state.banAddItemSwithState.inputValue);
    const inputValueExeption = useAppSelector(state => state.exeptionAddItemSwithState.inputValue);

    useEffect(() => {
        let counterUserSimbol = 0;
        banState.rules.forEach(el => (counterUserSimbol += el.text?.length));
        exeptionState.rules.forEach(el => (counterUserSimbol += el.text?.length));
        muteState.rules.forEach(el => {
            if (el.reason?.length) {
                counterUserSimbol += el.reason?.length;
            }
            if (el.response?.length) {
                counterUserSimbol += el.response?.length;
            }
        });

        if (muteState.ending_frase?.length) {
            counterUserSimbol += muteState.ending_frase?.length;
        }
        if (muteState.starting_frase?.length) {
            counterUserSimbol += muteState.starting_frase?.length;
        }
        if (reasonInputState?.length) {
            counterUserSimbol += reasonInputState?.length;
        }
        if (responseInputState?.length) {
            counterUserSimbol += responseInputState?.length;
        }
        if (inputValueBan?.length) {
            counterUserSimbol += inputValueBan?.length;
        }
        if (inputValueExeption?.length) {
            counterUserSimbol += inputValueExeption?.length;
        }

        dispatch(setCounter(maxSimbolRules - counterUserSimbol));
    }, [
        banState,
        muteState,
        reasonInputState,
        responseInputState,
        inputValueExeption,
        inputValueBan,
        dispatch,
    ]);

    return (
        <Counter>
            {activeLanguage['ChatRules.CharsRemaining']}: {userSimbolCounter} /{' '}
            <span>{maxSimbolRules}</span>
        </Counter>
    );
};
