import React, { HTMLAttributes, useEffect, useState } from 'react';
import style from './style';
import Button, { Tbutton } from '@js/components/microComponets/button';
import Alert from './alert';
import { Talert } from '@js/components/microComponets/alert';

interface Tprops extends HTMLAttributes<HTMLDivElement> {
    buttonContent: string;
    typeButt: Tbutton;
    stateAllertOuter: TalertState;
    setAllertFn: (v: TalertState) => void;
    styleAlert?: HTMLAttributes<HTMLDivElement>;
    buttonStyle?: HTMLAttributes<HTMLButtonElement>;
    buttonClassNames?: string;
    clickFn?: () => void;
    disabled?: boolean;
    timer?: number;
    duration?: number;
    disableAutoClear?: boolean;
}

export type TalertState = {
    typeAllert: Talert | '';
    textAllert: string;
};

export default ({
    buttonContent,
    clickFn,
    typeButt,
    stateAllertOuter,
    setAllertFn,
    styleAlert,
    buttonStyle,
    disabled,
    buttonClassNames,
    timer,
    duration,
    disableAutoClear,
    ...props
}: Tprops) => {
    const { ButtonBox } = style();

    const [stateAlert, setStateAlert] = useState<TalertState>({
        typeAllert: '',
        textAllert: '',
    });

    useEffect(() => {
        setStateAlert(stateAllertOuter);
        if (stateAllertOuter.textAllert != '') {
            if (!disableAutoClear) {
                setTimeout(
                    () => {
                        setAllertFn({ textAllert: '', typeAllert: '' });
                    },
                    timer ? timer : 1500,
                );
            }
        }
    }, [stateAllertOuter]);

    return (
        <ButtonBox
            {...props}
            style={{
                pointerEvents: stateAlert.typeAllert != '' ? 'none' : 'all',
                ...props.style,
            }}
        >
            <Button
                className={buttonClassNames}
                onClick={() => {
                    clickFn && clickFn();
                }}
                style={{
                    width: '100%',
                    textAlign: 'center',
                    pointerEvents: stateAlert.typeAllert != '' ? 'none' : 'all',
                    ...buttonStyle,
                }}
                typeButt={typeButt}
                disabled={disabled != undefined ? disabled : stateAlert.typeAllert != ''}
            >
                {buttonContent}
            </Button>
            <Alert styleAlert={styleAlert} stateAlert={stateAlert} duration={duration} />
        </ButtonBox>
    );
};
