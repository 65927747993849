import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { setActiveProfileChats, setAllChats } from '@maket/js/state/auth/authState';
import { TappDispatch } from '@maket/js/state/store';
import { Tchat, Tuser } from '@maket/js/types/state/auth';
import axios from 'axios';

type Tprops = {
    token: string;
    dispatch: TappDispatch;
    profile_id: number;
    setActiveProfileId?: React.Dispatch<React.SetStateAction<number>>;
    idProfile?: string | undefined;
    allChats?: boolean;
};

export default async ({
    token,
    dispatch,
    profile_id,
    setActiveProfileId,
    idProfile,
    allChats,
}: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.get<Array<Tchat>>(apiUrl + '/chats', {
            headers: { Authorization: `Bearer ${token}` },
            params: { profile_id },
        });
        setVisableLoader('notVisable');
        if (setActiveProfileId) {
            if (req.data) {
                dispatch(setActiveProfileChats(req.data));
            } else {
                if (idProfile == undefined) {
                    setActiveProfileId(0);
                }
            }
        } else {
            dispatch(setActiveProfileChats(req.data));
        }

        if (allChats) {
            if (req.data[0]) {
                dispatch(setAllChats(req.data));
            }
        }
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
