import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import axios from 'axios';

type Tprops = {
    token: string;
    currentPassword: string;
    newPassword: string;
};

export default async ({ currentPassword, newPassword, token }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post(
            apiUrl + '/account/change_password',
            {
                current_password: currentPassword,
                new_password: newPassword,
            },
            { headers: { Authorization: `Bearer ${token}` } },
        );
        setVisableLoader('notVisable');
        return req.data;
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
