import React from 'react';
import style from './style';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import GrayBox from '@maket/js/components/middleComponents/grayBox';
import BanRules from './banRules';
import ShartingPhrase from './shartingPhrase';
import Reason from './reason';
import EndingPhrase from './endingPhrase';
import BanButton from './banButton';
import BanExeption from './banExeption';
import ExeptionButton from './exeptionButton';

export default () => {
    const { Container, Item } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const user = useAppSelector(state => state.authState.user);
    let checkBan = false;
    let checkMute = false;
    let checkExeption = false;
    if (user) {
        checkBan = user.profiles[0].settings.ban_rules;
        checkMute = user.profiles[0].settings.mute_rules;
        checkExeption = user.profiles[0].settings.unban_rules;
    }
    return (
        <Container>
            <Item>
                <GrayBox
                    titleSize="1.25em"
                    title={activeLanguage['ChatRules.BanRules']}
                    content={<BanRules checkBan={checkBan} />}
                />
                <BanButton checkBan={checkBan} />

                <GrayBox
                    titleSize="1.25em"
                    style={{ marginTop: '2em' }}
                    title={activeLanguage['ChatRules.BanExeption']}
                    content={<BanExeption checkBan={checkExeption} />}
                />
                <ExeptionButton checkBan={checkExeption} />
            </Item>
            <Item>
                <GrayBox
                    titleSize="1.25em"
                    title={activeLanguage['ChatRules.MuteRules']}
                    content={<ShartingPhrase checkMute={checkMute} />}
                />
                <GrayBox content={<Reason checkMute={checkMute} />} />
                <GrayBox content={<EndingPhrase checkMute={checkMute} />} />
            </Item>
        </Container>
    );
};
