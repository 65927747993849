import React, { useEffect } from 'react';
import style from './style';
import Top from './top';
import LogoBox from '@js/components/mainComponents/header/components/logoBox';
import useTheme from '@js/hooks/useTheme';
import BackButton from '../../microComponets/backButton';

export default () => {
    const { Container, Main, size } = style();
    const { authBackground } = useTheme();

    useEffect(() => {
        document.body.setAttribute('style', `background-color: ${authBackground}`);
    });

    return (
        <Container>
            <LogoBox style={{ fontSize: size.isMobile ? '1.2em' : '1.8em' }} fullVersion />
            <Main>
                <BackButton />
                <Top />
            </Main>
        </Container>
    );
};
