import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    return {
        BottomCard: styled.div({
            flex: '0 0 21%',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5em',
        }),
        BottomCardIcon: styled.div({
            fontSize: '3em',
            marginBottom: size.isMobile ? '0.12em' : '0.27em',
        }),
        BottomCardTitle: styled.div({
            fontWeight: 700,
            fontSize: '1.125em',
        }),
        BottomCardText: styled.div({
            lineHeight: '1.25em',
        }),
        size,
    };
};
