import React, { useEffect } from 'react';
import style from './style';
import Top from './top';
import Middle from './middle';
import Bottom from './bottom';
import EndText from './endText';
import LogoBox from '@js/components/mainComponents/header/components/logoBox';
import useTheme from '@js/hooks/useTheme';
import BackButton from '../../microComponets/backButton';
import { NavLink } from 'react-router-dom';

export type TauthPage = 'auth' | 'reg';
type Tprops = { type: TauthPage };

export default ({ type }: Tprops) => {
    const { Container, Main, size } = style();
    const { authBackground } = useTheme();

    useEffect(() => {
        document.body.setAttribute('style', `background-color: ${authBackground}`);
    });

    return (
        <Container>
            <NavLink to={'/'}>
                <LogoBox style={{ fontSize: size.isMobile ? '1.2em' : '1.8em' }} fullVersion />
            </NavLink>
            <Main>
                {type == 'reg' && <BackButton />}
                <Top type={type} />
                {type == 'auth' && (
                    <>
                        <Middle />
                        <Bottom />
                        <Middle onliLine />
                        <EndText />
                    </>
                )}
            </Main>
        </Container>
    );
};
