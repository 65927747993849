import React, { useEffect, useState } from 'react';
import style from './style';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { CFormInput, CFormSelect, CInputGroup, CInputGroupText } from '@coreui/react';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import {
    setActiveDashbordPofileState,
    setDateState,
    setStatisticsState,
} from '@maket/js/state/dashbord';
import getStatistics from '@maket/js/api/requiresToken/service/getStatistics';

import { Calendar } from 'vanilla-calendar-pro';
import 'vanilla-calendar-pro/styles/layout.css';

import 'vanilla-calendar-pro/styles/themes/light.css';
import 'vanilla-calendar-pro/styles/themes/dark.css';
import { TactiveLanguage } from '@maket/js/types/state/language';

let activeCalendar = false;

export default () => {
    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.tokenState.token);
    const { Container, size, Icon } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const allChats = useAppSelector(state => state.authState.allChats) || [];
    const account = useAppSelector(state => state.authState.account);

    const { activeProfileId, DateState } = useAppSelector(state => state.dashbordState);

    useEffect(() => {
        const chats = activeProfileId ? [activeProfileId] : allChats.map(el => +el.chat_id);
        if (token) {
            if (DateState.start && chats.length != 0) {
                getStatistics({
                    dispatch,
                    token,
                    serviceType: 'telegram',
                    start_date: DateState.start,
                    stop_date: DateState.end,
                    chats,
                });
                if (DateState.start != DateState.end) {
                    activeCalendar = false;
                    const calendarDOM = document.getElementById('calendar');
                    calendarDOM?.classList.remove('active');
                }
            } else {
                dispatch(
                    setStatisticsState({
                        messages: [],
                        spam_messages: [],
                        users: [],
                        active_users: [],
                        spam_topics: [],
                        messages_count: 0,
                        spam_messages_count: 0,
                        users_count: 0,
                        active_users_count: 0,
                    }),
                );
            }
        }
    }, [activeProfileId, DateState, allChats, token]);

    useEffect(() => {
        const calendarDOM = document.getElementById('calendar');
        if (calendarDOM) {
            const calendar = new Calendar(calendarDOM, {
                selectionDatesMode: 'multiple-ranged',

                onClickDate(self, event) {
                    const [startDate, endDate] = self.context.selectedDates;
                    const length = (+new Date(endDate) - +new Date(startDate)) / 86400000;
                    // @ts-ignore: Unreachable code error
                    if (`${length}` == 'NaN') {
                        dispatch(
                            setDateState({
                                start: startDate,
                                end: startDate,
                            }),
                        );
                    } else if (length > 31) {
                        dispatch(
                            setDateState({
                                start: startDate,
                                end: new Date(new Date(startDate).getTime() + 2678400000)
                                    .toISOString()
                                    .split('T')[0],
                            }),
                        );
                    } else {
                        dispatch(
                            setDateState({
                                start: startDate,
                                end: endDate,
                            }),
                        );
                    }
                },
            });
            calendar.init();
            calendar.selectedDates =
                DateState.start == DateState.end
                    ? [DateState.end]
                    : [DateState.start, DateState.end];
            calendar.update();
        }
        if (activeCalendar) {
            calendarDOM?.classList.add('active');
        }
    });

    useEffect(() => {
        const linter = e => {
            if (!(e.target.closest('#calendarBox') || e.target.closest('#calendar'))) {
                activeCalendar = false;
                const calendarDOM = document.getElementById('calendar');
                calendarDOM?.classList.remove('active');
            }
        };
        document.addEventListener('click', linter);
        return () => {
            document.removeEventListener('click', linter);
        };
    }, []);

    const inputValue1 = DateState.start?.split('-').join('.');
    const inputValue2 = DateState.end?.split('-')?.join('.');

    return (
        <Container>
            <CInputGroup
                style={{
                    width: size.isLaptop
                        ? '50%'
                        : size.isMobile
                        ? '100%'
                        : size.isTab
                        ? '50%'
                        : '25%',
                    position: 'relative',
                    minWidth: '16.5rem',
                }}
            >
                <CInputGroupText
                    as="label"
                    htmlFor="inputGroupSelect01"
                    style={{
                        padding: size.isMobile ? '0.3em' : '0.5em',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Icon src="../img/icon/calendar.svg" />
                </CInputGroupText>

                <CFormInput
                    value={
                        inputValue1 == undefined
                            ? activeLanguage['Dashbord.NotSelect.Period']
                            : `${inputValue1} - ${inputValue2}`
                    }
                    type="text"
                    onChange={e => {
                        dispatch(setProfileDateState(e.target.value));
                    }}
                    id="calendarBox"
                    onClick={() => {
                        const calendarDOM = document.getElementById('calendar');
                        calendarDOM?.classList.toggle('active');
                        if (calendarDOM?.className.includes('active')) {
                            activeCalendar = true;
                        } else {
                            activeCalendar = false;
                        }
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 10,
                        top: 'calc(100% + 1em)',
                        left: '50%',
                        transform: 'translate(-50%)',
                        border: '1px solid #000',
                        borderRadius: '1em',
                    }}
                    id="calendar"
                ></div>
            </CInputGroup>

            <CInputGroup
                style={{
                    width: size.isLaptop
                        ? '50%'
                        : size.isMobile
                        ? '100%'
                        : size.isTab
                        ? '50%'
                        : '25%',
                }}
            >
                <CInputGroupText
                    as="label"
                    htmlFor="inputGroupSelect01"
                    style={{
                        padding: size.isMobile ? '0.3em' : '0.5em',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Icon src="../img/icon/chats.svg" />
                </CInputGroupText>
                <CFormSelect
                    value={activeProfileId || 'all'}
                    onChange={e => {
                        dispatch(setActiveDashbordPofileState(+e.target.value));
                    }}
                    options={[
                        {
                            label: activeLanguage['Dashbord.AllChats'],
                            value: 'all',
                        },
                        ...allChats
                            .filter(
                                el =>
                                    !(el.bot_status == 'insufficient_rights' ||
                                    el.bot_status == 'not_in_chat'
                                        ? true
                                        : el.owner_name != account.username),
                            )
                            .map(el => ({ label: el.chat_name, value: `${el.chat_id}` })),
                    ]}
                ></CFormSelect>
            </CInputGroup>
        </Container>
    );
};

const monthConfig = {
    1: 'Dashbord.Calendar.Month.January',
    2: 'Dashbord.Calendar.Month.February',
    3: 'Dashbord.Calendar.Month.March',
    4: 'Dashbord.Calendar.Month.April',
    5: 'Dashbord.Calendar.Month.May',
    6: 'Dashbord.Calendar.Month.June',
    7: 'Dashbord.Calendar.Month.July',
    8: 'Dashbord.Calendar.Month.August',
    9: 'Dashbord.Calendar.Month.September',
    10: 'Dashbord.Calendar.Month.October',
    11: 'Dashbord.Calendar.Month.November',
    12: 'Dashbord.Calendar.Month.December',
};

function genInputDate(
    activeLanguage: TactiveLanguage,
    DateState: {
        start: string;
        end: string;
    },
) {
    if (DateState.start) {
        const splitStart = DateState.start.split('-');
        const splitEnd = DateState.end.split('-');

        const numberMonthStart = splitStart[1][0] == '0' ? +splitStart[1][1] : +splitStart[1];
        const numberMonthEnd = splitEnd[1][0] == '0' ? +splitEnd[1][1] : +splitEnd[1];

        if (numberMonthEnd == numberMonthStart) {
            if (splitStart[2] == splitEnd[2]) {
                return `${activeLanguage[monthConfig[numberMonthStart]]} ${splitStart[2]}`;
            } else {
                return `${activeLanguage[monthConfig[numberMonthStart]]} ${splitStart[2]} - ${
                    splitEnd[2]
                }`;
            }
        } else {
            return `${activeLanguage[monthConfig[numberMonthStart]]} ${splitStart[2]} - ${
                activeLanguage[monthConfig[numberMonthEnd]]
            } ${splitEnd[2]}`;
        }
    } else {
        return activeLanguage['Dashbord.NotSelect.Period'];
    }
}
