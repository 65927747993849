import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { altTextBlackColor, liteBlue, whiteText } = useTheme();
    return {
        AuthSettingContainer: styled.div({
            display: 'flex',
            gap: '1em',
            alignItems: 'center',
            color: altTextBlackColor,
        }),
        AuthSettingMain: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5em',
            width: '100%',
        }),
        AuthSettingTitle: styled.div({
            textAlign: 'start',
            fontWeight: 700,
        }),
        AuthSettingText: styled.div({ textAlign: 'start' }),
        DeleteIcon: styled.div({
            fontSize: '1.75em',
            padding: '0.25em',
            borderRadius: 8,
            transition: '0.3s',
            ':hover': {
                backgroundColor: whiteText,
            },
        }),
        size,
    };
};
