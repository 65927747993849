import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';
import { motion } from 'framer-motion';
import { Link, NavLink } from 'react-router-dom';

export default () => {
    const size = useWindowSizeCheck();
    const { navBackgroundGrayDark, whiteText, navGrayText, profileSidebarTitleGray, logoLiteBlue } =
        useTheme();
    return {
        Container: styled(motion.div)({
            top: '8em',
            width: '100%',
            fontFamily: 'Inter, sans-serif',
            height: '100%',

            display: 'flex',
            flexDirection: 'column',
            gap: '0.75em',

            overflowX: 'auto',
            maxHeight: size.isMobile || size.isTab ? '94vh' : 'calc(95vh - 3.4em)',
            paddingBottom: '1em',
        }),
        Item: styled(NavLink)({
            color: whiteText,
            display: 'flex',
            gap: '1em',
            alignItems: 'center',
            padding: size.isMobile ? '1em' : '1em 0.5em',
            transition: '0.3s',
            borderRadius: 4,

            ':hover': {
                backgroundColor: navBackgroundGrayDark,
            },
        }),
        ItemIcon: styled.div({
            fontSize: size.isMobile ? '1.25em' : '1.075em',
            color: navGrayText,
            transition: '0.3s',
            transform: 'translate(0, 5%)',
        }),
        ItemIconImage: styled.img({
            width: size.isMobile ? '1em' : '0.75em',
            maxWidth: size.isMobile ? '1em' : '0.75em',
            height: size.isMobile ? '1em' : '0.75em',
            transition: '0.3s',

            transform: 'translate(0, 5%)',
        }),
        IconText: styled.div({
            fontSize: size.isMobile ? '1.25em' : '1.075em',
        }),
        LangBox: styled.div({
            padding: '1em',
        }),
        ProfileList: styled.div({
            display: 'block',
            transform: 'translate(0, -30%)',
            opacity: 0,
            transition: '0.3s',

            height: 0,
            maxHeight: 0,
            color: 'inherit',
            pointerEvents: 'none',

            '&.minify.minify.minify': {
                padding: '0.25em',
            },
            '&.active': {
                padding: size.isMobile ? '1em' : '0.25rem 1rem',
                opacity: 1,
                pointerEvents: 'all',
                transform: 'translate(0, 0)',
                height: 'fit-content',
                maxHeight: 'fit-content',
            },
        }),
        ProfileItem: styled.div({
            backgroundColor: '#FFFFFF0D',
            padding: '0.5rem',
            '& .title': {
                color: profileSidebarTitleGray,
                fontWeight: 700,
                marginBottom: '0.25rem',
            },

            display: 'flex',
            flexDirection: 'column',
            gap: '0.25rem',

            div: {
                transition: '0.3s',
                cursor: 'pointer',
                ':hover': {
                    color: logoLiteBlue,
                },
            },
        }),
        size,
    };
};
