import { apiUrl } from '@js/constants/values';
import { TappDispatch } from '@maket/js/state/store';
import { setToken } from '@maket/js/state/tokenState';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

export default (lang: string, dispatch: TappDispatch) => {
    return useGoogleLogin({
        flow: 'auth-code',
        onSuccess: codeResponse => {
            axios
                .post(`${apiUrl}/auth/google`, {
                    code: codeResponse.code,
                    language: lang.toLowerCase(),
                })
                .then(rez => {
                    const token = rez.data.token;
                    dispatch(setToken(token));
                })
                .catch(err => {});
        },
        onError: err => {
            {
            }
        },
        onNonOAuthError: nonOAuthError => {},
    });
};
