import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { whiteText, darkGray } = useTheme();
    return {
        Container: styled.div({
            color: darkGray,
            padding: '-0.71em',
            textAlign: 'center',

            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
        }),
        Item: styled.div({
            padding: size.isMobile ? '0.71em' : '2.21em',
            backgroundColor: whiteText,

            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
        }),
        size,
    };
};
