import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { altTextBlackColor } = useTheme();
    return {
        Container: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile ? '1em' : '2em',
        }),
        Item: styled.div({ display: 'flex', flexDirection: 'column', gap: '1em' }),
        ItemTitle: styled.div({
            fontSize: '1.125em',
            width: '100%',
            textAlign: 'start',
            color: altTextBlackColor,
        }),
        ItemText: styled.div({
            width: '100%',
            textAlign: 'start',
        }),

        AuthSettingContainer: styled.div({
            display: 'flex',
            gap: '1em',
            alignItems: 'center',
            color: altTextBlackColor,
        }),
        AuthSettingMain: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5em',
            width: '100%',
        }),
        AuthSettingTitle: styled.div({
            textAlign: 'start',
            fontWeight: 700,
        }),
        AuthSettingText: styled.div({ textAlign: 'start' }),
        size,
    };
};
