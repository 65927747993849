import React from 'react';
import style from './style';
import Auth from './components/auth';
import LogoBox from './components/logoBox';

import LanguargeButton from './components/languageButton';

import ButtonSettingBox from './components/buttonSetting/box';
import useWindowSizeCheck from '@js/hooks/useWindowSizeCheck';
import Burger from './components/burger';
import { NavLink } from 'react-router-dom';

export default () => {
    const { Container, Item } = style();
    const { isMobile, isTab } = useWindowSizeCheck();
    if (isMobile || isTab) {
        return (
            <Container id="header">
                <Item>
                    <Burger />
                    <NavLink to={'/'}>
                        <LogoBox />
                    </NavLink>
                    <ButtonSettingBox />
                </Item>
                {isTab ? <Item></Item> : <></>}
                <Item>
                    <Auth />
                </Item>
            </Container>
        );
    } else {
        return (
            <Container id="header">
                <Item>
                    <NavLink to={'/'}>
                        <LogoBox />
                    </NavLink>
                    <Burger />
                </Item>
                <Item>
                    <ButtonSettingBox />
                </Item>
                <Item>
                    <LanguargeButton />
                    <Auth />
                </Item>
            </Container>
        );
    }
};
