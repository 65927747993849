import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { TappDispatch } from '@maket/js/state/store';
import { Tchat } from '@maket/js/types/state/auth';
import axios from 'axios';

type Tprops = {
    token: string;
    dispatch: TappDispatch;
    chat_id: number;
    profile_id: number;
    new_profile_id?: number;
};

export default async ({ token, dispatch, chat_id, profile_id, new_profile_id }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post<Array<Tchat>>(
            apiUrl + '/profile/delete_chat',
            { chat_id, profile_id },
            {
                headers: { Authorization: `Bearer ${token}` },
            },
        );
        setVisableLoader('notVisable');
    } catch (e) {
        setVisableLoader('notVisable');
    }
    return new_profile_id;
};
