import { createSlice } from '@reduxjs/toolkit';

const initialState: { state: boolean; lastState: boolean | null } = {
    state: true,
    lastState: null,
};

const termsSwithState = createSlice({
    name: 'termsSwithState',
    initialState,
    reducers: {
        setSwithState: (state, { payload }: { payload: boolean }) => {
            state.lastState = state.state;
            state.state = payload;
        },
    },
});

export const { setSwithState } = termsSwithState.actions;

export default termsSwithState;
