import React from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';

type Tprops = { type?: 'page' };

export default ({ type }: Tprops) => {
    const { MainText } = style(undefined, type);
    const lang = useAppSelector(state => state.languageState.language) || 'ru';
    const languageList = useAppSelector(state => state.languageState.languageList) || 'ru';
    return <MainText>{languageList[lang]}</MainText>;
};
