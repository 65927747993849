import React, { useEffect } from 'react';
import style from './style';
import { CChart } from '@coreui/react-chartjs';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { Tstatistics } from '@maket/js/types/state/statistics';
import { TactiveLanguage } from '@maket/js/types/state/language';
import { getStyle } from '@coreui/utils';

function data(statistics: Tstatistics, activeLanguage: TactiveLanguage) {
    return {
        labels: statistics.messages.map((el, i) => {
            if (statistics.messages.length > 6) {
                if (i == 0 || i == statistics.messages.length - 1) {
                    return el.date;
                } else {
                    const checkIndex = Math.floor(statistics.messages.length / 5);
                    if (i % checkIndex == 0) {
                        return el.date;
                    } else {
                        return '';
                    }
                }
            }
            return el.date;
        }),
        datasets: [
            {
                label: activeLanguage['Dashbord.ChartNoSpam'],
                backgroundColor: 'rgba(220, 220, 220, 0.2)',
                borderColor: '#4BC06A',
                pointBackgroundColor: '#4BC06A',
                pointBorderColor: '#fff',
                data: statistics.messages.map(el => el.count),
            },
            {
                label: activeLanguage['Dashbord.ChartSpam'],
                backgroundColor: '#F86C6B',
                borderColor: '#F86C6B',
                pointBackgroundColor: '#F86C6B',
                pointBorderColor: '#fff',
                data: statistics.spam_messages.map(el => el.count),
            },
        ],
    };
}

const options: any = {
    responsive: true,

    aspectRatio: 3.5,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            font: { family: 'Inter,sans-serif' },
            fontFamily: 'Inter,sans-serif',
        },
    },
    scales: {
        x: {
            grid: {
                color: getStyle('--cui-border-color-translucent'),
            },
            ticks: {
                color: getStyle('--cui-body-color'),
                font: { family: 'Inter,sans-serif' },
                fontFamily: "Inter', sans-serif",
            },
        },
        y: {
            grid: {
                color: getStyle('--cui-border-color-translucent'),
            },
            ticks: {
                color: getStyle('--cui-body-color'),
                font: { family: 'Inter,sans-serif' },
                fontFamily: "Inter', sans-serif",
            },
        },
    },
    elements: {
        line: {
            borderJoinStyle: 'round',
            tension: 0.5,
        },
    },
};
let lastNumLeft = undefined;
let rect = '';
let left = '';
export default ({ time }: { time: string }) => {
    const { Container, Top, Bottom, TextBox, Title, Text } = style();
    const { BottomCenter, BottomItem, BottomItemTitle, BottomItemText, BottomItemUnderline } =
        style();

    useEffect(() => {
        const linterScroll = e => {
            const canvasLine = document.getElementById('canvasLine');
            if (canvasLine) {
                rect = canvasLine.getBoundingClientRect();
                const el = document.querySelector('.chartjs-tooltip');

                el?.setAttribute(
                    'style',
                    `opacity: 0; top: ${rect.y + rect.height / 2.75}px; left: ${left}`,
                );
            }
        };
        const linterMove = e => {
            const canvasLine = document.getElementById('canvasLine');
            if (canvasLine) {
                if (e.target.closest('#canvasLine')) {
                    rect = canvasLine.getBoundingClientRect();
                    const el = document.querySelector('.chartjs-tooltip');
                    const style = el?.getAttribute('style');
                    let leftSpl = style?.split('left: ');

                    if (leftSpl) {
                        left = leftSpl[1];
                        if (left) {
                            left = left.split(';');
                            if (left) {
                                lastNumLeft = left[0];
                                left = left[0];
                            } else {
                                left = lastNumLeft;
                            }
                        }
                    }
                    if (left) {
                        el?.setAttribute(
                            'style',
                            `opacity: 1; top: ${rect.y + rect.height / 2.75}px; left: ${left}`,
                        );
                    } else {
                        el?.setAttribute(
                            'style',
                            `opacity: 0; top: ${rect.y + rect.height / 2.75}px; left: ${left}`,
                        );
                    }
                } else {
                    rect = canvasLine.getBoundingClientRect();
                    const el = document.querySelector('.chartjs-tooltip');
                    if (el) {
                        el?.setAttribute(
                            'style',
                            `opacity: 0; top: ${rect.y + rect.height / 2.75}px; left: ${left}`,
                        );
                    }
                }
            }
        };
        document.getElementById('mainScrollbar')?.addEventListener('scroll', linterScroll);
        document.getElementById('mainScrollbar')?.addEventListener('mousemove', linterMove);
        return () => {
            document.getElementById('mainScrollbar')?.removeEventListener('scroll', linterScroll);
            document.getElementById('mainScrollbar')?.removeEventListener('mousemove', linterMove);
        };
    });

    const { statistics } = useAppSelector(state => state.dashbordState);

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    let spam_messagesCount = 0;
    let messagesCount = 0;

    statistics.spam_messages.forEach(el => {
        spam_messagesCount += +el.count;
    });
    statistics.messages.forEach(el => {
        messagesCount += +el.count;
    });
    const allMessage = spam_messagesCount + messagesCount;

    const spamPr = spam_messagesCount / allMessage;
    const spamPrFin = Math.round((spamPr == Infinity ? 0 : spamPr * 100) || 0);

    return (
        <Container>
            <Top>
                <TextBox>
                    <Title>{activeLanguage['Dashbord.Messages']}</Title>
                    <Text>{time}</Text>
                </TextBox>
                <CChart
                    id="canvasLine"
                    type="line"
                    data={data(statistics, activeLanguage)}
                    options={options}
                    style={{ fontFamily: 'Inter,sans-serif' }}
                    customTooltips={true}
                />
            </Top>
            <Bottom>
                <BottomCenter>
                    <BottomItem>
                        <BottomItemTitle>{activeLanguage['Dashbord.Spam']}</BottomItemTitle>
                        <BottomItemText>{activeLanguage['Dashbord.messages']}</BottomItemText>
                        <BottomItemText>({spamPrFin}%)</BottomItemText>
                        <BottomItemUnderline className="blue"></BottomItemUnderline>
                    </BottomItem>
                    <BottomItem>
                        <BottomItemTitle>{activeLanguage['Dashbord.NoSpam']}</BottomItemTitle>
                        <BottomItemText>{activeLanguage['Dashbord.messages']}</BottomItemText>
                        <BottomItemText>({100 - spamPrFin}%)</BottomItemText>
                        <BottomItemUnderline className="green"></BottomItemUnderline>
                    </BottomItem>
                </BottomCenter>
            </Bottom>
        </Container>
    );
};
