import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { whiteText } = useTheme();
    return {
        Upgrade: styled.div({
            display: 'flex',
            gap: size.isMobile ? '1em' : '2em',
            padding: '1.5em',
            backgroundColor: whiteText,
            width: size.isDesktop ? 'fit-content' : '100%',
            justifyContent: size.isDesktop ? 'start' : 'space-between',

            flexDirection: size.isMobile ? 'column' : 'row',
            alignItems: 'center',
        }),
        UpgradeText: styled.div({
            fontSize: size.isMobile ? '1.125em' : '1.25em',
            fontWeight: 600,
        }),
        size,
    };
};
