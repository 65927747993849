import React from 'react';
import style from './style';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { CButton, CTooltip } from '@coreui/react';
import Button from '@maket/js/components/microComponets/button';
import { NavLink } from 'react-router-dom';

export default () => {
    const { Upgrade, UpgradeText, size } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const user = useAppSelector(state => state.authState.user);
    let checkMute = false;
    if (user) {
        if (user.profiles[0]) {
            checkMute = user.profiles[0]?.settings.mute_rules;
        } else {
            checkMute = false;
        }
    }

    if (checkMute == false) {
        return (
            <Upgrade>
                <UpgradeText>{activeLanguage['ChatRules.Upgrade.Text']}</UpgradeText>
                <CTooltip content={activeLanguage['ChatRules.Upgrade.Tooltip']} placement="top">
                    <NavLink to={'/app/billing'}>
                        <CButton
                            style={{
                                width: size.isMobile ? '100%' : 'fit-content',
                                padding: '0.637em 1.625em',
                                fontWeight: 600,
                            }}
                            color="dark"
                        >
                            {activeLanguage['ChatRules.Upgrade.Button']}
                        </CButton>
                    </NavLink>
                </CTooltip>
            </Upgrade>
        );
    }
    return <></>;
};
