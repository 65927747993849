import React from 'react';
import style from './style';
import WidgetsBox from './widgetsBox';
import ChartLine from './chartLine';
import ChartDoughnutBox from './chartDoughnutBox';
import comparisonBCP47 from '@maket/js/state/language/comparisonBCP47';
import useAppSelector from '@maket/js/hooks/useAppSelector';

export default () => {
    const { Container } = style();

    const { statistics, DateState } = useAppSelector(state => state.dashbordState);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const language = useAppSelector(state => state.languageState.language);
    const langCode =
        Object.keys(comparisonBCP47)[
            Object.values(comparisonBCP47).findIndex(el => {
                return language == el;
            })
        ];

    let time =
        DateState.end != DateState.start
            ? `${new Date(DateState.start).toLocaleDateString(langCode, {
                  month: 'long',
                  day: 'numeric',
              })} - ${new Date(DateState.end).toLocaleDateString(langCode, {
                  month: 'long',
                  day: 'numeric',
              })}`
            : new Date(DateState.start).toLocaleDateString(langCode, {
                  month: 'long',
                  day: 'numeric',
              });

    if (time == 'Invalid Date') {
        time = activeLanguage['Dashbord.NotSelect.Period'];
    }
    return (
        <Container>
            <WidgetsBox />
            <ChartLine time={time} />
            <ChartDoughnutBox time={time} />
        </Container>
    );
};
