import React from 'react';
import Top from './top';
import Bottom from './bottom';
import style from './style';

export default () => {
    const { Container, Blur } = style();
    return (
        <Container>
            <Blur />
            <Top />
            <Bottom />
        </Container>
    );
};
