import React, { useEffect, useRef } from 'react';
import style from './style';
import CardColumn from '@js/components/microComponets/cardColumn';
import useAppSelector from '@js/hooks/useAppSelector';
import useTheme from '@js/hooks/useTheme';

const cardList = [
    { title: 'Main.Installation.CardOneTitle', text: 'Main.Installation.CardOneText' },
    { title: 'Main.Installation.CardTwoTitle', text: 'Main.Installation.CardTwoText' },
    { title: 'Main.Installation.CardThreeTitle', text: 'Main.Installation.CardThreeText' },
    { title: 'Main.Installation.CardFourTitle', text: 'Main.Installation.CardFourText' },
    { title: 'Main.Installation.CardFiveTitle', text: 'Main.Installation.CardFiveText' },
];

export default () => {
    const { Container, Left, ProgressBar, ProgressPointer, Right, size } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const { buttonBlue, installGrayText } = useTheme();

    const RightRef = useRef<HTMLDivElement>(null);
    const ProgressPointerRef = useRef<HTMLDivElement>(null);
    const ProgressPointerNextRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let scrollHeight = Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight,
        );

        const scrollLinter = (e: Event) => {
            // @ts-ignore: Unreachable code error
            const scrollPosition = e.target?.scrollTop - scrollHeight / 3.5;
            // @ts-ignore: Unreachable code error
            const rightElTopPosition = RightRef.current?.offsetTop - RightRef.current?.scrollHeight;
            const scrollVal =
                scrollPosition - rightElTopPosition > 0
                    ? scrollPosition - rightElTopPosition >
                      RightRef.current?.offsetHeight - ProgressPointerRef.current?.scrollHeight
                        ? RightRef.current?.offsetHeight - ProgressPointerRef.current?.scrollHeight
                        : scrollPosition - rightElTopPosition
                    : 0;

            ProgressPointerRef.current?.setAttribute(
                'style',
                `transform: translate(-50%,${scrollVal}px); z-index: 1`,
            );
            // ProgressPointerNextRef.current?.setAttribute(
            //     'style',
            //     `top: ${scrollVal + 0.1 * RightRef.current?.scrollHeight}px`,
            // );

            const activeNumber = RightRef.current
                ?.querySelectorAll('[data-number-item]')
                .forEach(el => {
                    // @ts-ignore: Unreachable code error
                    const elNum = el?.dataset.numberItem;
                    const activeNum =
                        Math.ceil(scrollVal / (RightRef.current?.offsetHeight / 5)) || 1;
                    if (elNum == activeNum) {
                        el.classList.add('active');
                    } else {
                        el.classList.remove('active');
                    }
                });
        };
        document.getElementById('wrap')?.addEventListener('scroll', scrollLinter);
        return () => document.getElementById('wrap')?.removeEventListener('scroll', scrollLinter);
    }, []);
    return (
        <Container>
            <Left>
                <ProgressBar>
                    <ProgressPointer
                        ref={ProgressPointerRef}
                        style={{ zIndex: 1 }}
                    ></ProgressPointer>
                    {/* <ProgressPointer
                        ref={ProgressPointerNextRef}
                        className="next"
                    ></ProgressPointer> */}
                </ProgressBar>
            </Left>
            <Right ref={RightRef}>
                {cardList.map((el, i) => (
                    <CardColumn
                        style={{ width: size.isMobile || size.isTab ? '100%' : '' }}
                        key={el.title + 'Install'}
                        text={activeLanguage[el.text]}
                        title={activeLanguage[el.title]}
                        typeCard="num"
                        typeColor="white"
                        number={i + 1}
                        titleColor={installGrayText}
                        textColor={installGrayText}
                        numberColor={installGrayText}
                        numberCenter={true}
                        borderBottomNone={true}
                    />
                ))}
            </Right>
        </Container>
    );
};
