import React from 'react';
import style from './style';
import Button from '@js/components/microComponets/button';

import useAppSelector from '@js/hooks/useAppSelector';
import { NavLink } from 'react-router-dom';

export default () => {
    const { Top, Background, BackgroundMask, Logo, MainText, MidleText, ButtonBox, size } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const title = activeLanguage['Main.PreviewTitle']?.split(' — ') || [];
    return (
        <Top>
            <Logo src="/img/Logo.svg" />
            <MainText>
                <span>{title[0]}</span> — {title[1]}
            </MainText>
            <MidleText>
                {activeLanguage[size.isMobile ? 'Main.PreviewTextMob' : 'Main.PreviewText']
                    ?.split('\\')
                    .map((el, i) => <div key={i + 'previewText'}>{el}</div>)}
            </MidleText>
            <ButtonBox>
                <NavLink
                    onClick={() => {
                        localStorage.setItem(
                            'MZPreRegTariff',
                            JSON.stringify({ tariffKey: 'free', timestamp: +new Date() }),
                        );
                    }}
                    style={{ width: size.isMobile ? '100%' : '' }}
                    to={'/app/auth/auth'}
                >
                    <Button
                        style={
                            size.isMobile || size.isTab
                                ? { fontSize: '2em', width: '100%' }
                                : { fontSize: '1.125em' }
                        }
                        typeButt="black"
                    >
                        {activeLanguage['Main.Button.Trial']}
                    </Button>
                </NavLink>
                <Button
                    onClick={() => {
                        const block = document.getElementById('mainTwo');
                        block?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'start',
                        });
                    }}
                    style={
                        size.isMobile || size.isTab
                            ? { fontSize: '2em', width: '100%' }
                            : { fontSize: '1.125em' }
                    }
                    typeButt="blue"
                >
                    {activeLanguage['Main.Button.Information']}
                </Button>
            </ButtonBox>
            <BackgroundMask />
            <Background src="/img/background/home__preview.svg" />
        </Top>
    );
};
