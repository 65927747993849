import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { TappDispatch } from '@maket/js/state/store';
import { Tuser } from '@maket/js/types/state/auth';
import axios from 'axios';

type Tprops = {
    token: string;
    profile_id: number;
};

export default async ({ token, profile_id }: Tprops) => {
    setVisableLoader('visable');
    const req = await axios.post<{ result: string; errors: string[]; data: {} }>(
        apiUrl + '/profile/delete',
        { profile_id },
        {
            headers: { Authorization: `Bearer ${token}` },
        },
    );
    setVisableLoader('notVisable');
    return req;
};
