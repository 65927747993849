import React, { useEffect, useState } from 'react';
import style from './style';
import Button from '@js/components/microComponets/button';
import { TauthPage } from '../..';
import useAppSelector from '@js/hooks/useAppSelector';
import { comparsion } from '..';
import Alert from './alert';
import { Talert } from '@js/components/microComponets/alert';
import { NavLink, useLocation } from 'react-router-dom';
import sendMailSucess, { clearState } from './sendMailSucess';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { TauthInputValueState } from '@maket/js/types/state/authInputsValue';
import { mailRegExp, passwordRegExp } from '@maket/js/constants/values';

type Tprops = { type: TauthPage };

export type TalertState = {
    typeAllert: Talert | '';
    textAllert: string;
};

let allertActive = false;

export default ({ type }: Tprops) => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { ButtonBox, size } = style();

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const lang = useAppSelector(state => state.languageState.language);
    const authInputsValues = useAppSelector(state => state.authInputsValueState);

    const acceptCheck = useAppSelector(state => state.termsSwithState.state);

    const [stateAlert, setStateAlert] = useState<TalertState>({
        typeAllert: '',
        textAllert: '',
    });

    useEffect(() => {
        const confirm = location.state?.confirm;
        if (confirm == 'error') {
            setStateAlert({ typeAllert: 'danger', textAllert: 'stateAlert' });
            clearState(setStateAlert);
        }
        if (confirm == 'ok') {
            setStateAlert({ typeAllert: 'success', textAllert: 'successConfirm' });
            clearState(setStateAlert);
        }
        if (confirm == 'PassReset') {
            setStateAlert({ typeAllert: 'success', textAllert: 'PassReset' });
            clearState(setStateAlert);
        }
        if (confirm == 'confirmChangeEmailErr') {
            setStateAlert({ typeAllert: 'danger', textAllert: 'ChangeEmailErr' });
            clearState(setStateAlert);
        }
        if (confirm == 'confirmChangeEmailErrSuccess') {
            setStateAlert({ typeAllert: 'success', textAllert: 'ChangeEmailConfirm' });
            clearState(setStateAlert);
        }

        const userTgError = location.state?.userTgError;
        if (userTgError == 'incorrectTgAccount') {
            setStateAlert({ typeAllert: 'danger', textAllert: 'incorrectTgAccount' });
            clearState(setStateAlert);
        }
    }, []);

    useEffect(() => {
        if (authInputsValues.pass != '' && type == 'reg') {
            const checkPass = authInputsValues.pass.match(passwordRegExp);
            if (!checkPass) {
                if (!allertActive) {
                    setStateAlert({
                        typeAllert: 'danger',
                        textAllert: 'passwordErrorRegExp',
                    });
                }
            } else {
                clearState(setStateAlert, allertActive, 100);
            }
        }
    }, [authInputsValues]);

    useEffect(() => {
        if (stateAlert.textAllert) {
            allertActive = true;
        } else {
            allertActive = false;
        }
    }, [stateAlert]);
    return (
        <ButtonBox>
            <Button
                onClick={() => {
                    if (type == 'auth') {
                        sendMailSucess(
                            type,
                            authInputsValues,
                            setStateAlert,
                            dispatch,
                            lang,
                            allertActive,
                        );
                    }
                    if (type == 'reg' && checkedValues(setStateAlert, authInputsValues)) {
                        if (acceptCheck) {
                            sendMailSucess(
                                type,
                                authInputsValues,
                                setStateAlert,
                                dispatch,
                                lang,
                                allertActive,
                            );
                        } else {
                            setStateAlert({
                                typeAllert: 'danger',
                                textAllert: 'AcceptError',
                            });
                            clearState(setStateAlert, allertActive);
                        }
                    }
                }}
                style={{ width: size.isMobile && type == 'reg' ? '100%' : '10em' }}
                typeButt="blue"
                disabled={stateAlert.typeAllert != ''}
            >
                {activeLanguage[comparsion[type].button]}
            </Button>
            {type == 'auth' && (
                <NavLink to={'/app/fogotPass'}>
                    <Button typeButt="transparent">{activeLanguage['Auth.Login.FogotPass']}</Button>
                </NavLink>
            )}
            <Alert stateAlert={stateAlert} />
        </ButtonBox>
    );
};

function checkedValues(
    setStateAlert: React.Dispatch<React.SetStateAction<TalertState>>,
    authInputsValues: TauthInputValueState,
) {
    const mailCheck = authInputsValues.mail.match(mailRegExp);
    const passwordCheck = authInputsValues.pass.match(passwordRegExp);

    if (mailCheck == null) {
        setStateAlert({ typeAllert: 'danger', textAllert: 'mailErrorRegExp' });
        clearState(setStateAlert, allertActive);
        return false;
    }
    if (passwordCheck == null) {
        setStateAlert({ typeAllert: 'danger', textAllert: 'passwordErrorRegExp' });
        clearState(setStateAlert, allertActive);
        return false;
    }
    if (mailCheck != null && passwordCheck != null) {
        return true;
    }
}
