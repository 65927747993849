import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import cardListInfo from '@maket/js/constants/cardListInfo';
import { setServiseList } from '@maket/js/state/serviceList/authState';
import { TappDispatch } from '@maket/js/state/store';
import { TServiceElement, TserviseNames } from '@maket/js/types/state/serviseList';
import axios from 'axios';

type Tprops = {
    serviceType: TserviseNames;
    dispatch: TappDispatch;
};

export default async ({ serviceType, dispatch }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post<Array<TServiceElement>>(apiUrl + '/service/tariff/list', {
            service_type: serviceType,
        });
        const sortedList: Array<any> = [];
        setVisableLoader('notVisable');
        cardListInfo('block').forEach(el => {
            const item = req.data.find(dtEl => dtEl.key == el.key);
            if (item) {
                sortedList.push(item);
            }
            if (el.key == 'enterprise') {
                sortedList.push({ key: 'enterprise' });
            }
        });
        dispatch(setServiseList(sortedList));
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
