import React, { useEffect, useRef } from 'react';
import style from './style';
import DateBox from './dateBox';
import Main from './main';
import Bottom from './bottom';

export default () => {
    const bottomRef = useRef<HTMLDivElement>(null);
    const { Container, Item, size } = style();

    useEffect(() => {
        if (bottomRef.current && !(size.isMobile || size.isTab)) {
            // @ts-ignore: Unreachable code error
            const maxHeightChildren = bottomRef.current.firstChild?.scrollHeight;
            bottomRef.current.setAttribute('style', `height: ${maxHeightChildren}px;`);
        }
    });

    return (
        <Container>
            <DateBox />
            <Item>
                <Main />
            </Item>
        </Container>
    );
};
