import { TthemeState } from '@js/types/state/theme';
import { createSlice } from '@reduxjs/toolkit';

const initialState: { theme: TthemeState } = { theme: 'white' };

const themeState = createSlice({
    name: 'themeState',
    initialState,
    reducers: {
        setTheme: (state, { payload }: { payload: TthemeState }) => {
            state.theme = payload;
        },
    },
});

export const { setTheme } = themeState.actions;

export default themeState;
