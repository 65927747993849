import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    return {
        GlobalBlurWhite: styled.div({
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            backgroundColor: '#fff',
            top: 0,
            left: 0,
            display: 'block',
            zIndex: '1000000',
        }),
        size,
    };
};
