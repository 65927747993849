import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { altTextBlackColor } = useTheme();
    return {
        PasswordCotainer: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5em',
            color: altTextBlackColor,
        }),
        PasswordItem: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '0.75em',
        }),
        PasswordItemTitle: styled.div({
            width: '100%',
            textAlign: 'start',
        }),
        PasswordItemContainer: styled.div({}),
        size,
    };
};
