import React, { useEffect, useRef } from 'react';
import style from './style';
import Chart from './chart';
import ProgressBarBox from './progressBarBox';
import useAppSelector from '@maket/js/hooks/useAppSelector';

export default ({ time }: { time: string }) => {
    const { Container, Left, Right, TextBox, Title, Text } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const { statistics } = useAppSelector(state => state.dashbordState);

    return (
        <Container>
            <Left>
                <TextBox>
                    <Title>{activeLanguage['Dashbord.MessageTypes.Title']}</Title>
                    <Text>{time}</Text>
                </TextBox>
                <Chart statistics={statistics} activeLanguage={activeLanguage} />
            </Left>
            <Right>
                <TextBox>
                    <Title>{activeLanguage['Dashbord.SpamTopics.Title']}</Title>
                    <Text>
                        {statistics.spam_topics.length == 0 ? activeLanguage['EmptyFar.'] : time}
                    </Text>
                </TextBox>
                <ProgressBarBox />
            </Right>
        </Container>
    );
};
