import React from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';

type Tprops = { onliLine?: boolean };

export default ({ onliLine }: Tprops) => {
    const { Container, Line, Content } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <Container>
            <Line />
            {!onliLine && <Content>{activeLanguage['Auth.Login.OtherWayAuth']}</Content>}
        </Container>
    );
};
