import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { setAgreeTermsAccount } from '@maket/js/state/auth/authState';
import { TappDispatch } from '@maket/js/state/store';
import axios from 'axios';

type Tprops = {
    token: string;
    dispatch: TappDispatch;
};

export default async ({ token, dispatch }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post<{ data: { redirect_url: string }; status: string }>(
            apiUrl + '/account/agree_with_term_of_service',
            {},
            {
                headers: { Authorization: `Bearer ${token}` },
            },
        );
        setVisableLoader('notVisable');
        dispatch(setAgreeTermsAccount());
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
