import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import confirmFogotPass from '@maket/js/api/auth/confirmFogotPass';

let onceCheck = true;

export default () => {
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state;

    useEffect(() => {
        if (state && state.code && onceCheck) {
            onceCheck = false;
            confirmFogotPass({ code: state.code }).then(rez => {
                if (rez == 'error') {
                    navigate('/app/fogotPass', { state: { confirm: rez } });
                }
                if (rez == 'ok') {
                    navigate('/app/auth/auth', { state: { confirm: 'PassReset' } });
                }
            });
        }
    });
    return <></>;
};
