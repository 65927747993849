import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import Button from '../../microComponets/button';
import ButtonAllert, { TalertState } from '../../microComponets/buttonAllert';

interface Tprops extends HTMLAttributes<HTMLDivElement> {
    state: boolean;
    title: string;
    main: any;
    buttonText: string;
    setState: (v: boolean) => void;
    setFunction?: () => void;
    allControllSetFunction?: () => void;
    allControllEndFunction?: () => void;
    closeFn?: () => void;
    closeNone?: boolean;
    closeText?: string;
    buttonStyles?: HTMLAttributes<HTMLButtonElement>;
    allertStateOuter?: TalertState;
    setAllertStateOuter?: React.Dispatch<React.SetStateAction<TalertState>>;
    allertStyle?: {};
    typeMainButt?: string;
}

export default ({
    state,
    setState,
    setFunction,
    allControllSetFunction,
    allControllEndFunction,
    closeFn,
    closeNone,
    title,
    main,
    buttonText,
    closeText,
    buttonStyles,
    allertStateOuter,
    setAllertStateOuter,
    allertStyle,
    typeMainButt,
    ...props
}: Tprops) => {
    return (
        <div {...props} style={{ fontFamily: 'Inter, sans-serif', ...props.style }}>
            <CModal
                backdrop="static"
                alignment="center"
                visible={state}
                onClose={() => {
                    if (closeFn) {
                        closeFn();
                    }
                    setState(false);
                }}
                aria-labelledby="VerticallyCenteredExample"
            >
                <CModalHeader style={{ fontFamily: 'Inter, sans-serif' }}>
                    <CModalTitle style={{ fontWeight: 600 }} id="VerticallyCenteredExample">
                        {title}
                    </CModalTitle>
                </CModalHeader>
                {main && (
                    <CModalBody style={{ fontFamily: 'Inter, sans-serif' }}>{main}</CModalBody>
                )}
                <CModalFooter
                    style={{ fontFamily: 'Inter, sans-serif', justifyContent: 'space-between' }}
                >
                    {setAllertStateOuter && allertStateOuter != undefined ? (
                        <ButtonAllert
                            typeButt="black"
                            onClick={() => {
                                if (allControllSetFunction) {
                                    allControllSetFunction();
                                } else {
                                    setState(false);
                                    setFunction && setFunction();
                                }
                            }}
                            style={buttonStyles}
                            buttonContent={buttonText}
                            stateAllertOuter={allertStateOuter}
                            setAllertFn={setAllertStateOuter}
                            styleAlert={allertStyle}
                        ></ButtonAllert>
                    ) : (
                        <Button
                            typeButt={typeMainButt ? typeMainButt : 'black'}
                            style={buttonStyles}
                            onClick={() => {
                                if (allControllSetFunction) {
                                    allControllSetFunction();
                                } else {
                                    setState(false);
                                    setFunction && setFunction();
                                }
                            }}
                        >
                            {buttonText}
                        </Button>
                    )}

                    {!closeNone ? (
                        <Button
                            style={buttonStyles}
                            typeButt="secondary"
                            onClick={() => {
                                if (allControllEndFunction) {
                                    allControllEndFunction();
                                } else {
                                    setState(false);
                                }
                            }}
                        >
                            {closeText}
                        </Button>
                    ) : (
                        <></>
                    )}
                </CModalFooter>
            </CModal>
        </div>
    );
};
