import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    return {
        Container: styled.div({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            flex: size.isMobile || size.isTab ? '1 1' : '0 0 50%',
            position: 'relative',
            padding: '3em 0',
        }),
        Background: styled.img({
            width: size.isLaptop ? '39.5vw' : size.isTab || size.isMobile ? '90vw' : '49.5vw',
            height: 'auto',
            transform: size.isMobile || size.isTab ? 'translate(1em)' : '',
        }),
        BackgroundMask: styled.div({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        }),
        size,
    };
};
