import { CNavItem } from '@coreui/react';

const navUserAuth = (type: 'init' | 'profileList' | 'doc') => {
    const arr = [];

    arr.push({
        component: CNavItem,
        name: 'Menu.Dashboard',
        to: '/app/dashboard',
        icon: 'chartDonut',
        badge: {
            color: 'info',
            text: 'NEW',
        },
    });

    if (type == 'profileList') {
        arr.push({
            component: CNavItem,
            name: 'Menu.Profile',
            to: 'popap',
            icon: 'pencilCircle',
            badge: {
                color: 'info',
                text: 'NEW',
            },
        });
    }
    if (type == 'init' || type == 'doc') {
        arr.push({
            component: CNavItem,
            name: 'User.Menu.Profilelist',
            to: '/app/profileList',
            icon: 'pencilCircle',
            badge: {
                color: 'info',
                text: 'NEW',
            },
        });
    }

    arr.push(
        ...[
            {
                component: CNavItem,
                name: 'Menu.ChatList',
                to: '/app/chatList',
                icon: 'barricade',
                badge: {
                    color: 'info',
                    text: 'NEW',
                },
            },
            // {
            //     component: CNavItem,
            //     name: 'Menu.Reports',
            //     to: '/app/reports',
            //     icon: 'browsers',
            //     badge: {
            //         color: 'info',
            //         text: 'NEW',
            //     },
            // },
            // {
            //     component: CNavItem,
            //     name: 'Menu.Setting',
            //     to: '/app/setting',
            //     icon: 'gearFine',
            //     badge: {
            //         color: 'info',
            //         text: 'NEW',
            //     },
            // },
            {
                component: CNavItem,
                name: 'Menu.Billing',
                to: '/app/billing',
                icon: 'currencyEth',
                badge: {
                    color: 'info',
                    text: 'NEW',
                },
            },
        ],
    );

    arr.push({
        component: CNavItem,
        name: 'Menu.Documentation',
        to: 'popapDoc',
        icon: 'fileText',
        badge: {
            color: 'info',
            text: 'NEW',
        },
    });

    return arr;
};

const navUserNoAuth = (typePage: 'init' | 'profileList' | 'doc') => {
    const arr = [
        {
            component: CNavItem,
            name: 'Menu.Documentation',
            to: 'popapDoc',
            icon: 'fileText',
            badge: {
                color: 'info',
                text: 'NEW',
            },
        },
    ];

    return arr;
};

const navigation = (type: 'auth' | 'noAuth', typePage: 'init' | 'profileList' | 'doc') =>
    type == 'auth' ? navUserAuth(typePage) : navUserNoAuth(typePage);

export default navigation;
