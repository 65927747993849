import React from 'react';

import CIcon from '@coreui/icons-react';
import {
    CCloseButton,
    CSidebar,
    CSidebarBrand,
    CSidebarFooter,
    CSidebarHeader,
} from '@coreui/react';

import Main from './main';

import useAppSelector from '@maket/js/hooks/useAppSelector';
import useWindowSizeCheck from '@maket/js/hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';

import { setActivePopap } from '@maket/js/state/openPopap/openPopapState';
import { setUnfoldable } from '@maket/js/state/header/authState';
import style from './style';

const AppSidebar = ({
    position,
    stateControllName,
}: {
    position: 'fixed' | 'sticky';
    stateControllName?: string;
}) => {
    const unfoldable = useAppSelector(state => state.headerState.unfoldable);
    const activePopapName = useAppSelector(state => state.openPopapState.activePopapName);
    const { navBackgroundGrayLite, navBackgroundGrayDark, mainNavBackground, whiteText } =
        useTheme();
    const dispatch = useAppDispatch();
    const { isTab, isMobile } = useWindowSizeCheck();
    const { size } = style();

    const stateControll = stateControllName
        ? // @ts-ignore: Unreachable code error
          useAppSelector(state => state[stateControllName].state)
        : undefined;

    return (
        <CSidebar
            className="border-end"
            colorScheme="dark"
            position={position}
            unfoldable={unfoldable}
            data-popap={size.isMobile || size.isTab ? 'false' : 'true'}
            visible={
                stateControll != undefined
                    ? stateControll == true
                    : activePopapName == 'navigationPopap' ||
                      activePopapName == 'navigationPopapAndLanguage'
            }
            style={{
                backgroundColor: mainNavBackground,
                height: !(isMobile || isTab) ? 'calc(100vh - 3.2em)' : '',
                top:
                    unfoldable && !(isMobile || isTab)
                        ? '3.4em'
                        : position == 'sticky'
                        ? ''
                        : !(isMobile || isTab)
                        ? '3.4em'
                        : '',
            }}
        >
            {(size.isMobile || size.isTab) && (
                <CSidebarHeader
                    style={{
                        backgroundColor: isMobile || isTab ? whiteText : navBackgroundGrayLite,
                        height: '3.2em',
                    }}
                    className="border-bottom"
                >
                    <CSidebarBrand>
                        <CIcon customClassName="sidebar-brand-full" icon={''} height={32} />
                        <CIcon customClassName="sidebar-brand-narrow" icon={''} height={32} />
                    </CSidebarBrand>
                    <CCloseButton
                        className="d-lg-none"
                        dark={!(isMobile || isTab)}
                        onClick={() => dispatch(setActivePopap(''))}
                    />
                </CSidebarHeader>
            )}
            <Main />
            <CSidebarFooter
                style={{ justifyContent: 'end' }}
                className="border-top d-none d-lg-flex"
            >
                <img
                    style={{
                        height: 13,
                        justifyContent: 'end',
                        backgroundColor: navBackgroundGrayDark,
                        transition: '0.3s',
                        transform: unfoldable ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                    src="/img/microIcon/grayArrow.svg"
                    onClick={() => dispatch(setUnfoldable(!unfoldable))}
                ></img>
            </CSidebarFooter>
        </CSidebar>
    );
};

export default React.memo(AppSidebar);
