import React, { useState } from 'react';

import useWindowSizeCheck from '@js/hooks/useWindowSizeCheck';
import Mobile from './mobile';
import { staticColor } from '@js/hooks/useTheme';
import Desktop from './desktop';

export type tNameSetting = 'Telegram' | 'Discord' | 'WebSite' | 'Email';
type Tprops = {};

export const comparison = {
    Telegram: {
        title: 'Telegram',
        iconName: 'telegram',
        iconColor: staticColor.telegramColor,
        id: 0,
    },
    Discord: {
        title: 'Discord',
        iconName: 'discord',
        iconColor: staticColor.discordColor,
        id: 1,
    },
    WebSite: {
        title: 'Web Site',
        iconName: 'globe',
        iconColor: '#000000',
        id: 2,
    },
    Email: {
        title: 'Email',
        iconName: 'mailReg',
        iconColor: '#000000',
        id: 3,
    },
};

export default ({}: Tprops) => {
    const [active, setActive] = useState<tNameSetting>('Telegram');

    const { isMobile, isTab } = useWindowSizeCheck();
    if (isMobile || isTab) {
        return <Mobile active={active} setActive={setActive} />;
    } else {
        return <Desktop active={active} setActive={setActive} />;
    }
};
