import React, { useEffect } from 'react';
import useTheme from '@maket/js/hooks/useTheme';
import RouteWrapper from '../../middleComponents/routeWrapper';
import Main from './main';
import chats from '@maket/js/api/requiresToken/chat/chats';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { useParams } from 'react-router-dom';

export default () => {
    const { authBackground } = useTheme();
    useEffect(() => {
        document.body.setAttribute('style', `background-color: ${authBackground}`);
    });
    const token = useAppSelector(state => state.tokenState.token);
    const dispatch = useAppDispatch();
    const { idProfile } = useParams();

    useEffect(() => {
        if (!idProfile) {
            if (token) {
                chats({
                    dispatch,
                    token,
                    profile_id: 0,
                });
            }
        }
    });

    return <RouteWrapper title="ChatList.Title" mainComponent={<Main />} type="onliWrapper" />;
};
