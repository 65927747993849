import React from 'react';
import { comparison, tNameSetting } from '../box';
import style from './style';

type Tprops = {
    type: tNameSetting;
    setActiveSetting: React.Dispatch<React.SetStateAction<tNameSetting>>;
    active: tNameSetting;
};

export default ({ type, setActiveSetting, active }: Tprops) => {
    const { PopapButton, MobileItemIcon, MobileItemName, CheckMarkMobile } = style();
    return (
        <PopapButton
            onClick={() => {
                setActiveSetting(type);
            }}
        >
            <MobileItemIcon
                animate={{
                    color: comparison[type].iconColor,
                }}
                className={`icon-${comparison[type].iconName}`}
            ></MobileItemIcon>
            <MobileItemName>{comparison[type].title}</MobileItemName>
            <CheckMarkMobile
                animate={{
                    opacity: type == active ? 1 : 0,
                }}
                className="icon-checkMark"
            ></CheckMarkMobile>
        </PopapButton>
    );
};
