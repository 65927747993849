import React from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';

export default () => {
    const { Container, Title } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <Container>
            <Title>
                {activeLanguage['Main.Work.Title']?.split('\\').map((el, i) => (
                    <div key={i + 'Text'}>
                        {el}
                        <br />
                    </div>
                ))}
            </Title>
        </Container>
    );
};
