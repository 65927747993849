import React, { useEffect, useState } from 'react';
import style from './style';
import GrayBox from '@maket/js/components/middleComponents/grayBox';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import Mail from './mail';
import PasswordSetting from './passwordSetting';
import ProfileSetting from './profileSetting';
import { Taccount } from '@maket/js/types/state/auth';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { TappDispatch } from '@maket/js/state/store';
import postSubscribeToNewsletter from '@maket/js/api/requiresToken/account/postSubscribeToNewsletter';
import accountUpdateMail from '@maket/js/api/requiresToken/account/accountUpdateMail';
import ButtonAllert, { TalertState } from '@maket/js/components/microComponets/buttonAllert';
import { useBlocker, useLocation, useNavigate } from 'react-router';
import { setBlockedPopap, setOnlyStateBlockedPopap } from '@maket/js/state/savedForms';
import getAccount from '@maket/js/api/requiresToken/account/getAccount';
import {
    clearChangeSafety,
    setInputMailSettingsValue,
} from '@maket/js/state/settingPass/settingPassValueState';

export default () => {
    const { Container } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <Container>
            <GrayBox title={activeLanguage['Safety.Item.One.Title']} content={<Mail />} />
            <GrayBox title={activeLanguage['Safety.Item.Two.Title']} content={<ProfileSetting />} />
            {/* <GrayBox title={activeLanguage['Safety.Item.Three.Title']} content={<AuthSetting />} /> */}
            <GrayBox
                title={activeLanguage['Safety.Item.Four.Title']}
                content={<PasswordSetting />}
            />
            <ButtonComponent />
        </Container>
    );
};

function ButtonComponent() {
    const navigate = useNavigate();
    const { size } = style();
    const location = useLocation();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const accountState = useAppSelector(state => state.authState.account);
    const token = useAppSelector(state => state.tokenState.token);
    const dispatch = useAppDispatch();
    const { mail, subscribe_to_newsletter } = useAppSelector(state => state.settingPassValueState);

    const [notClicked, setNotClicked] = useState(true);

    const [allertState, setAllertState] = useState<TalertState>({ textAllert: '', typeAllert: '' });

    useEffect(() => {
        let check = false;

        if (accountState?.email != mail) {
            check = true;
        }

        if (accountState?.subscribe_to_newsletter != subscribe_to_newsletter) {
            check = true;
        }

        setTimeout(() => {
            if (check) {
                if (allertState.textAllert) {
                    setTimeout(() => {
                        setNotClicked(false);
                    }, 2000);
                } else {
                    setNotClicked(false);
                }
            } else {
                if (allertState.textAllert) {
                    setTimeout(() => {
                        setNotClicked(true);
                    }, 2000);
                } else {
                    setNotClicked(true);
                }
            }
        }, 500);
    }, [accountState, mail, subscribe_to_newsletter]);

    useEffect(() => {
        if (location.state) {
            if (location.state.confirm == 'confirmChangeEmailErr') {
                navigate('/app/safety', { state: { safetyReload: '' } });
                window.location.reload();
                setAllertState({ textAllert: 'Auth.Allert.ChangeEmailErr', typeAllert: 'danger' });
            }
            if (location.state.confirm == 'confirmChangeEmailErrSuccess') {
                navigate('/app/safety', { state: { safetyReload: '' } });
                window.location.reload();
                setAllertState({
                    textAllert: 'Auth.Allert.ChangeEmailConfirm',
                    typeAllert: 'success',
                });
            }
        }
    }, [location.state]);

    useBlocker(n => {
        if (!notClicked) {
            // const historyState = { ...settingProfileValueState };
            dispatch(
                setBlockedPopap({
                    state: true,
                    resolveFn: () => {
                        buttonClick(
                            accountState,
                            subscribe_to_newsletter,
                            dispatch,
                            token,
                            mail,
                            setAllertState,
                            () => {
                                setTimeout(() => {
                                    navigate(n.nextLocation.pathname);
                                }, 1000);
                            },
                            () => {
                                dispatch(setOnlyStateBlockedPopap(false));
                            },
                        );

                        // dispatch(clearChange());

                        dispatch(
                            setBlockedPopap({
                                state: false,
                                rejectFn: () => {},
                                resolveFn: () => {},
                            }),
                        );
                    },
                    rejectFn: () => {
                        dispatch(
                            clearChangeSafety({
                                mail: accountState?.email,
                                subscribe_to_newsletter: accountState?.subscribe_to_newsletter,
                            }),
                        );

                        setTimeout(() => {
                            navigate(n.nextLocation.pathname);
                        }, 1000);

                        dispatch(
                            setBlockedPopap({
                                state: false,
                                rejectFn: () => {},
                                resolveFn: () => {},
                            }),
                        );

                        return false;
                    },
                }),
            );
            // dispatch(setHistoryProfile(historyState));
            // dispatch(setHistoryProfileAuth({ historyState, idProfile }));
            return true;
        } else {
            return false;
        }
    });

    return (
        <ButtonAllert
            setAllertFn={setAllertState}
            stateAllertOuter={allertState}
            onClick={() => {
                const loader = document.getElementById('loader');
                const check = loader?.className.includes('opacityNone');
                if (check) {
                    buttonClick(
                        accountState,
                        subscribe_to_newsletter,
                        dispatch,
                        token,
                        mail,
                        setAllertState,
                    );
                }
            }}
            style={{ width: size.isMobile || size.isTab ? '100%' : '10em' }}
            typeButt="black"
            disabled={notClicked}
            buttonContent={activeLanguage['Save.']}
            styleAlert={{
                // @ts-ignore: Unreachable code error
                width: `calc(${
                    size.isMobile || size.isTab ? '88vw' : size.isLaptop ? '60vw' : '78vw - 12em'
                } - ${size.isMobile ? '0' : size.isTab ? '3.5em' : '12em'})`,
                left: `calc(
					(${size.isMobile || size.isTab ? '88vw' : size.isLaptop ? '60vw' : '78vw - 12em'} 
					+ ${size.isMobile || size.isTab ? '0' : '12em'}
				)/2)`,
                bottom: size.isMobile || size.isTab ? '' : 'calc(130%)',
            }}
        ></ButtonAllert>
    );
}
function buttonClick(
    account: Taccount,
    subscribe_to_newsletter: boolean,
    dispatch: TappDispatch,
    token: string,
    newMail: string,
    setAllertState: React.Dispatch<React.SetStateAction<TalertState>>,
    outerFn?: () => void,
    outerExitFn?: () => void,
) {
    if (subscribe_to_newsletter != undefined) {
        postSubscribeToNewsletter({ token, dispatch, enable: subscribe_to_newsletter });
        outerFn && outerFn();
    }
    if (newMail != '' && newMail != account?.email) {
        if (newMail.length > 49) {
            setAllertState({
                textAllert: 'Auth.Alert.MailLengthError',
                typeAllert: 'danger',
            });
        } else {
            accountUpdateMail({ token, email: newMail })
                .then(res => {
                    getAccount({ token, dispatch })
                        .then(res => {
                            dispatch(setInputMailSettingsValue(res.data.email));
                        })
                        .then(r => {
                            setTimeout(() => {
                                if (res.error) {
                                    setAllertState({
                                        textAllert: 'Auth.Alert.MailErrorRegExp',
                                        typeAllert: 'danger',
                                    });
                                    outerExitFn && outerExitFn();
                                } else if (res.data.errors.length > 0) {
                                    if (res.data.errors.includes('email_already_in_use')) {
                                        setAllertState({
                                            textAllert: 'Safety.Alert.MailIsAlreadyInUse',
                                            typeAllert: 'danger',
                                        });
                                        outerExitFn && outerExitFn();
                                    }
                                } else {
                                    setAllertState({
                                        textAllert: 'Auth.Alert.MailSend',
                                        typeAllert: 'success',
                                    });
                                    outerFn && outerFn();
                                }
                            }, 1000);
                        });
                })
                .catch(err => {
                    getAccount({ token, dispatch })
                        .then(res => {
                            dispatch(setInputMailSettingsValue(res.data.email));
                        })
                        .then(r => {
                            setTimeout(() => {
                                setAllertState({
                                    textAllert: 'Auth.Alert.MailErrorRegExp',
                                    typeAllert: 'danger',
                                });
                                outerExitFn && outerExitFn();
                            }, 1000);
                        });
                });
        }
    }
}
