import {
    Taccount,
    TauthState,
    Tchat,
    Tlogger,
    Tservice,
    TserviceListItem,
    Ttariff,
    Tuser,
} from '@maket/js/types/state/auth';
import { createSlice, current } from '@reduxjs/toolkit';

const initialState: TauthState = {
    user: null,
    account: null,
    tariff: { current_tariff: undefined, switch_to_tariff: undefined, prolongation: undefined },
    service: null,
    serviceList: [],
    activeProfileChats: [],
    allChats: [],
    logerList: [],
};

const authState = createSlice({
    name: 'authState',
    initialState,
    reducers: {
        setAuth: (state, { payload }: { payload: Tuser }) => {
            state.user = payload;
        },
        setHistoryProfileAuth: (state, { payload }: { payload: Tuser }) => {
            const profile = state.user.profiles.find(el =>
                payload.idProfile ? +el.id == +payload.idProfile : false,
            );
            if (profile) {
                profile.status = payload.historyState.active ? 'active' : 'stopped';
                profile.delete_anonymous_messages = payload.historyState.delete_anonymous_messages;
                profile.delete_forwarded_story = payload.historyState.delete_forwarded_story;
                profile.language = payload.historyState.lang;
                profile.moderation = payload.historyState.moder;
                profile.name = payload.historyState.name;
                profile.active_protection = payload.historyState.protection;
            }
        },

        setAccount: (state, { payload }: { payload: Taccount }) => {
            state.account = payload;
        },

        setAgreeTermsAccount: state => {
            if (state.account) {
                state.account.agreement_tos_datetime = `${new Date().getTime()}`;
            }
        },

        setSubNewslatterAccount: (state, { payload }: { payload: boolean }) => {
            if (state.account) {
                state.account.subscribe_to_newsletter = payload;
            }
        },

        setTariff: (state, { payload }: { payload: Ttariff }) => {
            state.tariff = payload;
        },

        setService: (state, { payload }: { payload: Tservice }) => {
            state.service = payload;
        },

        setServiceList: (state, { payload }: { payload: Array<TserviceListItem> }) => {
            state.serviceList = payload;
        },

        setActiveProfileChats: (state, { payload }: { payload: Array<Tchat> }) => {
            state.activeProfileChats = payload;
        },

        setAllChats: (state, { payload }: { payload: Array<Tchat> }) => {
            state.allChats = payload;
        },

        setLoggerList: (state, { payload }: { payload: Array<Tlogger> }) => {
            state.logerList = payload;
        },

        logout: state => {
            state.user = null;
            state.account = null;
            state.tariff = {
                current_tariff: undefined,
                switch_to_tariff: undefined,
                prolongation: undefined,
            };
            state.service = null;
            state.serviceList = [];
        },

        setUserLangServer: (state, { payload }: { payload: string }) => {
            if (state.account) {
                state.account.language = payload;
            }
        },
    },
});

export const {
    setAuth,
    logout,
    setTariff,
    setAccount,
    setService,
    setServiceList,
    setAgreeTermsAccount,
    setSubNewslatterAccount,
    setActiveProfileChats,
    setLoggerList,
    setAllChats,
    setHistoryProfileAuth,
    setUserLangServer,
} = authState.actions;

export default authState;
