export default (type: 'page' | 'block') => [
    {
        key: 'free',
        name: 'Main.Price.CardFreeTitle',
        priceMonth: 'Main.Price.CardFreePriseMonth',
        priceYear: 'Main.Price.CardFreePriseYear',
        reductionMonth: 'Main.Price.CardMonth-Reduction',
        connect: type == 'page' ? 'Main.Price.CardConnectFree' : 'Main.Price.CardConnect',
        text: 'Main.Price.CardText',
        conditions: 'Main.Price.CardFreeConditions',
    },
    {
        key: 'basic',
        name: 'Main.Price.CardBasicTitle',
        priceMonth: 'Main.Price.CardBasicPriseMonth',
        priceYear: 'Main.Price.CardBasicPriseYear',
        reductionMonth: 'Main.Price.CardMonth-Reduction',
        connect: type == 'page' ? 'Main.Price.CardConnectFree' : 'Main.Price.CardConnect',
        text: 'Main.Price.CardText',
        conditions: 'Main.Price.CardBasicConditions',
    },
    {
        key: 'pro',
        name: 'Main.Price.CardProTitle',
        priceMonth: 'Main.Price.CardProPriseMonth',
        priceYear: 'Main.Price.CardProPriseYear',
        reductionMonth: 'Main.Price.CardMonth-Reduction',
        connect: type == 'page' ? 'Main.Price.CardConnectFree' : 'Main.Price.CardConnect',
        text: 'Main.Price.CardText',
        conditions: 'Main.Price.CardProConditions',
    },
    {
        key: 'expert',
        name: 'Main.Price.CardExpertTitle',
        priceMonth: 'Main.Price.CardExpertPriseMonth',
        priceYear: 'Main.Price.CardExpertPriseYear',
        reductionMonth: 'Main.Price.CardMonth-Reduction',
        connect: type == 'page' ? 'Main.Price.CardConnectFree' : 'Main.Price.CardConnect',
        text: 'Main.Price.CardText',
        conditions: 'Main.Price.CardExpertConditions',
    },
    {
        key: 'enterprise',
        name: 'Main.Price.CardEnterpriseTitle',
        priceMonth: 'Main.Price.CardEnterprisePriseMonth',
        priceYear: 'Main.Price.CardEnterprisePriseYear',
        reductionMonth: 'Main.Price.CardMonth-Reduction',
        connect: type == 'page' ? 'Main.Price.CardConnectFree' : 'Main.Price.CardEnterpriseConnect',
        text: 'Main.Price.CardText',
        conditions: 'Main.Price.CardEnterpriseConditions',
    },
];

export type Tcard = {
    key: string;
    name: string;
    priceMonth: string;
    priceYear: string;
    reductionMonth: string;
    reductionYear: string;
    connect: string;
    text: string;
    conditions: string;
};
