import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { setLoggerList } from '@maket/js/state/auth/authState';
import { TappDispatch } from '@maket/js/state/store';
import { Tlogger, Tuser } from '@maket/js/types/state/auth';
import axios from 'axios';

type Tprops = {
    token: string;
    dispatch: TappDispatch;
};

export default async ({ token, dispatch }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.get<Array<Tlogger>>(apiUrl + '/loggers', {
            headers: { Authorization: `Bearer ${token}` },
        });
        setVisableLoader('notVisable');
        dispatch(setLoggerList(req.data));
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
