import React from 'react';
import style from './style';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import disconnectService from '@maket/js/api/requiresToken/service/disconnectService';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import getConnectionList from '@maket/js/api/requiresToken/service/getConnectionList';
import connectService from '@maket/js/api/requiresToken/service/connectService';

export default () => {
    const {
        Container,
        MainContainer,
        Title,
        ItemContainer,
        Item,
        ItemEl,
        ItemElIcon,
        ItemElMain,
        ItemElTitle,
        ItemElText,
        ItemElState,
    } = style();

    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.tokenState.token);
    const serviceList = useAppSelector(state => state.authState.serviceList);
    const newServList: [
        Array<{ iconName: string; title: string; connected: boolean; accountName: string }>,
        Array<{ iconName: string; title: string; connected: boolean; accountName: string }>,
    ] = [[], []];
    serviceList.forEach(el => {
        if (el.type) {
            const title = el.type[0].toUpperCase() + el.type.toLowerCase().slice(1);
            if (el.connected) {
                newServList[0].push({
                    iconName: el.type,
                    title,
                    connected: el.connected,
                    accountName: el.service_user_str_id,
                });
            } else {
                newServList[1].push({
                    iconName: el.type,
                    title,
                    connected: el.connected,
                    accountName: el.service_user_str_id,
                });
            }
        }
    });

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <Container>
            <MainContainer>
                <Title>{activeLanguage['ConnecnedAccounts.Title']}</Title>
                <ItemContainer>
                    {newServList.map((item, i) => (
                        <Item key={i}>
                            {i == 0 && item.length == 0
                                ? activeLanguage['ConnecnedAccounts.PlugActive']
                                : ''}
                            {i == 1 && item.length == 0
                                ? activeLanguage['ConnecnedAccounts.PlugConnect']
                                : ''}
                            {item.map(itemEl => (
                                <ItemEl key={itemEl.title}>
                                    <ItemElIcon className={`icon-${itemEl.iconName}`} />
                                    <ItemElMain>
                                        <ItemElTitle>{itemEl.title}</ItemElTitle>
                                        <ItemElText>
                                            {itemEl.connected
                                                ? itemEl.accountName == null
                                                    ? ''
                                                    : `${activeLanguage['ConnecnedAccounts.AccountConnectText']}${itemEl.accountName}`
                                                : activeLanguage[
                                                      'ConnecnedAccounts.AccountNoConnectText'
                                                  ]}
                                        </ItemElText>
                                        <ItemElState
                                            onClick={() => {
                                                if (itemEl.connected) {
                                                    disconnectService({
                                                        serviceType: 'telegram',
                                                        token,
                                                    }).then(res =>
                                                        getConnectionList({
                                                            token,
                                                            dispatch,
                                                        }),
                                                    );
                                                } else {
                                                    connectService({
                                                        token,
                                                        serviceType: 'telegram',
                                                    });
                                                }
                                            }}
                                            className={!itemEl.connected ? '' : 'connect'}
                                        >
                                            {
                                                activeLanguage[
                                                    itemEl.connected
                                                        ? 'ConnecnedAccounts.Item.Status.Connect'
                                                        : 'ConnecnedAccounts.Item.Status.Disconnect'
                                                ]
                                            }
                                        </ItemElState>
                                    </ItemElMain>
                                </ItemEl>
                            ))}
                        </Item>
                    ))}
                </ItemContainer>
            </MainContainer>
        </Container>
    );
};
