import React, { useEffect } from 'react';
import style from './style';

import Card from './card';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import cardListInfo from '@maket/js/constants/cardListInfo';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';

import { setActiveKey, setPopapStateSub } from '@maket/js/state/tariffSwith';

type Tprops = { type?: 'page' };

const configConnectText: { [key: string]: { [key: string]: string } } = {
    free: {
        free: '',
        freeType: 'click',
        basic: '',
        basicType: 'click',
        pro: '',
        proType: 'click',
        expert: '',
        expertType: 'click',
    },
    basic: {
        free: 'Main.Price.LockDoungrade',
        freeType: 'noClick',
        basic: '',
        basicType: 'click',
        pro: 'Upgrade.',
        proType: 'click',
        expert: 'Upgrade.',
        expertType: 'click',
    },
    pro: {
        free: 'Main.Price.LockDoungrade',
        freeType: 'noClick',
        basic: 'Main.Price.LockDoungrade',
        basicType: 'noClick',
        pro: '',
        proType: 'noClick',
        expert: 'Upgrade.',
        expertType: 'click',
    },
    expert: {
        free: 'Main.Price.LockDoungrade',
        freeType: 'noClick',
        basic: 'Main.Price.LockDoungrade',
        basicType: 'noClick',
        pro: 'Main.Price.LockDoungrade',
        proType: 'noClick',
        expert: '',
        expertType: 'click',
    },
};

let onceAllertPreRegTariff = true;

export default ({ type }: Tprops) => {
    const dispatch = useAppDispatch();
    const {} = style();
    const tariffState = useAppSelector(state => state.tariffState.state);
    const { activeLanguage, activePriceSymbolType } = useAppSelector(state => state.languageState);
    const serviseList = useAppSelector(state => state.serviceListState.serviseList);
    const activeTariff = useAppSelector(state => state.authState.tariff);

    // если до авторизации был выбран тариф
    useEffect(() => {
        let MZPreRegTariff = localStorage.getItem('MZPreRegTariff');
        if (MZPreRegTariff && onceAllertPreRegTariff) {
            MZPreRegTariff = JSON.parse(MZPreRegTariff);
            if (MZPreRegTariff && activeTariff.current_tariff === null) {
                // @ts-ignore: Unreachable code error
                const timestamp = MZPreRegTariff.timestamp;

                if ((+new Date() - timestamp) / 60000 < 15) {
                    // @ts-ignore: Unreachable code error
                    dispatch(setActiveKey(MZPreRegTariff.tariffKey));
                    dispatch(setPopapStateSub(true));
                    onceAllertPreRegTariff = false;
                } else {
                    localStorage.removeItem('MZPreRegTariff');
                }
            }
        }
    }, [activeTariff]);
    //

    let activeTariffKey = '';
    let prolongation: boolean | undefined = undefined;

    let stopDateActiveTariff = '';
    let nextPriceActiveTariff = 0;
    let nextPriceSwitchTariff = 0;
    if (activeTariff.current_tariff) {
        activeTariffKey = activeTariff.current_tariff.tariff.key;
        prolongation = activeTariff.prolongation;

        if (activeTariff.current_tariff.stop_date) {
            stopDateActiveTariff = activeTariff.current_tariff.stop_date;
            if (activeTariff.current_tariff.period == 'month') {
                nextPriceActiveTariff = activeTariff.current_tariff.tariff.price_month;
            }
            if (activeTariff.current_tariff.period == 'year') {
                nextPriceActiveTariff = activeTariff.current_tariff.tariff.price_year;
            }
        }
    }
    if (activeTariff.switch_to_tariff) {
        if (activeTariff.switch_to_tariff.period == 'month') {
            nextPriceSwitchTariff = activeTariff.switch_to_tariff.tariff.price_month;
        }
        if (activeTariff.switch_to_tariff.period == 'year') {
            nextPriceSwitchTariff = activeTariff.switch_to_tariff.tariff.price_year;
        }
    }
    return serviseList.map((el, i) => {
        const card = cardListInfo('block').find(card => card.key == el.key);

        const priceMonth = (el.price_year / 12).toFixed(2);
        if (card == undefined) return;
        return (
            <Card
                key={card.name + i}
                cardKey={card.key}
                activeTariffKey={activeTariffKey}
                name={activeLanguage[card.name]}
                price={`
					${activePriceSymbolType == 'dollar' ? activeLanguage['SymbolDollar.'] : ''}${
                        (tariffState
                            ? isNaN(priceMonth) ? '':priceMonth
                            : el.price_month ? el.price_month.toString():'0').replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '')
                    }${activePriceSymbolType == 'euro' ? activeLanguage['SymbolEuro.'] : ''}
				`}
                reduction={activeLanguage[card.reductionMonth]}
                connect={
                    activeTariffKey == ''
                        ? activeLanguage[card.connect]
                        : activeLanguage[
                              card.key == 'enterprise'
                                  ? card.connect
                                  : configConnectText[activeTariffKey][card.key] == ''
                                  ? card.connect
                                  : prolongation
                                  ? configConnectText[activeTariffKey][card.key]
                                  : card.key == 'free'
                                  ? configConnectText[activeTariffKey][card.key]
                                  : card.connect
                          ]
                }
                // @ts-ignore: Unreachable code error
                connectType={
                    activeTariffKey == ''
                        ? 'click'
                        : card.key == 'enterprise'
                        ? 'click'
                        : prolongation
                        ? configConnectText[activeTariffKey][card.key + 'Type']
                        : card.key == 'free'
                        ? configConnectText[activeTariffKey][card.key + 'Type']
                        : 'click'
                }
                switchTariff={activeTariff.switch_to_tariff}
                text={activeLanguage[card.text]}
                conditions={activeLanguage[card.conditions]}
                type={type}
                stopDateActiveTariff={stopDateActiveTariff}
                nextPriceActiveTariff={nextPriceActiveTariff}
                nextPriceSwitchTariff={nextPriceSwitchTariff}
                prolongation={prolongation}
                stopDate={activeTariff.current_tariff?.stop_date}
                i={i}
            />
        );
    });
};
