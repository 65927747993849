import React, { HTMLAttributes, memo } from 'react';
import { CFormSwitch } from '@coreui/react';

interface Tprops extends HTMLAttributes<HTMLInputElement> {
    initState: boolean;
    setState: (v: boolean) => void;
    crop?: boolean;
    onliBlue?: boolean;
    closed?: boolean;
}

export default memo(
    ({ setState, initState, crop, onliBlue, closed, ...props }: Tprops) => {
        return (
            <CFormSwitch
                style={{
                    width: crop ? '2.6em' : '3.11em',
                    height: crop ? '1.77em' : '2em',
                    ...props.style,
                }}
                size="lg"
                onChange={e =>
                    setTimeout(() => {
                        setState(
                            (() => {
                                return e.target.checked;
                            })(),
                        );
                    }, 100)
                }
                defaultChecked={initState}
                id="formSwitchCheckDefaultLg"
                className={`${onliBlue ? 'switchOnliBlue' : ''} ${closed ? 'switchClosed' : ''}`}
                disabled={closed}
            />
        );
    },
    (p, n) => {
        return false;
    },
);
