import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default (background?: string, unfoldable?: boolean) => {
    const size = useWindowSizeCheck();
    const { whiteText, darkGray, blackIcon, grayPriceActiveButton } = useTheme();
    return {
        Container: styled.div({
            display: 'flex',
            width: '100%',
            height: '100%',
            maxWidth: unfoldable ? 'calc(100vw - 5rem)' : '',
            marginLeft: unfoldable ? '4rem' : '',
        }),
        MainContainer: styled.div({
            width: '100%',
            height: '100%',

            maxHeight: 'calc(100vh - 3.4em)',
            overflow: 'auto',
        }),
        ContainerMain: styled.div({
            padding: size.isDesktop
                ? '3.75em 5.31em'
                : size.isMobile
                ? '1.5em 0.5em'
                : '1.75em 2.31em',
            fontFamily: 'Inter, sans-serif',

            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile || size.isTab ? '1.5em' : '3.125em',

            fontSize: size.isMicroMobile ? '0.7em' : '',
        }),
        Top: styled.div({
            display: 'flex',
            width: '100%',
            gap: '1.56em',
            justifyContent: 'space-between',
            alignItems: size.isDesktop ? 'center' : 'start',
            flexDirection: size.isDesktop ? 'row' : 'column',
        }),
        Bottom: styled.div({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: size.isMobile || size.isTab ? 'column' : 'row',
            gap: '0.5em',
        }),
        TitleBox: styled.div({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1em',
            height: 'fit-content',

            width: '100%',
        }),
        Title: styled.div({
            fontSize: size.isMobile ? '1.75em' : '2.17em',
            fontWeight: 700,
            textAlign: 'start',
            width: '100%',

            display: 'flex',
            justifyContent: 'space-between',
        }),
        SubTitle: styled.div({
            color: darkGray,
            textAlign: 'start',
            width: '100%',
        }),
        CountBox: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: '1.125em',
        }),
        Counter: styled.div({
            fontSize: '1.5em',
            fontWeight: 700,
        }),
        ContainerWhiteImport: styled.div({
            backgroundColor: background ? background : whiteText,
            color: darkGray,
            padding: '0.71em',
            textAlign: 'center',
        }),

        TabBox: styled.div({
            display: 'flex',
            alignItems: 'center',
            justifyContent: size.isMobile ? 'space-around' : 'start',
            gap: '2em',
        }),
        Tab: styled.div({
            padding: '0.5em 0.675em',
            position: 'relative',
            fontWeight: 600,
            textAlign: 'center',
            fontSize: size.isMobile || size.isTab ? '0.875em' : '',

            cursor: 'pointer',

            ':after': {
                left: '50%',
                bottom: '0',
                transform: 'translate(-50%)',
                width: 'calc(100% - 0.5em)',
                height: '2px',
                backgroundColor: blackIcon,
                content: "''",
                position: 'absolute',
                opacity: 0,
            },

            transition: '0.3s',
            '&.active:after': {
                opacity: 1,
            },

            ':hover': { backgroundColor: grayPriceActiveButton },
        }),

        ChatCounterBox: styled.div({
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        }),
        CounterTop: styled.div({
            fontSize: size.isMobile || size.isTab ? '1em' : '1.5em',
            fontWeight: 700,
            minWidth: '5em',
        }),
        size,
    };
};
