import React, { memo, useEffect, useState } from 'react';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import style from './style';
import Button from '@maket/js/components/microComponets/button';
import Item from './item';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import {
    setInputReasonState,
    setInputResponseState,
    setSwithState,
} from '@maket/js/state/muteAddItemSwith';
import { motion } from 'framer-motion';
import {
    deleteMuteRulesState,
    setMuteRulesItemState,
    setMuteRulesState,
} from '@maket/js/state/banAndMuteRules';
import { AnimatePresence } from 'framer-motion';
import { TmuteRule } from '@maket/js/types/state/banAndMute';
import ButtonAllert, { TalertState } from '@maket/js/components/microComponets/buttonAllert';

export default ({ checkMute }: { checkMute: boolean }) => {
    const { Container, Title, TitleBox, size } = style(checkMute);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    return (
        <Container>
            {!(size.isMobile || size.isTab) && (
                <TitleBox>
                    <Title>{activeLanguage['ChatRules.Reason']}</Title>
                    <Title>{activeLanguage['ChatRules.Response']}</Title>
                </TitleBox>
            )}
            {(size.isMobile || size.isTab) && (
                <TitleBox>
                    <Title>{activeLanguage['ChatRules.Reason']}</Title>
                </TitleBox>
            )}

            <CreateListItem />
            <CreateButon />
        </Container>
    );
};

const CreateListIsolateRender = memo(
    ({ rules }: { rules: TmuteRule[] }) => {
        const dispatch = useAppDispatch();

        return rules.map((el, i) => (
            <Item
                key={i}
                type="info"
                reason={el.reason}
                response={el.response}
                enable={el.enable}
                setFunction={v => dispatch(setMuteRulesItemState({ value: v, index: i }))}
                buttonClickFn={() => {
                    dispatch(deleteMuteRulesState({ index: i }));
                }}
                reasonInputOuter={v => {
                    dispatch(setMuteRulesItemState({ reason: v, index: i }));
                }}
                responseInputOuter={v => {
                    dispatch(setMuteRulesItemState({ response: v, index: i }));
                }}
            />
        ));
    },
    (p, n) => {
        if (p.rules.length != n.rules.length) {
            return false;
        } else {
            return true;
        }
    },
);

function CreateListItem() {
    const rules = useAppSelector(state => state.banAndMuteRulesState.muteState.rules);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const { ReasonWhiteAllert } = style();

    if (rules.length == 0) {
        return <ReasonWhiteAllert>{activeLanguage['ChatRules.Mute.Text']}</ReasonWhiteAllert>;
    }
    return <CreateListIsolateRender rules={rules} />;
}

function CreateButon() {
    const [activeAddMute, setActiveAddMute] = useState(false);

    return (
        <>
            <AnimatePresence>
                {activeAddMute && (
                    <motion.div exit={{ opacity: 0 }} animate={{ opacity: activeAddMute ? 1 : 0 }}>
                        <WrapperInputElement />
                    </motion.div>
                )}
            </AnimatePresence>
            <WrapperButtonInput setActiveAddMute={setActiveAddMute} />
        </>
    );
}

function WrapperInputElement() {
    const dispatch = useAppDispatch();

    return (
        <Item
            type="add"
            switchPointerNone
            enable={true}
            setFunction={v => dispatch(setSwithState(v))}
            reasonInputFn={setInputReasonState}
            responseInputFn={setInputResponseState}
        />
    );
}

function WrapperButtonInput({
    setActiveAddMute,
}: {
    setActiveAddMute: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { size } = style();
    const rules = useAppSelector(state => state.banAndMuteRulesState.muteState.rules);
    const { reasonInputState, responseInputState } = useAppSelector(
        state => state.muteAddItemSwithState,
    );
    const dispatch = useAppDispatch();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const user = useAppSelector(state => state.authState.user);
    let checkMute = false;
    if (user) {
        checkMute = user.profiles[0].settings.mute_rules;
    }

    const [AlertState, setAlertState] = useState<TalertState>({ textAllert: '', typeAllert: '' });

    return (
        <ButtonAllert
            style={{
                width: size.isMobile ? '100%' : '',
                pointerEvents: checkMute ? 'all' : 'none',
            }}
            stateAllertOuter={AlertState}
            setAllertFn={setAlertState}
            onClick={() => {
                let checkOnliWhitespace = true;
                [...reasonInputState].forEach(el => {
                    if (el != ' ') {
                        checkOnliWhitespace = false;
                    }
                });
                if ((reasonInputState == '' && responseInputState == '') || checkOnliWhitespace) {
                    setActiveAddMute(p => !p);
                } else {
                    const reasonaddbox = document.querySelector('[data-reasonaddbox="add"]');
                    const isActive = reasonaddbox?.querySelector('input')?.checked;

                    if (reasonInputState != '') {
                        if (reasonInputState.length > 500) {
                            setAlertState({
                                textAllert: 'ProfileList.Alert.ReasonLengthError',
                                typeAllert: 'danger',
                            });
                        } else if (responseInputState.length > 500) {
                            setAlertState({
                                textAllert: 'ProfileList.Alert.ResponseLengthError',
                                typeAllert: 'danger',
                            });
                        } else {
                            const newRules = [...rules];
                            newRules.push({
                                enable: isActive,
                                reason: reasonInputState,
                                response: responseInputState,
                            });

                            dispatch(setMuteRulesState(newRules));
                            dispatch(setInputReasonState(''));
                            dispatch(setInputResponseState(''));
                            setTimeout(() => {
                                setActiveAddMute(false);
                            }, 300);
                        }
                    } else {
                        setAlertState({
                            textAllert: 'ProfileList.Alert.NotAllFill',
                            typeAllert: 'danger',
                        });
                    }
                }
            }}
            buttonStyle={{ pointerEvents: checkMute ? 'all' : 'none' }}
            typeButt="black"
            buttonContent={activeLanguage['ChatRules.Add']}
        ></ButtonAllert>
    );
}
