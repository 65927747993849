import React from 'react';
import style from './style';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { useNavigate } from 'react-router-dom';

export default () => {
    const navigate = useNavigate();
    const { BackButton, BackButtonUnderLine } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <BackButton onClick={() => navigate(-1)}>
            {activeLanguage['Back.']}
            <BackButtonUnderLine />
        </BackButton>
    );
};
