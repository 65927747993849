import React, { useEffect } from 'react';
import style from './style';
import FourBlock from '../mainRoute/fourBlock';
import useTheme from '@maket/js/hooks/useTheme';
import getTarifList from '@maket/js/api/noRequiresToken/getTarifList';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import AppSidebar from '../../mainComponents/sidebar/AppSidebar';

let onceCheck = true;

export default () => {
    const { Container, size } = style();
    const { authBackground } = useTheme();
    const dispatch = useAppDispatch();

    useEffect(() => {
        getTarifList({ serviceType: 'telegram', dispatch });
    }, []);

    useEffect(() => {
        document.body.setAttribute('style', `background-color: ${authBackground}`);
        document.getElementById('wrap')?.classList.add('scrollbar-unvisable');

        return () => {
            document.getElementById('wrap')?.classList.remove('scrollbar-unvisable');
        };
    });

    return (
        <Container>
            <AppSidebar
                position="sticky"
                stateControllName={size.isMobile || size.isTab ? '' : 'sidebarSickyState'}
            ></AppSidebar>
            <div style={{ overflow: 'auto' }}>
                <FourBlock type={'page'}></FourBlock>
            </div>
        </Container>
    );
};
