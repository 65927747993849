import React, { useEffect } from 'react';
import OneBlock from './oneBlock';
import TwoBlock from './twoBlock';
import ThreeBlock from './threeBlock';
import FourBlock from './fourBlock';
import FiveBlock from './fiveBlock';
import SixBlock from './sixBlock';
import SevenBlock from './sevenBlock';
import EightBlock from './eightBlock';
import NineBlock from './nineBlock';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { setActivePopap } from '@maket/js/state/openPopap/openPopapState';
import getTarifList from '@maket/js/api/noRequiresToken/getTarifList';

export default () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setActivePopap(''));
    }, []);
    useEffect(() => {
        getTarifList({ serviceType: 'telegram', dispatch });
    }, []);

    return (
        <>
            <OneBlock />
            <TwoBlock />
            <ThreeBlock />
            <FourBlock />
            <FiveBlock />
            {/* <SixBlock /> */}
            <SevenBlock />
            <EightBlock />
            <NineBlock />
        </>
    );
};
