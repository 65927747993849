import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { whiteText } = useTheme();
    return {
        Container: styled.div({
            margin: '-0.71em',
            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile ? '1em' : '2em',
        }),
        Item: styled.div({
            padding: size.isMobile ? '0.71em' : '2.21em',
            backgroundColor: whiteText,

            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
        }),
        ButtonBox: styled.div({
            display: 'flex',
            gap: '1em',
            flexDirection: size.isMobile || size.isTab ? 'column' : 'row',
        }),
        size,
    };
};
