import React from 'react';
import style from './style';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import Switch from '@maket/js/components/microComponets/switch';
import { setSwithState } from '@maket/js/state/tariffSwith';
import useTheme from '@maket/js/hooks/useTheme';

export default ({ type }: { type?: 'page' }) => {
    const {} = style();
    const dispatch = useAppDispatch();
    const unfoldable = useAppSelector(state => state.headerState.unfoldable);
    const { CardSettings, CardSettingsText, CardSettingsDis, size } = style(type, unfoldable);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const tariffState = useAppSelector(state => state.tariffState.state);

    const { buttonBlue, grayShadowColor } = useTheme();

    return (
        <CardSettings>
            <CardSettingsText style={{ color: tariffState ? grayShadowColor : buttonBlue }}>
                {
                    activeLanguage[
                        size.isMobile ? 'Main.Price.SwithMonthMob' : 'Main.Price.SwithMonth'
                    ]
                }
            </CardSettingsText>
            <Switch onliBlue setState={v => dispatch(setSwithState(v))} initState={tariffState} />
            <CardSettingsText>
                <span style={{ color: tariffState ? buttonBlue : grayShadowColor }}>
                    {
                        activeLanguage[
                            size.isMobile ? 'Main.Price.SwitchYearMob' : 'Main.Price.SwitchYear'
                        ]
                    }
                    <CardSettingsDis
                        style={{ borderColor: tariffState ? buttonBlue : grayShadowColor }}
                    >
                        {activeLanguage['Main.Price.Savings']}
                    </CardSettingsDis>
                </span>
            </CardSettingsText>
        </CardSettings>
    );
};
