import React, { HTMLAttributes } from 'react';
import style from './style';

export type Tcolor = 'white' | 'dark';
export type Tcard = 'num' | 'arrow';
interface Tprops extends HTMLAttributes<HTMLDivElement> {
    title: string;
    text: string;
    typeColor: Tcolor;
    typeCard: Tcard;
    number?: number;
    titleColor?: string;
    textColor?: string;
    numberColor?: string;
    numberCenter?: boolean;
    borderBottomNone?: boolean;
}

export default ({
    text,
    title,
    typeCard,
    typeColor,
    number,
    titleColor,
    textColor,
    numberColor,
    numberCenter,
    borderBottomNone,
    ...props
}: Tprops) => {
    const { Container, Left, LeftMainBox, Right, Icon, IconNum, Title, Text } = style(
        typeColor,
        typeCard,
        titleColor,
        textColor,
        numberColor,
        numberCenter,
        borderBottomNone,
    );
    return (
        <Container {...props} data-number-item={number || '0'}>
            <Left>
                <LeftMainBox>
                    {typeCard == 'arrow' ? (
                        <Icon className="icon-arrowCircle" />
                    ) : (
                        <IconNum className="iconNum">{number || '0'}</IconNum>
                    )}
                </LeftMainBox>
            </Left>
            <Right>
                <Title className="title">{title}</Title>
                <Text className="text">{text}</Text>
            </Right>
        </Container>
    );
};
