import { TServiceElement, TserviseList, TserviseNames } from '@maket/js/types/state/serviseList';
import { createSlice } from '@reduxjs/toolkit';

const initialState: TserviseList = { serviseList: [], activeServiceName: null };

const serviceListState = createSlice({
    name: 'serviceListState',
    initialState,
    reducers: {
        setServiseList: (state, { payload }: { payload: Array<TServiceElement> }) => {
            state.serviseList = payload;
        },
        setServiseActiveName: (state, { payload }: { payload: TserviseNames }) => {
            state.activeServiceName = payload;
        },
    },
});

export const { setServiseList, setServiseActiveName } = serviceListState.actions;

export default serviceListState;
