import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import axios from 'axios';

type Tprops = {
    token: string;
    photo: any;
};

export default async ({ token, photo }: Tprops) => {
    setVisableLoader('visable');
    var formData = new FormData();
    formData.append('upload_file', photo);

    const req = await axios
        .post(apiUrl + '/account/upload_file/avatar', formData, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => {
            setVisableLoader('notVisable');
            return res;
        })
        .catch(err => {
            setVisableLoader('notVisable');
            return err;
        });

    return req.data;
};
