import React from 'react';
import style from './style';
import useTheme from '@js/hooks/useTheme';
import { comparison, tNameSetting } from '../box';

type Tprops = {
    type: tNameSetting;
    setActiveSetting: React.Dispatch<React.SetStateAction<tNameSetting>>;
    activeName: tNameSetting;
};

export default ({ type, setActiveSetting, activeName }: Tprops) => {
    const { ButtonSetting, ButtonSettingIcon, ButtonSettingText, BottomLine } = style();
    const { textLowGray, mainBlack } = useTheme();
    return (
        <ButtonSetting onClick={() => setActiveSetting(type)}>
            <ButtonSettingIcon
                animate={{
                    color: type == activeName ? comparison[type].iconColor : textLowGray,
                }}
                className={`icon-${comparison[type].iconName}`}
            />
            <ButtonSettingText
                animate={{
                    color: type == activeName ? mainBlack : textLowGray,
                }}
            >
                {comparison[type].title}
            </ButtonSettingText>
            <BottomLine
                animate={{
                    opacity: type == activeName ? 1 : 0,
                }}
            />
        </ButtonSetting>
    );
};
