import { CAlert } from '@coreui/react';
import React, { HTMLAttributes } from 'react';

export type Talert = 'danger' | 'success' | 'info';
interface Tprops extends HTMLAttributes<HTMLDivElement> {
    type: Talert;
    children: any;
}

export default ({ type, children }: Tprops) => {
    return <CAlert color={type}>{children}</CAlert>;
};
