import React from 'react';
import style from './style';
import CardColumn from '@js/components/microComponets/cardColumn';
import useWindowSizeCheck from '@js/hooks/useWindowSizeCheck';
import useAppSelector from '@js/hooks/useAppSelector';

const cardContent = [
    { title: 'BlockOneTitle', text: 'BlockOneText' },
    { title: 'BlockTwoTitle', text: 'BlockTwoText' },
    { title: 'BlockThreeTitle', text: 'BlockThreeText' },
    { title: 'BlockFourTitle', text: 'BlockFourText' },
    { title: 'BlockFiveTitle', text: 'BlockFiveText' },
];

export default () => {
    const { Container } = style();
    const { isMobile, isTab } = useWindowSizeCheck();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <Container>
            {cardContent.map((el, i) => (
                <CardColumn
                    key={el.title + i}
                    text={activeLanguage['Main.Work.' + el.text]}
                    title={activeLanguage['Main.Work.' + el.title]}
                    typeCard={'num'}
                    typeColor="dark"
                    number={i + 1}
                    style={{ width: isMobile || isTab ? '100%' : '' }}
                />
            ))}
        </Container>
    );
};
