import { apiUrl, langBdConfig } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { setAccount } from '@maket/js/state/auth/authState';
import { setLanguage } from '@maket/js/state/language/languageState';
import { TappDispatch } from '@maket/js/state/store';
import { Taccount } from '@maket/js/types/state/auth';
import axios from 'axios';

type Tprops = {
    token: string;
    dispatch: TappDispatch;
    checkToken?: boolean;
};

export default async ({ token, dispatch, checkToken }: Tprops) => {
    setVisableLoader('visable');
    const req = await axios
        .get<Taccount>(apiUrl + '/account/data', {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => {
            setVisableLoader('notVisable');
            dispatch(setAccount(res.data));

            if (res.data?.language) {
                dispatch(setLanguage(langBdConfig[res.data.language]));
            }
            return res;
        })
        .catch(err => {
            setVisableLoader('notVisable');
            return err;
        });

    return req;
};
