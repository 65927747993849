import React from 'react';
import style from './style';

export default () => {
    const { Container, BackgroundMask, Background, ChatImg } = style();
    return (
        <Container>
            <BackgroundMask />
            <Background src="/img/background/Frame-1171277928.png"></Background>
            <ChatImg />
        </Container>
    );
};
