import React, { useEffect, useState } from 'react';
import style from './style';
import Main from './main';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import Items from './items';
import { useParams } from 'react-router-dom';

import { CreateTabs } from '@maket/js/components/middleComponents/routeWrapper';
import ProfileSelect from '@maket/js/components/middleComponents/routeWrapper/profileSelect';
import { Tchat, TtariffElement } from '@maket/js/types/state/auth';
import UpgradeButton from '@maket/js/components/middleComponents/upgradeButton';

export default () => {
    const { Container, Top, ChatCounterBox, size } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const { idProfile } = useParams();
    const profiles = useAppSelector(state => state.authState.user?.profiles);

    const authState = useAppSelector(state => state.authState);

    const [current_tariffS, setCurrent_tariffS] = useState<TtariffElement>({});
    const [activeProfileChatsS, setActiveProfileChatsS] = useState<Tchat[]>([]);

    if (idProfile == undefined) {
        const current_tariff = useAppSelector(state => state.authState.tariff.current_tariff);
        const activeProfileChats = useAppSelector(state => state.authState.activeProfileChats);

        useEffect(() => {
            setCurrent_tariffS(current_tariff);
        }, [current_tariff]);
        useEffect(() => {
            setActiveProfileChatsS(activeProfileChats);
        }, [activeProfileChats]);
    }

    let profileName = '';

    profiles?.forEach(el => {
        if (idProfile != undefined && +el.id == +idProfile) {
            profileName = el.name;
        }
    });

    const upgradeCheck =
        authState.account &&
        authState.tariff &&
        // authState.tariff.current_tariff &&
        authState.tariff.current_tariff?.tariff.key != 'expert';

    return (
        <Container
            style={{
                padding: size.isMobile
                    ? '1.5em 0.5em'
                    : size.isTab
                    ? '1.75em 2.31em'
                    : '2.75em 2.75em',
            }}
        >
            <Top>
                <ChatCounterBox>
                    <ProfileSelect
                        title={
                            idProfile
                                ? activeLanguage['ChatRules.Profile.Title']
                                : activeLanguage['ChatList.Title']
                        }
                        pageName="chatList"
                    />
                    {idProfile == undefined && (
                        <div
                            className="counter"
                            style={{
                                minWidth: '5em',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {
                                [...activeProfileChatsS].filter(
                                    el => el.owner_name == authState.account?.username && el.active,
                                )?.length
                            }{' '}
                            / {current_tariffS?.tariff?.count_chat || 0}
                        </div>
                    )}
                </ChatCounterBox>
                {upgradeCheck && <UpgradeButton />}
                {idProfile != undefined && <CreateTabs />}
            </Top>
            <Main />
            {idProfile == undefined && <Items />}
        </Container>
    );
};
