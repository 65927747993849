import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import Button from '@maket/js/components/microComponets/button';
import { BanRulesItemAdd } from '../banRules/banRulesItem';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { setInputState } from '@maket/js/state/banAddItemSwith';
import style from '../style';
import { setSpamRulesState } from '@maket/js/state/banAndMuteRules';
import { setSwithState } from '@maket/js/state/tariffSwith';
import ButtonAllert, { TalertState } from '@maket/js/components/microComponets/buttonAllert';

export default ({ checkBan }: { checkBan: boolean }) => {
    const [activeAddBan, setActiveAddBan] = useState(false);

    return (
        <>
            <AnimatePresence>{activeAddBan && <BanRulesItemAdd />}</AnimatePresence>
            <div
                style={{
                    opacity: checkBan ? '1' : '0.7',
                    pointerEvents: checkBan ? 'all' : 'none',
                    width: '100%',
                    display: 'flex',
                }}
            >
                <ButtonIsolate setActiveAddBan={setActiveAddBan} checkBan={checkBan} />
            </div>
        </>
    );
};

function ButtonIsolate({
    setActiveAddBan,
    checkBan,
}: {
    setActiveAddBan: React.Dispatch<React.SetStateAction<boolean>>;
    checkBan: boolean;
}) {
    const dispatch = useAppDispatch();
    const spamRulesState = useAppSelector(state => state.banAndMuteRulesState.banState.rules);
    const text = useAppSelector(state => state.banAddItemSwithState.inputValue);
    const stateSwith = useAppSelector(state => state.banAddItemSwithState.state);
    const { size } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const [AlertState, setAlertState] = useState<TalertState>({ textAllert: '', typeAllert: '' });

    return (
        <ButtonAllert
            style={{
                width: size.isMobile || size.isTab ? '100%' : '20em',
                pointerEvents: checkBan ? 'all' : 'none',
            }}
            stateAllertOuter={AlertState}
            setAllertFn={setAlertState}
            onClick={() => {
                let checkOnliWhitespace = true;
                [...text].forEach(el => {
                    if (el != ' ') {
                        checkOnliWhitespace = false;
                    }
                });
                if (text && !checkOnliWhitespace) {
                    if (text.length > 500) {
                        setAlertState({
                            textAllert: 'ProfileList.Alert.NameLengthError',
                            typeAllert: 'danger',
                        });
                    } else {
                        const newRules = [...spamRulesState];
                        newRules.push({
                            text: text,
                            enable: stateSwith,
                        });
                        dispatch(setSpamRulesState(newRules));
                        dispatch(setInputState(''));
                        dispatch(setSwithState(true));
                        setActiveAddBan(false);
                    }
                } else {
                    setActiveAddBan(p => !p);
                    dispatch(setInputState(''));
                }
            }}
            typeButt="black"
            buttonStyle={{ pointerEvents: checkBan ? 'all' : 'none' }}
            buttonContent={activeLanguage['ChatRules.AddBanRule']}
            styleAlert={{
                // @ts-ignore: Unreachable code error
                width: `calc(${
                    size.isMobile || size.isTab ? '88vw' : size.isLaptop ? '58vw' : '96vw - 22em'
                } - ${size.isMobile ? '0' : size.isTab ? '3.5em' : '22em'})`,
                left: `calc(
					(${size.isMobile || size.isTab ? '88vw' : size.isLaptop ? '58vw' : '96vw - 22em'} 
					+ ${size.isMobile || size.isTab ? '0' : '22em'}
				)/2)`,
                bottom: size.isMobile || size.isTab ? '' : 'calc(130%)',
            }}
        ></ButtonAllert>
    );
}
