import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import { TauthPage } from '../..';

export default (type: TauthPage) => {
    const size = useWindowSizeCheck();
    return {
        InputBox: styled.div({
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile && type == 'reg' ? '' : '1em',
            marginBottom: '-1em',
        }),
        InputItem: styled.div({}),
        size,
    };
};
