import React from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';
import Button from './button';
import useTheme from '@maket/js/hooks/useTheme';
import { TtariffElement } from '@maket/js/types/state/auth';

type Tprops = {
    name: string;
    price: string;
    reduction: string;
    connect: string;
    text: string;
    conditions: string;

    cardKey: string;
    activeTariffKey: string;
    i: number;

    connectType: 'click' | 'noClick';

    stopDateActiveTariff: string;
    switchTariff: TtariffElement;
    nextPriceActiveTariff: number;
    nextPriceSwitchTariff: number;

    prolongation: boolean | undefined;
    stopDate: string | null | undefined;
    type?: 'page';
};

export default ({
    name,
    price,
    reduction,
    conditions,
    connect,
    text,
    type,
    cardKey,
    activeTariffKey,
    connectType,
    stopDateActiveTariff,
    nextPriceActiveTariff,
    nextPriceSwitchTariff,
    switchTariff,
    prolongation,
    stopDate,
    i,
}: Tprops) => {
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    let checkActive = false;
    if (cardKey == activeTariffKey) {
        checkActive = true;
    }

    const { Container, ActiveContainer } = style(
        activeLanguage['Main.Price.BestPopulatityText'],
        checkActive,
        activeLanguage['Main.Price.YourText'],
        connectType,
        type,
    );

    if (checkActive) {
        return (
            <Container>
                <ActiveContainer>
                    <MainContent
                        name={name}
                        price={price}
                        reduction={reduction}
                        conditions={conditions}
                        connect={connect}
                        text={text}
                        type={type}
                        cardKey={cardKey}
                        activeTariffKey={activeTariffKey}
                        connectType={connectType}
                        stopDateActiveTariff={stopDateActiveTariff}
                        nextPriceActiveTariff={nextPriceActiveTariff}
                        switchTariff={switchTariff}
                        i={i}
                        prolongation={prolongation}
                        checkActive={checkActive}
                        stopDate={stopDate}
                        nextPriceSwitchTariff={nextPriceSwitchTariff}
                    />
                </ActiveContainer>
            </Container>
        );
    } else {
        return (
            <Container>
                <MainContent
                    name={name}
                    price={price}
                    reduction={reduction}
                    conditions={conditions}
                    connect={connect}
                    text={text}
                    type={type}
                    cardKey={cardKey}
                    activeTariffKey={activeTariffKey}
                    connectType={connectType}
                    stopDateActiveTariff={stopDateActiveTariff}
                    nextPriceActiveTariff={nextPriceActiveTariff}
                    switchTariff={switchTariff}
                    i={i}
                    prolongation={prolongation}
                    checkActive={checkActive}
                    stopDate={stopDate}
                    nextPriceSwitchTariff={nextPriceSwitchTariff}
                />
            </Container>
        );
    }
};

interface TmainProps extends Tprops {
    checkActive: boolean;
}

function MainContent({
    name,
    price,
    reduction,
    conditions,
    connect,
    text,
    stopDateActiveTariff,
    nextPriceActiveTariff,
    switchTariff,
    type,
    cardKey,
    connectType,
    checkActive,
    prolongation,
    nextPriceSwitchTariff,
    stopDate,
    i,
}: TmainProps) {
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const {
        Title,
        Price,
        ConditionsTitle,
        Conditions,
        Condition,
        Top,
        Bottom,
        TopTop,
        TopBottom,
        size,
    } = style(
        activeLanguage['Main.Price.BestPopulatityText'],
        checkActive,
        activeLanguage['Main.Price.YourText'],
        connectType,
        type,
    );

    const { mainBlack } = useTheme();

    return (
        <>
            <Top className="top">
                {size.isMobile ? (
                    <>
                        <TopTop>
                            <Title
                                style={{ color: checkActive ? mainBlack : '' }}
                                className="title"
                            >
                                {name}
                            </Title>
                            <Price className="price">
                                {cardKey != 'enterprise' ? (
                                    <>
                                        {price}
                                        <span>{reduction}</span>
                                    </>
                                ) : (
                                    <span style={{}}></span>
                                )}
                            </Price>
                        </TopTop>
                        <TopBottom>
                            <Button
                                tariffSwitch={switchTariff}
                                cardKey={cardKey}
                                checkActive={checkActive}
                                type={type}
                                connect={connect}
                                connectType={connectType}
                                stopDateActiveTariff={stopDateActiveTariff}
                                nextPriceActiveTariff={nextPriceActiveTariff}
                                nextPriceSwitchTariff={nextPriceSwitchTariff}
                                prolongation={prolongation}
                                stopDate={stopDate}
                            />
                        </TopBottom>
                    </>
                ) : (
                    <>
                        <Title
                            className="title"
                            style={{ wordWrap: cardKey != 'enterprise' ? 'normal' : 'break-word' }}
                        >
                            {name}
                        </Title>
                        <TopBottom>
                            <Price>
                                {cardKey != 'enterprise' ? (
                                    <>
                                        {price}
                                        <span>{reduction}</span>
                                    </>
                                ) : (
                                    <span style={{}}></span>
                                )}
                            </Price>
                            <Button
                                tariffSwitch={switchTariff}
                                cardKey={cardKey}
                                checkActive={checkActive}
                                type={type}
                                connect={connect}
                                connectType={connectType}
                                stopDateActiveTariff={stopDateActiveTariff}
                                nextPriceActiveTariff={nextPriceActiveTariff}
                                nextPriceSwitchTariff={nextPriceSwitchTariff}
                                prolongation={prolongation}
                                stopDate={stopDate}
                            />
                        </TopBottom>
                    </>
                )}
            </Top>
            <Bottom>
                <ConditionsTitle>{text}</ConditionsTitle>
                <Conditions>
                    {conditions?.split('\\').map((el, i) => (
                        <Condition key={i + 'Condition'}>
                            <span />
                            <div style={{ wordWrap: 'break-word', width: '100%' }}>{el}</div>
                        </Condition>
                    ))}
                </Conditions>
            </Bottom>
        </>
    );
}
