import React, { memo, useCallback, useEffect, useState } from 'react';
import style from './style';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import ButtonAllert, { TalertState } from '@maket/js/components/microComponets/buttonAllert';
import { TsettingPassValueState } from '@maket/js/types/state/settingPass';
import { passwordRegExp } from '@maket/js/constants/values';
import postNewPassword from '@maket/js/api/requiresToken/account_authentication/postNewPassword';

let duration = 0.3;

export default memo(
    () => {
        const { size } = style();
        const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
        const settingsPass = useAppSelector(state => state.settingPassValueState);
        const [alertState, setAlertState] = useState<TalertState>({
            typeAllert: '',
            textAllert: '',
        });
        const token = useAppSelector(state => state.tokenState.token);

        const callbackSetAllertState = useCallback((v: TalertState) => {
            setAlertState(v);
        }, []);

        useEffect(() => {
            const checkPass = settingsPass.newPass.match(passwordRegExp);
            const checkRepeatPass = settingsPass.repeatPass.match(passwordRegExp);
            if (!checkPass && settingsPass.newPass != '') {
                duration = 0;
                if (alertState.textAllert != 'Safety.Alert.ErrorPassRegExp') {
                    setAlertState({
                        textAllert: 'Safety.Alert.ErrorPassRegExp',
                        typeAllert: 'danger',
                    });
                }
            } else {
                if (settingsPass.newPass != settingsPass.repeatPass && settingsPass.newPass != '') {
                    if (alertState.textAllert != 'Safety.Alert.ErrorPassAccordance') {
                        setAlertState({
                            textAllert: 'Safety.Alert.ErrorPassAccordance',
                            typeAllert: 'danger',
                        });
                    }
                } else {
                    duration = 0.3;
                    setAlertState({ textAllert: '', typeAllert: '' });
                }
            }
        }, [settingsPass]);

        return (
            <ButtonAllert
                style={{ width: '100%' }}
                onClick={() => clickButton(setAlertState, settingsPass, token)}
                typeButt="black"
                buttonContent={activeLanguage['Safety.Pass.ButtonText']}
                setAllertFn={callbackSetAllertState}
                stateAllertOuter={alertState}
                duration={duration}
                disableAutoClear
            ></ButtonAllert>
        );
    },
    () => true,
);

function clickButton(
    setAlertState: React.Dispatch<React.SetStateAction<TalertState>>,
    settingsPass: TsettingPassValueState,
    token: string,
) {
    const { pass, newPass, repeatPass } = settingsPass;

    const newPassCheck = newPass.match(passwordRegExp);
    const repeatPassCheck = repeatPass.match(passwordRegExp);

    if (newPass != repeatPass) {
        setAlertState({ typeAllert: 'danger', textAllert: 'Safety.Alert.ErrorPassAccordance' });
    } else {
        if (newPassCheck != null && repeatPassCheck != null) {
            postNewPassword({ currentPassword: pass, newPassword: newPass, token })
                .then(res => {
                    if (res?.errors[0] == 'wrong_current_password') {
                        setAlertState({
                            typeAllert: 'danger',
                            textAllert: 'Safety.Alert.ErrorPassCurrent',
                        });
                    }
                    if (res?.errors[0] == undefined) {
                        setAlertState({
                            typeAllert: 'success',
                            textAllert: 'Safety.Alert.Success',
                        });
                        setTimeout(() => {
                            setAlertState({
                                typeAllert: '',
                                textAllert: '',
                            });
                        }, 2500);
                        const lastPassSetting = document
                            .getElementById('lastPassSetting')
                            ?.querySelector('input');
                        const newPassSetting = document
                            .getElementById('newPassSetting')
                            ?.querySelector('input');
                        const replacePassSetting = document
                            .getElementById('replacePassSetting')
                            ?.querySelector('input');

                        if (lastPassSetting && newPassSetting && replacePassSetting) {
                            lastPassSetting.value = '';
                            newPassSetting.value = '';
                            replacePassSetting.value = '';
                        }
                    }
                })
                .catch(res => {
                    setAlertState({
                        typeAllert: 'danger',
                        textAllert: 'Safety.Alert.ErrorPassCurrent',
                    });
                });
        } else {
            setAlertState({ typeAllert: 'danger', textAllert: 'Safety.Alert.ErrorPassRegExp' });
        }
    }
}
