import React, { useCallback, useEffect, useState } from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';
import { Talert } from '@js/components/microComponets/alert';
import ButtonAllert from '@maket/js/components/microComponets/buttonAllert';
import resetPassword from '@maket/js/api/noRequiresToken/resetPassword';
import { mailRegExp } from '@maket/js/constants/values';
import { useLocation } from 'react-router-dom';
import { clearState } from '../../../auth/top/buttonBox/sendMailSucess';

export type TalertState = {
    typeAllert: Talert | '';
    textAllert: string;
};

export default () => {
    const location = useLocation();

    const { size } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const [alertState, setAlertState] = useState<TalertState>({ typeAllert: '', textAllert: '' });
    const mailInput = useAppSelector(state => state.fogotPassState.mail);

    const callbackSetAlertState = useCallback((v: TalertState) => {
        setAlertState(v);
    }, []);

    useEffect(() => {
        const confirm = location.state?.confirm;
        if (confirm == 'error') {
            setAlertState({ typeAllert: 'danger', textAllert: 'FogotPass.Alert.PassNoReset' });
            clearState(setAlertState);
        }
        if (confirm == 'ok') {
            setAlertState({ typeAllert: 'success', textAllert: 'FogotPass.Alert.PassReset' });
            clearState(setAlertState);
        }
    }, []);

    return (
        <ButtonAllert
            onClick={() => {
                if (mailInput.match(mailRegExp) == null) {
                    setAlertState({
                        typeAllert: 'danger',
                        textAllert: 'FogotPass.Alert.MailSendError',
                    });
                    clearState(setAlertState);
                } else {
                    resetPassword({ email: mailInput }).then(res => {
                        if (res?.errors[0] == undefined) {
                            setAlertState({
                                typeAllert: 'success',
                                textAllert: 'FogotPass.Alert.MailSend',
                            });
                        } else {
                            if (res.errors.includes('email_not_found')) {
                                setAlertState({
                                    typeAllert: 'danger',
                                    textAllert: 'Auth.Alert.MailNotFound',
                                });
                            } else {
                                setAlertState({
                                    typeAllert: 'danger',
                                    textAllert: 'FogotPass.Alert.MailSendError',
                                });
                            }
                        }
                        clearState(setAlertState);
                    });
                }
            }}
            style={{ width: size.isMobile || size.isTab ? '100%' : '10em' }}
            typeButt="blue"
            buttonContent={activeLanguage['Send.']}
            stateAllertOuter={alertState}
            styleAlert={{
                // @ts-ignore: Unreachable code error
                width: `calc(${
                    size.isMobile
                        ? '98vw - 3em'
                        : size.isTab
                        ? '98vw - 2em'
                        : size.isLaptop
                        ? '60vw'
                        : '37.5vw'
                } - ${size.isMobile || size.isTab ? '2em' : '7em'})`,
                left: `calc(
				(${size.isMobile ? '98vw - 3em' : size.isTab ? '98vw - 2em' : size.isLaptop ? '60vw' : '37.5vw'} 
				- ${size.isMobile || size.isTab ? '2em' : '7em'}
				)/2)`,
            }}
            setAllertFn={callbackSetAlertState}
            timer={5000}
        ></ButtonAllert>
    );
};
