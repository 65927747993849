import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';
import { NavLink } from 'react-router-dom';

export default () => {
    const size = useWindowSizeCheck();
    const { blackIcon } = useTheme();
    return {
        BackButton: styled.div({
            display: 'flex',
            flexDirection: 'column',
            fontSize: '1.125em',
            lineHeight: '1.2em',
            fontWeight: 600,
            margin: '0 auto 0 0',
            color: blackIcon,
        }),
        BackButtonUnderLine: styled.div({
            backgroundColor: blackIcon,
            width: '100%',
            height: 1,
        }),
        size,
    };
};
