import React, { useEffect } from 'react';
import useTheme from '@maket/js/hooks/useTheme';
import RouteWrapper from '../../middleComponents/routeWrapper';
import Main from './main';
import getBan from '@maket/js/api/requiresToken/rule/getBan';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import getMute from '@maket/js/api/requiresToken/rule/getMute';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import getUnBan from '@maket/js/api/requiresToken/rule/getUnBan';

export default () => {
    const { authBackground } = useTheme();
    const token = useAppSelector(state => state.tokenState.token);
    const dispatch = useAppDispatch();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const rulesProfile = useAppSelector(state => state.banAndMuteRulesState.rulesProfile);

    useEffect(() => {
        document.body.setAttribute('style', `background-color: ${authBackground}`);
    });
    useEffect(() => {
        if (token && rulesProfile != null) {
            getBan({ token, dispatch, rulesProfile });
            getMute({ token, dispatch, activeLanguage, rulesProfile });
            getUnBan({ token, dispatch, rulesProfile });
        }
    }, [token, rulesProfile]);

    return <RouteWrapper title="ChatList.Title" mainComponent={<Main />} type="onliWrapper" />;
};
