import { TsettingPassValueState } from '@maket/js/types/state/settingPass';
import { createSlice } from '@reduxjs/toolkit';

const initialState: TsettingPassValueState = {
    mail: 'none',
    pass: '',
    newPass: '',
    repeatPass: '',
    subscribe_to_newsletter: false,
};

const settingPassValueState = createSlice({
    name: 'settingPassValueState',
    initialState,
    reducers: {
        setInputMailSettingsValue: (state, { payload }: { payload: string }) => {
            state.mail = payload;
        },
        setInputPassSettingsValue: (state, { payload }: { payload: string }) => {
            state.pass = payload;
        },
        setInputNewPassSettingsValue: (state, { payload }: { payload: string }) => {
            state.newPass = payload;
        },
        setInputRepeatPassSettingsValue: (state, { payload }: { payload: string }) => {
            state.repeatPass = payload;
        },
        setSubscribeToNewsletterValue: (state, { payload }: { payload: boolean }) => {
            state.subscribe_to_newsletter = payload;
        },

        clearChangeSafety: (
            state,
            { payload }: { payload: { subscribe_to_newsletter: boolean; mail: string } },
        ) => {
            state.subscribe_to_newsletter = payload.subscribe_to_newsletter;
            state.mail = payload.mail;
        },
    },
});

export const {
    setInputMailSettingsValue,
    setInputPassSettingsValue,
    setInputNewPassSettingsValue,
    setInputRepeatPassSettingsValue,
    setSubscribeToNewsletterValue,
    clearChangeSafety,
} = settingPassValueState.actions;

export default settingPassValueState;
