import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    return {
        Container: styled.div({
            width: '100%',
            display: 'flex',
            gap: size.isMobile ? '0.5em' : '1em',
            flexDirection: 'column',
        }),
        size,
    };
};
