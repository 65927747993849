import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { blackIcon } = useTheme();
    return {
        Container: styled.div({
            padding: size.isDesktop
                ? '2.75em 2.75em'
                : size.isMobile
                ? '1.5em 0.5em'
                : '1.75em 2.31em',
            fontFamily: 'Inter, sans-serif',

            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile || size.isTab ? '1.5em' : '3.125em',

            fontSize: size.isMicroMobile ? '0.7em' : '',
        }),
        Top: styled.div({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '1.56em',
        }),
        Bottom: styled.div({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: size.isMobile || size.isTab || size.isLaptop ? 'column' : 'row',
            gap: '0.5em',
        }),
        TitleBox: styled.div({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }),
        Title: styled.div({
            fontSize: size.isMobile ? '1.75em' : '2.17em',
            fontWeight: 700,
        }),
        CountBox: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: '1.125em',
        }),
        Counter: styled.div({
            fontSize: '1.5em',
            fontWeight: 700,
        }),

        TabBox: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: '2em',
        }),
        Tab: styled.div({
            padding: '0 0.5em 0.675em',
            position: 'relative',
            fontWeight: 600,

            ':after': {
                left: '50%',
                bottom: '0',
                transform: 'translate(-50%)',
                width: 'calc(100% - 0.5em)',
                height: '2px',
                backgroundColor: blackIcon,
                content: "''",
                position: 'absolute',
                opacity: 0,
            },

            '&.active:after': {
                opacity: 1,
            },
        }),
        ChatCounterBox: styled.div({
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            '& .counter': { fontSize: '1.5em', fontWeight: 700 },
        }),
        size,
    };
};
