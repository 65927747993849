import React, { useEffect } from 'react';
import style from './style';
import useTheme from '@maket/js/hooks/useTheme';
import Button from '@microComponets/button';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import RouteWrapper from '../../middleComponents/routeWrapper';
import connectService from '@maket/js/api/requiresToken/service/connectService';
import { useLocation } from 'react-router-dom';
import bind_by_code from '@maket/js/api/auth/bind_by_code';
import getConnectionList from '@maket/js/api/requiresToken/service/getConnectionList';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import getAccount from '@maket/js/api/requiresToken/account/getAccount';

export default () => {
    const dispatch = useAppDispatch();
    const { Container, Icon, Title, Text, ButtonBox, Wrapper } = style();

    const location = useLocation();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const token = useAppSelector(state => state.tokenState.token);

    const { authBackground } = useTheme();
    useEffect(() => {
        document.body.setAttribute('style', `background-color: ${authBackground}`);
    });
    useEffect(() => {
        if (token) {
            if (location.state?.result == 'fail') {
                bind_by_code({
                    token: token,
                    service_type: 'telegram',
                    tg_id: location.state.tg_id,
                    tg_str_id: location.state.tg_str_id,
                    code: location.state.code,
                }).then(res => getConnectionList({ token, dispatch }));
            }
        }
    }, [token]);

    const checkLinkTgAcc = location.state?.result
        ? location.state?.result == 'fail'
            ? true
            : false
        : false;
    return (
        <RouteWrapper
            title=""
            mainComponent={
                <Wrapper>
                    <Container>
                        <Icon className="icon-telegramOnliLogo" />
                        <Title>{activeLanguage['ConnectTgAccount.Title']}</Title>
                        <Text>{activeLanguage['ConnectTgAccount.Text']}</Text>
                        <ButtonBox>
                            {!checkLinkTgAcc && (
                                <Button
                                    onClick={() => {
                                        connectService({ token, serviceType: 'telegram' });
                                    }}
                                    typeButt="blue"
                                >
                                    {activeLanguage['ConnectTgAccount.ButtonText']}
                                </Button>
                            )}
                            {checkLinkTgAcc && (
                                <Button
                                    onClick={() => {
                                        bind_by_code({
                                            token: token,
                                            service_type: 'telegram',
                                            tg_id: location.state.tg_id,
                                            tg_str_id: location.state.tg_str_id,
                                            code: location.state.code,
                                        }).then(res => getAccount({ token, dispatch }));
                                    }}
                                    typeButt="green"
                                >
                                    {activeLanguage['ConnectTgAccount.OrButtonText'] +
                                        ' @' +
                                        location.state.tg_str_id}
                                </Button>
                            )}
                        </ButtonBox>
                    </Container>
                </Wrapper>
            }
            type="onliWrapper"
        />
    );
};
