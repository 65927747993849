import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import {
    setMuteStartingFraceState,
    setMuteEndingFraceState,
    setMuteState,
    setBDMuteState,
    setBDMuteStartingFraceState,
    setBDMuteEndingFraceState,
} from '@maket/js/state/banAndMuteRules';
import { TappDispatch } from '@maket/js/state/store';
import { TMuteState } from '@maket/js/types/state/banAndMute';
import { TactiveLanguage, TlanguageComp } from '@maket/js/types/state/language';
import axios from 'axios';

type Tprops = {
    token: string;
    dispatch: TappDispatch;
    rulesProfile: null | string;
    activeLanguage?: TactiveLanguage;
    languageComp?: TlanguageComp;
};

export default async ({ token, dispatch, activeLanguage, rulesProfile, languageComp }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.get<TMuteState>(apiUrl + `/profile/${rulesProfile}/rules/mute`, {
            headers: { Authorization: `Bearer ${token}` },
        });

        setVisableLoader('notVisable');
        dispatch(setMuteState(req.data));
        dispatch(setBDMuteState(req.data));

        if (activeLanguage) {
            if (req.data.starting_frase == null) {
                dispatch(setMuteStartingFraceState(activeLanguage['DefaultChatRules.StartPhase']));
                dispatch(
                    setBDMuteStartingFraceState(activeLanguage['DefaultChatRules.StartPhase']),
                );
            }
            if (req.data.ending_frase == null) {
                dispatch(setMuteEndingFraceState(activeLanguage['DefaultChatRules.EndPhrase']));
                dispatch(setBDMuteEndingFraceState(activeLanguage['DefaultChatRules.EndPhrase']));
            }
        }
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
