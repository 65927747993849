import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import axios from 'axios';

type Tprops = {
    token: string;
    lang: string;
};

export default async ({ token, lang }: Tprops) => {
    setVisableLoader('visable');
    const req = await axios
        .post(
            apiUrl + '/account/set_language',
            { code: lang },
            {
                headers: { Authorization: `Bearer ${token}` },
            },
        )
        .then(res => {
            setVisableLoader('notVisable');
            return res;
        })
        .catch(err => {
            setVisableLoader('notVisable');
            return err;
        });
    return req;
};
