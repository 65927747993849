import React, { memo } from 'react';
import style from './style';
import ButtonSetting from './button';
import { comparison, tNameSetting } from '../box';

type Tprops = { active: tNameSetting };

const VerticalSlider = memo(({ active }: Tprops) => {
    const { MobileButtonContainer, MobileButtonVrap1, MobileButtonVrap2 } = style();
    return (
        <MobileButtonVrap1>
            <MobileButtonVrap2>
                <MobileButtonContainer animate={{ top: -comparison[active].id * 30 + 4 }}>
                    <ButtonSetting key={'Telegram12'} type={'Telegram'} activeName={active} />
                    <ButtonSetting key={'Discord22'} type={'Discord'} activeName={active} />
                    <ButtonSetting key={'WebSite32'} type={'WebSite'} activeName={active} />
                    <ButtonSetting key={'Email42'} type={'Email'} activeName={active} />
                </MobileButtonContainer>
            </MobileButtonVrap2>
        </MobileButtonVrap1>
    );
});
export default VerticalSlider;
