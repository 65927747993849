import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { Tuser } from '@maket/js/types/state/auth';
import axios from 'axios';

type Tprops = {
    token: string;
    profile_id: number;
    name: string;
    language: string;
    is_active: boolean;
    moderation: boolean;
    is_active_protection: boolean;
    delete_forwarded_story: boolean;
    delete_anonymous_messages: boolean;
};

export default async ({
    token,
    name,
    profile_id,
    language,
    is_active,
    moderation,
    is_active_protection,
    delete_forwarded_story,
    delete_anonymous_messages,
}: Tprops) => {
    setVisableLoader('visable');
    const req = await axios
        .post<Tuser>(
            apiUrl + '/profile/update',
            {
                profile_id,
                name,
                language,
                is_active,
                moderation,
                is_active_protection,
                delete_forwarded_story,
                delete_anonymous_messages,
            },
            {
                headers: { Authorization: `Bearer ${token}` },
            },
        )
        .then(res => {
            setVisableLoader('notVisable');
            return res;
        })
        .catch(err => {
            setVisableLoader('notVisable');
            return err;
        });
    setVisableLoader('notVisable');
    return req;
};
