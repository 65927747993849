import { createSlice } from '@reduxjs/toolkit';

const initialState: { token: string } = { token: '' };

const tokenState = createSlice({
    name: 'tokenState',
    initialState,
    reducers: {
        setToken: (state, { payload }: { payload: string }) => {
            const date = new Date(Date.now() + 2678400e3).toUTCString();
            document.cookie = `token=${payload}; path=/; expires=${date}`;
            state.token = payload;
        },
        deleteToken: state => {
            document.cookie = `token=${undefined}; path=/;`;
            state.token = '';
        },
    },
});

export const { setToken, deleteToken } = tokenState.actions;

export default tokenState;
