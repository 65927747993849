import React, { useEffect, useRef } from 'react';
import style from './style';
import Switch from '@maket/js/components/microComponets/switch';
import Input from '@maket/js/components/microComponets/input';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import Button from '@maket/js/components/microComponets/button';

type Tprops = {
    type: 'info' | 'add';
    enable: boolean;
    setFunction: (v: boolean) => void;
    reason?: string;
    response?: string;
    reasonInputFn?: ActionCreatorWithPayload<string>;
    responseInputFn?: ActionCreatorWithPayload<string>;
    reasonInputOuter?: (v: string) => void;
    responseInputOuter?: (v: string) => void;
    buttonClickFn?: () => void;
    customButton?: any;
    switchPointerNone?: boolean;
};

export default ({
    type,
    reason,
    response,
    enable,
    setFunction,
    reasonInputFn,
    responseInputFn,
    reasonInputOuter,
    responseInputOuter,
    buttonClickFn,
    switchPointerNone,
    customButton,
}: Tprops) => {
    // @ts-ignore: Unreachable code error
    const { InputBox, Icon, size, Title } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const refBox = useRef<HTMLDivElement>(null);

    if (size.isMobile || size.isTab) {
        return (
            <InputBox ref={refBox}>
                <HeightCheker refBox={refBox} />

                <div
                    data-reasonaddbox={type == 'add' ? 'add' : ''}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Switch setState={setFunction} initState={enable} />
                    {type == 'add' ? (
                        customButton ? (
                            customButton
                        ) : (
                            // <Button
                            //     onClick={() => buttonClickFn && buttonClickFn()}
                            //     style={{ minWidth: '5em' }}
                            //     typeButt="black"
                            // >
                            //     +
                            // </Button>
                            <div style={{ minWidth: '5em' }}></div>
                        )
                    ) : (
                        <Icon
                            style={{ minWidth: '2.5em', maxWidth: '2.5em' }}
                            onClick={() => buttonClickFn && buttonClickFn()}
                            className="icon-trash"
                        />
                    )}
                </div>

                <Title>{activeLanguage['ChatRules.Reason']}</Title>
                <Input
                    placeholder={activeLanguage['ChatRules.Reason']}
                    iconName="none"
                    marginBottomNone
                    // @ts-ignore: Unreachable code error
                    setExtState={reasonInputFn}
                    setExtStateOuter={reasonInputOuter}
                    value={reason}
                    textareaRows={1}
                    textarea
                    style={{ height: '100%' }}
                    stopInputMaxSize3000
                />

                <Title>{activeLanguage['ChatRules.Response']}</Title>
                <Input
                    placeholder={activeLanguage['ChatRules.Response']}
                    iconName="none"
                    marginBottomNone
                    // @ts-ignore: Unreachable code error
                    setExtState={responseInputFn}
                    setExtStateOuter={responseInputOuter}
                    value={response}
                    textareaRows={1}
                    textarea
                    style={{ height: '100%' }}
                    stopInputMaxSize3000
                />
            </InputBox>
        );
    } else {
        return (
            <InputBox ref={refBox} data-reasonaddbox={type == 'add' ? 'add' : ''}>
                <HeightCheker refBox={refBox} />
                <Switch
                    style={{
                        pointerEvents: switchPointerNone ? 'none' : 'all',
                    }}
                    setState={setFunction}
                    initState={enable}
                />
                <Input
                    placeholder={activeLanguage['ChatRules.Reason']}
                    iconName="none"
                    marginBottomNone
                    // @ts-ignore: Unreachable code error
                    setExtState={reasonInputFn}
                    setExtStateOuter={reasonInputOuter}
                    value={reason}
                    textareaRows={1}
                    textarea
                    style={{ height: '100%' }}
                    stopInputMaxSize3000
                />
                <Input
                    placeholder={activeLanguage['ChatRules.Response']}
                    iconName="none"
                    marginBottomNone
                    // @ts-ignore: Unreachable code error
                    setExtState={responseInputFn}
                    setExtStateOuter={responseInputOuter}
                    value={response}
                    textareaRows={1}
                    textarea
                    style={{ height: '100%' }}
                    stopInputMaxSize3000
                />
                {type == 'add' ? (
                    customButton ? (
                        customButton
                    ) : (
                        // <Button
                        //     onClick={() => buttonClickFn && buttonClickFn()}
                        //     style={{ minWidth: '5em' }}
                        //     typeButt="black"
                        // >
                        //     +
                        // </Button>
                        <div style={{ minWidth: '5em' }}></div>
                    )
                ) : (
                    <Icon
                        style={{ minWidth: '2.5em', maxWidth: '2.5em' }}
                        onClick={() => buttonClickFn && buttonClickFn()}
                        className="icon-trash"
                    />
                )}
            </InputBox>
        );
    }
};

function HeightCheker({ refBox }: { refBox: React.RefObject<HTMLDivElement> }) {
    const muteState = useAppSelector(state => state.muteAddItemSwithState);
    const rules = useAppSelector(state => state.banAndMuteRulesState.muteState.rules);
    // @ts-ignore: Unreachable code error
    const { size } = style();
    const onense = useRef(true);

    useEffect(() => {
        if (refBox.current && !(size.isMobile || size.isTab)) {
            let maxHeigth = 0;
            const inputs = refBox.current.querySelectorAll('[data-textarea]');
            inputs.forEach(el => {
                if (el instanceof HTMLElement) {
                    if (onense) {
                        if (maxHeigth < el.scrollHeight) {
                            maxHeigth = el.scrollHeight;
                            if (maxHeigth > 11 * 16) {
                                maxHeigth = 11 * 16;
                            }
                        }
                    } else {
                        if (maxHeigth < el.offsetHeight) {
                            maxHeigth = el.offsetHeight;
                        }
                    }
                }
            });
            onense.current = false;
            refBox.current.setAttribute('style', `height: ${maxHeigth + 32}px`);
        }
    }, [muteState, rules]);
    return <></>;
}
