import React, { useEffect } from 'react';
import RouteWrapper from '../../middleComponents/routeWrapper';
import Main from './main';
import style from './style';
import getLoggers from '@maket/js/api/requiresToken/logger/getLoggers';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import getProfile from '@maket/js/api/requiresToken/payments/getProfile';

export default () => {
    const {} = style();
    const token = useAppSelector(state => state.tokenState.token);
    const dispatch = useAppDispatch();
    const profiles = useAppSelector(state => state.authState.user?.profiles);
    const current_tariff = useAppSelector(state => state.authState.tariff.current_tariff);

    useEffect(() => {
        if (token) {
            getProfile({ dispatch, token });
            getLoggers({ dispatch, token });
        }
    }, [token]);

    return (
        <RouteWrapper
            title="ProfileList.Title"
            background="#00000000"
            mainComponent={<Main />}
            type="allComponents"
            upgrade
            counterTop={{
                0: profiles?.filter(el => el.status == 'active')?.length || 0,
                1: current_tariff?.tariff.count_profile || 0,
            }}
        />
    );
};
