import React, { useEffect } from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';
import { CNavLink, CSidebarNav } from '@coreui/react';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import PropTypes from 'prop-types';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import htmlFilesConfig from '@maket/public/htmlFilesConfig.json';

let openDocsPopap = false;

export const AppSidebarNav = ({ items }: { items: any }) => {
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const { ItemIcon, ItemIconImage } = style();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes('documentation')) {
            openDocsPopap = true;
        }
    }, []);

    useEffect(() => {
        const chatListItem = document.querySelector('[href="/app/chatList"]');
        if (window.location.href.includes('chatList')) {
            if (window.location.href.split('chatList')[1] == '') {
                chatListItem?.classList.remove('notActive');
            }
        }
    });

    const navLink = (name: string, icon: string, indent = false) => {
        return (
            <>
                {icon ? (
                    <ItemIcon className={`icon-${icon} nav-icon`}></ItemIcon>
                ) : (
                    indent && (
                        <span className="nav-icon">
                            <span className="nav-icon-bullet"></span>
                        </span>
                    )
                )}
                {name && activeLanguage[name]}
            </>
        );
    };

    const navItem = (item: any, index: number) => {
        const { component, name, badge, icon, ...rest } = item;
        const Component = component;
        return (
            <>
                <Component as="div" key={index + item.name}>
                    {rest.to || rest.href ? (
                        <div
                            data-popap={
                                item.to == 'popap' || item.to == 'popapDoc' ? 'true' : 'false'
                            }
                            onClick={e => {
                                if (item.to == 'popap' || item.to == 'popapDoc') {
                                    const profileArrow = document.getElementById('profileArrowId');

                                    if (profileArrow) {
                                        const style = profileArrow.getAttribute('style');

                                        const profileList = [
                                            ...document.querySelectorAll('[data-profileList]'),
                                        ];

                                        if (style?.includes('180deg')) {
                                            profileArrow.setAttribute(
                                                'style',
                                                'transform: rotate(0deg)',
                                            );
                                            profileList.map(el => {
                                                el.classList.add('active');
                                            });
                                            openDocsPopap = true;
                                        } else {
                                            profileArrow.setAttribute(
                                                'style',
                                                'transform: rotate(180deg)',
                                            );
                                            profileList.map(el => {
                                                el.classList.remove('active');
                                            });
                                            openDocsPopap = false;
                                        }
                                    }
                                }
                            }}
                        >
                            <CNavLink
                                {...(rest.to && { as: NavLink })}
                                {...(rest.href && { target: '_blank', rel: 'noopener noreferrer' })}
                                {...rest}
                                onClick={e => {
                                    if (item.to == 'popap' || item.to == 'popapDoc') {
                                        e.preventDefault();
                                    }
                                }}
                                className={item.to.includes('chatList') ? 'notActive' : ''}
                                style={{
                                    pointerEvents:
                                        item.to == 'popap' || item.to == 'popapDoc'
                                            ? 'none'
                                            : 'all',
                                }}
                            >
                                {navLink(name, icon, badge)}
                                {(item.to == 'popap' || item.to == 'popapDoc') && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: '1 1',
                                            justifyContent: 'end',
                                            margin: 0,
                                        }}
                                    >
                                        <ItemIconImage
                                            id="profileArrowId"
                                            src=".././../img/microIcon/profileArrow.svg"
                                            className={`nav-icon transitionImportant`}
                                            style={{
                                                transform:
                                                    item.to == 'popapDoc' && openDocsPopap
                                                        ? 'transform: rotate(0deg)'
                                                        : 'rotate(180deg)',
                                            }}
                                        ></ItemIconImage>
                                    </div>
                                )}
                            </CNavLink>
                        </div>
                    ) : (
                        navLink(name, icon, badge)
                    )}
                </Component>
                {item.to == 'popap' && (
                    <Component key={index * 100 + item.name}>
                        <CreateProfileList />
                    </Component>
                )}
                {item.to == 'popapDoc' && (
                    <Component>
                        <CreateDocList />
                    </Component>
                )}
            </>
        );
    };

    const navGroup = (item: any, index: number) => {
        const { component, name, icon, items, to, ...rest } = item;
        const Component = component;
        return (
            <Component compact as="div" key={index} toggler={navLink(name, icon)} {...rest}>
                {item.items?.map((item: any, index: number) => (
                    <div key={item + index * 1000}>
                        {item.items ? navGroup(item, index) : navItem(item, index)}
                    </div>
                ))}
            </Component>
        );
    };

    return (
        <CSidebarNav
            as={SimpleBar}
            style={{
                fontSize: '1.075em',
                height: 'fit-content',
                overflow: 'hidden',
                minHeight: 'fit-content',
            }}
        >
            {items.map((item: any, index: number) => (
                <div key={item + index * 10000}>
                    {item.items ? navGroup(item, index) : navItem(item, index)}
                </div>
            ))}
        </CSidebarNav>
    );
};

AppSidebarNav.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

function CreateDocList() {
    const { ProfileList, ProfileItem } = style();

    let language = useAppSelector(state => state.languageState.language);

    let config = {};
    if (htmlFilesConfig) {
        htmlFilesConfig.langList.includes(language) ? language : (language = 'EN');
        if (htmlFilesConfig[language]) {
            config = htmlFilesConfig[language].config;
        }
    }

    const navigate = useNavigate();

    return Object.keys(config)
        .filter(el => el[0] != '_')
        .map((el, i) => (
            <ProfileList
                className={`minify ${openDocsPopap ? 'active' : ''}`}
                key={el}
                style={{ cursor: 'pointer' }}
                data-profilelist
                onClick={e => {
                    if (el.id == null) {
                        e.preventDefault();
                    }
                    navigate(`/app/documentation/${el}`);
                }}
            >
                <ProfileItem>
                    <div className={el.id == null ? 'title' : 'title'}>{el}</div>
                </ProfileItem>
            </ProfileList>
        ));
}
function CreateProfileList() {
    const { ProfileList, ProfileItem } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const activeProfiles = useAppSelector(state => state.authState.user?.profiles);
    const account = useAppSelector(state => state.authState.account);

    const navigate = useNavigate();

    if (activeProfiles && account) {
        return activeProfiles.map((el, i) => (
            <ProfileList
                key={el.id + i}
                data-profilelist
                onClick={e => {
                    if (el.id == null) {
                        e.preventDefault();
                    }
                }}
            >
                <ProfileItem>
                    <div
                        onClick={() => {
                            navigate(`/app/chatList/${el.id}`);
                        }}
                        className={el.id == null ? 'title' : 'title'}
                    >
                        {el.name}
                    </div>
                    {/* <div
                        onClick={() => {
                            navigate(`/app/chatList/${el.id}`);
                        }}
                    >
                        {activeLanguage['Menu.ChatList']}
                    </div>
                    <div
                        onClick={() => {
                            navigate(`/app/chatRules/${el.id}`);
                        }}
                    >
                        {activeLanguage['Menu.ChatRules']}
                    </div>
                    <div
                        onClick={() => {
                            navigate(`/app/profileSettings/${el.id}`);
                        }}
                    >
                        {activeLanguage['Menu.Setting']}
                    </div> */}
                </ProfileItem>
            </ProfileList>
        ));
    } else {
        return <></>;
    }
}
