import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default (titleSize?: string) => {
    const size = useWindowSizeCheck();
    const { altTextBlackColor, authBackground } = useTheme();
    return {
        Container: styled.div({
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
            fontFamily: 'Inter, sans-serif',
        }),
        Title: styled.div({
            fontSize: titleSize ? titleSize : '0.875em',
            fontWeight: 700,
            color: altTextBlackColor,
            textAlign: 'start',
        }),
        Content: styled.div({
            padding: size.isMobile ? '0.5em 0.75em' : '1em 1.25em',
            backgroundColor: authBackground,
            width: '100%',
        }),
        size,
    };
};
