import React, { useCallback, useState } from 'react';
import LanguageButton from '@mainComponents/header/components/languageButton';
import style from './style';

export default ({ initState }: { initState?: string }) => {
    const { LangBox, size } = style();
    const [active, setActive] = useState(initState || '');
    const setActiveFn = useCallback((v: string) => {
        setActive(p => {
            if (p == v) {
                return '';
            } else {
                return v;
            }
        });
    }, []);

    return (
        <LangBox>
            <LanguageButton
                style={{ width: size.isTab ? '100%' : '' }}
                openCheck={active}
                openFn={setActiveFn}
                reverce={true}
            ></LanguageButton>
        </LangBox>
    );
};
