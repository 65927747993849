import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { borderPriseGray, authBackground, liteGreen, navGrayText, liteBlue } = useTheme();
    return {
        Container: styled.div({
            border: `1px solid ${borderPriseGray}`,
            borderRadius: 4,
            overflow: 'hidden',
        }),
        Top: styled.div({
            padding: size.isMobile ? '0.75em' : '1.5em',
            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
            aspectRatio: '1/0.3',
            width: '100%',
            height: 'auto',
        }),
        Bottom: styled.div({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: authBackground,
            borderTop: `1px solid ${borderPriseGray}`,
        }),
        TextBox: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '0.25em',
        }),
        Title: styled.div({
            fontSize: '1.25em',
            fontWeight: 700,
        }),
        Text: styled.div({
            fontSize: '0.875em',
        }),

        BottomCenter: styled.div({
            width: size.isMobile ? '90%' : size.isTab ? '60%' : '28%',

            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1em 0 0.4em 0',
        }),
        BottomItem: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5em',
            alignItems: 'center',
            justifyContent: 'center',

            fontSize: '0.75em',

            position: 'relative',
            padding: '0 1em',

            minWidth: '10em',
        }),
        BottomItemTitle: styled.div({
            color: navGrayText,
        }),
        BottomItemText: styled.div({}),
        BottomItemUnderline: styled.div({
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '0.25em',
            borderRadius: 4,
            transform: 'translate(0,150%)',

            '&.green': { backgroundColor: liteGreen },
            '&.blue': { backgroundColor: '#F86C6B' },
        }),
        size,
    };
};
