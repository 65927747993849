import React, { memo, useEffect, useState } from 'react';
import style from './style';
import GrayBox from '@maket/js/components/middleComponents/grayBox';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import ProfileName from './profileName';
import LanguageBox from './languageBox';
import ActiveSetting from './activeSetting';
import { useParams } from 'react-router-dom';
import Button from './button';
import LocalPopapElSub from './localPopapElSub';
import { TalertState } from '@maket/js/components/microComponets/buttonAllert';

export default () => {
    const { Container, Item, ButtonBox, size } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const { idProfile } = useParams();
    const profiles = useAppSelector(state => state.authState.user?.profiles) || [];

    const settingProfile = profiles.find(el => (idProfile ? +el.id == +idProfile : false));

    return (
        <Container>
            <Item>
                <GrayBox
                    key="active-setting"
                    content={<ActiveSetting settingProfile={settingProfile} type="active" />}
                />
                <GrayBox
                    key="profile-name"
                    titleSize="1.25em"
                    title={activeLanguage['Settings.']}
                    content={<ProfileName settingProfile={settingProfile} />}
                />
                <GrayBox
                    key="moder-setting"
                    content={<ActiveSetting settingProfile={settingProfile} type="moder" />}
                />
                <GrayBox
                    key="language-box"
                    content={<LanguageBox settingProfile={settingProfile} />}
                />
                <GrayBox
                    key="protection-setting"
                    content={<ActiveSetting settingProfile={settingProfile} type="protection" />}
                />
                <GrayBox
                    key="delete-forwarded-story-setting"
                    content={
                        <ActiveSetting
                            settingProfile={settingProfile}
                            type="delete-forwarded-story"
                        />
                    }
                />
                <GrayBox
                    key="delete-anonymous-messages-setting"
                    content={
                        <ActiveSetting
                            settingProfile={settingProfile}
                            type="delete-anonymous-messages"
                        />
                    }
                />
            </Item>
            <StateAlertIsolate />
        </Container>
    );
};

const StateAlertIsolate = memo(() => {
    const { ButtonBox } = style();

    const [allertCheck, setallertCheck] = useState<TalertState>({
        typeAllert: '',
        textAllert: '',
    });

    function setallertCheckLocal(v: TalertState) {
        if (allertCheck.textAllert != v.textAllert) {
            setallertCheck(v);
        }
    }

    return (
        <>
            <ButtonBox>
                <Button
                    key="add-button"
                    type={'add'}
                    allertCheck={allertCheck}
                    setallertCheck={setallertCheckLocal}
                />
                <Button
                    key="delete-button"
                    type={'delete'}
                    allertCheck={allertCheck}
                    setallertCheck={setallertCheckLocal}
                />
            </ButtonBox>
            <LocalPopapElSub allertCheck={allertCheck} setallertCheck={setallertCheckLocal} />
        </>
    );
});
