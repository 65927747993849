import { noAuthPaht } from '@maket/js/constants/values';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useGetToken from '@maket/js/hooks/useGetToken';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default () => {
    const navigate = useNavigate();
    const location = useLocation();

    const tokenCookie = useGetToken();
    const user = useAppSelector(state => state.authState.user);

    useEffect(() => {
        if (user == null && (!tokenCookie || tokenCookie == 'undefined')) {
            let check = false;
            noAuthPaht.forEach(el => {
                if (location.pathname.includes(el)) {
                    check = true;
                }
            });
            if (!check) {
                navigate('/');
            }
        }
    }, [user, location.pathname]);

    return <></>;
};
