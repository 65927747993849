import React from 'react';
import style from './style';
import { AnimatePresence } from 'framer-motion';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { setActivePopap } from '@maket/js/state/openPopap/openPopapState';
import logout from '@maket/js/api/logout/logout';
import { useNavigate } from 'react-router-dom';

const popapItems = [
    { name: 'User.Menu.UserData', path: 'app/myData', type: 'link' },
    { name: 'User.Menu.Safety', path: 'app/safety', type: 'link' },
    { name: 'User.Menu.Service', path: 'app/connectedAccount', type: 'link' },
    { name: 'User.Menu.Logout', path: '', type: 'logout' },
];

export default () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { Container, Icon, Popap, PopapItem } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const activePopapName = useAppSelector(state => state.openPopapState.activePopapName);
    const userPicture = useAppSelector(state => state.authState.account?.picture);

    return (
        <Container
            data-popap="true"
            onClick={() => {
                dispatch(setActivePopap('userMenu'));
            }}
        >
            <Icon
                src={userPicture ? userPicture : '/img/microIcon/initUser.svg'}
                onError={e => {
                    e.target.setAttribute('src', '/img/microIcon/initUser.svg');
                }}
            />
            <AnimatePresence>
                {activePopapName == 'userMenu' && (
                    <Popap
                        animate={{
                            opacity: 1,
                            pointerEvents: 'all',
                            transform: 'translate(0, 100%)',
                        }}
                        exit={{}}
                    >
                        {popapItems.map(el => (
                            <PopapItem
                                key={el.path}
                                to={`/${el.path}`}
                                onClick={e => {
                                    if (el.type == 'logout') {
                                        e.preventDefault();
                                        logout(dispatch, navigate);
                                    }
                                }}
                            >
                                {activeLanguage[el.name]}
                            </PopapItem>
                        ))}
                    </Popap>
                )}
            </AnimatePresence>
        </Container>
    );
};
