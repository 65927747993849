import { TauthInputValueState } from '@maket/js/types/state/authInputsValue';
import { TauthPage } from '../..';
import { TalertState } from '.';
import mailAuth from '@maket/js/api/auth/mailAuth';
import mailReg from '@maket/js/api/auth/mailReg';
import { TappDispatch } from '@maket/js/state/store';
import { setToken } from '@maket/js/state/tokenState';
import { mailRegExp, passwordRegExp } from '@maket/js/constants/values';

export default function sendMailSucess(
    type: TauthPage,
    authInputsValues: TauthInputValueState,
    setStateAlert: React.Dispatch<React.SetStateAction<TalertState>>,
    dispatch: TappDispatch,
    lang: string,
    allertActive: boolean,
) {
    const checkMail = authInputsValues.mail.match(mailRegExp);
    if (type == 'auth') {
        if (authInputsValues.mail == '' || authInputsValues.pass == '') {
            setStateAlert({
                typeAllert: 'danger',
                textAllert: 'NotAllField',
            });
            clearState(setStateAlert, allertActive);

            if (authInputsValues.mail == '') {
                addErrorInput('regInputMail');
            }
            if (authInputsValues.pass == '') {
                addErrorInput('regInputPass');
            }
        } else if (!checkMail) {
            setStateAlert({
                typeAllert: 'danger',
                textAllert: 'mailErrorRegExp',
            });
            clearState(setStateAlert, allertActive);
        } else {
            if (authInputsValues.mail.length > 49) {
                setStateAlert({
                    typeAllert: 'danger',
                    textAllert: 'mailErrorLength',
                });
                clearState(setStateAlert, allertActive);
                addErrorInput('regInputMail');
            } else {
                mailAuth({ mail: authInputsValues.mail, pass: authInputsValues.pass })
                    .then(rez => {
                        const token = rez.data.token;
                        dispatch(setToken(token));
                    })
                    .catch(err => {
                        setStateAlert({
                            typeAllert: 'danger',
                            textAllert: 'authError',
                        });
                        clearState(setStateAlert, allertActive);
                    });
            }
        }
    }
    if (type == 'reg') {
        const checkMail = authInputsValues.mail.match(mailRegExp);
        const checkPass = authInputsValues.pass.match(passwordRegExp);
        if (
            authInputsValues.firstName == '' ||
            authInputsValues.lastName == '' ||
            authInputsValues.mail == '' ||
            authInputsValues.pass == ''
        ) {
            if (authInputsValues.firstName == '') {
                addErrorInput('regInputFirstname');
            }
            if (authInputsValues.lastName == '') {
                addErrorInput('regInputLastname');
            }
            if (authInputsValues.mail == '') {
                addErrorInput('regInputMail');
            }
            if (authInputsValues.pass == '') {
                addErrorInput('regInputPass');
            }
            setStateAlert({
                typeAllert: 'danger',
                textAllert: 'NotAllField',
            });
            clearState(setStateAlert, allertActive);
        } else if (!checkMail) {
            setStateAlert({
                typeAllert: 'danger',
                textAllert: 'mailErrorSend',
            });
            clearState(setStateAlert, allertActive);
            addErrorInput('regInputMail');
        } else if (!checkPass) {
            setStateAlert({
                typeAllert: 'danger',
                textAllert: 'mailErrorSend',
            });
            clearState(setStateAlert, allertActive);
            addErrorInput('regInputPass');
        } else {
            if (authInputsValues.mail.length > 49) {
                setStateAlert({
                    typeAllert: 'danger',
                    textAllert: 'mailErrorLength',
                });
                clearState(setStateAlert, allertActive);
                addErrorInput('regInputMail');
            } else {
                mailReg({
                    mail: authInputsValues.mail,
                    first_name: authInputsValues.firstName,
                    last_name: authInputsValues.lastName,
                    username: authInputsValues.firstName + ' ' + authInputsValues.lastName,
                    password: authInputsValues.pass,
                    lang,
                })
                    .then(res => {
                        if (res.result == 'error') {
                            if (res.errors.includes('email_already_exists')) {
                                setStateAlert({
                                    typeAllert: 'danger',
                                    textAllert: 'emailAlreadyExists',
                                });
                            } else {
                                setStateAlert({
                                    typeAllert: 'danger',
                                    textAllert: 'mailErrorSend',
                                });
                            }

                            clearState(setStateAlert, allertActive);
                        }
                        if (res.result == 'ok') {
                            setStateAlert({ typeAllert: 'info', textAllert: 'mailSend' });
                            clearState(setStateAlert, allertActive);

                            const regInputFirstname = document.getElementById('regInputFirstname');
                            const regInputLastname = document.getElementById('regInputLastname');
                            const regInputMail = document.getElementById('regInputMail');
                            const regInputPass = document.getElementById('regInputPass');

                            if (regInputFirstname) {
                                const input = regInputFirstname.querySelector('input');
                                input && (input.value = '');
                            }
                            if (regInputLastname) {
                                const input = regInputLastname.querySelector('input');
                                input && (input.value = '');
                            }
                            if (regInputMail) {
                                const input = regInputMail.querySelector('input');
                                input && (input.value = '');
                            }
                            if (regInputPass) {
                                const input = regInputPass.querySelector('input');
                                input && (input.value = '');
                            }
                        }
                    })
                    .catch(err => {
                        setStateAlert({
                            typeAllert: 'danger',
                            textAllert: 'mailErrorSend',
                        });
                        clearState(setStateAlert, allertActive);
                    });
            }
        }
    }
}

export function clearState(
    setStateAlert: React.Dispatch<React.SetStateAction<TalertState>>,
    allertActive: boolean,
    timer?: number,
) {
    setTimeout(
        () => {
            allertActive = false;
            setStateAlert({ typeAllert: '', textAllert: '' });
            window.history.replaceState({}, '');
        },
        timer != undefined ? timer : 5000,
    );
}

function addErrorInput(id: string) {
    const input = document.getElementById(id);
    input?.classList.add('inputRedShadow');
    setTimeout(() => {
        input?.classList.remove('inputRedShadow');
    }, 5000);
}
