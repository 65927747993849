import { createSlice } from '@reduxjs/toolkit';

const initialState: { unfoldable: boolean } = { unfoldable: false };

const headerState = createSlice({
    name: 'headerState',
    initialState,
    reducers: {
        setUnfoldable: (state, { payload }: { payload: boolean }) => {
            state.unfoldable = payload;
        },
    },
});

export const { setUnfoldable } = headerState.actions;

export default headerState;
