import React from 'react';
import style from './style';
import Input from '@maket/js/components/microComponets/input';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { setMuteEndingFraceState } from '@maket/js/state/banAndMuteRules';
import { userSimbolCounter } from '@maket/js/constants/values';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';

export default ({ checkMute }: { checkMute: boolean }) => {
    const { Container, Title } = style(checkMute);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <Container>
            <Title>{activeLanguage['ChatRules.EndPhrase']}</Title>
            <WrapperInput />
        </Container>
    );
};

function WrapperInput() {
    const dispatch = useAppDispatch();
    const ending_frase = useAppSelector(state => state.banAndMuteRulesState.muteState.ending_frase);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    return (
        <Input
            stopInput={userSimbolCounter < 1}
            value={ending_frase}
            placeholder={activeLanguage['ChatRules.EndPhrase']}
            iconName="pencilSimple"
            setExtStateOuter={v => {
                let checkOnliWhitespaceEnd = true;
                [...v].forEach(el => {
                    if (el != ' ') {
                        checkOnliWhitespaceEnd = false;
                    }
                });
                if (!checkOnliWhitespaceEnd) {
                    dispatch(setMuteEndingFraceState(v));
                } else {
                    dispatch(setMuteEndingFraceState(''));
                }
            }}
        />
    );
}
