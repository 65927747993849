import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { whiteText, footerBackground, logoLiteBlue, darkGray, grayShadowColor, liteGray } =
        useTheme();
    return {
        Container: styled.div({
            padding: size.isMobile || size.isTab ? '3em 1em 0' : '8em 0 0 0',
            display: 'flex',
            flexDirection: 'column',
            gap: size.isMicroMobile ? '2em' : size.isMobile || size.isTab ? '3em' : '3.75em',
            fontFamily: 'Inter, sans-serif',
            backgroundColor: footerBackground,
        }),
        Title: styled.div({
            fontSize: size.isMicroMobile ? '2em' : size.isMobile || size.isTab ? '3em' : '3.75em',
            fontWeight: 800,
            textAlign: 'center',
            width: size.isMobile || size.isTab ? '80%' : '50%',
        }),
        Contacts: styled.div({
            display: 'flex',
            gap: size.isMicroMobile ? '0.5em' : size.isMobile ? '1em' : '1.75em',
            alignItems: 'center',
        }),
        ContactButton: styled.button({
            backgroundColor: whiteText,
            borderRadius: 10.8,
            width: '4.725em',
            height: '4.725em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: '0.3s',

            ':hover': {
                boxShadow: `0 0 10px ${grayShadowColor}`,
            },
        }),
        ContactButtonIcon: styled.div({
            fontSize: '2em ',
        }),
        LogoBox: styled.div({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.75em',
            flexDirection: 'column',
            fontWeight: 600,
        }),
        Logo: styled.img({ width: '8em', aspectRatio: 1 }),
        LogoText: styled.div({
            fontSize: '2.5em',
            position: 'relative',
        }),
        LogoTexSignature: styled.div({
            position: 'absolute',
            top: '75%',
            right: 0,
            fontSize: '0.55em',
            color: logoLiteBlue,
        }),
        Buttons: styled.div({
            display: 'flex',
            gap: size.isMobile || size.isTab ? '1em' : '4em',

            flexDirection: size.isMobile || size.isTab ? 'column' : 'row',
        }),
        Main: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: size.isMicroMobile ? '2em' : size.isMobile || size.isTab ? '3em' : '4em',
            justifyContent: 'center',
            alignItems: 'center',
        }),
        Footer: styled.div({
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '0.5em',
            padding: '2em',
            flexDirection: size.isMobile || size.isTab ? 'column-reverse' : 'row',
            gap: size.isMobile || size.isTab ? '3em' : '',
            alignItems: size.isMobile || size.isTab ? 'center' : 'start',
        }),
        FooterItem: styled.div({
            display: 'flex',
            gap: '2.625em',
            fontSize: '1.5em',

            '&.mbColumn': {
                gap: '1em',
                flexDirection: size.isMobile ? 'column' : 'row',
            },
        }),
        GrayLink: styled.a({
            color: darkGray,
            fontSize: '1.125em',
            transition: '0.3s',
            textAlign: 'center',
            cursor: 'pointer',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',

            ':hover': {
                color: liteGray,
            },
        }),
        size,
    };
};
