import { Tstatistics } from '@maket/js/types/state/statistics';
import { createSlice } from '@reduxjs/toolkit';

const initialState: {
    DateState: { start: string; end: string };
    activeProfileId: number | null;
    statistics: Tstatistics;
} = {
    DateState: {
        start: new Date(new Date().getTime() - 2678400000).toISOString().split('T')[0],
        end: new Date().toISOString().split('T')[0],
    },
    activeProfileId: null,
    statistics: {
        messages: [],
        spam_messages: [],
        users: [],
        active_users: [],
        spam_topics: [],
        messages_count: 0,
        spam_messages_count: 0,
        users_count: 0,
        active_users_count: 0,
    },
};

const dashbordState = createSlice({
    name: 'dashbordState',
    initialState,
    reducers: {
        setDateState: (state, { payload }: { payload: { start: string; end: string } }) => {
            state.DateState = payload;
        },
        setActiveDashbordPofileState: (state, { payload }: { payload: number }) => {
            state.activeProfileId = payload;
        },
        setStatisticsState: (state, { payload }: { payload: Tstatistics }) => {
            state.statistics = payload;
        },
    },
});

export const { setActiveDashbordPofileState, setDateState, setStatisticsState } =
    dashbordState.actions;

export default dashbordState;
