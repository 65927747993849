import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';
import { NavLink } from 'react-router-dom';

export default () => {
    const size = useWindowSizeCheck();
    const { buttonBlue } = useTheme();
    return {
        Container: styled.div({
            textAlign: 'center',
            fontSize: size.isMicroMobile ? '0.7em' : size.isMobile ? '0.9em' : '1.125em',
        }),
        RegLink: styled(NavLink)({
            fontWeight: 700,
            color: buttonBlue,
        }),
        size,
    };
};
