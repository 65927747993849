import React, { HTMLAttributes } from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';

interface Tprops extends HTMLAttributes<HTMLDivElement> {
    type: 'google' | 'facebook' | 'apple';
}

const comparsion = {
    google: { iconName: 'googleAuth', text: 'Auth.Login.Google' },
    facebook: { iconName: 'facebookAuth', text: 'Auth.Login.Facebook' },
    apple: { iconName: 'appleAuth', text: 'Auth.Login.Apple' },
};

export default ({ type, ...props }: Tprops) => {
    const { Container, Icon, Text } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <Container {...props}>
            <Icon className={`icon-${comparsion[type].iconName}`} />
            <Text>{activeLanguage[comparsion[type].text]}</Text>
        </Container>
    );
};
