import React from 'react';
import style from './style';
import { CChart } from '@coreui/react-chartjs';
import { Tstatistics } from '@maket/js/types/state/statistics';
import { TactiveLanguage } from '@maket/js/types/state/language';

function data(statistics: Tstatistics, activeLanguage: TactiveLanguage) {
    const arrValues = [];
    let buffer = [...statistics.spam_topics];

    for (let i = 0; i < 5; i++) {
        const maxCount = Math.max(...buffer.map(el => el.count));
        const item = buffer.find(el => el.count == maxCount);
        if (item) {
            arrValues.push(item);
        }

        buffer = buffer.filter(el => el.count != maxCount);
    }

    let lastItem = { count: 0, topic_id: 'Others' };
    buffer.forEach(el => {
        lastItem.count += el.count;
    });

    if (arrValues.length != 0) {
        arrValues.push(lastItem);
    }

    return {
        labels:
            arrValues.length != 0
                ? arrValues.map(el => activeLanguage['Dashbord.SpamTopic.' + el.topic_id])
                : '',
        datasets: [
            {
                data: arrValues.map(el => el.count),
                backgroundColor: ['#FF6384', '#4BC0C0', '#FFCE56', '#E7E9ED', '#36A2EB'],
            },
        ],
    };
}

const options = {
    plugins: {
        legend: {
            labels: {
                font: {
                    family: 'Inter, sans-serif',
                },
                // color: getStyle('--cui-body-color'),
            },
        },
    },
    scales: {
        r: {
            grid: {
                // color: getStyle('--cui-border-color'),
            },
        },
    },
    defaults: {
        global: {
            defaultFont: 'Inter',
            fontFamily: 'Inter, sans-serif',
        },
    },
};

export default ({
    statistics,
    activeLanguage,
}: {
    statistics: Tstatistics;
    activeLanguage: TactiveLanguage;
}) => {
    let scrollWidth = Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.body.clientWidth,
        document.documentElement.clientWidth,
    );
    const {} = style();
    return (
        <CChart
            style={{ fontFamily: 'Inter, sans-serif' }}
            type="polarArea"
            data={data(statistics, activeLanguage)}
            options={options}
            customTooltips={false}
        />
    );
};
