import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { altTextBlackColor } = useTheme();
    return {
        MailContainer: styled.div({ display: 'flex', flexDirection: 'column', gap: '1em' }),
        MailTitle: styled.div({
            fontSize: '1.125em',
            width: '100%',
            textAlign: 'start',
            color: altTextBlackColor,
        }),
        size,
    };
};
