import React from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';

type Tprops = {};

export default ({}: Tprops) => {
    const { Container, RegLink } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const splitContent = activeLanguage['Auth.Login.EndTextLogin']?.split('/') || [];
    return (
        <Container>
            {splitContent[0]}
            <RegLink to={'/app/auth/reg'}> {splitContent[1]}</RegLink>
        </Container>
    );
};
