import React from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';
import { TauthPage } from '..';
import Accept from './accept';
import InputBox from './inputBox';
import ButtonBox from './buttonBox';

type Tprops = { type: TauthPage };

export const comparsion = {
    auth: {
        title: 'Auth.Login.Title',
        text: 'Auth.Login.Text',
        mailPlaceholder: 'Auth.MailPlaceholder',
        passPlaceholder: 'Auth.PassPlaceholder',
        firstNamePlaceholder: '',
        lastNamePlaceholder: '',
        button: 'Auth.Login.Button',
    },
    reg: {
        title: 'Auth.Register.Title',
        text: 'Auth.Register.Text',
        mailPlaceholder: 'Auth.MailPlaceholder',
        passPlaceholder: 'Auth.PassPlaceholder',
        firstNamePlaceholder: 'FirstName.',
        lastNamePlaceholder: 'LastName.',
        button: 'Auth.Register.Button',
    },
};

export default ({ type }: Tprops) => {
    const { Container, Title, Text, TopBox } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    return (
        <Container>
            <TopBox>
                <Title>{activeLanguage[comparsion[type].title]}</Title>
                {type == 'auth' && <Text>{activeLanguage[comparsion[type].text]}</Text>}
            </TopBox>
            <InputBox type={type} />
            {/* {type == 'reg' && <Accept />} */}

            <ButtonBox type={type} />
        </Container>
    );
};
