import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import axios from 'axios';

type Tprops = {
    email: string;
};

export default async ({ email }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post(apiUrl + '/account/reset_password', {
            email: email,
        });
        setVisableLoader('notVisable');
        return req.data;
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
