import React, { useEffect, useState } from 'react';
import style from './style';
import { AnimatePresence } from 'framer-motion';
import useAppSelector from '@maket/js/hooks/useAppSelector';

export default () => {
    const { Container, Text, Icon } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const [active, setActive] = useState(true);

    let cookieConfirm = document.cookie.split(';').filter(el => el.includes('cookieConfirm'))[0];
    if (cookieConfirm) {
        cookieConfirm = cookieConfirm.split('cookieConfirm=')[1];
    }

    return (
        <AnimatePresence>
            {!cookieConfirm && (
                <Container
                    initial={{
                        transform: 'translate(0, 100%)',
                    }}
                    animate={{
                        transform: 'translate(0, 0)',
                    }}
                    exit={{ transform: 'translate(0, 100%)' }}
                >
                    <Icon
                        alt=""
                        src="/img/microIcon/close.svg"
                        onClick={() => {
                            const date = new Date(Date.now() + 2678400e3 * 12).toUTCString();
                            document.cookie = `cookieConfirm=true; path=/; expires=${date}`;
                            setActive(false);
                        }}
                    />
                    <Text
                        className="scrollbar"
                        dangerouslySetInnerHTML={{ __html: activeLanguage['Cookie.Text'] }}
                    ></Text>
                </Container>
            )}
        </AnimatePresence>
    );
};
