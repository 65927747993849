import React, { useEffect } from 'react';
import style from './style';
import Switch from '@maket/js/components/microComponets/switch';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { setSubscribeToNewsletterValue } from '@maket/js/state/settingPass/settingPassValueState';

let onceCheck = true;

export default () => {
    const dispatch = useAppDispatch();
    const { AuthSettingContainer, AuthSettingMain, AuthSettingTitle, AuthSettingText, size } =
        style();
    const accountState = useAppSelector(state => state.authState.account);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const { subscribe_to_newsletter } = useAppSelector(state => state.settingPassValueState);

    useEffect(() => {
        if (accountState) {
            if (onceCheck) {
                onceCheck = false;
                dispatch(setSubscribeToNewsletterValue(accountState.subscribe_to_newsletter));
            }
        }
    }, [accountState?.subscribe_to_newsletter]);

    return (
        <AuthSettingContainer>
            <Switch
                initState={subscribe_to_newsletter}
                setState={v => dispatch(setSubscribeToNewsletterValue(v))}
            />
            <AuthSettingMain>
                <AuthSettingTitle>
                    {activeLanguage['Safety.SubMailNewsletterTitle']}
                </AuthSettingTitle>
            </AuthSettingMain>
        </AuthSettingContainer>
    );
};
