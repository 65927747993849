import React, { useEffect } from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';
import CardListEl from './cardListEl';
import LocalPopapElSub from './localPopapElSub';
import LocalPopapElOffSub from './localPopapElOffSub';
import EndButton from './endButton';
import CardSettings from './cardSettings';
import LocalPopapElEnterprice from './localPopapElEnterprice';

export default ({ type }: { type?: 'page' }) => {
    const unfoldable = useAppSelector(state => state.headerState.unfoldable);
    const { Container, Title, Text, TGIcon, Top, Bottom, CardList, Annotation } = style(
        type,
        unfoldable,
    );

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    return (
        <Container id="mainPlans" className="scrollbar">
            <Top>
                <Title>
                    {activeLanguage['Main.Price.Title']}
                    <div style={{ display: 'flex', gap: '0.5em', justifyContent: 'center' }}>
                        <TGIcon className="icon-telegram" /> Telegram
                    </div>
                </Title>
                <Text>{activeLanguage['Main.Price.Text']}</Text>
            </Top>
            <Bottom>
                <CardSettings type={type} />
                <CardList>
                    <CardListEl type={type} />
                </CardList>
                <Annotation>{activeLanguage['Main.Price.Annotation']}</Annotation>
                {type == 'page' && <EndButton />}
            </Bottom>
            {type == 'page' && (
                <>
                    <LocalPopapElSub />
                    <LocalPopapElOffSub />
                </>
            )}
            <LocalPopapElEnterprice />
        </Container>
    );
};
