import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import { motion } from 'framer-motion';

export default () => {
    const size = useWindowSizeCheck();
    return {
        Container: styled.div({ display: 'flex' }),
        AnimateContainer: styled(motion.div)({}),
        size,
    };
};
