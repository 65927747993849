import '@maket/css/style.css';
import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from '@js/components/mainComponents/App';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '@state/store';

//auth
import { GoogleOAuthProvider } from '@react-oauth/google';

import * as Sentry from '@sentry/react';
import { googleClientId, isStrictMode } from './constants/values';

import MainRoute from './components/routes/mainRoute';
import Auth from './components/routes/auth';
import ConfirmMail from './components/routes/confirmMail';
import Price from './components/routes/price';
import TermsOfUse from './components/routes/termsOfUse';
import ConnectTgAccount from './components/routes/connectTgAccount';
import ConnectedAccount from './components/routes/connectedAccount';
import ChatList from './components/routes/chatList';
import Safety from './components/routes/safety';
import MyData from './components/routes/myData';
import ChatRules from './components/routes/chatRules';
import ProfileList from './components/routes/profileList';
import Dashboard from './components/routes/dashboard';
import FogotPass from './components/routes/fogotPass';
import ConfirmFogotPass from './components/routes/confirmFogotPass';
import ProfileSettings from './components/routes/profileSettings';
import ConfirmChangeEmail from './components/routes/confirmChangeEmail';
import PrivacyPolicy from './components/routes/privacyPolicy';
import CookiePolicy from './components/routes/cookiePolicy';
import RefundPolicy from './components/routes/refundPolicy';
import Layout from './components/mainComponents/layout';

const Documentation = lazy(() => import('./components/routes/documentation'));

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', process.env.SENTRY_TRACE_PROPAGATION_TARGETS],
        // Set profilesSampleRate to 1.0 to profile every transaction.
        // Since profilesSampleRate is relative to tracesSampleRate,
        // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
        // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
        // results in 25% of transactions being profiled (0.5*0.5=0.25)
        profilesSampleRate: 1.0,
    });
}

// const router = createBrowserRouter(createRoutesFromElements(<App />));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Layout />}>
            <Route index element={<MainRoute />} />
            <Route path="app/auth/auth" element={<Auth type="auth" />} />
            <Route path="app/auth/success" element={<Auth type="auth" />} />
            <Route path="app/auth/fail" element={<Auth type="auth" />} />

            <Route path="app/registration/confirm">
                <Route index path="*" element={<Auth type="auth" />} />
            </Route>
            <Route path="app/change_password/confirm">
                <Route index path="*" element={<FogotPass />} />
            </Route>
            <Route path="app/change_email/confirm">
                <Route index path="*" element={<ConfirmChangeEmail />} />
            </Route>

            <Route path="app/auth/reg" element={<Auth type="reg" />} />
            <Route path="app/confirmMail" element={<ConfirmMail />} />
            <Route path="app/confirmChangeEmail" element={<ConfirmChangeEmail />} />

            <Route path="app/confirmFogotPass" element={<ConfirmFogotPass />} />

            <Route path="app/billing" element={<Price />} />
            <Route path="app/termsOfUse" element={<TermsOfUse />} />
            <Route path="app/connectTgAccount" element={<ConnectTgAccount />} />
            <Route path="app/connectedAccount" element={<ConnectedAccount />} />
            <Route path="app/chatList" element={<ChatList />} />
            <Route path="app/chatList/:idProfile" element={<ChatList />} />
            <Route path="app/safety" element={<Safety />} />
            <Route path="app/myData" element={<MyData />} />
            <Route path="app/chatRules" element={<ChatRules />} />
            <Route path="app/chatRules/:idProfile" element={<ChatRules />} />
            <Route path="app/profileList" element={<ProfileList />} />
            <Route path="app/profileSettings/:idProfile" element={<ProfileSettings />} />
            <Route path="app/dashboard" element={<Dashboard />} />
            <Route
                path="app/documentation/:chapter?"
                element={
                    <Suspense fallback={<>...</>}>
                        <Documentation />
                    </Suspense>
                }
            />
            <Route path="app/fogotPass" element={<FogotPass />} />
            <Route path="app/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="app/cookiePolicy" element={<CookiePolicy />} />
            <Route path="app/refundPolicy" element={<RefundPolicy />} />
        </Route>,
    ),
);

const StrictModeWrapper = isStrictMode ? (
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
) : (
    <RouterProvider router={router} />
);

createRoot(document.getElementById('wrap')).render(
    <GoogleOAuthProvider clientId={googleClientId}>
        <Provider store={store}>{StrictModeWrapper}</Provider>
    </GoogleOAuthProvider>,
);
