import { Ttheme } from '@js/types/state/theme';
import useAppSelector from './useAppSelector';

export const staticColor = {
    telegramColor: '#37AEE2',
    discordColor: '#5A68F3',
};

const whiteColors: Ttheme = {
    buttonBlue: '#239CFF',
    buttonBlueHover: '#5A68F3',
    blackIcon: '#000',
    textLowGray: '#E4E4E7',
    mainBlack: '#000',
    whiteText: '#fff',
    grayShadowColor: '#1a1a1a59',
    hoverLiteGreen: '#F2F6FD',
    altTextBlackColor: '#1C1C1C',
    grayTextSelect: '#1A1A1Ab3',
    logoLiteBlue: '#63C2DE',
    darkGray: '#73818F',
    navBlackBack: '#242E3D',
    navGrayText: '#708393',
    navHoverGray: '#ffffff1A',
    navBackgroundGrayLite: '#3A4248',
    navBackgroundGrayDark: '#252A2E',

    main__previewBackground: '#0B1320',
    liteBlue: '#D6ECFF',
    liteGray: '#404040',
    borderPriseGray: '#E2E3E6',
    installGrayText: '#ABABAB',
    footerBackground: '#F1F1F1',
    mainNavBackground: '#2E343A',

    authBackground: '#F5F5F5',
    inputBorderColor: '#D7DAE2',

    notificationRed: '#F86C6B',
    disconnectRed: '#DD302F',
    liteGreen: '#4BC06A',
    grayPriceActiveButton: '#ebebeb',

    secondaryButton: '#6b7785',
    secondaryHoverButton: '#565f6a',

    profileSidebarTitleGray: '#A8C4D1',
    liteGreenProfilePopap: '#CDEDD8',
};
const darkColors: Ttheme = {
    buttonBlue: '',
    buttonBlueHover: '',
    blackIcon: '',
    textLowGray: '',
    mainBlack: '',
    whiteText: '',
    grayShadowColor: '',
    hoverLiteGreen: '',
    altTextBlackColor: '',
    grayTextSelect: '',
    logoLiteBlue: '',
    darkGray: '',
    navBlackBack: '',
    navGrayText: '',
    navHoverGray: '',
    navBackgroundGrayLite: '',
    navBackgroundGrayDark: '',
    mainNavBackground: '',

    main__previewBackground: '',
    liteBlue: '',
    liteGray: '',
    borderPriseGray: '',
    installGrayText: '',
    footerBackground: '',

    authBackground: '',
    inputBorderColor: '',

    notificationRed: '',
    disconnectRed: '',
    liteGreen: '',
    grayPriceActiveButton: '',

    secondaryButton: '',
    secondaryHoverButton: '',

    profileSidebarTitleGray: '',
    liteGreenProfilePopap: '',
};

export default (): Ttheme => {
    const theme = useAppSelector(state => state.themeState.theme);

    if (theme == 'white') {
        return whiteColors;
    } else {
        return darkColors;
    }
};
