import React from 'react';
import style from './style';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { CProgress } from '@coreui/react';
import { TspamTopics } from '@maket/js/types/state/statistics';

function maxValue(items: TspamTopics) {
    return items.reduce((acc, item) => {
        if (acc < item.count) {
            acc = item.count;
        }
        return acc;
    }, items[0].count);
}

export default () => {
    const {
        PropgressBarBox,
        PropgressBarItem,
        ProgressBarTitle,
        ProgressBarValue,
        ProgressBarWrapper,
        ProgressBar,
    } = style();

    const statistics = useAppSelector(state => state.dashbordState.statistics);

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <PropgressBarBox>
            {[...statistics.spam_topics]
                ?.sort((n, p) => (n.count > p.count ? -1 : 1))
                ?.map(el => (
                    <PropgressBarItem>
                        <ProgressBarTitle>
                            {activeLanguage['Dashbord.SpamTopic.' + el.topic_id]}
                        </ProgressBarTitle>
                        <ProgressBarWrapper>
                            <ProgressBarValue>{el.count}</ProgressBarValue>
                            <ProgressBar>
                                <CProgress
                                    color="info"
                                    height={4}
                                    value={(el.count / maxValue(statistics.spam_topics)) * 100}
                                />
                            </ProgressBar>
                        </ProgressBarWrapper>
                    </PropgressBarItem>
                ))}
        </PropgressBarBox>
    );
};
