import React, { HTMLAttributes, useEffect } from 'react';
import style from './style';
import useTheme from '@maket/js/hooks/useTheme';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import AppSidebar from '../../mainComponents/sidebar/AppSidebar';

import UpgradeButton from '@components/middleComponents/upgradeButton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ProfileSelect from './profileSelect';

type Tprops = {
    title: string;
    mainComponent: any;
    type: 'onliWrapper' | 'allComponents';
    subTitle?: string;
    background?: string;
    upgrade?: boolean;
    mainContainerProps?: HTMLAttributes<HTMLDivElement>;
    tabsActive?: boolean;
    profileSelect?: 'profileSettings' | 'chatList';
    counterTop?: { 0: number; 1: number };
    titleStraight?: boolean;
};

export default ({
    title,
    subTitle,
    mainComponent,
    type,
    background,
    upgrade,
    mainContainerProps,
    tabsActive,
    profileSelect,
    counterTop,
    titleStraight,
}: Tprops) => {
    const unfoldable = useAppSelector(state => state.headerState.unfoldable);
    const {
        Container,
        MainContainer,
        ContainerMain,
        Top,
        TitleBox,
        Title,
        SubTitle,
        ContainerWhiteImport,
        ChatCounterBox,
        CounterTop,
        size,
    } = style(background, unfoldable);

    const { authBackground } = useTheme();
    useEffect(() => {
        document.body.setAttribute('style', `background-color: ${authBackground}`);
    });
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    if (type == 'onliWrapper') {
        return (
            <Container>
                <AppSidebar
                    position="sticky"
                    stateControllName={size.isMobile || size.isTab ? '' : 'sidebarSickyState'}
                ></AppSidebar>
                <MainContainer id="mainScrollbar" className="scrollbar">
                    {mainComponent}
                </MainContainer>
            </Container>
        );
    } else {
        return (
            <Container>
                <AppSidebar
                    position="sticky"
                    stateControllName={size.isMobile || size.isTab ? '' : 'sidebarSickyState'}
                ></AppSidebar>
                <MainContainer id="mainScrollbar" className="scrollbar">
                    <ContainerMain {...mainContainerProps}>
                        <Top>
                            {profileSelect ? (
                                <ChatCounterBox>
                                    <ProfileSelect
                                        title={activeLanguage[title]}
                                        pageName={profileSelect}
                                    />
                                    {counterTop && (
                                        <CounterTop className="counter">
                                            {counterTop[0]} / {counterTop[1]}
                                        </CounterTop>
                                    )}
                                </ChatCounterBox>
                            ) : (
                                <TitleBox>
                                    <Title>
                                        {titleStraight ? title : activeLanguage[title]}{' '}
                                        {counterTop && (size.isMobile || size.isTab) && (
                                            <CounterTop className="counter">
                                                {/* @ts-ignore: Unreachable code error*/}
                                                {counterTop[0]} / {counterTop[1]}
                                            </CounterTop>
                                        )}
                                    </Title>
                                    {subTitle && <SubTitle>{activeLanguage[subTitle]}</SubTitle>}
                                </TitleBox>
                            )}

                            {upgrade && (
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '1em',
                                        alignItems: 'center',
                                        justifyContent: 'end',
                                        maxWidth: size.isDesktop ? '50%' : '100%',
                                        width: size.isDesktop ? '50%' : '100%',
                                    }}
                                >
                                    {counterTop && (size.isDesktop || size.isLaptop) && (
                                        <CounterTop className="counter">
                                            {counterTop[0]} / {counterTop[1]}
                                        </CounterTop>
                                    )}
                                    <UpgradeButton></UpgradeButton>
                                </div>
                            )}
                        </Top>
                        {tabsActive && (
                            <CreateTabs
                                style={{ marginTop: size.isMobile || size.isTab ? '0' : '-1em' }}
                            />
                        )}
                        <ContainerWhiteImport>{mainComponent}</ContainerWhiteImport>
                    </ContainerMain>
                </MainContainer>
            </Container>
        );
    }
};

export function CreateTabs(props: HTMLAttributes<HTMLDivElement>) {
    const { Tab, TabBox } = style();

    const location = useLocation();
    const navigate = useNavigate();
    const { idProfile } = useParams();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    return (
        <TabBox {...props}>
            <Tab
                onClick={() => {
                    navigate(`/app/chatList/${idProfile}`);
                }}
                className={`${location.pathname.includes('chatList') ? 'active' : ''}`}
            >
                {activeLanguage['Menu.ChatList']}
            </Tab>
            <Tab
                onClick={() => {
                    navigate(`/app/chatRules/${idProfile}`);
                }}
                className={`${location.pathname.includes('chatRules') ? 'active' : ''}`}
            >
                {activeLanguage['Menu.ChatRules']}
            </Tab>
            <Tab
                onClick={() => {
                    navigate(`/app/profileSettings/${idProfile}`);
                }}
                className={`${location.pathname.includes('profileSettings') ? 'active' : ''}`}
            >
                {activeLanguage['Menu.Setting']}
            </Tab>
        </TabBox>
    );
}
