import React, { useEffect, useRef } from 'react';
import style from './style';
import Button from '@maket/js/components/microComponets/button';
import BottomItem from './bottomItem';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { Taccount, Tchat, Tlogger, Tprofile, TserviceListItem } from '@maket/js/types/state/auth';

type Tprops = {};

const bottomItems = [
    { title: 'ProfileList.Card.OneTitle', text: 'ProfileList.Card.OneText' },
    { title: 'ProfileList.Card.TwoTitle', text: 'ProfileList.Card.TwoText' },
    { title: 'ProfileList.Card.ThreeTitle', text: 'ProfileList.Card.ThreeText' },
];

export default ({}: Tprops) => {
    const { Bottom, size } = style();
    const bottomRef = useRef<HTMLDivElement>(null);
    const profiles = useAppSelector(state => state.authState.user?.profiles);
    const logerList = useAppSelector(state => state.authState.logerList);
    const serviceList = useAppSelector(state => state.authState.serviceList);

    useEffect(() => {
        if (bottomRef.current && !(size.isMobile || size.isTab || size.isLaptop)) {
            // @ts-ignore: Unreachable code error
            let maxHeightChildren = bottomRef.current.firstChild?.scrollHeight;
            // @ts-ignore: Unreachable code error
            if (bottomRef.current.children) {
                [...bottomRef.current.children]?.forEach(el => {
                    if (el.scrollHeight > maxHeightChildren) {
                        maxHeightChildren = el.scrollHeight;
                    }
                });
            }

            bottomRef.current.setAttribute('style', `height: ${maxHeightChildren}px;`);
        }
    });

    return (
        <Bottom ref={bottomRef}>
            {bottomItems.map((el, i) => (
                <BottomItem
                    key={el.title}
                    title={el.title}
                    text={el.text}
                    active={getActiveItem(profiles, logerList, serviceList, i)}
                />
            ))}
        </Bottom>
    );
};

function getActiveItem(
    profiles: Tprofile[] | undefined,
    logerList: Tlogger[],
    serviceList: TserviceListItem[],
    i: number,
) {
    if (i == 0 && serviceList[0]?.service_user_id) {
        return true;
    }
    let checkOnceProfileChat: boolean = false;
    if (profiles) {
        profiles.forEach(el => {
            if (el.logger_channel_name) {
                checkOnceProfileChat = true;
            }
        });
        if (i == 1 && logerList.length > 0) {
            return true;
        }
    }

    // @ts-ignore: Unreachable code error
    if (i == 2 && checkOnceProfileChat == true) {
        return true;
    }

    return false;
}
