import React, { memo } from 'react';
import style from './style';
import Input from '@js/components/microComponets/input';
import useAppSelector from '@js/hooks/useAppSelector';
import { mailRegExp } from '@maket/js/constants/values';
import { setMailInputValue } from '@maket/js/state/fogotPass/fogotPassState';

export default memo(() => {
    const { InputBox, InputItem } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <InputBox>
            <InputItem>
                <Input
                    setExtState={setMailInputValue}
                    placeholder={activeLanguage['FogotPass.InputPlaceholder']}
                    iconName="mailReg"
                    regExp={mailRegExp}
                />
            </InputItem>
        </InputBox>
    );
});
