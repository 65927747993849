import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    return {
        Container: styled.div({ display: 'flex', width: '100%', height: '100%' }),
        MainContainer: styled.div({
            width: '100%',
            height: '100%',
        }),
        size,
    };
};
