import React from 'react';
import style from './style';
import Left from './left';
import Right from './right';

export default () => {
    const { Container } = style();
    return (
        <Container id="mainTwo">
            <Left></Left>
            <Right></Right>
        </Container>
    );
};
