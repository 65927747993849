import { userSimbolCounter } from '@maket/js/constants/values';
import { createSlice } from '@reduxjs/toolkit';

const initialState: {
    state: boolean;
    resolveFn: () => void;
    rejectFn: () => void;
} = { state: false, rejectFn: () => {}, resolveFn: () => {} };

const savedFormsState = createSlice({
    name: 'savedFormsState',
    initialState,
    reducers: {
        setBlockedPopap: (
            state,
            {
                payload,
            }: { payload: { state: boolean; resolveFn: () => void; rejectFn: () => void } },
        ) => {
            state.state = payload.state;
            state.rejectFn = payload.rejectFn;
            state.resolveFn = payload.resolveFn;
        },
        setOnlyStateBlockedPopap: (state, { payload }: { payload: boolean }) => {
            state.state = payload;
        },
    },
});

export const { setBlockedPopap, setOnlyStateBlockedPopap } = savedFormsState.actions;

export default savedFormsState;
