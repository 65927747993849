import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme, { staticColor } from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { whiteText, authBackground, mainBlack, disconnectRed } = useTheme();
    const { telegramColor, discordColor } = staticColor;

    return {
        Container: styled.div({
            display: 'flex',
            width: '100%',
            height: '100%',
            fontFamily: 'Inter, sans-serif',

            fontSize: size.isMicroMobile ? '0.5em' : size.isMobile ? '0.7em' : '',
        }),
        MainContainer: styled.div({
            margin: size.isDesktop ? '5.5em 6.5em' : '1.5em 2.5em',

            width: 'calc(100% - 5em)',
            height: 'calc(100% - 5em)',

            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
        }),
        Title: styled.div({
            fontSize: '2.75em',
            fontWeight: 600,
        }),
        ItemContainer: styled.div({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: size.isMobile ? 'start' : 'space-between',
            gap: '2em',
        }),
        Item: styled.div({
            backgroundColor: whiteText,
            padding: '1.5em',
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
        }),
        ItemEl: styled.div({
            backgroundColor: authBackground,
            padding: '1.25em',

            display: 'flex',
            gap: '1em',
        }),
        ItemElIcon: styled.div({
            fontSize: '3em',
            '&.icon-telegram': { color: telegramColor },
            '&.icon-discord': { color: discordColor },
        }),
        ItemElMain: styled.div({
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
            gap: '1em',
        }),
        ItemElTitle: styled.div({
            fontSize: '1.25em',
            fontWeight: 600,
        }),
        ItemElText: styled.div({ fontSize: '0.875em' }),
        ItemElState: styled.div({
            cursor: 'pointer',
            fontSize: '0.875em',
            padding: '0.5em',
            backgroundColor: mainBlack,
            color: whiteText,
            width: 'fit-content',
            '&.connect': { backgroundColor: disconnectRed },
        }),
        size,
    };
};
