import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { inputBorderColor } = useTheme();
    return {
        Container: styled.div({
            display: 'flex',
            gap: '0.75em',
            alignItems: 'center',
            fontSize: size.isMicroMobile ? '0.7em' : size.isMobile ? '0.9em' : '1.125em',
            border: `1px solid ${inputBorderColor}`,
            width: '100%',
            borderRadius: 8,
            padding: '0.8125em',
            justifyContent: 'center',
            cursor: 'pointer',

            transition: '0.3s',
            ':hover': {
                backgroundColor: inputBorderColor,
            },
        }),
        Icon: styled.div({
            fontSize: '1.40em',
            width: '1em',
            height: '1em',
            maxHeight: '0.98em',
        }),
        Text: styled.div({}),
        size,
    };
};
