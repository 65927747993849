import React, { memo } from 'react';
import style from './style';
import { setLanguage } from '@js/state/language/languageState';
import CheckMarkInSelectElement from './checkMarkInSelectElement';
import { TlanguageList } from '@js/types/state/language';
import { TargetAndTransition } from 'framer-motion';
import useAppSelector from '@js/hooks/useAppSelector';
import useWindowSizeCheck from '@js/hooks/useWindowSizeCheck';
import { setActivePopap } from '@js/state/openPopap/openPopapState';
import useAppDispatch from '@js/hooks/useAppDispatch';

type Tprops = {
    openFn?: (v: string) => void;
    openCheck?: string;
    type?: 'page';
    reverce?: boolean;
};

const animateStyle = (
    active: boolean,
    mob: boolean,
    languageList: TlanguageList,
    type?: 'page',
    reverce?: boolean,
): TargetAndTransition => {
    if (active) {
        if (mob) {
            document
                .getElementById('navBar')
                ?.setAttribute(
                    'style',
                    `transform: translate(0px); padding-bottom: ${
                        Object.values(languageList).length * 15 + 3
                    }vw`,
                );
        }
        return {
            opacity: 1,
            pointerEvents: 'all',
            transform: reverce ? 'translate(0, -3em)' : 'translate(0, 100%)',
            width:
                mob || type == 'page'
                    ? '100%'
                    : Object.values(languageList).length < 8
                    ? 'calc((1.1875em * 2 + 1em)*4)'
                    : 'calc((1.1875em * 2 + 1em)*8 + 1.5em)',
        };
    } else {
        if (mob) {
            document.getElementById('navBar')?.setAttribute('style', `transform: translate(0px);`);
        }
        return {
            width:
                mob || type == 'page'
                    ? '100%'
                    : Object.values(languageList).length < 8
                    ? 'calc((1.1875em * 2 + 1em)*4)'
                    : 'calc((1.1875em * 2 + 1em)*8 + 1.5em)',
            opacity: 0,
            pointerEvents: 'none',
        };
    }
};

const SelectlistComponent = memo(
    ({ openCheck, openFn, type, reverce }: Tprops) => {
        const languageList = useAppSelector(state => state.languageState.languageList);
        const { activePopapName } = useAppSelector(state => state.openPopapState);
        const { isTab, isMobile } = useWindowSizeCheck();
        const dispatch = useAppDispatch();

        const { SelectList, SelectElement } = style(
            Object.values(languageList).length,
            type,
            reverce,
        );
        return (
            <SelectList
                animate={animateStyle(
                    openCheck != undefined
                        ? openCheck == 'languagePopap'
                        : type == 'page'
                        ? activePopapName == 'languagePopapPage'
                        : activePopapName == 'languagePopap',
                    isMobile || isTab,
                    languageList,
                    type,
                    reverce,
                )}
            >
                {Object.values(languageList).map((el, i) => (
                    <SelectElement
                        key={el + i + 'select'}
                        onClick={() => {
                            if (openFn != undefined) {
                                dispatch(setActivePopap(''));
                                openFn('navigationPopap');
                            } else {
                                dispatch(
                                    setActivePopap(
                                        type == 'page'
                                            ? ''
                                            : isMobile || isTab
                                            ? 'navigationPopap'
                                            : '',
                                    ),
                                );
                            }

                            dispatch(setLanguage(Object.keys(languageList)[i]));
                        }}
                    >
                        {el}
                        <CheckMarkInSelectElement elLangKey={Object.keys(languageList)[i]} />
                    </SelectElement>
                ))}
            </SelectList>
        );
    },
    () => true,
);
export default SelectlistComponent;
