import React, { useEffect } from 'react';
import style from './style';
import RouteWrapper from '../../middleComponents/routeWrapper';
import Main from './main';
import getAccount from '@maket/js/api/requiresToken/account/getAccount';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';

export default () => {
    const {} = style();

    const token = useAppSelector(state => state.tokenState.token);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (token) {
            getAccount({ dispatch, token });
        }
    }, [token]);

    return <RouteWrapper title="Safety.Title" mainComponent={<Main />} type="onliWrapper" />;
};
