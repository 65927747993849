import React, { useEffect } from 'react';
import style from './style';
import Input from '@maket/js/components/microComponets/input';
import {
    setInputNewPassSettingsValue,
    setInputPassSettingsValue,
    setInputRepeatPassSettingsValue,
} from '@maket/js/state/settingPass/settingPassValueState';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { passwordRegExp } from '@maket/js/constants/values';
import Button from './button';

export default () => {
    const { PasswordCotainer, PasswordItem, PasswordItemTitle, PasswordItemContainer, size } =
        style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <PasswordCotainer>
            <PasswordItemContainer>
                <PasswordItem>
                    <PasswordItemTitle>{activeLanguage['Safety.CurrentPass']}</PasswordItemTitle>
                    <IsolateInputs type="lastPassSetting" />
                </PasswordItem>
                <PasswordItem>
                    <PasswordItemTitle>{activeLanguage['Safety.NewPass']}</PasswordItemTitle>
                    <IsolateInputs type="newPassSetting" />
                </PasswordItem>
                <PasswordItem>
                    <PasswordItemTitle>{activeLanguage['Safety.RepeatPass']}</PasswordItemTitle>
                    <IsolateInputs type="replacePassSetting" />
                </PasswordItem>
            </PasswordItemContainer>
            <Button />
        </PasswordCotainer>
    );
};

function IsolateInputs({
    type,
}: {
    type: 'lastPassSetting' | 'newPassSetting' | 'replacePassSetting';
}) {
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const { pass, newPass, repeatPass } = useAppSelector(state => state.settingPassValueState);

    if (type == 'lastPassSetting') {
        return (
            <Input
                value={pass}
                id="lastPassSetting"
                iconName="passReg"
                placeholder={activeLanguage['Safety.CurrentPass']}
                setExtState={setInputPassSettingsValue}
                typeInput="password"
            />
        );
    }
    if (type == 'newPassSetting') {
        return (
            <Input
                value={newPass}
                id="newPassSetting"
                iconName="passReg"
                placeholder={activeLanguage['Safety.NewPass']}
                setExtState={setInputNewPassSettingsValue}
                regExp={passwordRegExp}
                typeInput="password"
            />
        );
    }
    if (type == 'replacePassSetting') {
        return (
            <Input
                value={repeatPass}
                id="replacePassSetting"
                iconName="passReg"
                placeholder={activeLanguage['Safety.RepeatPass']}
                setExtState={setInputRepeatPassSettingsValue}
                regExp={passwordRegExp}
                typeInput="password"
            />
        );
    }
}
