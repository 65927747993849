import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import Button from '@maket/js/components/microComponets/button';
import { ExeptionRulesItemAdd } from '../banExeption/exeptionRulesItem';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';

import { setExeptionRulesState } from '@maket/js/state/banAndMuteRules';
import { setInputState, setSwithState } from '@maket/js/state/exeptionAddItemSwith';
import style from '../style';
import ButtonAllert, { TalertState } from '@maket/js/components/microComponets/buttonAllert';

export default ({ checkBan }: { checkBan: boolean }) => {
    const [activeAddBan, setActiveAddBan] = useState(false);

    return (
        <>
            <AnimatePresence>{activeAddBan && <ExeptionRulesItemAdd />}</AnimatePresence>
            <div
                style={{
                    opacity: checkBan ? '1' : '0.7',
                    pointerEvents: checkBan ? 'all' : 'none',
                    width: '100%',
                    display: 'flex',
                }}
            >
                <ButtonIsolate setActiveAddBan={setActiveAddBan} checkBan={checkBan} />
            </div>
        </>
    );
};

function ButtonIsolate({
    setActiveAddBan,
    checkBan,
}: {
    setActiveAddBan: React.Dispatch<React.SetStateAction<boolean>>;
    checkBan: boolean;
}) {
    const { size } = style();
    const dispatch = useAppDispatch();
    const spamRulesState = useAppSelector(state => state.banAndMuteRulesState.exeptionState.rules);
    const text = useAppSelector(state => state.exeptionAddItemSwithState.inputValue);
    const stateSwith = useAppSelector(state => state.exeptionAddItemSwithState.state);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const [AlertState, setAlertState] = useState<TalertState>({ textAllert: '', typeAllert: '' });

    return (
        <ButtonAllert
            style={{ width: size.isMobile ? '100%' : '', pointerEvents: checkBan ? 'all' : 'none' }}
            stateAllertOuter={AlertState}
            setAllertFn={setAlertState}
            onClick={() => {
                let checkOnliWhitespace = true;
                [...text].forEach(el => {
                    if (el != ' ') {
                        checkOnliWhitespace = false;
                    }
                });
                if (text && !checkOnliWhitespace) {
                    if (text.length > 500) {
                        setAlertState({
                            textAllert: 'ProfileList.Alert.NameLengthError',
                            typeAllert: 'danger',
                        });
                    } else {
                        const newRules = [...spamRulesState];
                        newRules.push({
                            text: text,
                            enable: stateSwith,
                        });
                        dispatch(setExeptionRulesState(newRules));
                        dispatch(setInputState(''));
                        dispatch(setSwithState(true));
                        setActiveAddBan(false);
                    }
                } else {
                    setActiveAddBan(p => !p);
                    dispatch(setInputState(''));
                }
            }}
            typeButt="black"
            buttonStyle={{ pointerEvents: checkBan ? 'all' : 'none' }}
            buttonContent={`+ ${activeLanguage['ChatRules.BanExeption.Button']}`}
        ></ButtonAllert>
    );
}
