import { setUserSimbolCounter } from '@maket/js/constants/values';
import { createSlice } from '@reduxjs/toolkit';

const initialState: { value: number } = {
    value: 3000,
};

const userCounterSimbolRulesState = createSlice({
    name: 'userCounterSimbolRulesState',
    initialState,
    reducers: {
        setCounter: (state, { payload }: { payload: number }) => {
            state.value = payload;
            setUserSimbolCounter(payload);
        },
    },
});

export const { setCounter } = userCounterSimbolRulesState.actions;

export default userCounterSimbolRulesState;
