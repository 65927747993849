import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import { Tcard, Tcolor } from '.';
import useTheme from '@js/hooks/useTheme';

export default (
    typeColor: Tcolor,
    typeCard: Tcard,
    titleColor?: string,
    textColor?: string,
    numberColor?: string,
    numberCenter?: boolean,
    borderBottomNone?: boolean,
) => {
    const size = useWindowSizeCheck();
    const { buttonBlue, liteBlue, main__previewBackground, whiteText, liteGray, blackIcon } =
        useTheme();
    return {
        Container: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: '2em',
            padding: '0 0 1.8em',
            borderBottom: borderBottomNone
                ? ''
                : `1px solid ${typeColor == 'white' ? liteBlue : liteGray}`,
            width: '33.75em',
            position: 'relative',
            paddingTop: '0.7em',

            color: typeColor == 'white' ? main__previewBackground : whiteText,

            '&.active': {
                '& .title': { color: blackIcon },
                '& .iconNum': { color: buttonBlue, border: `1px solid ${buttonBlue}` },
            },
        }),
        Left: styled.div({
            width: '3em',
            minWidth: '3em',
        }),
        LeftMainBox: styled.div({
            display: 'flex',
            alignItems: typeCard == 'arrow' ? 'center' : numberCenter ? 'center' : 'start',
            position: 'absolute',
            height: '100%',
            top: 0,
            left: 0,
        }),
        Right: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '0.8em',
        }),
        Icon: styled.div({
            fontSize: '2em',
            color: buttonBlue,
        }),
        IconNum: styled.div({
            borderRadius: '50%',
            border: `1px solid ${numberColor ? numberColor : liteGray}`,
            color: numberColor ? numberColor : '',
            width: '2em',
            height: '2em',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            fontSize: '1.5em',
            fontWeight: '700',
        }),
        Title: styled.div({
            fontSize: '1.5em',
            fontWeight: 700,
            color: titleColor ? titleColor : '',
        }),
        Text: styled.div({
            fontSize: '1.125em',
            width: typeColor == 'dark' ? (size.isMobile || size.isTab ? '100%' : '86%') : '100%',
            lineHeight: '1.5em',
            color: textColor ? textColor : '',
        }),
        size,
    };
};
