import React, { createContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './header/index';
import Footer from './footer';
import { TlayoutContext } from '@js/types/context/layout';
import useWindowBlur from '@js/hooks/useWindowBlur';
import useAppDispatch from '@js/hooks/useAppDispatch';
import { setActivePopap } from '@js/state/openPopap/openPopapState';
import useExelParce from '@js/hooks/useExelParce';
import LangChecker from './langChecker';

import '@coreui/coreui/dist/css/coreui.min.css';
import TokenCheck, { createLocationState } from './tokenCheck';
import AccessCheck from './accessCheck';
import AppSidebar from './sidebar/AppSidebar';
import { sidebarFixedPath } from '@maket/js/constants/values';
import CookieConfirm from '../middleComponents/cookieConfirm';
import SidebarLinterUpdate from './sidebarLinterUpdate';
import LoaderTopRight from './loaderTopRight';
import GlobalBlur from './globalBlur';
import SaveCheck from './saveCheck';

export const LayoutContext = createContext<TlayoutContext>({});

export default () => {
    const location = useLocation();
    const [globalBlur, setGlobalBlur] = useState(true);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        if (location.hash.includes('registration_confirm')) {
            navigate('/app/confirmMail', {
                state: { code: location.search.split('code=')[1].split('&')[0] },
            });
        }
        if (location.hash.includes('change_password_confirm')) {
            navigate('/app/confirmFogotPass', {
                state: { code: location.search.split('code=')[1].split('&')[0] },
            });
        }
        if (location.hash.includes('change_email_confirm')) {
            navigate('/app/confirmChangeEmail', {
                state: { code: location.search.split('code=')[1].split('&')[0] },
            });
        }
        if (location.hash.includes('assign_tg_code')) {
            // @ts-ignore: Unreachable code error
            const state = createLocationState(location);
            navigate('/app/auth/auth', { state });
        }
    });

    useEffect(() => {
        useExelParce(dispatch);

        const windowClick = (e: any) => {
            const isPopapEl = e.target.closest('[data-popap]');
            const isPopap = isPopapEl?.dataset.popap == 'true';

            if (!isPopap) {
                dispatch(setActivePopap(''));
            }
        };
        window.addEventListener('click', windowClick);

        setTimeout(() => {
            setGlobalBlur(false);
        }, 1000);
        return () => {
            window.removeEventListener('click', windowClick);
        };
    }, []);

    return (
        <LayoutContext.Provider value={'val'}>
            {location.pathname.includes('auth') ||
            location.pathname.includes('termsOfUse') ||
            location.pathname.includes('fogotPass') ? (
                <></>
            ) : (
                <>
                    <Header />
                    {checkSidebarActive() && <AppSidebar position="fixed"></AppSidebar>}
                    <div
                        style={{ height: '3.40em', minHeight: '3.40em', maxHeight: '3.4em' }}
                    ></div>
                </>
            )}

            {/* {'Отслеживание различных событий'} */}
            <LangChecker />
            <TokenCheck />
            <AccessCheck />
            <CookieConfirm />
            <SidebarLinterUpdate />
            {/* {'Конец отслеживания различных событий'} */}

            <SaveCheck />
            {globalBlur && <GlobalBlur></GlobalBlur>}

            <LoaderTopRight />

            <Outlet />
            <Footer />
        </LayoutContext.Provider>
    );
};

export function checkSidebarActive() {
    return sidebarFixedPath.includes(location.pathname);
}
