import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    return {
        Bottom: styled.div({
            padding: size.isMobile || size.isTab ? '0 2em 6em' : '0 4em 3.75em',
            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile ? '6em' : '3em',
            fontSize: size.isMobile || size.isTab ? '0.935em' : '',
        }),
        BottomTop: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile || size.isTab ? '2.5em' : '0.75em',
        }),
        BottomTopTitle: styled.div({
            fontSize: size.isMobile || size.isTab ? '3em' : '1.5em',
            fontWeight: 700,
        }),
        BottomTopText: styled.div({
            fontSize: size.isMicroMobile ? '1.5em' : size.isMobile || size.isTab ? '2em' : '',
        }),
        BottomBottom: styled.div({
            display: 'flex',
            fontSize: size.isMobile || size.isTab ? '2em' : '',
            justifyContent: 'space-between',
            flexDirection: size.isMobile || size.isTab ? 'column' : 'row',
            gap: size.isMobile ? '2em' : '',
        }),
        size,
    };
};
