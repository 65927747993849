import React, { useEffect } from 'react';
import style from './style';
import LogoBox from '../../mainComponents/header/components/logoBox';
import useWindowSizeCheck from '@maket/js/hooks/useWindowSizeCheck';
import BackButton from '../../microComponets/backButton';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useTheme from '@maket/js/hooks/useTheme';

import htmlFilesConfig from '@maket/public/htmlFilesConfig.json';

export default () => {
    const { Container, Main, Title, Text, List, ListItem, ListItemNumber, ListItemText } = style();
    const size = useWindowSizeCheck();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const text = activeLanguage['Terms.Text']?.split('//');
    let language = useAppSelector(state => state.languageState.language);

    const { authBackground } = useTheme();
    useEffect(() => {
        document.body.setAttribute('style', `background-color: ${authBackground}`);
    });

    let split = '';
    let splitTitle = '';
    if (htmlFilesConfig) {
        htmlFilesConfig.langList.includes(language) ? language : (language = 'EN');
        if (htmlFilesConfig[language]) {
            split = htmlFilesConfig[language]['files']['_Privacy Policy.html'].data;
            splitTitle = htmlFilesConfig[language]['files']['_Privacy Policy.html'].dataTitle;
        }
    }

    return (
        <Container>
            <LogoBox style={{ fontSize: size.isMobile ? '1.2em' : '1.8em' }} fullVersion />
            <Main>
                <BackButton />
                <Title>{splitTitle}</Title>
                <div dangerouslySetInnerHTML={{ __html: split }}></div>
            </Main>
        </Container>
    );
};
