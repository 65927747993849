import React from 'react';
import style from './style';
import {
    deleteExeptionRulesState,
    setExeptionRulesItemState,
} from '@maket/js/state/banAndMuteRules';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import BanRulesItem from './exeptionRulesItem';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';

export default ({ checkBan }: { checkBan: boolean }) => {
    const { Container } = style(checkBan);

    return (
        <Container>
            <BanList />
        </Container>
    );
};

function BanList() {
    const exeption = useAppSelector(state => state.banAndMuteRulesState.exeptionState.rules);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    if (exeption.length == 0) {
        return <>{activeLanguage['ChatRules.BanExeption.Text']}</>;
    }
    return (
        <>
            {exeption.map((el, i) => (
                <CreateBanRulesItem i={i} text={el.text} key={el.text + i} />
            ))}
        </>
    );
}

function CreateBanRulesItem({ i, text }: { i: number; text: string }) {
    const switchState = useAppSelector(
        state => state.banAndMuteRulesState.exeptionState.rules[i].enable,
    );
    const dispatch = useAppDispatch();

    return (
        <BanRulesItem
            initState={switchState}
            setState={(v: boolean) => {
                dispatch(setExeptionRulesItemState({ index: i, value: v }));
            }}
            deleteFn={() => {
                dispatch(deleteExeptionRulesState({ index: i }));
            }}
            text={text}
        />
    );
}
