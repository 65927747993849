import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';
import { motion } from 'framer-motion';

export default () => {
    const size = useWindowSizeCheck();
    const { whiteText, liteGreen, blackIcon } = useTheme();
    return {
        BottomItem: styled.div({
            flex: size.isMobile || size.isTab ? '0 0 100%' : '0 0 24%',
            width: '100%',

            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
            backgroundColor: whiteText,
            borderRadius: 4,
            padding: '1em',

            height: '100%',
            position: 'relative',

            '&.active': {
                backgroundColor: liteGreen,
                color: whiteText,
                '& .BottomItemTitleBotTag': {
                    padding: '0.5em',
                    '& .Underline': {
                        opacity: 1,
                    },
                },
            },
        }),
        BottomItemTitle: styled.div({ fontSize: '1.25em', fontWeight: 700 }),
        BottomItemText: styled.div({
            fontSize: '0.81em',
        }),
        BottomItemTitleBotTag: styled.div({
            display: 'inline-flex',
            alignItems: 'center',
            gap: '0.25em',
            color: liteGreen,
            backgroundColor: whiteText,
            padding: '0.5em 0',

            borderRadius: 6,
            fontWeight: 700,
            position: 'relative',
            marginBottom: '0.25em',

            cursor: 'pointer',

            fontSize: size.isLaptop ? '0.7em' : '0.9em',
        }),
        Underline: styled.div({
            position: 'absolute',
            width: '80%',
            bottom: '0.5em',
            left: '0.5em',
            backgroundColor: liteGreen,
            height: 1,
            opacity: 0,
        }),
        Icon: styled.div({
            fontSize: '1.25em',
        }),
        CopyAllert: styled(motion.div)({
            position: 'absolute',
            color: whiteText,
            top: 0,
            left: '50%',
            transform: 'translate(-50%, -50%)',
            whiteSpace: 'nowrap',
            backgroundColor: blackIcon,
            fontSize: '0.8em',

            display: 'flex',
            gap: '0.5em',
            alignItems: 'center',

            border: `1px solid ${whiteText}`,
            padding: '0.5em',
            borderRadius: '24px',
        }),
        size,
    };
};
