import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { TserviseNames } from '@maket/js/types/state/serviseList';
import axios from 'axios';

type Tprops = {
    token: string;
    serviceType: TserviseNames;
};

export default async ({ serviceType, token }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post<{
            service_type: TserviseNames;
            bind_url: string;
            bind_user_id: number;
        }>(
            apiUrl + `/service/${serviceType}/get_bind_url`,
            {},
            { headers: { Authorization: `Bearer ${token}` } },
        );
        setVisableLoader('notVisable');

        if (req.data.bind_url) {
            window.open(req.data.bind_url, '_self');
        }
        return req.data;
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
