import React from 'react';
import style from './style';

export type Tbutton =
    | 'blue'
    | 'white'
    | 'black'
    | 'transparent'
    | 'allWhite'
    | 'gray'
    | 'secondary'
    | 'warning'
    | 'green';

interface Tprops extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    typeButt: Tbutton;
    children: any;
    borderColor?: string;
}

export default ({ typeButt, children, borderColor, ...props }: Tprops) => {
    const { Button } = style(typeButt, borderColor);
    return <Button {...props}>{children}</Button>;
};
