import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    return {
        Container: styled.div({
            display: 'flex',
            gap: size.isMobile || size.isTab ? '2em' : '10%',

            marginTop: size.isMobile || size.isTab ? '2em' : '4em',
            fontFamily: 'Inter, sans-serif',

            flexDirection: size.isMobile || size.isTab ? 'column' : 'row',
        }),
        Left: styled.div({
            width: size.isMobile || size.isTab ? '100%' : '40%',
            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
            height: '100%',
        }),
        Right: styled.div({
            width: size.isMobile || size.isTab ? '100%' : '40%',
            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
            height: '100%',
        }),

        TextBox: styled.div({ display: 'flex', flexDirection: 'column', gap: '1em' }),
        Title: styled.div({
            fontSize: '1.125em',
            fontWeight: 700,
        }),
        Text: styled.div({ fontSize: '0.78em' }),

        PropgressBarBox: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5em',
        }),
        PropgressBarItem: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: '2em',
            justifyContent: 'space-between',
        }),
        ProgressBarTitle: styled.div({ width: '50%' }),
        ProgressBarValue: styled.div({ width: '3em', maxWidth: '3em' }),
        ProgressBarWrapper: styled.div({
            width: '50%',
            display: 'flex',
            gap: '1em',
            alignItems: 'center',
        }),
        ProgressBar: styled.div({ width: '100%' }),
        size,
    };
};
