import React, { useEffect } from 'react';
import style from './style';
import { CFormSelect } from '@coreui/react';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { setActiveRulesProfileState } from '@maket/js/state/banAndMuteRules';
import { useNavigate, useParams } from 'react-router-dom';

type Tprops = { title: string; pageName: 'chatList' | 'profileSettings' | 'ChatRules' };

export default ({ title, pageName }: Tprops) => {
    const { size } = style();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const profiles = useAppSelector(state => state.authState.user);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const rulesProfile = useAppSelector(state => state.banAndMuteRulesState.rulesProfile);

    const { idProfile } = useParams();

    useEffect(() => {
        if (rulesProfile == null && profiles?.profiles) {
            dispatch(setActiveRulesProfileState(`${profiles.profiles[0]?.id}`));
        }
    }, [profiles]);

    useEffect(() => {
        if (idProfile != undefined) {
            dispatch(setActiveRulesProfileState(idProfile));
        } else {
            dispatch(setActiveRulesProfileState(0));
        }
    }, [idProfile]);

    if (profiles) {
        return (
            <div
                style={{
                    width: size.isMobile || size.isLaptop || size.isTab ? '100%' : '50%',
                    display: 'flex',
                    gap: '1em',
                    alignItems: size.isMobile ? 'start' : 'center',
                    flexDirection: size.isMobile ? 'column' : 'row',
                }}
            >
                <div style={{ minWidth: '5em', fontSize: '2em', fontWeight: 700 }}>{title}</div>

                <CFormSelect
                    style={{ maxWidth: '100%' }}
                    value={rulesProfile != undefined ? rulesProfile : profiles.profiles[0]?.id}
                    onChange={e => {
                        navigate(`/app/${pageName}/${e.target.value}`);
                    }}
                    options={
                        idProfile
                            ? [
                                  ...profiles.profiles.map(pr => ({
                                      label: `${
                                          pr.name || activeLanguage['ProfileList.Name.Init']
                                      }`,
                                      value: `${pr.id}`,
                                  })),
                              ]
                            : [
                                  { label: activeLanguage['All.'], value: '0' },
                                  ...profiles.profiles.map(pr => ({
                                      label: `${
                                          pr.name || activeLanguage['ProfileList.Name.Init']
                                      }`,
                                      value: `${pr.id}`,
                                  })),
                              ]
                    }
                />
            </div>
        );
    }
    return <></>;
};
