import React, { memo, useEffect, useState } from 'react';
import style from './style';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import editProfile from '@maket/js/api/requiresToken/profile/editProfile';
import { Tprofile } from '@maket/js/types/state/auth';
import getProfile from '@maket/js/api/requiresToken/payments/getProfile';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import deleteProfile from '@maket/js/api/requiresToken/profile/deleteProfile';
import ButtonAllert, { TalertState } from '@maket/js/components/microComponets/buttonAllert';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { setState } from '@maket/js/state/profilePopap';
import Button from '@maket/js/components/microComponets/button';
import { setBlockedPopap } from '@maket/js/state/savedForms';
import {
    clearProfileSettings,
    setHistoryProfile,
} from '@maket/js/state/settingProfile/settingPassValueState';
import { setHistoryProfileAuth } from '@maket/js/state/auth/authState';

type Tprops = {
    type: 'add' | 'delete';
    allertCheck: TalertState;
    setallertCheck: React.Dispatch<React.SetStateAction<TalertState>>;
};

export default memo(({ type, setallertCheck, allertCheck }: Tprops) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { size } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const token = useAppSelector(state => state.tokenState.token);
    const settingProfileValueState = useAppSelector(state => state.settingProfileValueState);

    const { idProfile } = useParams();

    const profiles = useAppSelector(state => state.authState.user?.profiles) || [];
    const settingProfile = profiles.find(el => (idProfile ? +el.id == +idProfile : false));

    const [notClicked, setNotClicked] = useState(true);

    useEffect(() => {
        if (idProfile) {
            const activeProfile = profiles?.find(el => +el.id == +idProfile);

            const check =
                activeProfile?.name != settingProfileValueState.name ||
                (activeProfile.status == 'active'
                    ? settingProfileValueState.active == false
                    : settingProfileValueState.active == true) ||
                activeProfile.language != settingProfileValueState.lang ||
                activeProfile.moderation != settingProfileValueState.moder ||
                activeProfile.active_protection != settingProfileValueState.protection ||
                activeProfile.delete_forwarded_story !=
                    settingProfileValueState.delete_forwarded_story ||
                activeProfile.delete_anonymous_messages !=
                    settingProfileValueState.delete_anonymous_messages;

            setTimeout(() => {
                if (check) {
                    if (allertCheck.textAllert) {
                        setTimeout(() => {
                            setNotClicked(false);
                        }, 1000);
                    } else {
                        setNotClicked(false);
                    }
                } else {
                    if (allertCheck.textAllert) {
                        setTimeout(() => {
                            setNotClicked(true);
                        }, 1000);
                    } else {
                        setNotClicked(true);
                    }
                }
            }, 500);
        }
    }, [settingProfileValueState]);

    if (type == 'delete') {
        return (
            <Button
                onClick={() => {
                    if (settingProfile) {
                        // deleteProfile({ token, profile_id: settingProfile.id })
                        //     .then(res => {
                        //         if (res.data.errors[0]) {
                        //             setallertCheck({
                        //                 typeAllert: 'danger',
                        //                 textAllert: res.data.errors.includes('last_profile_delete')
                        //                     ? 'ProfileSettings.Alert.LastProfileDelete'
                        //                     : activeLanguage[''],
                        //             });
                        //         } else {
                        //             navigate('/app/profileList');
                        //             getProfile({ dispatch, token });
                        //         }
                        //     })

                        //     .catch(err => {});
                        dispatch(setState(true));
                    }
                }}
                style={{ width: size.isMobile || size.isTab ? '100%' : '10em' }}
                typeButt={type == 'delete' ? 'warning' : 'black'}
                disabled={undefined}
            >
                {activeLanguage['Delete.']}
            </Button>
        );
    }

    useBlocker(n => {
        if (!notClicked) {
            const historyState = { ...settingProfileValueState };
            dispatch(
                setBlockedPopap({
                    state: true,
                    resolveFn: () => {
                        buttonClickFn();

                        // dispatch(clearChange());
                        setTimeout(() => {
                            navigate(n.nextLocation.pathname);
                        }, 1000);

                        dispatch(
                            setBlockedPopap({
                                state: false,
                                rejectFn: () => {},
                                resolveFn: () => {},
                            }),
                        );
                    },
                    rejectFn: () => {
                        setTimeout(() => {
                            navigate(n.nextLocation.pathname);

                            setTimeout(() => {
                                dispatch(clearProfileSettings());
                            }, 300);
                        }, 1000);

                        dispatch(
                            setBlockedPopap({
                                state: false,
                                rejectFn: () => {},
                                resolveFn: () => {},
                            }),
                        );

                        return false;
                    },
                }),
            );
            dispatch(setHistoryProfile(historyState));
            dispatch(setHistoryProfileAuth({ historyState, idProfile }));
            return true;
        } else {
            return false;
        }
    });

    function buttonClickFn() {
        if (
            settingProfile &&
            settingProfileValueState.active != null &&
            settingProfileValueState.name != null &&
            settingProfileValueState.name != '' &&
            settingProfileValueState.moder != null &&
            settingProfileValueState.protection != null &&
            settingProfileValueState.lang != null &&
            settingProfileValueState.delete_forwarded_story != null &&
            settingProfileValueState.delete_anonymous_messages != null
        ) {
            editProfile({
                token,
                profile_id: settingProfile.id,
                name: settingProfileValueState.name,
                language: settingProfileValueState.lang,
                is_active: settingProfileValueState.active,
                moderation: settingProfileValueState.moder,
                is_active_protection: settingProfileValueState.protection,
                delete_forwarded_story: settingProfileValueState.delete_forwarded_story,
                delete_anonymous_messages: settingProfileValueState.delete_anonymous_messages,
            })
                .then(res => {
                    if (res.response && res.response.status != 200) {
                        setallertCheck({
                            typeAllert: 'danger',
                            textAllert: 'ProfileSettings.Alert.NotSend',
                        });
                    } else if (res.data?.errors || res.data.error) {
                        if (res.data.error.includes('active_profile_limit_reached')) {
                            setallertCheck({
                                typeAllert: 'danger',
                                textAllert: 'ProfileSettings.Alert.LimitProfiles',
                            });
                        } else {
                            setallertCheck({
                                typeAllert: 'danger',
                                textAllert: 'ProfileSettings.Alert.NotSend',
                            });
                        }
                    } else {
                        setallertCheck({
                            typeAllert: 'success',
                            textAllert: 'MyData.Update.Success',
                        });
                    }

                    setTimeout(() => {
                        getProfile({ token, dispatch });
                    }, 1500);
                })
                .catch(err => {
                    setallertCheck({
                        typeAllert: 'danger',
                        textAllert: 'ProfileSettings.Alert.NotSend',
                    });
                });
        } else {
            if (settingProfileValueState.name == '') {
                setallertCheck({
                    typeAllert: 'danger',
                    textAllert: 'ProfileSettings.Alert.LengthNameError',
                });
            } else {
                setallertCheck({
                    typeAllert: 'danger',
                    textAllert: 'ProfileSettings.Alert.NotSend',
                });
            }
        }
    }

    return (
        <ButtonAllert
            onClick={() => {
                const loader = document.getElementById('loader');
                const check = loader?.className.includes('opacityNone');
                if (check) {
                    buttonClickFn();
                }
            }}
            style={{ width: size.isMobile || size.isTab ? '100%' : '10em' }}
            typeButt={type == 'delete' ? 'warning' : 'black'}
            buttonContent={type == 'delete' ? activeLanguage['Delete.'] : activeLanguage['Save.']}
            stateAllertOuter={allertCheck}
            setAllertFn={setallertCheck}
            styleAlert={{
                // @ts-ignore: Unreachable code error
                width: `calc(${
                    size.isMobile || size.isTab ? '98vw' : size.isLaptop ? '40vw' : '37.5vw'
                } - ${size.isMobile ? '0' : size.isTab ? '3.5em' : '10em'})`,
                left: `calc(
					(${size.isMobile || size.isTab ? '98vw' : size.isLaptop ? '40vw' : '37.5vw'} 
					+ ${size.isMobile || size.isTab ? '0' : '12em'}
				)/2)`,
                bottom: size.isMobile || size.isTab ? '' : 'calc(130%)',
            }}
            disabled={type == 'add' ? notClicked : undefined}
        ></ButtonAllert>
    );
});
