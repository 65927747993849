import { apiUrl } from '@js/constants/values';
import { TappDispatch } from '@maket/js/state/store';
import { Ttariff } from '@maket/js/types/state/auth';
import { TserviseNames } from '@maket/js/types/state/serviseList';
import axios from 'axios';
import getTarifState from './getTarifState';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';

type Tprops = {
    token: string;
    serviceType: TserviseNames;
    dispatch: TappDispatch;
};

export default async ({ serviceType, dispatch, token }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post<Ttariff>(
            apiUrl + '/service/subscribe/cancel',
            {
                service_type: serviceType,
            },
            { headers: { Authorization: `Bearer ${token}` } },
        );
        setVisableLoader('notVisable');
        getTarifState({ dispatch, token, serviceType: 'telegram' });
        return req.data;
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
