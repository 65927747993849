import Popap from '@maket/js/components/middleComponents/popap';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { setBlockedPopap, setOnlyStateBlockedPopap } from '@maket/js/state/savedForms';

import React, { useEffect } from 'react';

export default () => {
    const { state, rejectFn, resolveFn } = useAppSelector(state => state.savedFormsState);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const dispatch = useAppDispatch();

    const token = useAppSelector(state => state.tokenState.token);

    useEffect(() => {
        if (!token) {
            rejectFn();
        }
    }, [token, state, rejectFn, resolveFn]);

    return (
        <Popap
            setState={v => dispatch(setOnlyStateBlockedPopap(false))}
            state={state}
            allControllSetFunction={() => {
                resolveFn();
            }}
            allControllEndFunction={() => {
                rejectFn();
            }}
            title={activeLanguage['SavedForm.Title']}
            main={''}
            buttonText={activeLanguage['Yes.']}
            closeText={activeLanguage['No.']}
            buttonStyles={{ width: '100%' }}
            typeMainButt="green"
        />
    );
};
