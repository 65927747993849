import confirmAuth from '@maket/js/api/auth/confirmAuth';
import confirmChangeEmail from '@maket/js/api/auth/confirmChangeEmail';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const state = location.state;

    useEffect(() => {
        if (state && state.code) {
            confirmAuth({ code: state.code, dispatch }).then(rez => {
                if (rez == 'error') {
                    navigate('/app/auth/reg', { state: { confirm: rez } });
                }
                if (rez == 'ok') {
                    navigate('/app/auth/auth', { state: { confirm: rez } });
                }
            });
        }
    });
    return (
        <div
            style={{
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                backgroundColor: '#fff',
                top: 0,
                left: 0,
            }}
        ></div>
    );
};
