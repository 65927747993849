import { createSlice } from '@reduxjs/toolkit';

const initialState: {
    state: boolean;
    inputValue: string;
} = {
    state: true,
    inputValue: '',
};

const banAddItemSwithState = createSlice({
    name: 'banAddItemSwithState',
    initialState,
    reducers: {
        setSwithState: (state, { payload }: { payload: boolean }) => {
            state.state = payload;
        },
        setInputState: (state, { payload }: { payload: string }) => {
            state.inputValue = payload;
        },
    },
});

export const { setSwithState, setInputState } = banAddItemSwithState.actions;

export default banAddItemSwithState;
