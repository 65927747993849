import React, { useEffect, useRef } from 'react';
import style from './style';
import { CBadge, CFormSelect, CTable } from '@coreui/react';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { TactiveLanguage } from '@maket/js/types/state/language';
import { Tlogger, Tprofile } from '@maket/js/types/state/auth';
import ButtonBox from './buttonBox';
import deleteProfile from '@maket/js/api/requiresToken/profile/deleteProfile';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { TappDispatch } from '@maket/js/state/store';
import getProfile from '@maket/js/api/requiresToken/payments/getProfile';
import deleteLogger from '@maket/js/api/requiresToken/logger/deleteLogger';
import setLogger from '@maket/js/api/requiresToken/logger/setLogger';
import { TwindowSize } from '@maket/js/hooks/useWindowSizeCheck';
import MobileTable from './mobileTable';
import Items from './items';
import { useParams } from 'react-router-dom';

export default () => {
    const dispatch = useAppDispatch();
    const {
        Main,
        OneCell,
        OneCellMain,
        OneCellText,
        ThreeCell,
        TwoCell,
        OneCellIcon,
        FiveCell,
        FourCell,
        size,
    } = style();

    const { idProfile } = useParams();

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const language = useAppSelector(state => state.languageState.language);
    const profiles = useAppSelector(state => state.authState.user?.profiles);
    const token = useAppSelector(state => state.tokenState.token);
    const loggerList = useAppSelector(state => state.authState.logerList);

    const columns = createColumns(activeLanguage, size);
    const rows = createRows(
        dispatch,
        language,
        token,
        loggerList,
        profiles,
        activeLanguage,
        OneCell,
        OneCellText,
        OneCellMain,
        TwoCell,
        OneCellIcon,
        ThreeCell,
        FourCell,
        FiveCell,
    );

    const TableRef = useRef<HTMLTableElement>(null);

    useEffect(() => {
        if (TableRef.current) {
            const trS = TableRef.current.querySelectorAll('tr');
            trS.forEach(el => {
                const scrHTrs = el.scrollHeight;
                el.querySelectorAll('td').forEach(elD => {
                    elD.setAttribute('style', `height: ${scrHTrs}px`);
                });
            });
        }
    });

    return (
        <Main>
            {size.isMobile || size.isTab ? (
                <MobileTable />
            ) : (
                <CTable
                    ref={TableRef}
                    columns={columns}
                    items={rows}
                    striped
                    style={{ fontSize: size.isLaptop ? '0.875em' : '1em', maxWidth: '90vw' }}
                />
            )}
            <ButtonBox />
            {idProfile == undefined && <Items />}
        </Main>
    );
};

function createColumns(activeLanguage: TactiveLanguage, size: TwindowSize) {
    return [
        {
            key: 'col1',
            label: activeLanguage['ProfileList.Column.OneName'],
            _props: {
                scope: 'col',
                color: 'primary',
                align: 'center',
                valign: 'middle',
                style: { width: '20%', maxWidth: '10em' },
            },
        },
        {
            key: 'col2',
            label: (
                <div style={{ textAlign: size.isLaptop ? 'center' : 'start' }}>
                    {activeLanguage['ProfileList.Column.TwoName']}
                </div>
            ),
            _props: {
                scope: 'col',
                color: 'primary',
                align: 'center',
                valign: 'middle',
                style: { width: '15%' },
            },
        },
        {
            key: 'col3',
            label: (
                <div style={{ textAlign: 'center' }}>
                    {activeLanguage['ProfileList.Column.ThreeName']}
                </div>
            ),
            _props: {
                scope: 'col',
                color: 'primary',
                align: 'center',
                valign: 'middle',
                style: { width: '15%' },
            },
        },
        {
            key: 'col4',
            label: activeLanguage['ProfileList.Column.FourName'],
            _props: {
                scope: 'col',
                color: 'primary',
                align: 'center',
                valign: 'middle',
                style: { width: '10%' },
            },
        },
        {
            key: 'col5',
            label: activeLanguage['ProfileList.Column.FiveName'],
            _props: {
                scope: 'col',
                color: 'primary',
                align: 'center',
                valign: 'middle',
                style: { width: '10%' },
            },
        },
    ];
}

function createRows(
    dispatch: TappDispatch,
    language: string,
    token: string,
    loggerList: Array<Tlogger>,
    profiles: Tprofile[] | undefined,
    activeLanguage: TactiveLanguage,
    OneCell: any,
    OneCellText: any,
    OneCellMain: any,
    TwoCell: any,
    OneCellIcon: any,
    ThreeCell: any,
    FourCell: any,
    FiveCell: any,
) {
    const rows: Array<{}> = [];

    profiles?.map(el => {
        rows.push({
            col1: (
                <OneCell to={`/app/chatList/${el.id}`}>
                    <OneCellMain data-onecell>
                        <OneCellText className="title">
                            {el.name != '' ? el.name : activeLanguage['ProfileList.Name.Init']}
                        </OneCellText>
                    </OneCellMain>
                </OneCell>
            ),
            col2: (
                <TwoCell
                    onClick={() => {
                        if (!el.logger_channel_name) {
                        }
                    }}
                >
                    <CFormSelect
                        defaultValue={
                            el.logger_channel_id
                                ? el.logger_channel_id
                                : loggerList.length == 0
                                ? el.logger_channel_id
                                    ? el.logger_channel_id
                                    : ''
                                : 'add'
                        }
                        aria-label="Default select example"
                        onChange={(e: any) => {
                            if (e.target.value == 'null') {
                                deleteLogger({ dispatch, token, profile_id: el.id }).then(res =>
                                    getProfile({ token, dispatch }),
                                );
                            } else {
                                setLogger({
                                    dispatch,
                                    token,
                                    profile_id: el.id,
                                    logger_channel_id: e.target.value,
                                }).then(res => getProfile({ token, dispatch }));
                            }
                        }}
                        options={
                            !el.logger_channel_id
                                ? [
                                      {
                                          label: activeLanguage['Add.'],
                                          value: 'add',
                                          disabled: true,
                                      },
                                      ...loggerList.map(loger => {
                                          return {
                                              label: loger.channel_name,
                                              value: `${loger.channel_id}`,
                                          };
                                      }),
                                  ]
                                : loggerList.length == 0
                                ? [{ label: el.logger_channel_name, value: el.logger_channel_id }]
                                : [
                                      ...loggerList.map(loger => {
                                          return {
                                              label: loger.channel_name,
                                              value: `${loger.channel_id}`,
                                          };
                                      }),
                                      { label: activeLanguage['Delete.'], value: 'null' },
                                  ]
                        }
                        disabled={loggerList.length == 0}
                    />
                </TwoCell>
            ),
            col3: <ThreeCell>{el.create_dt.split('T')[0].split('-').join('/')}</ThreeCell>,
            col4: <FourCell>{el.chats}</FourCell>,
            col5: (
                <FiveCell>
                    <CBadge color={el.status == 'active' ? 'success' : 'danger'}>
                        {el.status}
                    </CBadge>
                </FiveCell>
            ),
            _cellProps: {
                class: { scope: 'row' },
                align: 'center',
                valign: 'middle',
            },
        });
    });

    return rows;
}
