import getProfile from '@maket/js/api/requiresToken/payments/getProfile';
import deleteProfile from '@maket/js/api/requiresToken/profile/deleteProfile';
import { TalertState } from '@maket/js/components/microComponets/buttonAllert';
import Popap from '@maket/js/components/middleComponents/popap';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { setState } from '@maket/js/state/profilePopap';
import { Tprofile } from '@maket/js/types/state/auth';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default ({
    allertCheck,
    setallertCheck,
}: {
    allertCheck: TalertState;
    setallertCheck: React.Dispatch<React.SetStateAction<TalertState>>;
}) => {
    const dispatch = useAppDispatch();
    const { idProfile } = useParams();
    const profiles = useAppSelector(state => state.authState.user?.profiles) || [];
    const settingProfile = profiles.find(el => (idProfile ? +el.id == +idProfile : false));
    const navigate = useNavigate();
    const popapState = useAppSelector(state => state.profilePopapState.state);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const profileName = profiles.find(el => +el.id == +idProfile)?.name;

    const token = useAppSelector(state => state.tokenState.token);
    return (
        <Popap
            setState={v => dispatch(setState(v))}
            state={popapState}
            setFunction={() => {
                if (settingProfile) {
                    deleteProfile({ token, profile_id: settingProfile.id })
                        .then(res => {
                            if (res.data.errors[0]) {
                                setallertCheck({
                                    typeAllert: 'danger',
                                    textAllert: res.data.errors.includes('last_profile_delete')
                                        ? 'ProfileSettings.Alert.LastProfileDelete'
                                        : activeLanguage[''],
                                });
                            } else {
                                navigate('/app/profileList');
                                getProfile({ dispatch, token });
                            }
                        })

                        .catch(err => {});
                }
            }}
            title={`${activeLanguage['ProfileSettings.AllertDeleteProfile']?.slice(0, -1)}${
                profileName ? ': ' + profileName : ''
            }?`}
            main={''}
            buttonText={activeLanguage['Agree.']}
            closeText={activeLanguage['Back.']}
            buttonStyles={{ width: '100%' }}
            typeMainButt="warning"
        />
    );
};
