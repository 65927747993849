import React, { useEffect, useRef } from 'react';
import style from './style';
import Button from '@maket/js/components/microComponets/button';
import BottomItem from './bottomItem';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { Taccount, Tchat, TserviceListItem } from '@maket/js/types/state/auth';

type Tprops = {};

const bottomItems = [
    { title: 'ChatList.Card.OneTitle', text: 'ChatList.Card.OneText' },
    { title: 'ChatList.Card.TwoTitle', text: 'ChatList.Card.TwoText' },
    { title: 'ChatList.Card.ThreeTitle', text: 'ChatList.Card.ThreeText' },
    { title: 'ChatList.Card.FiveTitle', text: 'ChatList.Card.FiveText' },
];

export default ({}: Tprops) => {
    const { Bottom, size } = style();
    const bottomRef = useRef<HTMLDivElement>(null);
    const activeProfileChats = useAppSelector(state => state.authState.activeProfileChats);
    const serviceList = useAppSelector(state => state.authState.serviceList);
    const account = useAppSelector(state => state.authState.account);

    useEffect(() => {
        if (bottomRef.current && !(size.isMobile || size.isTab || size.isLaptop)) {
            // @ts-ignore: Unreachable code error
            let maxHeightChildren = bottomRef.current.firstChild?.scrollHeight;
            // @ts-ignore: Unreachable code error
            if (bottomRef.current.children) {
                [...bottomRef.current.children]?.forEach(el => {
                    if (el.scrollHeight > maxHeightChildren) {
                        maxHeightChildren = el.scrollHeight;
                    }
                });
            }

            bottomRef.current.setAttribute('style', `height: ${maxHeightChildren}px;`);
        }
    });

    return (
        <Bottom ref={bottomRef}>
            {bottomItems.map((el, i) => (
                <BottomItem
                    key={el.title}
                    title={el.title}
                    text={el.text}
                    active={getActiveItem(activeProfileChats, serviceList, i, account)}
                />
            ))}
        </Bottom>
    );
};

function getActiveItem(
    activeProfileChats: Tchat[],
    serviceList: TserviceListItem[],
    i: number,
    account: Taccount,
) {
    if (i == 0 && serviceList[0]?.service_user_id) {
        return true;
    }
    //
    let checkOnceAdminChat: boolean = false;
    activeProfileChats.forEach(el => {
        if (el.bot_status != 'not_in_chat' && (account?.username || el.owner_name == null)) {
            checkOnceAdminChat = true;
        }
    });
    if (i == 1 && checkOnceAdminChat) {
        return true;
    }
    //
    let checkUserChat: boolean = false;
    activeProfileChats.forEach(el => {
        if (
            (el.owner_name == account?.username || el.owner_name == null) &&
            el.bot_status == 'connected'
        ) {
            checkUserChat = true;
        }
    });
    if (i == 2 && checkUserChat) {
        return true;
    }
    //
    let checkOnceActiveChat: boolean = false;
    activeProfileChats.forEach(el => {
        if (el.active && (el.owner_name == account?.username || el.owner_name == null)) {
            checkOnceActiveChat = true;
        }
    });
    // @ts-ignore: Unreachable code error
    if (i == 3 && checkOnceActiveChat == true) {
        return true;
    }

    return false;
}
