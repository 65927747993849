import React, { useEffect, lazy, Suspense } from 'react';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import { createLocationState } from './tokenCheck';
import Layout from './layout';
import MainRoute from '../routes/mainRoute';
import Auth from '../routes/auth';
import ConfirmMail from '../routes/confirmMail';
import Price from '../routes/price';
import TermsOfUse from '../routes/termsOfUse';
import ConnectTgAccount from '../routes/connectTgAccount';
import ConnectedAccount from '../routes/connectedAccount';
import ChatList from '../routes/chatList';
import Safety from '../routes/safety';
import MyData from '../routes/myData';
import ChatRules from '../routes/chatRules';
import ProfileList from '../routes/profileList';
import Dashboard from '../routes/dashboard';
import FogotPass from '../routes/fogotPass';
import ConfirmFogotPass from '../routes/confirmFogotPass';
import ProfileSettings from '../routes/profileSettings';
import ConfirmChangeEmail from '../routes/confirmChangeEmail';
import PrivacyPolicy from '../routes/privacyPolicy';
import CookiePolicy from '../routes/cookiePolicy';
import RefundPolicy from '../routes/refundPolicy';

const Documentation = lazy(() => import('../routes/documentation'));

export default () => {
    return (
        <Route path="/" element={<Layout />}>
            <Route index element={<MainRoute />} />
            <Route path="app/auth/auth" element={<Auth type="auth" />} />
            <Route path="app/auth/success" element={<Auth type="auth" />} />
            <Route path="app/auth/fail" element={<Auth type="auth" />} />

            <Route path="app/registration/confirm">
                <Route index path="*" element={<Auth type="auth" />} />
            </Route>
            <Route path="app/change_password/confirm">
                <Route index path="*" element={<FogotPass />} />
            </Route>
            <Route path="app/change_email/confirm">
                <Route index path="*" element={<ConfirmChangeEmail />} />
            </Route>

            <Route path="app/auth/reg" element={<Auth type="reg" />} />
            <Route path="app/confirmMail" element={<ConfirmMail />} />
            <Route path="app/confirmChangeEmail" element={<ConfirmChangeEmail />} />

            <Route path="app/confirmFogotPass" element={<ConfirmFogotPass />} />

            <Route path="app/billing" element={<Price />} />
            <Route path="app/termsOfUse" element={<TermsOfUse />} />
            <Route path="app/connectTgAccount" element={<ConnectTgAccount />} />
            <Route path="app/connectedAccount" element={<ConnectedAccount />} />
            <Route path="app/chatList" element={<ChatList />} />
            <Route path="app/chatList/:idProfile" element={<ChatList />} />
            <Route path="app/safety" element={<Safety />} />
            <Route path="app/myData" element={<MyData />} />
            <Route path="app/chatRules" element={<ChatRules />} />
            <Route path="app/chatRules/:idProfile" element={<ChatRules />} />
            <Route path="app/profileList" element={<ProfileList />} />
            <Route path="app/profileSettings/:idProfile" element={<ProfileSettings />} />
            <Route path="app/dashboard" element={<Dashboard />} />
            <Route
                path="app/documentation/:chapter?"
                element={
                    <Suspense fallback={<>...</>}>
                        <Documentation />
                    </Suspense>
                }
            />
            <Route path="app/fogotPass" element={<FogotPass />} />
            <Route path="app/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="app/cookiePolicy" element={<CookiePolicy />} />
            <Route path="app/refundPolicy" element={<RefundPolicy />} />
        </Route>
    );
};
