import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { TappDispatch } from '@maket/js/state/store';
import { setToken } from '@maket/js/state/tokenState';
import axios from 'axios';

type Tprops = {
    code: string;
    dispatch: TappDispatch;
};

export default async ({ code, dispatch }: Tprops) => {
    setVisableLoader('visable');
    const req = await axios
        .post(`${apiUrl}/account/login-by-code`, {
            code: code,
        })
        .then(res => {
            setVisableLoader('notVisable');
            return res;
        })
        .catch(err => {
            setVisableLoader('notVisable');
            return err;
        });
    if (req.data && req.data.token) {
        dispatch(setToken(req.data.token));
    }
    setVisableLoader('notVisable');
    return req;
};
