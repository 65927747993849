import ButtonAllert, { TalertState } from '@maket/js/components/microComponets/buttonAllert';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { setErrorCancelTariff, setPopapStateOffSub } from '@maket/js/state/tariffSwith';
import React, { useEffect, useState } from 'react';
import style from './style';
import useAppSelector from '@maket/js/hooks/useAppSelector';

export default () => {
    const dispatch = useAppDispatch();
    const { size } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const errorCancel = useAppSelector(state => state.tariffState.errorCancel);
    const [allertState, setAllertState] = useState<TalertState>({ typeAllert: '', textAllert: '' });
    const current_tariff = useAppSelector(state => state.authState.tariff.current_tariff);
    const prolongation = useAppSelector(state => state.authState.tariff.prolongation);

    useEffect(() => {
        if (errorCancel != '') {
            setAllertState({ typeAllert: 'danger', textAllert: errorCancel });
        } else {
            setAllertState({ typeAllert: '', textAllert: '' });
        }
    }, [errorCancel]);

    if (!current_tariff || prolongation == false) {
        return <></>;
    }
    return (
        <ButtonAllert
            onClick={() => dispatch(setPopapStateOffSub(true))}
            stateAllertOuter={allertState}
            setAllertFn={v => dispatch(setErrorCancelTariff(v.textAllert))}
            buttonContent={activeLanguage['Main.Price.CancelSub']}
            style={{
                margin: '1em auto 0',
                width: size.isMobile || size.isTab ? '100%' : '',
                fontSize: size.isMobile || size.isTab ? '2em' : '',
            }}
            styleAlert={{ width: size.isMobile || size.isTab ? '100%' : '200%' }}
            typeButt="allWhite"
        ></ButtonAllert>
    );
};
