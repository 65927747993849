import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import { motion } from 'framer-motion';

export default () => {
    const size = useWindowSizeCheck();
    return {
        ButtonBox: styled.div({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: size.isMicroMobile ? '0.6em' : size.isMobile ? '0.8em' : '',
            position: 'relative',
        }),
        AlertBox: styled(motion.div)({
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            zIndex: 10,
        }),
        size,
    };
};
