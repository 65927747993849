import { combineReducers, configureStore } from '@reduxjs/toolkit';
import openPopapState from './openPopap/openPopapState';
import themeState from './theme/themeState';
import languageState from './language/languageState';
import activeSlide from './activeSlide/activeSlideState';
import authInputsValueState from './authInputsValue/authInputsValueState';
import tokenState from './tokenState';
import authState from './auth/authState';
import headerState from './header/authState';
import serviceListState from './serviceList/authState';
import tariffState from './tariffSwith';
import termsSwithState from './termsSwith';
import TwoFASettingSwith from './TwoFASettingSwith';
import settingPassValueState from './settingPass/settingPassValueState';
import sidebarSickyState from './sidebarSicky/sidebarSickyState';
import banAndMuteRulesState from './banAndMuteRules';
import banAddItemSwithState from './banAddItemSwith';
import muteAddItemSwithState from './muteAddItemSwith';
import userCounterSimbolRulesState from './userCounterSimbolRules/userCounterSimbolRulesState';
import fogotPassState from './fogotPass/fogotPassState';
import settingProfileValueState from './settingProfile/settingPassValueState';
import settingAccountValueState from './settingAccount/settingAccountValueState';
import exeptionAddItemSwithState from './exeptionAddItemSwith';
import dashbordState from './dashbord';
import profilePopapState from './profilePopap';
import savedFormsState from './savedForms';

const rootReduser = combineReducers({
    [openPopapState.name]: openPopapState.reducer,
    [themeState.name]: themeState.reducer,
    [languageState.name]: languageState.reducer,
    [activeSlide.name]: activeSlide.reducer,
    [authInputsValueState.name]: authInputsValueState.reducer,
    [tokenState.name]: tokenState.reducer,

    [authState.name]: authState.reducer,
    [headerState.name]: headerState.reducer,
    [serviceListState.name]: serviceListState.reducer,
    [tariffState.name]: tariffState.reducer,
    [termsSwithState.name]: termsSwithState.reducer,
    [TwoFASettingSwith.name]: TwoFASettingSwith.reducer,
    [settingPassValueState.name]: settingPassValueState.reducer,

    [sidebarSickyState.name]: sidebarSickyState.reducer,
    [banAndMuteRulesState.name]: banAndMuteRulesState.reducer,
    [banAddItemSwithState.name]: banAddItemSwithState.reducer,
    [muteAddItemSwithState.name]: muteAddItemSwithState.reducer,
    [exeptionAddItemSwithState.name]: exeptionAddItemSwithState.reducer,

    [userCounterSimbolRulesState.name]: userCounterSimbolRulesState.reducer,
    [fogotPassState.name]: fogotPassState.reducer,
    [settingProfileValueState.name]: settingProfileValueState.reducer,
    [settingAccountValueState.name]: settingAccountValueState.reducer,

    [dashbordState.name]: dashbordState.reducer,
    [profilePopapState.name]: profilePopapState.reducer,
    [savedFormsState.name]: savedFormsState.reducer,
});

const store = configureStore({
    reducer: rootReduser,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});
export default store;

export type TrootState = ReturnType<typeof store.getState>;
export type TappDispatch = typeof store.dispatch;
