import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import axios from 'axios';

type Tprops = {
    mail: string;
    first_name: string;
    last_name: string;
    username: string;
    lang: string;
    password: string;
};

export default async ({ mail, first_name, last_name, username, lang, password }: Tprops) => {
    setVisableLoader('visable');
    const req = await axios.post(apiUrl + '/account/registration', {
        email: mail,
        first_name: first_name,
        last_name: last_name,
        username: username,
        language: lang.toLowerCase(),
        password,
    });
    setVisableLoader('notVisable');
    return req.data;
};
