import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { blackIcon } = useTheme();
    return {
        Container: styled.div({
            display: 'flex',
            gap: '1.5em',
            flexDirection: size.isMobile ? 'column' : 'row',
        }),
        Icon: styled.img({
            fontSize: size.isMobile ? '1.5em' : '2em',
            width: '1em',
            height: '1em',
            transform: 'translate(0, -5%)',
            color: blackIcon,
        }),
        size,
    };
};
