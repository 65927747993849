import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { TserviseNames } from '@maket/js/types/state/serviseList';
import axios from 'axios';

type Tprops = {
    token: string;
    serviceType: TserviseNames;
};

export default async ({ serviceType, token }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post(
            apiUrl + `/service/${serviceType}/unbind`,
            {},
            { headers: { Authorization: `Bearer ${token}` } },
        );
        setVisableLoader('notVisable');

        return req.data;
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
