import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { textLowGray, buttonBlue } = useTheme();
    return {
        Container: styled.div({
            flex: '0 0 42%',
            display: 'flex',
            gap: '2em',
            marginTop: size.isMobile || size.isTab ? '2em' : '6em',
            width: size.isMobile || size.isTab ? '100%' : '',
            overflow: 'hidden',
            minHeight: 'fit-content',
            padding: 2,
        }),
        Left: styled.div({}),
        ProgressBar: styled.div({
            width: 2,
            height: '100%',
            backgroundColor: textLowGray,
            position: 'relative',
        }),
        ProgressPointer: styled.div({
            position: 'absolute',
            top: 0,
            left: 1,
            width: 4,
            height: '15%',
            backgroundColor: buttonBlue,
            transition: '0.05s',
            transform: 'translate(-50%)',

            '&.next': {
                top: '10%',
                backgroundColor: textLowGray,
            },
        }),
        Right: styled.div({
            fontSize: size.isMicroMobile ? '0.5em' : size.isMobile || size.isTab ? '0.8em' : '',
        }),
        size,
    };
};
