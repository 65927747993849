import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { setTariff } from '@maket/js/state/auth/authState';
import { TappDispatch } from '@maket/js/state/store';
import { Ttariff, Tuser } from '@maket/js/types/state/auth';
import { TserviseNames } from '@maket/js/types/state/serviseList';
import axios from 'axios';

type Tprops = {
    token: string;
    serviceType: TserviseNames;
    dispatch: TappDispatch;
    notDispatching?: boolean;
};

export default async ({ token, serviceType, dispatch, notDispatching }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post<Ttariff>(
            apiUrl + '/service/tariff/state',
            {
                service_type: serviceType,
            },
            { headers: { Authorization: `Bearer ${token}`, CacheControl: 'no-cache' } },
        );
        setVisableLoader('notVisable');
        if (!notDispatching) {
            dispatch(setTariff(req.data));
        }

        return req.data;
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
