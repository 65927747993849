import { userSimbolCounter } from '@maket/js/constants/values';
import { createSlice } from '@reduxjs/toolkit';

const initialState: {
    state: boolean;
    inputValue: string;
} = {
    state: true,
    inputValue: '',
};

const exeptionAddItemSwithState = createSlice({
    name: 'exeptionAddItemSwithState',
    initialState,
    reducers: {
        setSwithState: (state, { payload }: { payload: boolean }) => {
            state.state = payload;
        },
        setInputState: (state, { payload }: { payload: string }) => {
            state.inputValue = payload;
        },
    },
});

export const { setSwithState, setInputState } = exeptionAddItemSwithState.actions;

export default exeptionAddItemSwithState;
