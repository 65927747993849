import Popap from '@maket/js/components/middleComponents/popap';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { setPopapStateEnterprice } from '@maket/js/state/tariffSwith';
import React, { memo, useEffect, useRef, useState } from 'react';
import style from './style';
import Switch from '@maket/js/components/microComponets/switch';
import Input from '@maket/js/components/microComponets/input';
import { TalertState } from '@maket/js/components/microComponets/buttonAllert';
import { mailRegExp } from '@maket/js/constants/values';
import sendMailContacts from '@maket/js/api/requiresToken/contacts/sendMailContacts';

export default () => {
    const inputValues = useRef({
        mail: '',
        firstname: '',
        lastname: '',
        company: '',
        chatQuantity: '',
        accept: true,
    });
    const { size } = style();
    const dispatch = useAppDispatch();

    const token = useAppSelector(state => state.tokenState.token);

    const popapState = useAppSelector(state => state.tariffState.popapStateEnterprice);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const lang = useAppSelector(state => state.languageState.language);

    const [allertState, setAllertState] = useState<TalertState>({ textAllert: '', typeAllert: '' });

    return (
        <Popap
            setState={v => dispatch(setPopapStateEnterprice(v))}
            state={!!popapState}
            allControllSetFunction={() => {
                if (inputValues.current.accept) {
                    if (
                        (popapState == 'question'
                            ? false
                            : inputValues.current.chatQuantity == '') ||
                        inputValues.current.company == '' ||
                        inputValues.current.firstname == '' ||
                        inputValues.current.lastname == '' ||
                        inputValues.current.mail == ''
                    ) {
                        setAllertState({ textAllert: 'Auth.NotAllField', typeAllert: 'danger' });
                    } else {
                        if (inputValues.current.mail.match(mailRegExp) == null) {
                            setAllertState({
                                textAllert: 'Auth.Alert.MailErrorRegExp',
                                typeAllert: 'danger',
                            });
                        } else {
                            if (inputValues.current.mail.length > 49) {
                                setAllertState({
                                    textAllert: 'Auth.Alert.MailLengthError',
                                    typeAllert: 'danger',
                                });
                            } else if (inputValues.current.firstname.length > 49) {
                                setAllertState({
                                    textAllert: 'Main.Price.Alert.FirstNameError',
                                    typeAllert: 'danger',
                                });
                            } else if (inputValues.current.lastname.length > 49) {
                                setAllertState({
                                    textAllert: 'Main.Price.Alert.LastNameError',
                                    typeAllert: 'danger',
                                });
                            } else if (inputValues.current.company.length > 99) {
                                setAllertState({
                                    textAllert: 'Main.Price.Alert.CompanyNameError',
                                    typeAllert: 'danger',
                                });
                            } else {
                                const sendObjEnterprice = {
                                    dispatch,
                                    token,
                                    mail: inputValues.current.mail,
                                    firstname: inputValues.current.firstname,
                                    lastname: inputValues.current.lastname,
                                    company: inputValues.current.company,
                                    chatQuantity: inputValues.current.chatQuantity,
                                    lang: lang,
                                };
                                const sendObjQuestion = {
                                    dispatch,
                                    token,
                                    mail: inputValues.current.mail,
                                    firstname: inputValues.current.firstname,
                                    lastname: inputValues.current.lastname,
                                    question: inputValues.current.company,
                                    lang: lang,
                                };
                                sendMailContacts(
                                    popapState == 'question' ? sendObjQuestion : sendObjEnterprice,
                                ).then(res => {
                                    setAllertState({
                                        textAllert: 'Main.Price.SendSuccess.ContactSales',
                                        typeAllert: 'success',
                                    });
                                    setTimeout(() => {
                                        dispatch(setPopapStateEnterprice(false));
                                    }, 2500);
                                });
                            }
                        }
                    }
                } else {
                    setAllertState({ textAllert: 'Auth.Accept.Error', typeAllert: 'danger' });
                }
            }}
            closeNone
            title={
                activeLanguage[
                    popapState == 'question'
                        ? 'Main.Price.Question.Title'
                        : 'Main.Price.ContactSales.Title'
                ]
            }
            main={<PopapMain inputValues={inputValues} popapState={popapState} />}
            buttonText={activeLanguage['Submit.']}
            allertStateOuter={allertState}
            allertStyle={{
                // @ts-ignore: Unreachable code error
                width: `calc(${size.isMobile ? '88vw' : '500px - 2em'})`,
                left: `calc((${size.isMobile ? '88vw' : '500px - 2em'})/2)`,
            }}
            // @ts-ignore: Unreachable code error
            buttonStyles={{ width: '100%' }}
            setAllertStateOuter={setAllertState}
        />
    );
};

const PopapMain = memo(
    ({
        inputValues,
        popapState,
    }: {
        inputValues: React.MutableRefObject<{
            mail: string;
            firstname: string;
            lastname: string;
            company: string;
            chatQuantity: string;
            accept: boolean;
        }>;
        popapState: boolean | 'question';
    }) => {
        const { PopapMainContainer, PopapMainLine, AcceptBox, AcceptLink } = style();
        const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

        const splitText = activeLanguage['Main.Price.ContactSales.PrivacyPolicy'].split('/');

        return (
            <PopapMainContainer>
                <PopapMainLine style={{ lineHeight: '1.5em' }}>
                    {activeLanguage['Main.Price.ContactSales.Text']}
                </PopapMainLine>

                <PopapMainLine>
                    <Input
                        inputStyle={{ style: { padding: '1em' } }}
                        iconName="none"
                        placeholder={
                            activeLanguage[
                                popapState == 'question'
                                    ? 'Main.Price.Question.Mail'
                                    : 'Main.Price.ContactSales.Input1'
                            ]
                        }
                        setExtStateOuter={v => {
                            inputValues.current.mail = v;
                        }}
                        marginBottomNone
                    />
                </PopapMainLine>
                <PopapMainLine>
                    <Input
                        inputStyle={{ style: { padding: '1em' } }}
                        iconName="none"
                        placeholder={activeLanguage['Main.Price.ContactSales.Input2']}
                        setExtStateOuter={v => {
                            inputValues.current.firstname = v;
                        }}
                        marginBottomNone
                    />
                </PopapMainLine>
                <PopapMainLine>
                    <Input
                        inputStyle={{ style: { padding: '1em' } }}
                        iconName="none"
                        placeholder={activeLanguage['Main.Price.ContactSales.Input3']}
                        setExtStateOuter={v => {
                            inputValues.current.lastname = v;
                        }}
                        marginBottomNone
                    />
                </PopapMainLine>
                <PopapMainLine>
                    <Input
                        inputStyle={{ style: { padding: '1em' } }}
                        iconName="none"
                        placeholder={
                            activeLanguage[
                                popapState == 'question'
                                    ? 'Main.Price.Question.Input4'
                                    : 'Main.Price.ContactSales.Input4'
                            ]
                        }
                        setExtStateOuter={v => {
                            inputValues.current.company = v;
                        }}
                        marginBottomNone
                    />
                </PopapMainLine>
                {popapState != 'question' && (
                    <PopapMainLine>
                        <Input
                            inputStyle={{ style: { padding: '1em' } }}
                            iconName="none"
                            placeholder={activeLanguage['Main.Price.ContactSales.Input5']}
                            setExtStateOuter={v => {
                                inputValues.current.chatQuantity = v;
                            }}
                            marginBottomNone
                            typeInput="number"
                        />
                    </PopapMainLine>
                )}

                <PopapMainLine>
                    <Switch initState={true} setState={v => (inputValues.current.accept = v)} />
                    <div>
                        {splitText[0]}
                        <AcceptLink to={'/app/termsOfUse'}>{splitText[1]}</AcceptLink>
                    </div>
                </PopapMainLine>
            </PopapMainContainer>
        );
    },
);
