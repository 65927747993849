import React from 'react';
import style from './style';
import Switch from '@maket/js/components/microComponets/switch';
import Input from '@maket/js/components/microComponets/input';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useTheme from '@maket/js/hooks/useTheme';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { setInputState, setSwithState } from '@maket/js/state/exeptionAddItemSwith';

type Tprops = {
    text: string;
    initState: boolean;
    setState: (v: boolean) => void;
    deleteFn?: () => void;
};

export default ({ setState, text, initState, deleteFn }: Tprops) => {
    const { AuthSettingContainer, AuthSettingMain, AuthSettingText, DeleteIcon, size } = style();
    return (
        <AuthSettingContainer>
            <Switch initState={initState} setState={setState} />
            <AuthSettingMain>
                <AuthSettingText>{text}</AuthSettingText>
            </AuthSettingMain>
            {deleteFn && <DeleteIcon onClick={() => deleteFn()} className="icon-trash" />}
        </AuthSettingContainer>
    );
};

export function ExeptionRulesItemAdd() {
    const dispatch = useAppDispatch();
    const { AuthSettingContainer, AuthSettingMain, size } = style();
    const { authBackground } = useTheme();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    return (
        <AuthSettingContainer style={{ backgroundColor: authBackground, padding: '1em' }}>
            <Switch
                initState={true}
                setState={v => {
                    dispatch(setSwithState(v));
                }}
            />
            <AuthSettingMain>
                <Input
                    stopInputMaxSize3000
                    placeholder={activeLanguage['ChatRules.BanExeption.Button']}
                    setExtState={setInputState}
                    iconName="fileText"
                    marginBottomNone
                />
            </AuthSettingMain>
        </AuthSettingContainer>
    );
}
