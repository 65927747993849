import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { blackIcon } = useTheme();
    return {
        Icon: styled.div({
            fontSize: size.isMobile ? '1.5em' : '2em',
            width: '1em',
            height: '1em',
            transform: 'translate(0, -15%)',
            color: blackIcon,
        }),
        size,
    };
};
