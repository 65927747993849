import {
    TbanRule,
    TbanState,
    TExeptionRule,
    TExeptionState,
    TmuteRule,
    TMuteState,
} from '@maket/js/types/state/banAndMute';
import { createSlice, current } from '@reduxjs/toolkit';

const initialState: {
    banState: TbanState;
    muteState: TMuteState;
    exeptionState: TExeptionState;

    BDbanState: TbanState;
    BDmuteState: TMuteState;
    BDexeptionState: TExeptionState;

    rulesProfile: null | string;
} = {
    banState: { spam_filter: true, rules: [] },
    muteState: { starting_frase: '', ending_frase: '', rules: [] },
    exeptionState: { rules: [] },

    BDbanState: { spam_filter: true, rules: [] },
    BDmuteState: { starting_frase: '', ending_frase: '', rules: [] },
    BDexeptionState: { rules: [] },

    rulesProfile: null,
};

const banAndMuteRulesState = createSlice({
    name: 'banAndMuteRulesState',
    initialState,
    reducers: {
        setSpamFilterState: (state, { payload }: { payload: boolean }) => {
            state.banState.spam_filter = payload;
        },
        setSpamRulesState: (state, { payload }: { payload: Array<TbanRule> }) => {
            state.banState.rules = payload;
        },

        setSpamState: (state, { payload }: { payload: TbanState }) => {
            state.banState = payload;
        },
        setBDSpamState: (state, { payload }: { payload: TbanState }) => {
            state.BDbanState = payload;
        },

        setSpamRuleState: (state, { payload }: { payload: { index: number; value: boolean } }) => {
            const el = state.banState.rules[payload.index];
            if (el) {
                el.enable = payload.value;
                state.banState.rules[payload.index] = el;
            }
        },
        deleteSpamRulesState: (state, { payload }: { payload: { index: number } }) => {
            state.banState.rules.splice(payload.index, 1);
        },

        setMuteStartingFraceState: (state, { payload }: { payload: string }) => {
            state.muteState.starting_frase = payload;
        },
        setBDMuteStartingFraceState: (state, { payload }: { payload: string }) => {
            state.BDmuteState.starting_frase = payload;
        },

        setMuteEndingFraceState: (state, { payload }: { payload: string }) => {
            state.muteState.ending_frase = payload;
        },
        setBDMuteEndingFraceState: (state, { payload }: { payload: string }) => {
            state.BDmuteState.ending_frase = payload;
        },

        setMuteRulesState: (state, { payload }: { payload: Array<TmuteRule> }) => {
            state.muteState.rules = payload;
        },

        setMuteState: (state, { payload }: { payload: TMuteState }) => {
            state.muteState = payload;
        },
        setBDMuteState: (state, { payload }: { payload: TMuteState }) => {
            state.BDmuteState = payload;
        },

        deleteMuteRulesState: (state, { payload }: { payload: { index: number } }) => {
            state.muteState.rules.splice(payload.index, 1);
        },
        setMuteRulesItemState: (
            state,
            {
                payload,
            }: { payload: { index: number; value?: boolean; reason?: string; response?: string } },
        ) => {
            const el = state.muteState.rules[payload.index];

            if (el) {
                if (payload.value != undefined) {
                    el.enable = payload.value;
                }
                if (payload.reason) {
                    el.reason = payload.reason;
                }
                if (payload.response) {
                    el.response = payload.response;
                }
                state.muteState.rules[payload.index] = el;
            }
        },

        setExeptionState: (state, { payload }: { payload: TExeptionState }) => {
            state.exeptionState = payload;
        },
        setBDExeptionState: (state, { payload }: { payload: TExeptionState }) => {
            state.BDexeptionState = payload;
        },

        setExeptionRulesState: (state, { payload }: { payload: Array<TExeptionRule> }) => {
            state.exeptionState.rules = payload;
        },
        setExeptionRulesItemState: (
            state,
            { payload }: { payload: { index: number; value: boolean } },
        ) => {
            const el = state.exeptionState.rules[payload.index];
            if (el) {
                el.enable = payload.value;
                state.exeptionState.rules[payload.index] = el;
            }
        },

        setActiveRulesProfileState: (state, { payload }: { payload: string }) => {
            state.rulesProfile = payload;
        },
        deleteExeptionRulesState: (state, { payload }: { payload: { index: number } }) => {
            state.exeptionState.rules.splice(payload.index, 1);
        },
        clearChange: state => {
            state.banState = state.BDbanState;
            state.exeptionState = state.BDexeptionState;
            state.muteState = state.BDmuteState;
        },
    },
});

export const {
    setMuteState,
    setSpamState,
    setMuteEndingFraceState,
    setMuteRulesState,
    setSpamRuleState,
    setMuteStartingFraceState,
    deleteSpamRulesState,
    setSpamFilterState,
    setMuteRulesItemState,
    setSpamRulesState,
    deleteMuteRulesState,
    setExeptionState,
    setExeptionRulesState,
    setActiveRulesProfileState,
    deleteExeptionRulesState,
    setBDSpamState,
    setBDMuteState,
    setBDMuteStartingFraceState,
    setBDMuteEndingFraceState,
    setBDExeptionState,
    setExeptionRulesItemState,
    clearChange,
} = banAndMuteRulesState.actions;

export default banAndMuteRulesState;
