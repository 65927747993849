import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    return {
        Container: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
            zIndex: 1,
            fontSize: size.isMicroMobile ? '0.6em' : size.isMobile || size.isTab ? '0.8em' : '',
        }),
        size,
    };
};
