import React from 'react';
import style from './style';
import GrayBox from '@maket/js/components/middleComponents/grayBox';
import useAppSelector from '@maket/js/hooks/useAppSelector';

import UserData from './userData';
import AvatarContainer from './avatarContainer';
import Button from './button';

export default () => {
    const { Container, size } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <Container>
            <GrayBox
                titleSize="1.25em"
                title={activeLanguage['MyData.Item.Title']}
                content={<UserData />}
            />
            <GrayBox content={<AvatarContainer />} />
            <Button />
        </Container>
    );
};
