import React from 'react';
import style from './style';

export default () => {
    const { Container, Background, BackgroundMask } = style();
    return (
        <Container>
            <BackgroundMask></BackgroundMask>
            <Background src="/img/background/image-67d137b07e541.webp" />
        </Container>
    );
};
