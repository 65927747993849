import React from 'react';
import style from './style';

import useAppSelector from '@js/hooks/useAppSelector';
import useAppDispatch from '@js/hooks/useAppDispatch';
import { setActivePopap } from '@js/state/openPopap/openPopapState';
import { AnimatePresence } from 'framer-motion';
import useWindowSizeCheck, { TwindowSize } from '@js/hooks/useWindowSizeCheck';
import { setStateSidebar } from '@maket/js/state/sidebarSicky/sidebarSickyState';
import { checkSidebarActive } from '../../../layout';
import { TappDispatch } from '@maket/js/state/store';

export default () => {
    const { Burger, BurgerContainer, size } = style();
    const activePopapName = useAppSelector(state => state.openPopapState.activePopapName);
    const lastActivePopapName = useAppSelector(state => state.openPopapState.lastActivePopapName);
    const dispatch = useAppDispatch();
    const { isMobile, isTab } = useWindowSizeCheck();

    const durationCheck =
        isMobile || isTab
            ? (activePopapName == 'navigationPopap' && lastActivePopapName == 'languagePopap') ||
              (activePopapName == 'languagePopap' && lastActivePopapName == 'navigationPopap') ||
              activePopapName == 'navigationPopapAndLanguage'
            : activePopapName != 'navigationPopap' && lastActivePopapName != 'navigationPopap';

    const sidebarState = useAppSelector(state => state.sidebarSickyState.state);

    return (
        <AnimatePresence>
            <BurgerContainer
                data-popap="true"
                onClick={() => burgerClick(dispatch, activePopapName, size)}
            >
                {getVisable('burger', activePopapName, size, sidebarState) && (
                    <Burger
                        initial={{ transform: 'translate(50%, -50%)' }}
                        animate={{ transform: 'translate(-50%, -50%)' }}
                        exit={{ transform: 'translate(50%, -50%)' }}
                        transition={{
                            duration: checkSidebarActive()
                                ? durationCheck || lastActivePopapName == activePopapName
                                    ? 0
                                    : 0.3
                                : 0.3,
                        }}
                        src="/img/microIcon/burger.svg"
                    />
                )}
                {getVisable('close', activePopapName, size, sidebarState) && (
                    <Burger
                        initial={{ transform: 'translate(-150%, -50%)' }}
                        animate={{ transform: 'translate(-50%, -50%)' }}
                        exit={{ transform: 'translate(-150%, -50%)' }}
                        transition={{
                            duration: checkSidebarActive() ? (durationCheck ? 0 : 0.3) : 0.3,
                        }}
                        src="/img/microIcon/close.svg"
                    />
                )}
            </BurgerContainer>
        </AnimatePresence>
    );
};

function getVisable(
    type: 'burger' | 'close',
    activePopapName: string,
    size: TwindowSize,
    sidebarState: boolean,
) {
    if (type == 'burger') {
        if (size.isMobile || size.isTab) {
            return (
                activePopapName != 'navigationPopap' &&
                activePopapName != 'languagePopap' &&
                activePopapName != 'navigationPopapAndLanguage'
            );
        } else {
            if (checkSidebarActive()) {
                return activePopapName != 'navigationPopap';
            } else {
                return !sidebarState;
            }
        }
    }
    if (type == 'close') {
        if (size.isMobile || size.isTab) {
            return (
                activePopapName == 'navigationPopap' ||
                activePopapName == 'languagePopap' ||
                activePopapName == 'navigationPopapAndLanguage'
            );
        } else {
            if (checkSidebarActive()) {
                return activePopapName == 'navigationPopap';
            } else {
                return sidebarState;
            }
        }
    }
}

function burgerClick(dispatch: TappDispatch, activePopapName: string, size: TwindowSize) {
    if (checkSidebarActive() || size.isMobile || size.isTab) {
        dispatch(
            setActivePopap(
                activePopapName == 'navigationPopapAndLanguage'
                    ? 'navigationPopapAndLanguage'
                    : 'navigationPopap',
            ),
        );
    } else {
        dispatch(setStateSidebar());
    }
}
