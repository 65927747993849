import React, { memo } from 'react';
import style from './style';
import { AnimatePresence, MotionProps } from 'framer-motion';
import useAppSelector from '@js/hooks/useAppSelector';
import SelectList from './selectList';
import MainContainer from './mainContainer';

interface Tprops extends MotionProps {
    openFn?: (v: string) => void;
    openCheck?: string;
    type?: 'page';
    reverce?: boolean;
}

const LangaugeButton = memo(({ openFn, openCheck, type, reverce, ...props }: Tprops) => {
    const { Container } = style(undefined, type);
    const activePopapName = useAppSelector(state => state.openPopapState.activePopapName);
    const checkAnim =
        openCheck != undefined ? openCheck == 'languagePopap' : activePopapName == 'languagePopap';
    return (
        <AnimatePresence>
            <Container
                data-popap="true"
                initial={{
                    borderRadius: checkAnim ? (reverce ? '0 0 8px 8px' : '8px 8px 0 0') : 8,
                }}
                animate={{
                    borderRadius: checkAnim ? (reverce ? '0 0 8px 8px' : '8px 8px 0 0') : 8,
                }}
                exit={{ borderRadius: 8 }}
                {...props}
            >
                <MainContainer openCheck={openCheck} openFn={openFn} type={type} />
                <AnimatePresence>
                    <SelectList
                        openCheck={openCheck}
                        openFn={openFn}
                        type={type}
                        reverce={reverce}
                    />
                </AnimatePresence>
            </Container>
        </AnimatePresence>
    );
});

export default LangaugeButton;
