import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default (checkMute: boolean) => {
    const size = useWindowSizeCheck();
    const { altTextBlackColor } = useTheme();
    return {
        Container: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
            opacity: checkMute ? '1' : '0.7',
            pointerEvents: checkMute ? 'all' : 'none',
        }),
        Title: styled.div({
            width: '100%',
            textAlign: 'start',
            color: altTextBlackColor,
            fontSize: '1.25em',
            fontWeight: 600,
        }),
        size,
    };
};
