import { createSlice } from '@reduxjs/toolkit';

const initialState: { activePopapName: string; lastActivePopapName: string } = {
    activePopapName: '',
    lastActivePopapName: '',
};

const openPopapState = createSlice({
    name: 'openPopapState',
    initialState,
    reducers: {
        setActivePopap: (state, { payload }) => {
            if (payload == state.activePopapName) {
                state.lastActivePopapName = state.activePopapName;
                state.activePopapName = '';
            } else {
                state.lastActivePopapName = state.activePopapName;
                state.activePopapName = payload;
            }
        },
    },
});

export const { setActivePopap } = openPopapState.actions;

export default openPopapState;
