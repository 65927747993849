import { TactiveLanguage, TlanguageComp, TlanguageList } from '@js/types/state/language';
import { createSlice } from '@reduxjs/toolkit';
import comparisonBCP47 from './comparisonBCP47';

const initialState: {
    language: string;
    languageComp: TlanguageComp;
    languageList: TlanguageList;
    activeLanguage: TactiveLanguage;
    activePriceSymbolType: 'euro' | 'dollar';
} = {
    language: '',
    languageComp: {},
    languageList: {},
    activeLanguage: {},
    activePriceSymbolType: 'dollar',
};

const languageState = createSlice({
    name: 'languageState',
    initialState,
    reducers: {
        setLanguage: (state, { payload }: { payload: string }) => {
            state.language = payload;
            const date = new Date(Date.now() + 2678400e3).toUTCString();
            document.cookie = `language=${payload}; path=/; expires=${date}`;

            if (['DE', 'ES'].includes(state.language)) {
                state.activePriceSymbolType = 'euro';
            } else {
                state.activePriceSymbolType = 'dollar';
            }
        },
        setLanguageComp: (state, { payload }: { payload: TlanguageComp }) => {
            state.languageComp = payload;
        },
        setLanguageList: (state, { payload }: { payload: TlanguageList }) => {
            state.languageList = payload;

            const cookieLanguage = getCookieLang();
            if (cookieLanguage) {
                state.language = cookieLanguage;
            } else {
                state.language =
                    // @ts-ignore: Unreachable code error
                    comparisonBCP47[Intl.DateTimeFormat().resolvedOptions().locale] || 'EN';
            }

            if (['DE', 'ES'].includes(state.language)) {
                state.activePriceSymbolType = 'euro';
            } else {
                state.activePriceSymbolType = 'dollar';
            }
        },
        setActiveLanguage: state => {
            const activeLanguage: { [key: string]: string } = {};
            for (const key in state.languageComp) {
                const tagComp = state.languageComp[key];
                const activeTag = tagComp.find(el => el.langKey == state.language);

                if (activeTag?.value) {
                    activeLanguage[key] = activeTag.value;
                }
            }

            state.activeLanguage = activeLanguage;
        },
    },
});

export const { setLanguage, setLanguageComp, setLanguageList, setActiveLanguage } =
    languageState.actions;

export default languageState;

function getCookieLang() {
    let language = document.cookie.split(';').filter(el => el.includes('language'))[0];
    if (language) {
        language = language.split('language=')[1];
    }
    return language;
}
