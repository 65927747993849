import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default (checkBan: boolean) => {
    const size = useWindowSizeCheck();
    return {
        Container: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
            opacity: checkBan ? '1' : '0.7',
            pointerEvents: checkBan ? 'all' : 'none',
        }),
        size,
    };
};
