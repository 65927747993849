import React, { useEffect } from 'react';
import Input from '@maket/js/components/microComponets/input';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import style from './style';
import {
    setInputFirstNameSettingsValue,
    setInputLastNameSettingsValue,
} from '@maket/js/state/settingAccount/settingAccountValueState';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { Taccount } from '@maket/js/types/state/auth';
import { TactiveLanguage } from '@maket/js/types/state/language';

export default () => {
    const dispatch = useAppDispatch();
    const { Container, Item, ItemTitle } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    return (
        <Container>
            <Item>
                <ItemTitle>{activeLanguage['FirstName.']}</ItemTitle>
                <IsolateInput activeLanguage={activeLanguage} type="firstName" />
            </Item>
            <Item>
                <ItemTitle>{activeLanguage['LastName.']}</ItemTitle>
                <IsolateInput activeLanguage={activeLanguage} type="lastName" />
            </Item>
        </Container>
    );
};

function IsolateInput({
    activeLanguage,
    type,
}: {
    activeLanguage: TactiveLanguage;
    type: 'firstName' | 'lastName';
}) {
    const { firstName, lastName } = useAppSelector(state => state.settingAccountValueState);
    const dispatch = useAppDispatch();

    const account = useAppSelector(state => state.authState.account);
    useEffect(() => {
        if (account && firstName == '') {
            dispatch(setInputFirstNameSettingsValue(account.first_name));
        }
        if (account && lastName == '') {
            dispatch(setInputLastNameSettingsValue(account.last_name));
        }
    }, [account]);

    if (type == 'firstName') {
        return (
            <Input
                value={firstName}
                iconName="userReg"
                placeholder={activeLanguage['FirstName.']}
                setExtState={setInputFirstNameSettingsValue}
            />
        );
    } else {
        return (
            <Input
                value={lastName}
                iconName="userReg"
                placeholder={activeLanguage['LastName.']}
                setExtState={setInputLastNameSettingsValue}
            />
        );
    }
}
