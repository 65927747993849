import { TsettingAccountValueState } from '@maket/js/types/state/settingAccount';
import { createSlice } from '@reduxjs/toolkit';

const initialState: TsettingAccountValueState = {
    firstName: '',
    lastName: '',
    photo: null,
};

const settingAccountValueState = createSlice({
    name: 'settingAccountValueState',
    initialState,
    reducers: {
        setInputFirstNameSettingsValue: (state, { payload }: { payload: string }) => {
            state.firstName = payload;
        },
        setInputLastNameSettingsValue: (state, { payload }: { payload: string }) => {
            state.lastName = payload;
        },
        setInputPhotoSettingsValue: (state, { payload }: { payload: any }) => {
            state.photo = payload;
        },

        clearChangeMyData: (state, { payload }: { payload: TsettingAccountValueState }) => {
            state.firstName = payload.firstName;
            state.lastName = payload.lastName;
            state.photo = payload.photo;
        },
    },
});

export const {
    setInputFirstNameSettingsValue,
    setInputLastNameSettingsValue,
    setInputPhotoSettingsValue,
    clearChangeMyData,
} = settingAccountValueState.actions;

export default settingAccountValueState;
