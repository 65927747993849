import React, { useEffect } from 'react';
import style from './style';
import { CWidgetStatsA } from '@coreui/react';
import { CChartBar, CChartLine } from '@coreui/react-chartjs';
import useAppSelector from '@maket/js/hooks/useAppSelector';

export default () => {
    const { Container } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const statistics = useAppSelector(state => state.dashbordState.statistics);

    useEffect(() => {
        const counter = document.querySelectorAll('.card-body');
        counter.forEach(el => {
            const count = el.querySelector('div');
            if (count) {
                const newCount = document.createElement('div');
                newCount.innerHTML = `${count.firstChild?.innerHTML || 0}`;
                newCount.classList.add('fs-4', 'fw-semibold');
                count.firstChild?.remove();
                count.prepend(newCount);
            }
        });
    });
    return (
        <Container>
            <CWidgetStatsA
                color="success"
                value={statistics.messages_count}
                title={activeLanguage['Dashbord.Messages']}
                style={{ flex: '1 1', width: '100%', height: '100%' }}
                chart={
                    <CChartLine
                        style={{ height: '70px' }}
                        data={{
                            labels: statistics.messages.map(el => el.date),
                            datasets: [
                                {
                                    label: '',
                                    backgroundColor: '#000',
                                    borderColor: 'rgba(255,255,255,.55)',
                                    pointBackgroundColor: 'rgb(27,158,62)',
                                    data: statistics.messages.map(el => el.count),
                                },
                            ],
                        }}
                        customTooltips={false}
                        options={{
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },

                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    border: {
                                        display: false,
                                    },
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        display: false,
                                    },
                                },
                                y: {
                                    display: false,
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        display: false,
                                    },
                                },
                            },

                            elements: {
                                line: {
                                    borderWidth: 1,
                                    tension: 0.4,
                                },
                                point: {
                                    radius: 4,
                                    hitRadius: 10,
                                    hoverRadius: 4,
                                },
                            },
                        }}
                    />
                }
            />

            <CWidgetStatsA
                color="danger"
                value={statistics.spam_messages_count}
                title={activeLanguage['Dashbord.SpamMessages']}
                style={{ flex: '1 1', width: '100%', height: '100%' }}
                chart={
                    <CChartLine
                        customTooltips={false}
                        style={{ height: '70px' }}
                        data={{
                            labels: statistics.spam_messages.map(el => el.date),
                            datasets: [
                                {
                                    label: '',
                                    backgroundColor: 'rgba(255,255,255,.2)',
                                    borderColor: 'rgba(255,255,255,.55)',
                                    pointBackgroundColor: 'rgba(229,83,83)',
                                    data: statistics.spam_messages.map(el => el.count),
                                },
                            ],
                        }}
                        options={{
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    border: {
                                        display: false,
                                    },
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        display: false,
                                    },
                                },
                                y: {
                                    display: false,
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        display: false,
                                    },
                                },
                            },
                            elements: {
                                line: {
                                    borderWidth: 1,
                                },
                                point: {
                                    radius: 4,
                                    hitRadius: 10,
                                    hoverRadius: 4,
                                },
                            },
                        }}
                    />
                }
            />

            <CWidgetStatsA
                color="warning"
                value={statistics.users_count}
                title={activeLanguage['Dashbord.Users']}
                style={{ flex: '1 1', width: '100%', height: '100%' }}
                chart={
                    <CChartLine
                        customTooltips={false}
                        style={{ height: '70px' }}
                        data={{
                            labels: statistics.users.map(el => el.date),
                            datasets: [
                                {
                                    label: '',
                                    backgroundColor: 'rgba(255,255,255,.2)',
                                    borderColor: 'rgba(255,255,255,.55)',
                                    data: statistics.users.map(el => el.count),
                                },
                            ],
                        }}
                        options={{
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    display: false,
                                },
                                y: {
                                    display: false,
                                },
                            },
                            elements: {
                                line: {
                                    borderWidth: 2,
                                    tension: 0.4,
                                },
                                point: {
                                    radius: 0,
                                    hitRadius: 10,
                                    hoverRadius: 4,
                                },
                            },
                        }}
                    />
                }
            />

            <CWidgetStatsA
                color="info"
                value={statistics.active_users_count}
                title={activeLanguage['Dashbord.ActiveUsers']}
                style={{ flex: '1 1', width: '100%', height: '100%' }}
                chart={
                    <CChartBar
                        customTooltips={false}
                        style={{ height: '70px' }}
                        data={{
                            labels: statistics.active_users.map(el => el.date),
                            datasets: [
                                {
                                    label: '',
                                    backgroundColor: 'rgba(255,255,255,.55)',
                                    borderColor: 'rgba(255,255,255,.55)',
                                    data: statistics.active_users.map(el => el.count),
                                    barPercentage: 0.6,
                                },
                            ],
                        }}
                        options={{
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                        drawTicks: false,
                                    },
                                    ticks: {
                                        display: false,
                                    },
                                },
                                y: {
                                    border: {
                                        display: false,
                                    },
                                    grid: {
                                        display: false,
                                        drawTicks: false,
                                    },
                                    ticks: {
                                        display: false,
                                    },
                                },
                            },
                        }}
                    />
                }
            />
        </Container>
    );
};
