import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';
import { motion } from 'framer-motion';

export default (checkMute: boolean) => {
    const size = useWindowSizeCheck();
    const { altTextBlackColor, liteBlue, whiteText } = useTheme();
    return {
        Container: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
            opacity: checkMute ? '1' : '0.7',
            pointerEvents: checkMute ? 'all' : 'none',
            minHeight: '4em',
        }),
        Title: styled.div({
            width: size.isMobile || size.isTab ? '100%' : 'calc(50% - 0.5em)',
            textAlign: 'start',
            color: altTextBlackColor,
            fontSize: '1.25em',
            fontWeight: 600,
        }),
        TitleBox: styled.div({ display: 'flex', gap: '1em' }),
        InputBox: styled(motion.div)({
            display: 'flex',
            flexDirection: size.isMobile || size.isTab ? 'column' : 'row',
            alignItems: 'center',
            gap: '1em',
            backgroundColor: whiteText,
            padding: '1em',
        }),
        Icon: styled.button({
            width: 'calc(3.11em / 2)',
            minWidth: 'calc(3.11em / 2)',
            height: 'calc(3.11em / 2)',
            minHeight: 'calc(3.11em / 2)',
            fontSize: '2em',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: altTextBlackColor,

            borderRadius: 8,
            transition: '0.3s',

            ':hover': {
                backgroundColor: liteBlue,
            },
        }),
        ReasonWhiteAllert: styled.div({ backgroundColor: whiteText, padding: '1em' }),
        size,
    };
};
