import styled from '@emotion/styled';
import useTheme from '@js/hooks/useTheme';
import useWindowSizeCheck from '@js/hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    const { main__previewBackground, whiteText } = useTheme();
    return {
        Container: styled.div({
            fontFamily: 'Inter, sens-serif',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: size.isMobile ? '4em' : size.isTab ? '3em' : '2em',
            backgroundColor: main__previewBackground,
            color: whiteText,
            padding: size.isMobile ? '6em 2em' : '10em 4em',

            fontSize: size.isLaptop ? '0.8em' : size.isDesktop ? '' : '0.5em',
        }),
        Title: styled.div({
            fontSize: size.isTab ? '4em' : '5.625em',
            fontWeight: 800,
        }),
        Text: styled.div({
            lineHeight: '1.28em',
            fontSize: size.isMobile || size.isTab ? '2em' : '1.3125em',
        }),
        Background: styled.img({
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 0,
            opacity: size.isMobile ? 0 : 1,
        }),
        CardList: styled.div({
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: size.isMobile || size.isTab ? 'column' : 'row',
            gap: '2em',
            fontSize: size.isMobile ? '2em' : size.isTab ? '1.3125em' : '1.15em',
        }),
        BackgroundMask: styled.div({
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 1,
        }),
        size,
    };
};
