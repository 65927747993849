import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import { Tbutton } from '.';
import useTheme from '@js/hooks/useTheme';

export default (type: Tbutton, borderColor?: string) => {
    const size = useWindowSizeCheck();
    const {
        buttonBlue,
        whiteText,
        buttonBlueHover,
        navHoverGray,
        mainBlack,
        darkGray,
        blackIcon,
        textLowGray,
        secondaryButton,
        secondaryHoverButton,
        disconnectRed,
        liteGreen,
        liteGreenProfilePopap,
    } = useTheme();

    const config = {
        backgroundColor: {
            blue: buttonBlue,
            black: mainBlack,
            white: blackIcon,
            transparent: '#00000000',
            allWhite: whiteText,
            gray: textLowGray,
            secondary: secondaryButton,
            warning: disconnectRed,
            green: liteGreen,
        },
        backgroundColorHover: {
            blue: buttonBlueHover,
            black: darkGray,
            white: navHoverGray,
            transparent: textLowGray,
            allWhite: textLowGray,
            gray: textLowGray,
            secondary: secondaryHoverButton,
            warning: disconnectRed,
            green: liteGreenProfilePopap,
        },
        color: {
            blue: whiteText,
            black: whiteText,
            white: whiteText,
            transparent: blackIcon,
            allWhite: mainBlack,
            gray: mainBlack,
            secondary: whiteText,
            warning: whiteText,
            green: whiteText,
        },
        border: {
            blue: borderColor ? borderColor : '',
            black: borderColor ? borderColor : whiteText,
            white: borderColor ? borderColor : whiteText,
            transparent: borderColor ? borderColor : '',
            allWhite: borderColor ? borderColor : whiteText,
            gray: borderColor ? borderColor : textLowGray,
            secondary: borderColor ? borderColor : secondaryHoverButton,
            warning: borderColor ? borderColor : disconnectRed,
            green: borderColor ? borderColor : liteGreen,
        },
        borderHover: {
            blue: '',
            black: whiteText,
            white: whiteText,
            transparent: '',
            allWhite: whiteText,
            gray: textLowGray,
            secondary: secondaryHoverButton,
            warning: whiteText,
            green: liteGreenProfilePopap,
        },
    };

    return {
        Button: styled.button({
            width: 'fit-content',
            padding: '0.937em 1.625em',
            backgroundColor: config.backgroundColor[type],
            borderRadius: 8,
            fontWeight: 600,
            height: 'calc(1em * 2 + 1em)',
            maxHeight: 'calc(1em * 2 + 1em)',

            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            widows: '100%',

            color: config.color[type],
            border: type == 'white' || type == 'black' ? `2px solid ${config.border[type]}` : '',

            transition: '0.3s',
            position: 'relative',
            ':hover': {
                backgroundColor: config.backgroundColorHover[type],
                borderColor: config.borderHover[type],
            },
            ':disabled': { backgroundColor: config.backgroundColorHover[type] },
        }),
        size,
    };
};
