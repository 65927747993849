import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';
import { NavLink } from 'react-router-dom';

export default () => {
    const size = useWindowSizeCheck();
    const { whiteText, blackIcon } = useTheme();
    return {
        Container: styled.div({
            width: size.isMobile || size.isTab ? '98vw' : size.isLaptop ? '60vw' : '37.5vw',
            margin: '0 auto',

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

            gap: size.isMobile ? '1em' : '2.625em',

            padding: size.isMobile || size.isTab ? '1em' : size.isLaptop ? '4em 0' : '6.125em 0',
            fontFamily: 'Inter, sans-serif',
        }),
        Main: styled.div({
            padding: size.isMobile || size.isTab ? '1em' : '3.75em 3.75em 4.25em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: size.isMicroMobile ? '1em' : size.isMobile ? '1.5em' : '2em',
            width: '100%',

            backgroundColor: whiteText,
        }),
        size,
    };
};
