import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';
import { NavLink } from 'react-router-dom';

export default () => {
    const size = useWindowSizeCheck();
    const { buttonBlue, buttonBlueHover } = useTheme();
    return {
        Container: styled.div({
            display: 'flex',
            gap: '1.25em',
            alignItems: 'center',
        }),
        LoginButton: styled(NavLink)({
            backgroundColor: buttonBlue,
            color: '#fff',
            padding: size.isLaptop || size.isMicroMobile ? '0.75em' : '0.75em 1.625em',
            borderRadius: 8,
            fontSize: size.isMicroMobile ? '0.7em' : size.isLaptop ? '0.9em' : '',

            transition: '0.3s',
            ':hover': {
                backgroundColor: buttonBlueHover,
            },
        }),
        size,
    };
};
