import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { setStatisticsState } from '@maket/js/state/dashbord';
import { TappDispatch } from '@maket/js/state/store';
import { TserviseNames } from '@maket/js/types/state/serviseList';
import axios from 'axios';

type Tprops = {
    serviceType: TserviseNames;
    token: string;
    dispatch: TappDispatch;
    start_date: string;
    stop_date: string;
    chats: number[];
};

export default async ({ serviceType, token, start_date, stop_date, chats, dispatch }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post(
            apiUrl + '/service/statistic',
            {
                type: serviceType,
                start_date,
                stop_date,
                chats,
            },
            { headers: { Authorization: `Bearer ${token}` } },
        );
        setVisableLoader('notVisable');

        dispatch(setStatisticsState(req.data));
        return req.data;
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
