import React, { useEffect } from 'react';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import style from './style';
import { CFormSelect } from '@coreui/react';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { setLangProfile } from '@maket/js/state/settingProfile/settingPassValueState';
import { Tprofile } from '@maket/js/types/state/auth';

let lastWidth = Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.body.clientWidth,
    document.documentElement.clientWidth,
);

export default ({ settingProfile }: { settingProfile: Tprofile | undefined }) => {
    const dispatch = useAppDispatch();
    const { Container, Item, ItemTitle, ItemText } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const languageList = useAppSelector(state => state.languageState.languageList);

    const text = activeLanguage['ChatList.Text']?.split('\\') || [];

    const { lang } = useAppSelector(state => state.settingProfileValueState);

    useEffect(() => {
        let scrollWidth = Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.body.clientWidth,
            document.documentElement.clientWidth,
        );
        if (settingProfile && lastWidth == scrollWidth) {
            dispatch(setLangProfile(settingProfile.language));
        }
    }, [settingProfile]);

    return (
        <Container>
            <Item>
                <ItemTitle>{text[0]}</ItemTitle>
                <CFormSelect
                    defaultValue={lang?.toUpperCase()}
                    style={{ maxWidth: '20em' }}
                    onChange={e => {
                        dispatch(setLangProfile(e.target.value.toLowerCase()));
                    }}
                    options={[
                        ...Object.values(languageList).map((lang, i) => {
                            return { label: lang, value: Object.keys(languageList)[i] };
                        }),
                    ]}
                />
                <ItemText>{text[1]}</ItemText>
            </Item>
        </Container>
    );
};
