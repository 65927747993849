import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    return {
        Container: styled.div({
            flex: size.isMobile || size.isTab ? '' : '0 0 35%',
            height: size.isMobile || size.isTab ? '' : '100%',
            display: 'flex',
            justifyContent: size.isMobile || size.isTab ? 'center' : 'start',
        }),
        BackgroundMask: styled.div({}),
        Background: styled.img({
            width: size.isTab ? '75%' : '100%',
            height: 'fit-content',
            aspectRatio: '1/1.2',
            margin: size.isTab ? '0 auto' : '',
        }),
        ChatImg: styled.img({}),
        size,
    };
};
