import React, { HTMLAttributes } from 'react';
import style from './style';

interface Tprops extends HTMLAttributes<HTMLDivElement> {
    title: string;
    text: string;
    iconName: string;
}

export default ({ title, text, iconName, ...props }: Tprops) => {
    const { BottomCard, BottomCardIcon, BottomCardTitle, BottomCardText } = style();
    return (
        <BottomCard {...props}>
            <BottomCardIcon className={`icon-${iconName}`}></BottomCardIcon>
            <BottomCardTitle>{title}</BottomCardTitle>
            <BottomCardText>{text}</BottomCardText>
        </BottomCard>
    );
};
