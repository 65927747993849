import React, { useEffect } from 'react';
import style from './style';
import RouteWrapper from '../../middleComponents/routeWrapper';
import Main from './main';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import getAccount from '@maket/js/api/requiresToken/account/getAccount';
import useAppSelector from '@maket/js/hooks/useAppSelector';

export default () => {
    const {} = style();
    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.tokenState.token);

    useEffect(() => {
        if (token) {
            getAccount({ dispatch, token });
        }
    }, [token]);

    return <RouteWrapper title="MyData.Title" mainComponent={<Main />} type="allComponents" />;
};
