import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';

export default (
    bestPopularityText?: string,
    checkActive?: boolean,
    activeText?: string,
    connectType?: 'click' | 'noClick',
    type?: 'page',
) => {
    const size = useWindowSizeCheck();
    const {
        borderPriseGray,
        darkGray,
        buttonBlue,
        whiteText,
        buttonBlueHover,
        mainBlack,
        grayPriceActiveButton,
    } = useTheme();
    return {
        Container: styled.div({
            borderRadius: 8,
            border: `1px solid ${borderPriseGray}`,
            flex: size.isMobile ? '' : size.isTab ? '0 0 48%' : '0 0 19.5%',
            width: size.isMobile ? '' : size.isTab ? '48%' : '19.5%',
            maxWidth: size.isMobile
                ? ''
                : type == 'page'
                ? size.isTab
                    ? '48%'
                    : '30%'
                : size.isTab
                ? '48%'
                : '19.5%',
            position: 'relative',

            ':nth-of-type(3n)':
                connectType == 'click'
                    ? {
                          paddingTop: size.isMobile || size.isTab ? '4em' : '',
                          borderRadius: '0 0 8px 8px',
                          border: `1px solid ${buttonBlue}`,
                          '& .title': {
                              color: buttonBlue,
                          },
                          '& .top': {
                              gap: '2em',
                          },
                          '& .button': {
                              backgroundColor: buttonBlue,
                              ':hover': { backgroundColor: buttonBlueHover },
                          },
                          ':after': {
                              outline: `1px solid ${buttonBlue}`,
                              content: `'${bestPopularityText || 'Самый популярный'}'`,
                              color: whiteText,
                              position: 'absolute',
                              textTransform: 'uppercase',
                              top: size.isMobile || size.isTab ? '3em' : '0',
                              left: 0,
                              width: '100%',
                              transform: 'translate(0, -100%)',
                              backgroundColor: buttonBlue,
                              borderRadius: '8px 8px 0 0',
                              padding: '1em',
                              textAlign: 'center',
                              fontSize: size.isTab ? '1.25em' : size.isMobile ? '1.5em' : '0.75em',
                          },
                          ':before':
                              size.isMobile || size.isTab
                                  ? {
                                        content: "''",
                                        position: 'absolute',
                                        top: -2,
                                        left: -2,
                                        width: 'calc(100% + 4px)',
                                        backgroundColor: whiteText,
                                        height: '3em',
                                    }
                                  : {},
                      }
                    : {},
        }),
        ActiveContainer: styled.div(
            checkActive
                ? {
                      '& .title': {
                          color: mainBlack,
                          paddingTop: size.isMobile || size.isTab ? '1.25em' : '',
                      },
                      '& .price': {
                          paddingTop: size.isMobile ? '1.25em' : '',
                      },
                      '& .top': {
                          gap: '2em',
                      },
                      borderRadius: '0 0 8px 8px',
                      height: '100%',
                      border: `1px solid ${mainBlack}`,

                      ':after': {
                          content: `'${activeText || 'Ваш План'}'`,
                          color: whiteText,
                          position: 'absolute',
                          textTransform: 'uppercase',
                          top: size.isMobile || size.isTab ? '3em' : '0',
                          left: 0,
                          width: '100%',
                          transform: 'translate(0, -100%)',
                          backgroundColor: mainBlack,
                          borderRadius: '8px 8px 0 0',
                          padding: '1em',
                          textAlign: 'center',
                          fontSize: size.isTab ? '1.25em' : size.isMobile ? '1.5em' : '0.75em',
                      },
                      ':before':
                          size.isMobile || size.isTab
                              ? {
                                    content: "''",
                                    position: 'absolute',
                                    top: -2,
                                    left: -2,
                                    width: 'calc(100% + 4px)',
                                    backgroundColor: whiteText,
                                    height: '3em',
                                }
                              : {},
                  }
                : { height: '100%' },
        ),
        Title: styled.div({
            fontSize: size.isTab ? '3em' : '2em',
        }),
        Price: styled.div({
            fontSize: size.isMobile ? '2em' : size.isTab ? '3em' : '2.5vw',
            display: 'flex',
            alignItems: 'center',
            gap: '0.22em',
            span: {
                fontSize: '0.4em',
            },
        }),
        ConditionsTitle: styled.div({
            fontSize: size.isMobile ? '1.4em' : size.isTab ? '1.65em' : '1.125em',
        }),
        Conditions: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '0.75em',

            color: darkGray,
        }),
        Condition: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: '0.5em',
            fontSize: size.isMobile ? '1.25em' : size.isTab ? '1.5em' : '',
            span: {
                display: 'inline-block',
                width: '0.25em',
                minWidth: '0.25em',
                height: '0.25em',
                minHeight: '0.25em',
                backgroundColor: darkGray,
                borderRadius: '50%',
            },
        }),
        Top: styled.div({
            aspectRatio: size.isMobile || size.isTab ? '' : '0.85',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            borderBottom: size.isMobile ? '' : `1px solid ${borderPriseGray}`,
            borderRadius: '0 0 8px 8px',
            padding: size.isTab ? '1.5em' : '1em',
            fontSize: size.isMobile ? '2em' : '',
            gap: size.isTab ? '6em' : '2em',
        }),
        TopTop: styled.div({
            display: 'flex',
            justifyContent: 'space-between',
        }),
        TopBottom: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5em',
            fontSize: size.isTab ? '1.75em' : type == 'page' ? '1em' : '0.875em',
        }),
        Bottom: styled.div({
            padding: size.isTab ? '1.5em' : '1em',
            display: 'flex',
            flexDirection: 'column',
            gap: '1.125em',
            fontSize: size.isMobile || size.isTab ? '1.5em' : '',
        }),

        ActiveButton: styled.div({
            padding: '0.637em',
            backgroundColor: grayPriceActiveButton,
            borderRadius: 8,

            display: 'inline-flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }),
        ActiveButtonText: styled.div({
            fontSize: size.isMobile ? '0.8em' : size.isTab ? '1em' : '0.85vw',
            lineHeight: '1.25em',
            width: '100%',
            display: 'inline',
            '&.blue': { color: buttonBlue },
        }),
        size,
    };
};
