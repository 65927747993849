import React from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';
import InputBox from './inputBox';
import ButtonBox from './buttonBox';

export default () => {
    const { Container, Title, Text, TopBox } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    return (
        <Container>
            <TopBox>
                <Title>{activeLanguage['FogotPass.Title']}</Title>
                <Text>{activeLanguage['FogotPass.Text']}</Text>
            </TopBox>
            <InputBox />
            <ButtonBox />
        </Container>
    );
};
