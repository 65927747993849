import { setLanguageComp, setLanguageList } from '@js/state/language/languageState';
import { TappDispatch } from '@js/state/store';
import { TlanguageComp, TlanguageList } from '@js/types/state/language';

// @ts-ignore: Unreachable code error
import lang from '@maket/public/lang.csv';

export default (dispatch: TappDispatch) => {
    const clearLang: Array<{ [key: string]: string }> = lang.filter(
        (el: { Variable: string }) => el.Variable != '',
    );
    const languageComp: TlanguageComp = {};
    clearLang.forEach(el => {
        for (const key in el) {
            if (key.length == 2) {
                const lang = { langKey: key, value: el[key] };
                if (languageComp[el.Variable]) {
                    languageComp[el.Variable].push(lang);
                } else {
                    languageComp[el.Variable] = [lang];
                }
            }
        }
    });
    dispatch(setLanguageComp(languageComp));

    const languageList: TlanguageList = {};
    for (const key in lang[0]) {
        if (key.length == 2 && lang[0][key]) {
            languageList[key] = lang[0][key];
        }
    }
    dispatch(setLanguageList(languageList));
};
