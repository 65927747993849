import { createSlice } from '@reduxjs/toolkit';

const initialState: {
    state: boolean;
} = {
    state: false,
};

const profilePopapState = createSlice({
    name: 'profilePopapState',
    initialState,
    reducers: {
        setState: (state, { payload }: { payload: boolean }) => {
            state.state = payload;
        },
    },
});

export const { setState } = profilePopapState.actions;

export default profilePopapState;
