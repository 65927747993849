import React, { useEffect, useState } from 'react';
import style from './style';
import {
    deleteSpamRulesState,
    setSpamFilterState,
    setSpamRuleState,
} from '@maket/js/state/banAndMuteRules';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import BanRulesItem from './banRulesItem';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';

export default ({ checkBan }: { checkBan: boolean }) => {
    const { Container } = style(checkBan);

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const stateSwitch = useAppSelector(state => state.banAndMuteRulesState.banState.spam_filter);
    const dispatch = useAppDispatch();

    return (
        <Container>
            <BanRulesItem
                initState={true}
                setState={v => {
                    dispatch(setSpamFilterState(v));
                }}
                text={activeLanguage['ChatRules.SpamFilter']}
                closed={true}
            />
            <BanList />
        </Container>
    );
};

function BanList() {
    const rules = useAppSelector(state => state.banAndMuteRulesState.banState.rules);
    return (
        <>
            {rules.map((el, i) => (
                <CreateBanRulesItem i={i} text={el.text} key={el.text + i} />
            ))}
        </>
    );
}

function CreateBanRulesItem({ i, text }: { i: number; text: string }) {
    const switchState = useAppSelector(
        state => state.banAndMuteRulesState.banState.rules[i].enable,
    );
    const dispatch = useAppDispatch();

    return (
        <BanRulesItem
            initState={switchState}
            setState={(v: boolean) => {
                dispatch(setSpamRuleState({ index: i, value: v }));
            }}
            deleteFn={() => {
                dispatch(deleteSpamRulesState({ index: i }));
            }}
            text={text}
        />
    );
}
