import { AnimatePresence } from 'framer-motion';
import React from 'react';
import ButtonSetting from './button';
import { tNameSetting } from '../box';

type Tprops = {
    active: tNameSetting;
    setActive: React.Dispatch<React.SetStateAction<tNameSetting>>;
};

export default ({ active, setActive }: Tprops) => {
    return (
        <AnimatePresence>
            <ButtonSetting
                key={'Telegram1'}
                type={'Telegram'}
                setActiveSetting={setActive}
                activeName={active}
            />
            <ButtonSetting
                key={'Discord2'}
                type={'Discord'}
                setActiveSetting={setActive}
                activeName={active}
            />
            <ButtonSetting
                key={'WebSite3'}
                type={'WebSite'}
                setActiveSetting={setActive}
                activeName={active}
            />
            <ButtonSetting
                key={'Email4'}
                type={'Email'}
                setActiveSetting={setActive}
                activeName={active}
            />
        </AnimatePresence>
    );
};
