import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import {
    setBDExeptionState,
    setExeptionState,
    setSpamState,
} from '@maket/js/state/banAndMuteRules';
import { TappDispatch } from '@maket/js/state/store';
import { TbanState } from '@maket/js/types/state/banAndMute';
import axios from 'axios';

type Tprops = {
    token: string;
    dispatch: TappDispatch;
    rulesProfile: null | string;
};

export default async ({ token, dispatch, rulesProfile }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.get<TbanState>(apiUrl + `/profile/${rulesProfile}/rules/unban`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        setVisableLoader('notVisable');
        dispatch(setExeptionState(req.data));
        dispatch(setBDExeptionState(req.data));
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
