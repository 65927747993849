import React from 'react';
import style from './style';

type Tprops = { iconName: string };

export default ({ iconName }: Tprops) => {
    const { ContactButton, ContactButtonIcon } = style();
    return (
        <ContactButton>
            <ContactButtonIcon className={`icon-${iconName}`} />
        </ContactButton>
    );
};
