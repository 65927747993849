import { createSlice } from '@reduxjs/toolkit';

const initialState: {
    state: boolean;
    activeKey: string;

    popapStateSub: boolean;
    popapStateOffSub: boolean;
    popapStateEnterprice: boolean | 'question';

    errorCancel: string;
} = {
    state: true,
    activeKey: '',
    popapStateSub: false,
    popapStateOffSub: false,
    popapStateEnterprice: false,
    errorCancel: '',
};

const tariffState = createSlice({
    name: 'tariffState',
    initialState,
    reducers: {
        setSwithState: (state, { payload }: { payload: boolean }) => {
            state.state = payload;
        },
        setActiveKey: (state, { payload }: { payload: string }) => {
            state.activeKey = payload;
        },
        setPopapStateSub: (state, { payload }: { payload: boolean }) => {
            state.popapStateSub = payload;
        },
        setPopapStateOffSub: (state, { payload }: { payload: boolean }) => {
            state.popapStateOffSub = payload;
        },
        setPopapStateEnterprice: (state, { payload }: { payload: boolean | 'question' }) => {
            state.popapStateEnterprice = payload;
        },
        setErrorCancelTariff: (state, { payload }: { payload: string }) => {
            state.errorCancel = payload;
        },
    },
});

export const {
    setSwithState,
    setActiveKey,
    setPopapStateSub,
    setPopapStateOffSub,
    setErrorCancelTariff,
    setPopapStateEnterprice,
} = tariffState.actions;

export default tariffState;
