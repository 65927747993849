import React from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';
import Button from '@js/components/microComponets/button';
import { NavLink } from 'react-router-dom';

export default () => {
    const { Container, Title, Text, Background, BackgroundMask, size } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <Container>
            <Title>{activeLanguage['Main.Start.Title']}</Title>
            <Text>{activeLanguage['Main.Start.Text']}</Text>
            <NavLink
                style={{
                    display: 'block',
                    width: size.isMobile ? '100%' : size.isTab ? '50%' : '29%',
                    zIndex: 2,
                    margin: '0 auto',
                }}
                to={'/app/auth/auth'}
                onClick={() => {
                    localStorage.setItem(
                        'MZPreRegTariff',
                        JSON.stringify({ tariffKey: 'free', timestamp: +new Date() }),
                    );
                }}
            >
                <Button
                    style={{
                        width: '100%',
                        zIndex: 2,
                        margin: '0 auto',
                        fontSize: size.isMobile || size.isTab ? '1.75em' : '',
                    }}
                    typeButt="blue"
                >
                    {activeLanguage['Main.Button.Trial']}
                </Button>
            </NavLink>
            <BackgroundMask />
            <Background src="/img/background/home__start.svg"></Background>
        </Container>
    );
};
