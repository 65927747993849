import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { Tuser } from '@maket/js/types/state/auth';
import axios from 'axios';

type Tprops = {
    token: string;
    name: string;
    language: string;
};

export default async ({ token, name, language }: Tprops) => {
    setVisableLoader('visable');
    const req = await axios.post(
        apiUrl + '/profile/create',
        { name, language },
        {
            headers: { Authorization: `Bearer ${token}` },
        },
    );
    setVisableLoader('notVisable');
    return req;
};
