import { TlanguageList } from '../types/state/language';

export const isStrictMode = false;

export const apiUrl = 'https://bot-api.mazekeeper.com/api/v1';
export const tgUserLinkBase = 'https://t.me/';

export const noAuthPaht = [
    '/app/auth/auth',
    '/app/auth/reg',
    '/app/termsOfUse',
    '/app/documentation',
    '/app/fogotPass',
    '/app/success/auth',
    '/app/confirmMail',
    '/app/success/fogotPass',
    '/app/confirmFogotPass',
    '/app/confirmChangeEmail',
    '/app/success/change_email_confirm',
    'app/change_email/confirm',
    'app/change_password/confirm',
    'app/success/auth',
    'app/privacyPolicy',
    'app/cookiePolicy',
    'app/refundPolicy',
];
export const sidebarFixedPath = [
    '/',
    '/app/auth/auth',
    '/app/auth/reg',
    '/app/termsOfUse',
    '/app/fogotPass',
    '/app/success',
];

export const paymentsRedirectUrl = 'https://mazekeeper.com/app/billing';

export const maxSimbolRules = 3000;
export let userSimbolCounter = 3000;
export function setUserSimbolCounter(v: number) {
    userSimbolCounter = v;
}

export const langBdConfig: TlanguageList = {
    ru: 'RU',
    en: 'EN',
    de: 'DE',
    pt: 'PT',
    es: 'ES',
    zh: 'ZH',
    uk: 'UK',
    fr: 'FR',
    et: 'ET',
    el: 'EL',
};

export const mailRegExp = /^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$/;
export const passwordRegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,40}$/;

export const fbAppId = '1041539757659186';
export const googleClientId =
    '448129692587-mujtsnijeg6ped85a8b1brc8rvrikpda.apps.googleusercontent.com';

export const saveCheckUrlAtr = ['chatRules', 'profileSettings', 'myData', 'safety'];
