import React, { memo, useEffect } from 'react';
import style from './style';
import { TauthPage } from '../..';
import Input from '@js/components/microComponets/input';
import useAppSelector from '@js/hooks/useAppSelector';
import { comparsion } from '..';
import {
    setInputFirstNameValue,
    setInputLastNameValue,
    setInputMailValue,
    setInputPassValue,
} from '@js/state/authInputsValue/authInputsValueState';
import { mailRegExp, passwordRegExp } from '@maket/js/constants/values';

type Tprops = {
    type: TauthPage;
};

export default memo(({ type }: Tprops) => {
    const { InputBox, InputItem } = style(type);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    useEffect(() => {
        const regInputMail = document.getElementById('regInputMail');
        const regInputPass = document.getElementById('regInputPass');
        if (regInputMail) {
            const input = regInputMail.querySelector('input');
            input && (input.value = '');
        }
        if (regInputPass) {
            const input = regInputPass.querySelector('input');
            input && (input.value = '');
        }
    }, []);

    return (
        <InputBox>
            {type == 'reg' && (
                <InputItem>
                    <IsolateInput
                        id="regInputFirstname"
                        placeholder={activeLanguage[comparsion[type].firstNamePlaceholder]}
                        iconName="userReg"
                        typeInput="text"
                        autocomplete="given-name"
                    />

                    <IsolateInput
                        id="regInputLastname"
                        placeholder={activeLanguage[comparsion[type].lastNamePlaceholder]}
                        iconName="userReg"
                        typeInput="text"
                        autocomplete="additional-name"
                    />
                </InputItem>
            )}
            <InputItem>
                <IsolateInput
                    id="regInputMail"
                    placeholder={activeLanguage[comparsion[type].mailPlaceholder]}
                    iconName="mailReg"
                    typeInput="text"
                    regExp={type == 'reg' ? mailRegExp : undefined}
                />
                <IsolateInput
                    id="regInputPass"
                    placeholder={activeLanguage[comparsion[type].passPlaceholder]}
                    iconName="passReg"
                    regExp={type == 'reg' ? passwordRegExp : undefined}
                    typeInput="password"
                />
            </InputItem>
        </InputBox>
    );
});

function IsolateInput({
    id,
    placeholder,
    iconName,
    typeInput,
    regExp,
    autocomplete,
}: {
    id: string;
    placeholder: string;
    iconName: string;
    typeInput: string;
    regExp?: RegExp;
    autocomplete?: string;
}) {
    const config = {
        regInputFirstname: setInputFirstNameValue,
        regInputLastname: setInputLastNameValue,
        regInputMail: setInputMailValue,
        regInputPass: setInputPassValue,
    };

    const values = useAppSelector(state => state.authInputsValueState);

    const configValues = {
        regInputFirstname: values.firstName,
        regInputLastname: values.lastName,
        regInputMail: values.mail,
        regInputPass: values.pass,
    };

    return (
        <Input
            id={id}
            value={configValues[id]}
            setExtState={config[id]}
            placeholder={placeholder}
            iconName={iconName}
            typeInput={typeInput}
            regExp={regExp}
            autocomplete={autocomplete}
        />
    );
}
