import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';
import { NavLink } from 'react-router-dom';

export default () => {
    const size = useWindowSizeCheck();
    const { buttonBlue } = useTheme();
    return {
        Container: styled.div({
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile || size.isTab ? '1em' : '2em',
        }),
        Title: styled.div({
            fontSize: size.isMobile || size.isTab ? '1.5em' : '2.75em',
            fontWeight: 600,
        }),
        Text: styled.div({
            fontSize: size.isMobile || size.isTab ? '0.75em' : '1.125em',
        }),
        TopBox: styled.div({
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            gap: size.isMobile || size.isTab ? '0.5em' : '1em',
        }),

        AcceptBox: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: '1em',
            fontSize: '0.875em',
        }),
        AcceptText: styled.div({}),
        AcceptLink: styled(NavLink)({
            color: buttonBlue,
        }),
        size,
    };
};
