import useAppDispatch from '@js/hooks/useAppDispatch';
import useAppSelector from '@js/hooks/useAppSelector';
import { setActiveLanguage } from '@js/state/language/languageState';
import postUserLang from '@maket/js/api/requiresToken/account/postUserLang';
import { setUserLangServer } from '@maket/js/state/auth/authState';
import React, { useEffect } from 'react';

export default () => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(state => state.languageState.language);
    const token = useAppSelector(state => state.tokenState.token);
    const userLangServer = useAppSelector(state => state.authState.account?.language);

    useEffect(() => {
        dispatch(setActiveLanguage());
    }, [language]);

    useEffect(() => {
        if (
            language &&
            userLangServer != undefined &&
            language != 'undefined' &&
            token &&
            userLangServer != language.toLowerCase()
        ) {
            postUserLang({ token, lang: language.toLowerCase() }).then(res => {
                if (res.data.status == 'ok') {
                    dispatch(setUserLangServer(language.toLowerCase()));
                }
            });
        }
    }, [token, language, userLangServer]);
    return <></>;
};
