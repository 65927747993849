import React from 'react';
import style from './style';
import { TargetAndTransition } from 'framer-motion';
import PopapButton from './popapButton';
import useAppSelector from '@js/hooks/useAppSelector';
import { tNameSetting } from '../box';

type Tprops = {
    setActive: React.Dispatch<React.SetStateAction<tNameSetting>>;
    active: tNameSetting;
};

const animateCompose = (active: string): TargetAndTransition => {
    if (active == 'mobileButtonHeaderSettings') {
        return { opacity: 1, pointerEvents: 'all' };
    } else {
        return { opacity: 0, pointerEvents: 'none' };
    }
};

export default ({ setActive, active }: Tprops) => {
    const activePopapName = useAppSelector(state => state.openPopapState.activePopapName);
    const { MobilePopap } = style();
    return (
        <MobilePopap animate={animateCompose(activePopapName)}>
            <PopapButton
                key={'Telegram13'}
                type={'Telegram'}
                setActiveSetting={setActive}
                active={active}
            />
            <PopapButton
                key={'Discord23'}
                type={'Discord'}
                setActiveSetting={setActive}
                active={active}
            />
            <PopapButton
                key={'WebSite33'}
                type={'WebSite'}
                setActiveSetting={setActive}
                active={active}
            />
            <PopapButton
                key={'Email43'}
                type={'Email'}
                setActiveSetting={setActive}
                active={active}
            />
        </MobilePopap>
    );
};
