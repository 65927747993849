import React from 'react';
import style from './style';
import Left from './left';
import Right from './right';
import useAppSelector from '@js/hooks/useAppSelector';

export default () => {
    const { Container, Title, Main, Annotation } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <Container>
            <Title>{activeLanguage['Main.Installation.Title']}</Title>
            <Main>
                <Left />
                <Right />
            </Main>
            <Annotation>{activeLanguage['Main.Installation.Annotation']}</Annotation>
        </Container>
    );
};
