import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { setLoggerList } from '@maket/js/state/auth/authState';
import { TappDispatch } from '@maket/js/state/store';
import { Tlogger, Tuser } from '@maket/js/types/state/auth';
import axios from 'axios';

type Tprops = {
    token: string;
    dispatch: TappDispatch;
    mail: string;
    firstname: string;
    lastname: string;
    company: string;
    lang: string;
    chatQuantity?: string;
    question?: string;
};

export default async ({
    token,
    dispatch,
    mail,
    firstname,
    lang,
    lastname,
    chatQuantity,
    company,
    question,
}: Tprops) => {
    setVisableLoader('visable');
    let sendObj = {};
    let method = '';
    if (question) {
        sendObj = {
            email: mail,
            first_name: firstname,
            last_name: lastname,
            question: question,
            language: lang.toLowerCase(),
        };
        method = '/contact/sales';
    } else {
        sendObj = {
            email: mail,
            first_name: firstname,
            last_name: lastname,
            company: company,
            chats_count: chatQuantity,
            language: lang.toLowerCase(),
        };
        method = '/contact/connect_enterprise';
    }
    const req = await axios.post<Array<Tlogger>>(apiUrl + method, sendObj, {
        headers: { Authorization: `Bearer ${token}` },
    });
    setVisableLoader('notVisable');
    return req;
};
