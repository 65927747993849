import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    return {
        Container: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile ? '2em' : size.isTab ? '4em' : '6em',
            flex: size.isMobile || size.isTab ? '1 1' : '0 0 50%',
        }),
        Title: styled.div({
            fontSize: size.isMobile || size.isTab ? '2em' : '3.75em',
            fontWeight: 800,
        }),
        ListCard: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5em',
        }),
        size,
    };
};
