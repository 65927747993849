import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';

export default () => {
    const size = useWindowSizeCheck();
    return {
        Container: styled.div({
            padding: size.isLaptop
                ? '6em 4em'
                : size.isTab || size.isMobile
                ? '2em 1em'
                : '10em 4em',
            fontFamily: 'Inter, sans-serif',
            display: 'flex',
            flexDirection: size.isMobile || size.isTab ? 'column' : 'row',
            fontSize: size.isDesktop ? '1em' : size.isMicroMobile ? '0.7em' : '0.9em',
        }),
        size,
    };
};
