import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

export default () => {
    const size = useWindowSizeCheck();
    const { grayShadowColor, whiteText, grayTextSelect, hoverLiteGreen } = useTheme();
    return {
        Container: styled.div({
            position: 'relative',
        }),
        Icon: styled.img({
            width: '2.25em',
            height: '2.25em',
            overflow: 'hidden',
            borderRadius: '50%',
        }),
        Popap: styled(motion.div)({
            position: 'absolute',
            right: 0,
            bottom: '-10px',

            display: 'flex',
            flexDirection: 'column',
            padding: '1.5em',
            boxShadow: `0 0 10px ${grayShadowColor}`,
            backgroundColor: whiteText,
            borderRadius: 8,

            opacity: 0,
            pointerEvents: 'none',
            zIndex: 100,
        }),
        PopapItem: styled(NavLink)({
            padding: '0.75em',
            color: grayTextSelect,
            transition: '0.3s',
            width: 'calc((30px + 1.5em)*4.2)',
            borderRadius: 4,

            ':hover': {
                backgroundColor: hoverLiteGreen,
            },
        }),
        size,
    };
};
