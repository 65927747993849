import React from 'react';
import style from './style';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import Notification from './notification';
import Profile from './profile';

type Tprops = {};

export default ({}: Tprops) => {
    const { LoginButton, Container } = style();

    const user = useAppSelector(state => state.authState.user);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    if (user) {
        return (
            <Container>
                {/* <Notification /> */}
                <Profile />
            </Container>
        );
    } else {
        return <LoginButton to={'/app/auth/auth'}>{activeLanguage['Login.']}</LoginButton>;
    }
};
