import { CSpinner } from '@coreui/react';
import React from 'react';

export default () => {
    return (
        <div
            id="loader"
            className="opacityNone"
            style={{ position: 'fixed', top: '5em', right: '2em', zIndex: 10000 }}
        >
            <CSpinner color="primary" />
        </div>
    );
};

export function setVisableLoader(type: 'visable' | 'notVisable') {
    const loader = document.getElementById('loader');
    if (type == 'notVisable') {
        loader?.classList.add('opacityNone');
    } else {
        loader?.classList.remove('opacityNone');
    }
}
