import { createSlice } from '@reduxjs/toolkit';

const initialState: { state: boolean } = {
    state: true,
};

const sidebarSickyState = createSlice({
    name: 'sidebarSickyState',
    initialState,
    reducers: {
        setStateSidebar: (state, { payload }: { payload?: boolean }) => {
            if (payload) {
                state.state = payload;
            } else {
                state.state = !state.state;
            }
        },
    },
});

export const { setStateSidebar } = sidebarSickyState.actions;

export default sidebarSickyState;
