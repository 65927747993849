import React, { useEffect } from 'react';
import style from './style';
import { AnimatePresence } from 'framer-motion';
import Alert from '@js/components/microComponets/alert';
import { TalertState } from '.';
import useAppSelector from '@maket/js/hooks/useAppSelector';

const comparison: { [key: string]: string } = {
    stateAlert: 'Auth.Alert.SuccessNoConfirm',
    successConfirm: 'Auth.Alert.SuccessConfirm',
    mailSend: 'Auth.Alert.MailSend',
    mailErrorSend: 'Auth.Alert.MailSendError',
    mailErrorLength: 'Auth.Alert.MailLengthError',
    authError: 'Auth.Alert.AuthError',

    mailErrorRegExp: 'Auth.Alert.MailErrorRegExp',
    passwordErrorRegExp: 'Safety.Alert.ErrorPassRegExp',

    incorrectTgAccount: 'Auth.Allert.IncorrectTgAccount',
    emailAlreadyExists: 'Auth.Alert.EmailAlreadyExists',
    MailNotFound: 'Auth.Alert.MailNotFound',
    PassReset: 'FogotPass.Alert.PassReset',

    ChangeEmailConfirm: 'Auth.Allert.ChangeEmailConfirm',
    ChangeEmailErr: 'Auth.Allert.ChangeEmailErr',

    NotAllField: 'Auth.NotAllField',

    AcceptError: 'Auth.Accept.Error',
};

type Tprops = {
    stateAlert: TalertState;
};
let lastText = '';
export default ({ stateAlert }: Tprops) => {
    const { AlertBox } = style();

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    useEffect(() => {
        lastText = stateAlert.textAllert;
    });
    return (
        <AnimatePresence>
            {stateAlert.typeAllert && (
                <AlertBox
                    initial={{ transform: 'translate(0, 70%)', opacity: 0 }}
                    animate={{ transform: 'translate(0, 120%)', opacity: 1 }}
                    exit={{ transform: 'translate(0, 70%)', opacity: 0 }}
                    transition={{ duration: lastText == stateAlert.textAllert ? 0 : 0.3 }}
                >
                    <Alert style={{ width: '100%' }} type={stateAlert.typeAllert}>
                        {activeLanguage[comparison[stateAlert.textAllert]]}
                    </Alert>
                </AlertBox>
            )}
        </AnimatePresence>
    );
};
