import React, { useEffect, useRef } from 'react';
import style from './style';

import Main from './main';
import ButtonMain from './buttonMain';
import Counter from './counter';
import UpgradeButton from '@components/middleComponents/upgradeButton';
import { CreateTabs } from '@maket/js/components/middleComponents/routeWrapper';
import { useParams } from 'react-router-dom';
import ProfileSelect from '@maket/js/components/middleComponents/routeWrapper/profileSelect';
import useAppSelector from '@maket/js/hooks/useAppSelector';

export default () => {
    const bottomRef = useRef<HTMLDivElement>(null);
    const { Container, Top, TitleBox, Title, size } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    useEffect(() => {
        if (bottomRef.current && !(size.isMobile || size.isTab)) {
            // @ts-ignore: Unreachable code error
            const maxHeightChildren = bottomRef.current.firstChild?.scrollHeight;
            bottomRef.current.setAttribute('style', `height: ${maxHeightChildren}px;`);
        }
    });

    const { idProfile } = useParams();

    return (
        <Container
            style={{
                padding: size.isMobile
                    ? '1.5em 0.5em'
                    : size.isTab
                    ? '1.75em 2.31em'
                    : '2.75em 2.75em',
            }}
        >
            <Top>
                <TitleBox>
                    <ProfileSelect
                        title={
                            idProfile
                                ? activeLanguage['ChatRules.Profile.Title']
                                : activeLanguage['Menu.ChatRules']
                        }
                        pageName="ChatRules"
                    />
                    <Counter />
                </TitleBox>
                <UpgradeButton />
            </Top>
            {idProfile != undefined && (
                <CreateTabs style={{ marginTop: size.isMobile || size.isTab ? '0' : '-1em' }} />
            )}
            <Main />
            <ButtonMain />
        </Container>
    );
};
