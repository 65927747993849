import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { installGrayText } = useTheme();
    return {
        Container: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile || size.isTab ? '2em' : '',
            padding: size.isMobile || size.isTab ? '3em 1em' : '7.5em 4em',
            fontFamily: 'Inter, sans-serif',

            fontSize: size.isLaptop ? '0.7em' : '',
        }),
        Title: styled.div({
            width: size.isMobile || size.isTab ? '100%' : '42%',
            fontSize: size.isMicroMobile ? '2em' : size.isMobile || size.isTab ? '3em' : '5.625em',
            fontWeight: 800,
            textAlign: size.isMobile || size.isTab ? 'center' : 'start',
        }),
        Main: styled.div({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: size.isMobile || size.isTab ? 'column-reverse' : 'row',
        }),
        Annotation: styled.div({
            fontSize: size.isDesktop
                ? '0.875rem'
                : size.isMobile || size.isTab
                ? '0.9em'
                : '0.8rem',
            color: installGrayText,
            width: size.isLaptop ? '26vw' : size.isMobile || size.isTab ? '100%' : '20vw',
            marginTop: size.isMobile || size.isTab ? '0em' : size.isLaptop ? '2em' : '4em',
        }),
        size,
    };
};
