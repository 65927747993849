import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import axios from 'axios';

type Tprops = {
    token: string;
    first_name: string;
    last_name: string;
    photo: any;
};

export default async ({ token, first_name, last_name, photo }: Tprops) => {
    setVisableLoader('visable');
    const req = await axios
        .post(
            apiUrl + '/account/update',
            { first_name, last_name, avatar: photo },
            {
                headers: { Authorization: `Bearer ${token}` },
            },
        )
        .then(res => {
            setVisableLoader('notVisable');
            return res;
        })
        .catch(err => {
            setVisableLoader('notVisable');
            return err;
        });
    return req;
};
