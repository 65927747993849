import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { whiteText } = useTheme();
    return {
        Container: styled.div({
            zIndex: 1,
            color: whiteText,
        }),
        Title: styled.div({
            fontSize: size.isMicroMobile
                ? '2.5em'
                : size.isMobile || size.isTab
                ? '3em'
                : '5.625em',
            fontWeight: 800,
            textAlign: size.isMobile || size.isTab ? 'center' : 'start',
        }),
        size,
    };
};
