import React, { useEffect, useState } from 'react';
import style from './style';
import Button from '@maket/js/components/microComponets/button';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import accountUpdate from '@maket/js/api/requiresToken/account/accountUpdate';
import accountUpdatePhoto from '@maket/js/api/requiresToken/account/accountUpdatePhoto';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import getAccount from '@maket/js/api/requiresToken/account/getAccount';
import {
    clearChangeMyData,
    setInputPhotoSettingsValue,
} from '@maket/js/state/settingAccount/settingAccountValueState';
import ButtonAllert, { TalertState } from '@maket/js/components/microComponets/buttonAllert';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { setBlockedPopap, setOnlyStateBlockedPopap } from '@maket/js/state/savedForms';

type Tprops = {};

export default ({}: Tprops) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.tokenState.token);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const { size } = style();
    const location = useLocation();
    const settingAccount = useAppSelector(state => state.settingAccountValueState);
    const account = useAppSelector(state => state.authState.account);

    const [notClicked, setNotClicked] = useState(true);

    const [AllertState, setAllertState] = useState<TalertState>({ textAllert: '', typeAllert: '' });

    function setAllertStateLoc(v: TalertState) {
        if (v.textAllert != AllertState.textAllert) {
            setAllertState(v);
        }
    }

    useEffect(() => {
        console.log(settingAccount);
    }, [settingAccount]);
    useEffect(() => {
        console.log(account);
    }, [account]);
    useEffect(() => {
        console.log(notClicked);
    }, [notClicked]);

    useEffect(() => {
        if (location.state) {
            if (location.state.confirm == 'confirmChangeEmailErr') {
                setAllertStateLoc({
                    textAllert: 'MyData.Avatar.Error',
                    typeAllert: 'danger',
                });
            }
            if (location.state.confirm == 'confirmChangeEmailErrSuccess') {
                setAllertStateLoc({
                    textAllert: 'MyData.Avatar.Error',
                    typeAllert: 'danger',
                });
            }
        }
    }, [location.state]);

    useEffect(() => {
        let check = false;
        if (account?.first_name != settingAccount.firstName) {
            check = true;
        }
        if (account?.last_name != settingAccount.lastName) {
            check = true;
        }
        if (settingAccount.photo) {
            check = true;
        }

        setTimeout(() => {
            if (check) {
                if (AllertState.textAllert) {
                    setTimeout(() => {
                        setNotClicked(false);
                    }, 2000);
                } else {
                    setNotClicked(false);
                }
            } else {
                if (AllertState.textAllert) {
                    setTimeout(() => {
                        setNotClicked(true);
                    }, 2000);
                } else {
                    setNotClicked(true);
                }
            }
        }, 500);
    }, [settingAccount, account]);

    useBlocker(n => {
        if (!notClicked) {
            // const historyState = { ...settingProfileValueState };
            dispatch(
                setBlockedPopap({
                    state: true,
                    resolveFn: () => {
                        buttonClickFn(
                            () => {
                                setTimeout(() => {
                                    navigate(n.nextLocation.pathname);
                                }, 1000);
                            },
                            () => {
                                dispatch(setOnlyStateBlockedPopap(false));
                            },
                        );

                        // dispatch(clearChange());

                        dispatch(
                            setBlockedPopap({
                                state: false,
                                rejectFn: () => {},
                                resolveFn: () => {},
                            }),
                        );
                    },
                    rejectFn: () => {
                        dispatch(
                            clearChangeMyData({
                                firstName: account?.first_name,
                                lastName: account?.last_name,
                                photo: undefined,
                            }),
                        );

                        setTimeout(() => {
                            navigate(n.nextLocation.pathname);
                        }, 1000);

                        dispatch(
                            setBlockedPopap({
                                state: false,
                                rejectFn: () => {},
                                resolveFn: () => {},
                            }),
                        );

                        return false;
                    },
                }),
            );
            // dispatch(setHistoryProfile(historyState));
            // dispatch(setHistoryProfileAuth({ historyState, idProfile }));
            return true;
        } else {
            return false;
        }
    });

    function buttonClickFn(outerFN?: () => void, outerExitFn?: () => void) {
        if (settingAccount.firstName.length > 49) {
            setAllertStateLoc({
                textAllert: 'MyData.AllertLengthFirstName',
                typeAllert: 'danger',
            });
            outerExitFn && outerExitFn();
        } else if (settingAccount.lastName.length > 49) {
            setAllertStateLoc({
                textAllert: 'MyData.AllertLengthLastName',
                typeAllert: 'danger',
            });
            outerExitFn && outerExitFn();
        } else if (settingAccount.photo) {
            accountUpdatePhoto({ token, photo: settingAccount.photo })
                .then(res => {
                    if (res.data && res.data.filename) {
                        accountUpdate({
                            token,
                            first_name: settingAccount.firstName,
                            last_name: settingAccount.lastName,
                            photo: res.data.filename,
                        })
                            .then(res => {
                                setTimeout(() => {
                                    getAccount({ dispatch, token });
                                    dispatch(setInputPhotoSettingsValue(null));
                                }, 2500);
                            })
                            .then(res => {
                                setAllertStateLoc({
                                    textAllert: 'MyData.Update.Success',
                                    typeAllert: 'success',
                                });
                                outerFN && outerFN();
                            });
                    } else {
                        setAllertStateLoc({
                            textAllert: 'MyData.Avatar.Error',
                            typeAllert: 'danger',
                        });
                        outerExitFn && outerExitFn();
                    }
                })
                .catch(err => {
                    setAllertStateLoc({
                        textAllert: 'MyData.Avatar.Error',
                        typeAllert: 'danger',
                    });
                    outerExitFn && outerExitFn();
                });
        } else {
            accountUpdate({
                token,
                first_name: settingAccount.firstName,
                last_name: settingAccount.lastName,
                photo: null,
            })
                .catch(() => {
                    setAllertStateLoc({
                        textAllert: 'MyData.Update.Error',
                        typeAllert: 'danger',
                    });
                    outerExitFn && outerExitFn();
                })
                .then(res => {
                    if (res.name == 'AxiosError') {
                        setAllertStateLoc({
                            textAllert: 'MyData.Update.Error',
                            typeAllert: 'danger',
                        });
                        outerExitFn && outerExitFn();
                    } else {
                        setAllertStateLoc({
                            textAllert: 'MyData.Update.Success',
                            typeAllert: 'success',
                        });
                        outerFN && outerFN();
                    }
                })
                .then(res => {
                    setTimeout(() => {
                        getAccount({ dispatch, token });
                        dispatch(setInputPhotoSettingsValue(null));
                    }, 2500);
                });
        }
    }

    return (
        <ButtonAllert
            setAllertFn={setAllertStateLoc}
            stateAllertOuter={AllertState}
            onClick={() => {
                const loader = document.getElementById('loader');
                const check = loader?.className.includes('opacityNone');
                if (check) {
                    buttonClickFn();
                }
            }}
            style={{ width: size.isMobile || size.isTab ? '100%' : '10em' }}
            typeButt="black"
            disabled={notClicked}
            buttonContent={activeLanguage['Save.']}
            styleAlert={{
                // @ts-ignore: Unreachable code error
                width: `calc(${
                    size.isMobile || size.isTab ? '88vw' : size.isLaptop ? '60vw' : '100vw - 31em'
                } - ${size.isMobile ? '0' : size.isTab ? '3.5em' : '12em'})`,
                left: `calc(
					(${size.isMobile || size.isTab ? '88vw' : size.isLaptop ? '60vw' : '100vw - 31em'} 
					+ ${size.isMobile || size.isTab ? '0' : '12em'}
				)/2)`,
                bottom: size.isMobile || size.isTab ? '' : 'calc(130%)',
            }}
            timer={2500}
        ></ButtonAllert>
    );
};
