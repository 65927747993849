import React, { useEffect } from 'react';
import Input from '@maket/js/components/microComponets/input';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import style from './style';
import { Tprofile } from '@maket/js/types/state/auth';
import { setInputNameSettingsValue } from '@maket/js/state/settingProfile/settingPassValueState';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { useBlocker, useLocation, useParams } from 'react-router';

let lastWidth = Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.body.clientWidth,
    document.documentElement.clientWidth,
);

export default ({ settingProfile }: { settingProfile: Tprofile | undefined }) => {
    const { Container, Item, ItemTitle } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    return (
        <Container>
            <Item>
                <ItemTitle>{activeLanguage['ProfileSettings.Profile.Name']}</ItemTitle>
                <IsolateInput settingProfile={settingProfile} />
            </Item>
        </Container>
    );
};

function IsolateInput({ settingProfile }: { settingProfile: Tprofile | undefined }) {
    const dispatch = useAppDispatch();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);

    const { name } = useAppSelector(state => state.settingProfileValueState);

    useEffect(() => {
        let scrollWidth = Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.body.clientWidth,
            document.documentElement.clientWidth,
        );
        if (settingProfile && lastWidth == scrollWidth) {
            dispatch(setInputNameSettingsValue(settingProfile?.name));
        }
    }, [settingProfile]);

    return (
        <Input
            value={name || activeLanguage['ProfileList.Name.Init']}
            iconName="userReg"
            placeholder={activeLanguage['ProfileSettings.Profile.Name']}
            setExtState={setInputNameSettingsValue}
        />
    );
}
