export default {
    'en-US': 'EN',
    'en-AU': 'EN',
    'en-CA': 'EN',
    'en-GB': 'EN',
    'en-IE': 'EN',
    'en-IN': 'EN',
    'en-NZ': 'EN',
    'en-ZA': 'EN',

    'de-AT': 'GE',
    'de-CH': 'GE',
    'de-DE': 'GE',

    'pt-PT': 'PT',

    'es-AR': 'ES',
    'es-CL': 'ES',
    'es-CO': 'ES',
    'es-ES': 'ES',
    'es-MX': 'ES',
    'es-US': 'ES',

    'ru-RU': 'RU',
    ru: 'RU',

    'zh-CN': 'ZH',
    'zh-HK': 'ZH',
    'zh-TW': 'ZH',
};

export const langCodes = ['EN', 'RU', 'GE'];
