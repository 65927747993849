import React, { useEffect } from 'react';
import useTheme from '@maket/js/hooks/useTheme';
import RouteWrapper from '../../middleComponents/routeWrapper';
import Main from './main';
import chats from '@maket/js/api/requiresToken/chat/chats';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import useAppSelector from '@maket/js/hooks/useAppSelector';

export default () => {
    const { authBackground } = useTheme();
    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.tokenState.token);
    useEffect(() => {
        document.body.setAttribute('style', `background-color: ${authBackground}`);
    });

    useEffect(() => {
        if (token) {
            chats({ dispatch, token, profile_id: 0, allChats: true });
        }
    }, [token]);

    return <RouteWrapper title="" mainComponent={<Main />} type="onliWrapper" />;
};
