import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@maket/js/hooks/useTheme';

export default (type: 'addChat' | 'connectTg' | 'upgrade', line?: boolean) => {
    const size = useWindowSizeCheck();
    const { disconnectRed, buttonBlue, navGrayText } = useTheme();
    return {
        Container: styled.div({
            margin: line ? '0' : '0 1em',
            backgroundColor:
                type == 'addChat' ? disconnectRed : type == 'connectTg' ? buttonBlue : navGrayText,
            display: 'flex',
            flexDirection: line ? 'row' : 'column',
            alignItems: line ? 'center' : '',
            gap: '0.75em',
            padding: '1em',
            fontFamily: 'Manrope, sans-serif',
            fontWeight: 600,
            fontSize: '0.935em',
        }),
        IconBox: styled.div({
            display: 'flex',
            gap: '1em',
            alignItems: 'center',
        }),
        Icon: styled.div({
            fontSize: '1.75em',
        }),
        IconText: styled.div({
            fontSize: type == 'upgrade' ? '1.35em' : '',
        }),
        Text: styled.div({
            fontWeight: type == 'connectTg' ? 600 : 400,
            lineHeight: '1.5em',
            fontSize: type == 'connectTg' ? '1.3em' : '',
        }),
        size,
    };
};
