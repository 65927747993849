import postSubscribeCancel from '@maket/js/api/requiresToken/payments/postSubscribeCancel';
import Popap from '@maket/js/components/middleComponents/popap';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { TappDispatch } from '@maket/js/state/store';
import { setErrorCancelTariff, setPopapStateOffSub } from '@maket/js/state/tariffSwith';
import React from 'react';
import style from './style';

export default () => {
    const dispatch = useAppDispatch();
    const { size } = style();

    const popapState = useAppSelector(state => state.tariffState.popapStateOffSub);
    const token = useAppSelector(state => state.tokenState.token);

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    return (
        <Popap
            setState={v => dispatch(setPopapStateOffSub(v))}
            state={popapState}
            setFunction={() => clickButton(dispatch, token)}
            title={activeLanguage['Main.Price.CancelPopap.Title']}
            main={activeLanguage['Main.Price.CancelPopap.Text']}
            buttonText={activeLanguage['Main.Price.CancelPopap.ButtonAgree']}
            closeText={activeLanguage['Main.Price.CancelPopap.ButtonExit']}
            buttonStyles={{ width: '100%' }}
        />
    );
};

function clickButton(dispatch: TappDispatch, token: string) {
    postSubscribeCancel({ dispatch, token, serviceType: 'telegram' }).then(res => {
        if (res?.error != '') {
            dispatch(setErrorCancelTariff(res?.error));
        }
    });
}
