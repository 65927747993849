import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { setLoggerList } from '@maket/js/state/auth/authState';
import { TappDispatch } from '@maket/js/state/store';
import { Tlogger, Tuser } from '@maket/js/types/state/auth';
import axios from 'axios';

type Tprops = {
    token: string;
    dispatch: TappDispatch;
    profile_id: number;
    logger_channel_id: number;
};

export default async ({ token, dispatch, profile_id, logger_channel_id }: Tprops) => {
    try {
        setVisableLoader('visable');
        const req = await axios.post<Array<Tlogger>>(
            apiUrl + '/profile/set_logger',
            { profile_id, logger_channel_id },
            {
                headers: { Authorization: `Bearer ${token}` },
            },
        );
        setVisableLoader('notVisable');
        // dispatch(setLoggerList(req.data));
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
