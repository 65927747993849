import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme, { staticColor } from '@js/hooks/useTheme';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

export default (type?: 'page', unfoldable?: boolean) => {
    const size = useWindowSizeCheck();
    const { main__previewBackground, buttonBlue, darkGray } = useTheme();
    return {
        Container: styled(motion.div)({
            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
            padding:
                size.isTab || size.isMobile ? '4em 2em' : type == 'page' ? '2em' : '6.75em 4em',
            fontFamily: 'Inter, sans-serif',
            color: main__previewBackground,

            maxWidth: type == 'page' && unfoldable ? 'calc(100vw - 5rem)' : '',
            marginLeft: type == 'page' && unfoldable ? '4rem' : '',

            maxHeight:
                type == 'page' ? (size.isMobile ? '' : 'calc(100vh - 3.4em)') : 'fit-content',
            height: type == 'page' ? (size.isMobile ? '' : 'calc(100vh - 3.4em)') : 'fit-content',

            fontSize: size.isDesktop ? '1em' : size.isLaptop ? '0.7em' : '0.5em',
            overflowX: type == 'page' ? 'auto' : 'initial',
        }),
        Title: styled.div({
            fontSize: size.isMicroMobile
                ? '3em'
                : size.isTab || size.isMobile
                ? '4em'
                : type == 'page'
                ? '2em'
                : '3.75em',
            fontWeight: 800,
            display: 'flex',
            gap: '0.26em',
            width: size.isTab || size.isMobile ? '100%' : '',
            justifyContent: size.isTab || size.isMobile ? 'center' : 'start',
            textAlign: size.isTab || size.isMobile ? 'center' : 'start',
            flexDirection: size.isMobile ? 'column' : 'row',
        }),
        Text: styled.div({
            fontSize: size.isTab || size.isMobile ? '2em' : type == 'page' ? '0.9em' : '1.35em',
            color: darkGray,
            textAlign: size.isTab || size.isMobile ? 'center' : 'start',
        }),
        TGIcon: styled.div({
            fontSize: '1.06em',
            color: staticColor.telegramColor,
        }),
        Top: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
        }),
        Bottom: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
        }),

        CardSettings: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: '0.5em',
            justifyContent:
                size.isTab || size.isMobile ? 'center' : type == 'page' ? 'center' : 'end',
            fontSize: size.isMicroMobile ? '1.3em' : size.isTab || size.isMobile ? '2em' : '',
        }),
        CardSettingsText: styled.div({
            fontSize: '1.125em',
            span: {
                display: 'flex',
                gap: '0.5em',
                color: buttonBlue,
                alignItems: 'center',
            },
        }),
        CardList: styled.div({
            paddingTop: '2.1875em',
            display: 'flex',
            justifyContent: size.isTab ? 'space-around' : 'space-between',
            flexDirection: size.isMobile ? 'column' : 'row',
            flexWrap: size.isMobile ? 'nowrap' : size.isTab ? 'wrap' : 'nowrap',
            rowGap: type == 'page' ? '3em' : size.isMobile || size.isTab ? '2em' : '',
            height: 'fit-content',
        }),
        CardSettingsDis: styled(motion.div)({
            fontSize: '0.775em',
            padding: '0.42em',
            borderRadius: '12px',
            border: `1px solid ${buttonBlue}`,
        }),
        Annotation: styled.div({
            marginTop: '1em',
            color: main__previewBackground,
            fontSize: '0.9rem',
            textAlign: 'center',
            fontWeight: 300,
        }),

        PopapMainContainer: styled.div({
            display: 'flex',
            gap: '1em',
            flexDirection: 'column',
        }),
        PopapMainLine: styled.div({
            display: 'flex',
            alignItems: 'center',
            gap: '1em',
        }),
        AcceptBox: styled.div({}),
        AcceptLink: styled(NavLink)({
            color: buttonBlue,
        }),
        size,
    };
};
