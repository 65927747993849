import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import confirmChangeEmail from '@maket/js/api/auth/confirmChangeEmail';

export default () => {
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state;

    useEffect(() => {
        if (state && state.code) {
            confirmChangeEmail({ code: state.code }).then(rez => {
                if (rez == 'error') {
                    navigate('/app/auth/reg', { state: { confirm: 'confirmChangeEmailErr' } });
                }
                if (rez == 'ok') {
                    navigate('/app/auth/auth', {
                        state: { confirm: 'confirmChangeEmailErrSuccess' },
                    });
                }
            });
        }
    });
    return <></>;
};
