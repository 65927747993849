import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import useTheme from '@js/hooks/useTheme';

export default () => {
    const size = useWindowSizeCheck();
    const { main__previewBackground, whiteText, buttonBlue } = useTheme();
    return {
        Container: styled.div({
            display: 'flex',
            flexDirection: 'column',
            gap: size.isMobile || size.isTab ? '12em' : '6em',
            fontFamily: 'Inter, sans-serif',
            backgroundColor: main__previewBackground,
            color: whiteText,
            position: 'relative',

            fontSize: size.isLaptop
                ? '0.8em'
                : size.isTab
                ? '0.6em'
                : size.isMobile
                ? '0.535em'
                : '',
        }),
        Blur: styled.div({
            position: 'absolute',
            top: 0,
            left: '50%',
            width: '100%',
            aspectRatio: 1,
            transform:
                size.isMobile || size.isTab ? 'translate(-50%, -95%)' : 'translate(-50%, -97%)',
            background: buttonBlue,
            borderRadius: '50%',
            filter: size.isMobile || size.isTab ? 'blur(100px)' : 'blur(150px)',
        }),
        size,
    };
};
