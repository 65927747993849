import { apiUrl, paymentsRedirectUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { TappDispatch } from '@maket/js/state/store';
import { TserviseNames } from '@maket/js/types/state/serviseList';
import axios from 'axios';

type Tprops = {
    token: string;
    serviceType: TserviseNames;
    dispatch: TappDispatch;
    cardKey: string;
    period: 'month' | 'year';
};

export default async ({ token, serviceType, dispatch, cardKey, period }: Tprops) => {
    setVisableLoader('visable');
    const req = await axios.post<{ data: { redirect_url: string }; status: string; error: string }>(
        apiUrl + '/service/subscribe',
        {
            service_type: serviceType,
            tariff_key: cardKey,
            period,
            success_url: paymentsRedirectUrl,
            cancel_url: paymentsRedirectUrl,
        },
        {
            headers: { Authorization: `Bearer ${token}` },
        },
    );
    setVisableLoader('notVisable');
    if (req.data.data.redirect_url) {
        window.open(req.data.data.redirect_url, '_self');
    }
    return req;
};

// https://dev.mazekeeper.com/
// http://localhost:3000/
