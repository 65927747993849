import React from 'react';
import style from './style';
import useAppSelector from '@js/hooks/useAppSelector';

type Tprops = { elLangKey: string };

export default ({ elLangKey }: Tprops) => {
    const { SelectElementCheckMark } = style();
    const language = useAppSelector(state => state.languageState.language);
    return (
        <SelectElementCheckMark
            animate={{
                opacity: language == elLangKey ? 1 : 0,
            }}
            className="icon-checkMark"
        ></SelectElementCheckMark>
    );
};
