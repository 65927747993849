import React, { useEffect, useRef } from 'react';
import style from './style';
import Button from '@maket/js/components/microComponets/button';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { setInputPhotoSettingsValue } from '@maket/js/state/settingAccount/settingAccountValueState';

let photoloadLocal = '';

export default () => {
    const dispatch = useAppDispatch();
    const IconRef = useRef<HTMLDivElement>(null);
    const ContainerRef = useRef<HTMLDivElement>(null);
    const {
        AuthSettingContainer,
        AuthSettingMain,
        AuthSettingTitle,
        AuthSettingText,
        Icon,
        IconBox,
        size,
    } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const picture = useAppSelector(state => state.authState.account?.picture);
    const photoLoad = useAppSelector(state => state.settingAccountValueState.photo);

    useEffect(() => {
        if (photoLoad) {
            if (ContainerRef.current && IconRef.current) {
                const img = IconRef.current.querySelector('img');
                img?.setAttribute('src', photoloadLocal);
                img?.setAttribute('style', `width: 100%; height: 100%`);
            }
        } else if (picture) {
            if (ContainerRef.current && IconRef.current) {
                const img = IconRef.current.querySelector('img');
                img?.setAttribute('src', picture);
                img?.setAttribute('style', `width: 100%; height: 100%`);
            }
        } else if (ContainerRef.current && IconRef.current) {
            const heigthContainer = ContainerRef.current.scrollHeight;
            IconRef.current.setAttribute(
                'style',
                `
					width: ${heigthContainer}px;
					min-width: ${heigthContainer}px;
					height: ${heigthContainer}px;
					min-height: ${heigthContainer}px;
			`,
            );
        }
    });

    return (
        <AuthSettingContainer ref={ContainerRef}>
            <IconBox ref={IconRef}>
                <Icon src="../img/icon/uploadSimple.svg" style={{ width: '50%', height: '50%' }} />
            </IconBox>
            <AuthSettingMain>
                <AuthSettingTitle>{activeLanguage['MyData.Avatar.Title']}</AuthSettingTitle>
                <AuthSettingText>{activeLanguage['MyData.Avatar.Text']}</AuthSettingText>
                <Button style={{ width: size.isMobile ? '100%' : '' }} typeButt="black">
                    {activeLanguage['MyData.Avatar.ButtonText']}
                    <input
                        onChange={e => {
                            if (e.target.files) {
                                dispatch(setInputPhotoSettingsValue(e.target.files[0]));
                                var reader = new FileReader();
                                reader.onload = function (e) {
                                    if (IconRef.current) {
                                        const img = IconRef.current.querySelector('img');
                                        photoloadLocal = e.target.result;
                                        img?.setAttribute('src', e.target.result);
                                        img?.setAttribute('style', `width: '100%'; height: '100%'`);
                                    }
                                };
                                reader.readAsDataURL(e.target.files[0]);
                            }
                        }}
                        type="file"
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            top: 0,
                            left: 0,
                            opacity: 0,
                        }}
                    />
                </Button>
            </AuthSettingMain>
        </AuthSettingContainer>
    );
};
