import Popap from '@maket/js/components/middleComponents/popap';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { TappDispatch } from '@maket/js/state/store';
import { setPopapStateSub } from '@maket/js/state/tariffSwith';
import React, { useState } from 'react';
import style from './style';
import Switch from '@maket/js/components/microComponets/switch';
import postConnectTariff from '@maket/js/api/requiresToken/payments/postConnectTariff';
import postSubscribeToNewsletter from '@maket/js/api/requiresToken/account/postSubscribeToNewsletter';
import postAgreeTermsOfService from '@maket/js/api/requiresToken/account/postAgreeTermsOfService';
import getTarifState from '@maket/js/api/requiresToken/payments/getTarifState';
import { TalertState } from '@maket/js/components/microComponets/buttonAllert';

export default () => {
    const dispatch = useAppDispatch();

    const popapState = useAppSelector(state => state.tariffState.popapStateSub);
    const key = useAppSelector(state => state.tariffState.activeKey);
    const token = useAppSelector(state => state.tokenState.token);
    const tariffState = useAppSelector(state => state.tariffState.state);

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const [switchActive, setSwitchActive] = useState(true);

    return (
        <Popap
            setState={v => dispatch(setPopapStateSub(v))}
            state={popapState}
            closeFn={() => {
                localStorage.removeItem('MZPreRegTariff');
            }}
            setFunction={() => {
                postSubscribeToNewsletter({ token, dispatch, enable: switchActive });
                postAgreeTermsOfService({ token, dispatch });
                clickButton(dispatch, token, key, tariffState);
            }}
            closeNone
            title={activeLanguage['Main.Price.PopapTitle']}
            main={<PopapMain setSwitchActive={setSwitchActive} switchActive={switchActive} />}
            buttonText={activeLanguage['Agree.']}
        />
    );
};

function PopapMain({
    setSwitchActive,
    switchActive,
}: {
    switchActive: boolean;
    setSwitchActive: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { PopapMainContainer, PopapMainLine, AcceptBox, AcceptLink } = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const splitContent = activeLanguage['Main.Price.PopapTermsText']?.split('/') || [];
    let language = useAppSelector(state => state.languageState.language);

    return (
        <PopapMainContainer>
            <PopapMainLine>{activeLanguage['Main.Price.PopapAlertText']}</PopapMainLine>
            <PopapMainLine>
                <Switch initState={switchActive} setState={v => setSwitchActive(v)} />
                {activeLanguage['Main.Price.PopapNewsText']}
            </PopapMainLine>
            <PopapMainLine>
                <AcceptBox>
                    {splitContent[0]}
                    {/* <div dangerouslySetInnerHTML={{ __html: split }}></div> */}
                    <br />
                    <AcceptLink to={'/app/termsOfUse'}>{splitContent[1]}</AcceptLink>
                    <br />
                    <AcceptLink to={'/app/cookiePolicy'}>{splitContent[2]}</AcceptLink>
                    <br />
                    <AcceptLink to={'/app/privacyPolicy'}>{splitContent[3]}</AcceptLink>
                </AcceptBox>
            </PopapMainLine>
        </PopapMainContainer>
    );
}

export function clickButton(
    dispatch: TappDispatch,
    token: string,
    cardKey: string,
    tariffState: boolean,
    callbackSetAllertState?: (v: TalertState) => void,
) {
    getTarifState({ dispatch, token, serviceType: 'telegram', notDispatching: true }).then(rez => {
        if (rez?.switch_to_tariff == null) {
            postConnectTariff({
                dispatch,
                token,
                cardKey,
                serviceType: 'telegram',
                period: tariffState ? 'year' : 'month',
            })
                .then(res => {
                    if (callbackSetAllertState) {
                        if (res && res.data && res.data.error) {
                            const err = res.data.error;
                            if (err.includes('tariff_not_found')) {
                                callbackSetAllertState({
                                    textAllert: 'TARIFF_NOT_FOUND',
                                    typeAllert: 'danger',
                                });
                            }
                            if (err.includes('subscription_not_found')) {
                                callbackSetAllertState({
                                    textAllert: 'SUBSCRIPTION_NOT_FOUND',
                                    typeAllert: 'danger',
                                });
                            }
                            if (err.includes('prev_migration_in_process')) {
                                callbackSetAllertState({
                                    textAllert: 'PREV_MIGRATION_IN_PROCESS',
                                    typeAllert: 'danger',
                                });
                            }
                        }
                    }
                    getTarifState({ dispatch, token, serviceType: 'telegram' });
                })
                .catch(err => {});
        }
    });
}
