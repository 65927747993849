import React from 'react';
import style from './style';
import { tNameSetting } from '../box';
import ButtonSetting from './button';
import { AnimatePresence } from 'framer-motion';
import useAppSelector from '@js/hooks/useAppSelector';
import { setActivePopap } from '@js/state/openPopap/openPopapState';
import useAppDispatch from '@js/hooks/useAppDispatch';
import VerticalSlider from './verticalSlider';
import MobilePopap from './popap';

type Tprops = {
    active: tNameSetting;
    setActive: React.Dispatch<React.SetStateAction<tNameSetting>>;
};

export default ({ active, setActive }: Tprops) => {
    const { MobileContainer } = style();
    const dispatch = useAppDispatch();

    return (
        <MobileContainer
            onClick={() => {
                dispatch(setActivePopap('mobileButtonHeaderSettings'));
            }}
            data-popap="true"
        >
            <VerticalSlider active={active} />
            <ButtonSetting key={'Telegram12'} type={'Telegram'} activeName={active} disabled />

            <AnimatePresence>
                <MobilePopap setActive={setActive} active={active} />
            </AnimatePresence>
        </MobileContainer>
    );
};
