import styled from '@emotion/styled';
import useWindowSizeCheck from '@hooks/useWindowSizeCheck';
import { motion } from 'framer-motion';

export default () => {
    const size = useWindowSizeCheck();
    return {
        ButtonSetting: styled.div({
            display: 'flex',
            gap: '0.94em',
            alignItems: 'center',
            position: 'relative',
            lineHeight: '155%',
            fontSize: '1.0625em',
            color: '#E4E4E7',
            transition: '0.3s',
            fontWeight: 600,
            padding: '11px 0',
            cursor: 'pointer',

            pointerEvents: 'none', // убрать когда будут доступны версии кроме telegram
        }),
        ButtonSettingIcon: styled(motion.div)({
            color: '#E4E4E7',
            fontSize: '1.5em',
            transition: '0.3s',
            transform: 'translate(0, 9%)',
        }),
        ButtonSettingText: styled(motion.div)({}),
        BottomLine: styled(motion.div)({
            position: 'absolute',
            bottom: 0,
            left: '50%',
            height: 2,
            transform: 'translate(-50%)',
            width: 'calc(100% - 0.5em)',
            backgroundColor: '#2E343A',
            borderRadius: 1,
            opacity: 0,
        }),
        size,
    };
};
